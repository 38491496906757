import React, {useEffect} from 'react'
import clsx from 'clsx'
import '../../css/style.css';
import 'react-toastify/dist/ReactToastify.css';
import {Provider, useDispatch, useSelector} from 'react-redux'
import {BrowserRouter, BrowserRouter as Router, Redirect, Route, Switch, useLocation} from 'react-router-dom'
import {PersistGate} from 'redux-persist/integration/react'
import {persistor, store} from '../../configureStore'
import Intl from '../../provider/intl'
import {getLocale} from '../../utils/utils'
import routes from '../../routes'
import {useRoutesByUserPermissions} from '../../utils/hooks'
import useStyles from './style'
import UI from '../../layouts/UI'
import {initializeGtm, logPageView} from '../../utils/GTM'
import Tour from '../../layouts/Tour'
import {hasLoadedCompanyInformationsSelector,} from "../Negotiation/Finance/selectors/companyInformations";
import {getCompanyInformations} from "../Negotiation/Finance/services/company";
import {appIdSelector, userSelectedSelector, userUnitiesSelector} from "../Login/selectors/user";
import {ToastContainer} from "react-toastify";
import updateSiteColors from "../../utils/updateSiteColors";
import {RecoilRoot} from "recoil";
import {createTheme} from "../../theme";
import {SettingsConsumer, SettingsProvider} from "../../contexts/settings";
import moment from "moment-timezone";
import {useMounted} from '../../hooks/use-mounted';
import PrivateRoute from "../../components/PrivateRoute";
import {CssBaseline, ThemeProvider} from "@mui/material";
import RecoilNexus from "recoil-nexus";
import useAuth from "../../hooks/use-auth";
import AuthMiddleware from "../../middlewares/AuthMiddleware";
import StatusPage from "../../pages/StatusPage/StatusPage";

moment.tz.setDefault("America/Sao_Paulo");

initializeGtm()

const Routes = () => {
  const routes = useRoutesByUserPermissions();
  return routes.map(({exact, Component, path, id}) => <Route key={id} exact={exact} component={Component}
                                                             path={path}/>);
};

function Dashboard() {
  const classes = useStyles();
  return (
    <div>
      <div className={clsx(classes.content)}>
        <Routes/>
      </div>
    </div>
  );
}

const UsePageViews = ({appconfig}) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const appId = useSelector(appIdSelector);
  const unities = useSelector(userUnitiesSelector);
  const hasLoadedCompany = useSelector(hasLoadedCompanyInformationsSelector);
  const isMobile = window.navigator.userAgent.toLowerCase().includes("mobi");
  const isMounted = useMounted();
  const {isAuthenticated} = useAuth();

  React.useEffect(() => {
    if (isAuthenticated && unities && unities.length > 0 && !location.pathname.includes('login') && !hasLoadedCompany) {
      dispatch(getCompanyInformations(appId, unities[unities.length - 1]));
    }
  }, [unities, location, appconfig, isMobile, isAuthenticated]);

  React.useEffect(() => {
    if (isMounted()) {
      logPageView();
    }
  }, [isMounted, appconfig, location])

  return (
    <Switch>
      {routes
        .filter((route) => route.public)
        .map(({exact, Component, path, id}) => <Route key={id} exact={exact} component={Component} path={path}/>)}
      <PrivateRoute component={Dashboard}/>

      <Route
        exact
        path="/status/:code"
        component={StatusPage}
      />
      <Redirect to="/status/404"/>
    </Switch>
  )
};

const Content = () => {
  const appconfig = useSelector(userSelectedSelector);

  useEffect(() => {
    updateSiteColors();
  }, []);

  return (
    <SettingsProvider>
      <SettingsConsumer>
        {(settings) => {
          const theme = createTheme({
            colorPreset: settings.colorPreset,
            contrast: settings.contrast,
            direction: settings.direction,
            paletteMode: settings.paletteMode,
            responsiveFontSizes: settings.responsiveFontSizes
          });

          return (
            <React.Fragment>
              <ToastContainer/>

              <ThemeProvider theme={theme}>
                <CssBaseline/>
                <Intl locale={getLocale()}>
                  <UI/>
                  <Tour/>
                  <Router>
                    <UsePageViews appconfig={appconfig}/>
                  </Router>
                </Intl>
              </ThemeProvider>
            </React.Fragment>
          )
        }}
      </SettingsConsumer>
    </SettingsProvider>
  );
};

const App = () => (
  <BrowserRouter>
    <RecoilRoot>
      <RecoilNexus/>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <AuthMiddleware>
            <Content/>
          </AuthMiddleware>
        </PersistGate>
      </Provider>
    </RecoilRoot>
  </BrowserRouter>
);

export default App;
