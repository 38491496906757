import React from 'react';
import {useHistory} from 'react-router-dom';
import Button from "@mui/material/Button";
import {Scrollbar} from "../../components/scrollbar";
import useMediaQuery from "@mui/material/useMediaQuery";
import {Theme} from "@mui/material/styles/createTheme";
import {Layout as DashboardLayout} from "../../layouts/dashboard";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import InicioTimeline from "../../components/timeline/inicio-timeline";
import {PATH_ROUTE} from "../../routes";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import {slideDirectionState} from "../../recoil/atoms/slideDirectionState";
import {useRecoilValue, useSetRecoilState} from "recoil";
import Slide from "@mui/material/Slide";
import {matriculaTypeState} from "../../recoil/atoms/matriculaTypeState";
import matriculasSelector from "../../recoil/selectors/matriculasSelector";
import tipoMatricula from "../../utils/tipoMatricula";
import { setRecoil } from 'recoil-nexus';

const Inicio: React.FC = () => {
  const history = useHistory();
  const smUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const slideDirection = useRecoilValue(slideDirectionState);
  const setSlideDirection = useSetRecoilState(slideDirectionState);
  const matriculaType = useRecoilValue(matriculaTypeState);
  const matriculas = useRecoilValue(matriculasSelector);
  const hasPreReservaSemPagamento = matriculas.some(x => x.tipoMatricula_Value === tipoMatricula.reservaSemValor) && matriculas.length === 1;
  if (hasPreReservaSemPagamento)
    setRecoil(matriculaTypeState, 'reserva');
  
  return (
    <DashboardLayout backgroundColor="white">
      <Scrollbar>
        <Slide in direction={slideDirection}>
          <Card
            elevation={0}
          >
            <Container
              maxWidth="lg"
              sx={{
                pb: 8,
              }}
            >
              <Scrollbar>
                <Box>
                  <Stack
                    direction="column"
                    sx={{
                      mt: 4,
                      position: 'relative',
                      zIndex: 1,
                    }}
                  >
                    <Typography
                      variant="h5"
                      component="h1"
                      sx={{
                        mb: 1,
                        width: '100%',
                        minWidth: '200px',
                        maxWidth: '620px',
                      }}
                    >
                      {(matriculaType === 'matricula' || matriculaType === null)
                        && 'Bem-vindo ao Nosso Portal de Matrículas e Serviços!'}
                      {(matriculaType === 'reserva')
                        && 'Bem-vindo ao Nosso Portal de Reserva de Vagas!'}
                    </Typography>

                    <Box
                      component="img"
                      src="/img/logo-md.webp"
                      sx={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        height: 'auto',
                        width: '100%',
                        maxWidth: '282px',
                        pb: 2,
                        display: mdUp ? 'block' : 'none',
                        opacity: 0.4,
                        zIndex: -1,
                      }}
                    />
                  </Stack>

                  <InicioTimeline/>

                  <Stack
                    direction="row"
                    alignItems="flex-start"
                    justifyContent="flex-end"
                    flexWrap="wrap"
                    spacing={8}
                    sx={{
                      mt: 2,
                      mb: 2,
                    }}
                  >
                    <Button
                      variant="contained"
                      onClick={() => {
                        history.push(PATH_ROUTE.INFORME_SEUS_DADOS)
                        setSlideDirection("left")
                      }}
                      fullWidth={!smUp}
                      sx={{
                        mt: 1
                      }}
                      endIcon={(
                        <svg className="seb seb-arrow-right" width="24" height="24" viewBox="0 0 24 24"
                             fill="currentColor"
                             xmlns="http://www.w3.org/2000/svg">
                          <path d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z"/>
                        </svg>
                      )}
                    >
                      Certo podemos Avançar
                    </Button>
                  </Stack>
                </Box>
              </Scrollbar>
            </Container>
          </Card>
        </Slide>
      </Scrollbar>
    </DashboardLayout>
  )
}

export default Inicio;
