import React, {Fragment} from 'react'
import useStyles from './style'
import {Grid} from "@mui/material";
import Typography from "@mui/material/Typography";

export default function RecurrencePaymentDialogSummary({ items, payments }) {
  const classes = useStyles()

  return (
    <React.Fragment>
      <Grid container spacing={2} className={classes.paymentDetail}>
        <PaymentDetail
          payments={payments}
          classes={classes}
        />
      </Grid>
    </React.Fragment>
  )
}

export const PaymentDetail = ({ payments, receipt }) => {
  const classes = useStyles()
  return (
    <Grid item xs={12} sm={8} >
      <Typography variant='h6' gutterBottom className={classes.title}>
        Dados do Cartão
      </Typography>
      <Grid container className={classes.details}>
        {receipt && receipt.authorizationcode &&
          <Fragment>
            <Grid item xs={6}>
              <Typography variant='subtitle1' className={classes.detail} >Número da transação:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant='subtitle1' >{receipt.authorizationcode}</Typography>
            </Grid>
          </Fragment>
        }
        {receipt && receipt.nsu && <Fragment>
          <Grid item xs={6}>
            <Typography variant='subtitle1' className={classes.detail} >NSU:</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant='subtitle1' >{receipt.nsu}</Typography>
          </Grid>
        </Fragment>
        }
        <Grid item xs={6}>
          <Typography variant='subtitle1' className={classes.detail} >Nome do titular:</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant='subtitle1' className={classes.cardName}>{payments.cardName}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant='subtitle1' className={classes.detail} >Número do cartão:</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant='subtitle1' >{payments.bin.slice(0, 4)} {payments.bin.slice(4, payments.bin.length)}** **** ****</Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}

