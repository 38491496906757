import { selector } from "recoil";
import parcelaRegularSelecionadaSelector from "./carrinho/servicoRegular/parcelaRegularSelecionadaSelector";
import parcelasObrigatoriosSelecionadasSelector from "./carrinho/servicosObrigatorios/parcelasObrigatoriosSelecionadasSelector";
import parcelasUpSellingSelecionadasSelector from "./carrinho/servicosUpSelling/parcelasUpSellingSelecionadasSelector";

const possuiRecorrenteSelector = selector({
  key: 'possuiRecorrenteSelector',
  get: ({get}) => {
    const parcelaRegularSelecionada = get(parcelaRegularSelecionadaSelector);
    const parcelasObrigatoriosSelecionadas = get(parcelasObrigatoriosSelecionadasSelector);
    const parcelasUpSellingSelecionadas = get(parcelasUpSellingSelecionadasSelector);

    const regularPossuiRecorrente = parcelaRegularSelecionada?.fullAvista === false || parcelaRegularSelecionada?.totalParcela !> 1;
    const obrigatoriosPossuiRecorrente = Object.values(parcelasObrigatoriosSelecionadas).some(parcela => parcela?.totalParcela !> 1 || parcela?.fullAvista === false);
    const upSellingPossuiRecorrente = Object.values(parcelasUpSellingSelecionadas).some(parcela => parcela?.totalParcela !> 1 || parcela?.fullAvista === false);

    return regularPossuiRecorrente || obrigatoriosPossuiRecorrente || upSellingPossuiRecorrente;
  },
});

export default possuiRecorrenteSelector

