import {makeStyles} from "@mui/styles";

export const useStyles = makeStyles(theme => ({
  relativeBox: {
    position: 'relative',
    minWidth: 150,
    width: 150,
    zIndex: 1000,
    minHeight: '70vh',
    height: 'auto'
  },
  desktopBox: {
    minWidth: 'min-content',
    width: 'min-content',
    display: 'flex',
    minHeight: '70vh',
    height: '100%'
  },
  absoluteBox: {
    position: 'absolute',
    height: '100%',
  },
  maxHeight: {
    height: '100%'
  },
  box: {
    paddingLeft: '1.5rem',
    marginTop: '1.5rem',
    maxWidth: 350,
    transition: 'width 1s ease-in-out',
    width: '100%',
    height: '100%',
    padding: 1,
    '&:hover': {
      maxWidth: 'none',
      width: 'min-content'
    }
  },
  card: {
    padding: '0.5rem',
    width: '100%',
    height: '100%'
  },
  list: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: "background.paper"
  },
  textListItem: {
    textWrap: 'nowrap',
  }
}))
