import React, {Fragment, useState} from "react";
import {useSelector, useDispatch} from "react-redux";
import {some, isNil, every} from "lodash";
import classNames from "classnames";
import PaymentForm from "../../../../../components/PaymentForm";
import OrderSummary, {PaymentDetail} from "../OrderSummary";
import useStyles from "./style";
import {
  sendPaymentCredit,
  sendPaymentDebit,
} from "../../../../actions";
import {
  paymentDataSelector,
  paymentSelector,
  isLoadingPaymentSelector,
} from "../../../../selectors/payments";
import {InstallmentStatus} from "../../../../../../../utils/InstallmentStatus";
import {setMessageSnack} from "../../../../../../../layouts/actions";
import {injectIntl} from "react-intl";
import {userCpfCnpjSelector, cpfCnpjSelector} from "../../../../../../Login/selectors/user";
import {DialogContent, Grid} from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";

export const CARD_TYPES = {
  CREDIT: "credit",
  DEBIT: "debit",
};

const steps = [
  {
    label: "Pagamento",
    id: "lbl-mdl-pag-pag",
  },
  {
    label: "Revisão",
    isVoltar: true,
    isConfirmar: true,
    id: "lbl-mdl-pag-rev",
  },
  {
    label: "Confirmação",
    isFechar: true,
    isSmallModal: true,
    id: "lbl-mdl-pag-cfm",
  },
];

export default injectIntl(({intl: {messages}, items, handleClose}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [activeStep, setActiveStep] = useState(0);

  const total = items
    ? items.reduce((prev, curr) => prev + Number(curr.correctvalue), 0)
    : 0;
  const installment =
    items && some(items, ["enables_installment_payment", false])
      ? undefined
      : 0;
  const totalDiscount = items
    ? items.reduce(
      (prev, curr) =>
        Number(prev) + Number(curr.correctvalue - curr.documentvalue),
      0
    )
    : 0;

  const [isFirstMessage, setFirstMessage] = useState(true);

  const {message, receipt, waitingRede, debit} = useSelector(
    paymentDataSelector
  );

  const {loading, error} = useSelector(paymentSelector);

  const cpfcnpj = useSelector(userCpfCnpjSelector);
  const cpfCnpjAdmin = useSelector(cpfCnpjSelector);

  const [payment, setPayment] = useState({
    cardName: "",
    cardId: "",
    numberToken: "",
    bin: "",
    expiryDateMonth: "",
    expiryDateYear: "",
    installment,
    total,
    totalDiscount,
    isAgreement: false,
    cardType: "credit",
    items,
    isNegotiation: false,
    cpf: cpfcnpj,
  });

  if (isFirstMessage && items && items.length > 1) {
    setFirstMessage(false);
    if (
      isNil(installment) &&
      some(items, ["enables_installment_payment", true])
    ) {
      dispatch(
        setMessageSnack(messages["payment-installments-message-unavailable"])
      );
    } else if (every(items, ["enables_installment_payment", true])) {
      dispatch(setMessageSnack(messages["payment-installments-message"]));
    }
  }

  React.useEffect(() => {
    const filterAvailable = items.filter(
      (item) => item.paymentavailability === InstallmentStatus.AVAILABLE
    );
    if (!filterAvailable && filterAvailable.length === 0) {
      handleClose();
    }
  }, [handleClose, items]);

  const finishPayment = () => {
    dispatch(
      payment.cardType === CARD_TYPES.CREDIT
        ? sendPaymentCredit(items, payment)
        : sendPaymentDebit(items, payment, cpfCnpjAdmin)
    );
    handleNext();
  };

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const getStepContent = () => {
    switch (activeStep) {
      case 0:
        return (
          <PaymentForm
            payment={payment}
            setPayment={setPayment}
            handleNext={handleNext}
            isLoadingSelector={isLoadingPaymentSelector}
          />
        );
      case 1:
        return <OrderSummary items={items} payments={payment}/>;
      default:
        return (
          <Success
            error={error}
            message={message}
            classes={classes}
            receipt={receipt}
            payment={payment}
          />
        );
    }
  };

  const controllActiveStep =
    activeStep !== 2 || loading || (activeStep === 2 && error) ? activeStep : 3;
  return (
    <DialogContent dividers>
      <Grid
        container
        justifyContent="space-between"
        direction="row"
        alignItems="center"
        className={classes.content}
        sx={{
          width: "100%",
        }}
      >
        <Grid item xs={12}>
          <Stepper
            activeStep={controllActiveStep}
            className={classes.stepper}
            alternativeLabel
          >
            {steps.map((step) => (
              <Step key={step.label}>
                <StepLabel id={step.id}>{step.label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Grid>
        {loading ? (
          <LoadingCheckout waitingRede={waitingRede} debit={debit}/>
        ) : (
          <Fragment>
            <Grid item xs={12}>
              {getStepContent()}
            </Grid>
            <Buttons
              activeStep={activeStep}
              error={error}
              handleBack={handleBack}
              finishPayment={finishPayment}
              handleClose={handleClose}
            />
          </Fragment>
        )}
      </Grid>
    </DialogContent>
  );
});

const Buttons = ({
                   activeStep,
                   error,
                   handleBack,
                   finishPayment,
                   handleClose,
                 }) => {
  const classes = useStyles();
  return (
    <Grid item xs={12} className={classes.buttons}>
      {(steps[activeStep].isVoltar ||
        (error && steps[activeStep].isFechar)) && (
        <Button
          id="btn-mdl-pag-vlt"
          onClick={handleBack}
          className={classes.button}
        >
          Voltar
        </Button>
      )}
      {steps[activeStep].isConfirmar && (
        <Button
          id="btn-mdl-pag-cfm"
          variant="contained"
          color="primary"
          onClick={finishPayment}
          className={classes.button}
        >
          Confirmar
        </Button>
      )}
      {steps[activeStep].isFechar && (
        <Button
          id="btn-mdl-pag-fch"
          variant="contained"
          color="primary"
          onClick={handleClose}
          className={classes.button}
        >
          Fechar
        </Button>
      )}
    </Grid>
  );
};
const Success = ({message, receipt, payment, error}) => {
  const classes = useStyles();

  let messages = [];

  if (message) {
    messages = message.split("|");
  }

  return (
    <React.Fragment>
      <Box justifyContent="center">
        <Grid container className={classes.loading} alignContent="center">
          <Grid item xs={12} align="center">
            <Typography
              className={classNames(error && classes.error)}
              variant="h6"
              gutterBottom
              color="primary"
            >
              {error ? "Pagamento não realizado!" : "Obrigado! "}
            </Typography>
            <Grid item xs={12} align="center">
              {messages.map((m, i) => {
                return (
                  <Typography variant="subtitle1" key={i}>
                    {m}
                  </Typography>
                );
              })}
            </Grid>
          </Grid>
          {receipt && receipt.authorizationcode && (
            <Grid item xs={12} align="center">
              <PaymentDetail payments={payment} receipt={receipt}/>
            </Grid>
          )}
        </Grid>
      </Box>
    </React.Fragment>
  );
};

const LoadingCheckout = ({waitingRede, debit}) => {
  const classes = useStyles();
  const isProcessingDebit = debit.success && !debit.concluded;
  return (
    <React.Fragment>
      <Grid container className={classes.loading}>
        <Grid item xs={12} align="center">
          <Typography variant="h5" gutterBottom>
            {isProcessingDebit
              ? "Aguardando confirmação de pagamento da rede..."
              : "Aguarde, enviando pagamento..."}
          </Typography>
        </Grid>
        <Grid item xs={12} align="center">
          <Typography variant="subtitle1">
            {isProcessingDebit &&
              "Clique no botão abaixo e informe o código da rede para prosseguir com o pagamento."}
          </Typography>
        </Grid>
        {isProcessingDebit && (
          <Grid item xs={12} align="center">
            <Button
              id="rede"
              variant="contained"
              color="primary"
              target="_blank"
              className={classes.buttonRede}
              disabled={waitingRede}
            >
              {waitingRede ? <CircularProgress size={20}/> : "Clique aqui!"}
            </Button>
          </Grid>
        )}
        {!isProcessingDebit && (
          <Grid item xs={12} align="center">
            <CircularProgress/>
          </Grid>
        )}
      </Grid>
    </React.Fragment>
  );
};
