import { pipe, prop } from "ramda";
import { isEmpty } from "lodash";
import {
  getAPIDataSelector,
  hasErrorAPISelector,
  hasLoadedAPISelector,
  isLoadingAPISelector,
} from "../../../../utils/API/selectors";
import { createSelector } from "reselect";

const baseFinanceSelector = prop("finance");

export const informationRecurrentCardSelector = pipe(
  baseFinanceSelector,
  prop("informationRecurrentCard")
);

export const informationRecurrentCardDataSelector = getAPIDataSelector(
  informationRecurrentCardSelector
);

export const hasLoadedInformationRecurrentCardSelector = hasLoadedAPISelector(
  informationRecurrentCardSelector
);

export const hasErrorInformationRecurrentCardSelector = hasErrorAPISelector(
  informationRecurrentCardSelector
);

export const isLoadingInformationRecurrentCardSelector = isLoadingAPISelector(
  informationRecurrentCardSelector
);

export const isMockRegistration = createSelector(
  hasLoadedInformationRecurrentCardSelector,
  hasErrorInformationRecurrentCardSelector,
  informationRecurrentCardDataSelector,
  (hasLoaded, hasError, data) => (hasLoaded && isEmpty(data)) || hasError
);

export const isFetchRegistration = createSelector(
  hasLoadedInformationRecurrentCardSelector,
  isLoadingInformationRecurrentCardSelector,
  hasErrorInformationRecurrentCardSelector,
  informationRecurrentCardDataSelector,
  (hasLoaded, isLoading, hasError, data) =>
    isEmpty(data) && !hasLoaded && !isLoading && !hasError
);
