import {CardContent, Grid, Skeleton} from "@mui/material";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import React from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import {Theme} from "@mui/material/styles/createTheme";

export default function HomeCardSkeleton() {
  const smUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));

  return (
    <Card
      sx={{
        maxWidth: smUp ? 300 : "100%",
        width: "100%",
        flex: 1,
        minWidth: 240,
        height: "fit-content"
      }}
      elevation={0}
    >
      <Skeleton variant="rounded" height={140}/>
      <CardContent
        sx={{
          p: 1,
        }}
      >
        <Typography
          gutterBottom
          component="div"
          fontSize="medium"
          fontWeight="bold"
          color="primary"
          align="center"
        >
          <Skeleton/>
        </Typography>

        <Typography
          gutterBottom
          component="div"
          fontSize="small"
          fontWeight="bold"
          align="center"
        >
          <Skeleton/>
        </Typography>

        <Stack
          justifyContent="center"
          direction="row"
          sx={{
            pb: 1
          }}
        >
          <Skeleton
            variant="rounded"
            width="100%"
            height={40}
          />
        </Stack>

        <Grid
          container
          spacing={2}
          sx={{
            mt: 'auto'
          }}
        >
          <Grid item xs={6}>
            <Skeleton
              variant="rounded"
              width="100%"
              height={40}
            />
          </Grid>
          <Grid item xs={6}>
            <Skeleton
              variant="rounded"
              width="100%"
              height={40}
            />
          </Grid>
        </Grid>

        <Stack
          justifyContent="center"
          direction="row"
          sx={{
            pt: 2
          }}
        >
          <Skeleton
            variant="rounded"
            width="100%"
            height={40}
          />
        </Stack>
      </CardContent>
    </Card>
  )
}
