import React, {useState} from "react";
import PaymentIcon from "../PaymentIcon/PaymentIcon";
import classNames from "../../utils/classNames";
import * as _walletService from "../../service/wallet-service";
import {useRecoilState, useRecoilValue, useSetRecoilState} from "recoil";
import {creditCardsState} from "../../recoil/atoms/creditCardsState";
import {useCardVerification} from "../../hooks/useCardVerification";
import * as mensagem from '../../components/shared/Message.js';
import {walletShowChangeSubsState} from "../../recoil/atoms/walletShowChangeSubsState";
import {walletCardChangeSubsState} from "../../recoil/atoms/walletCardChangeSubsState";
import {walletCardSubsState} from "../../recoil/atoms/walletCardSubsState";
import {walletCardReloadState} from "../../recoil/atoms/walletCardReloadState";
import Button from "@mui/material/Button";
import {LoadingButton} from "@mui/lab";
import TextField from "@mui/material/TextField";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import {selectedMatriculaState} from "../../recoil/atoms/selectedMatriculaState";
import {selectedUnidadeState} from "../../recoil/atoms/selectedUnidadeState";

const CardSelection = (props) => {
  const {
    onClick,
    onClickChange,
    card: {
      cardId,
      lastFourDigits = '',
      expirationYear = '',
      expirationMonth = '',
      cardholderName = '',
      brand = '',
      numberToken = null,
      isExpired = false,
      canRemove = false
    },
    buttonLabel = 'Selecionar',
    type = 'card',
    selected = false,
    showButton = true,
    showRemove = true,
  } = props;
  const [creditCards, setCreditCards] = useRecoilState(creditCardsState);
  const cardNumber = `**** **** **** ${lastFourDigits}`;
  const [isDeleting, setIsDeleting] = useState(false);
  const [isLoadingSubs, setIsLoadingSubs] = useState(false);
  const [showVerification, setShowVerification] = useState(false);
  const [subs, setSubs] = useState([]);
  const hasSubs = subs.length > 0;
  const [operation, setSetOperation] = useState("");
  const setShowChangeSubs = useSetRecoilState(walletShowChangeSubsState);
  const setCardChangeSubs = useSetRecoilState(walletCardChangeSubsState);
  const selectedMatricula = useRecoilValue(selectedMatriculaState);
  const unidade = useRecoilValue(selectedUnidadeState);
  const setCardSubs = useSetRecoilState(walletCardSubsState);
  const setReload = useSetRecoilState(walletCardReloadState);
  const operations = {
    delete: "delete",
    select: "select",
    changeSubsCard: "changeSubsCard",
  }
  const {
    cvv,
    setCvv,
    isVerifying,
    handleVerifyCard,
  } = useCardVerification({
    cardId,
    handleCardSelection: onClick
  })

  const handleCardSelection = (card) => {
    if (onClick)
      onClick(card);
  }

  const handleCardExclusion = () => {
    if (isDeleting) return;

    setIsDeleting(true);

    const unidadeId = selectedMatricula?.unidade_Id ?? unidade?.id;

    _walletService.cartaoApagar(
      unidadeId,
      cardId ?? ""
    ).then((resp) => {
      if (resp.ok) {
        let cards = creditCards.filter(c => c.cardId !== cardId);
        setCreditCards(cards);
        setReload(true)
        mensagem.ExibeMensagem(`Cartão final ${lastFourDigits} removido!`, "0")
      } else {
        mensagem.ExibeMensagem(`Falha ao remover o cartão final ${lastFourDigits}.`, "2")
      }
    }).catch(() => {
      mensagem.ExibeMensagem(`Falha ao remover o cartão final ${lastFourDigits}.`, "2")
    }).finally(() => setIsDeleting(false))
  }

  const handleCancelVerification = () => {
    setShowVerification(false);
    setCvv("");
  }

  const handleConfirm = () => {
    switch (operation) {
      case operations.delete:
        handleCardExclusion()
        break;
      case operations.select:
        handleVerifyCard();
        break;
      default:
        console.log("operação não implementada")
    }
    setShowVerification(false);
    setCvv("");
  }

  const handleOperation = op => () => {
    setSetOperation(op)

    if (op === operations.select && onClickChange) {
      onClickChange();
      return;
    }

    if (op === operations.delete) {
      handleLoadSubscriptions()
      return;
    }

    if (op === operations.changeSubsCard) {
      setShowChangeSubs(true);
      setCardSubs(subs);
      setCardChangeSubs(props.card)
      return;
    }

    if (null == numberToken) //Solicita verificação do cartão para recuperar o numberToken
    {
      setShowVerification(true);
    } else if (op === operations.select) {
      handleCardSelection(props.card)
    }
  }

  const handleLoadSubscriptions = () => {
    if (isLoadingSubs) return;

    setIsLoadingSubs(true);

    const unidadeId = selectedMatricula?.unidade_Id ?? unidade?.id

    _walletService.cartaoAssinaturas(
      unidadeId,
      cardId ?? ""
    ).then(async (resp) => {
      if (resp.ok) {
        const json = await resp.json();

        if (json.length) {
          mensagem.ExibeMensagem(`O cartão possui recorrência ativa. Altere antes de remover.`, "1");
          setSubs(json);
        } else {
          setShowVerification(true);
        }
        return;
      }
      mensagem.ExibeMensagem(`Falha ao carregar assinaturas.`, "2")
    }).catch(() => {
      mensagem.ExibeMensagem(`Falha ao carregar assinaturas.`, "2")
    }).finally(() => setIsLoadingSubs(false))
  }

  const handleChangeCVV = (event) => {
    const {value} = event.target;
    if (value.length > 4) {
      event.target.value = value.slice(0, 4);
      return;
    }
    setCvv(value)
  }

  const renderConfirmForm = () => {
    return (
      <form
        onSubmit={handleConfirm}
      >
        <div className='smo-wallet-card-selection-actions-confirmation-container'>
          <div className='smo-wallet-card-selection-actions-confirmation-container-text'>
            {operation !== operations.delete &&
              <>
                <div className="input-group">
                  <TextField
                    type="number"
                    label={operation === operations.delete ? "Confirme a exclusão" : "Confirme o CVV"}
                    error={cvv.length < 3 || cvv.length > 4}
                    id="securityCode"
                    name="securityCode"
                    autoComplete="cc-csc"
                    size="small"
                    value={cvv}
                    onChange={handleChangeCVV}
                    required
                    InputProps={{
                      endAdornment: (
                        <svg className="seb seb-credit" width="47" height="30" viewBox="0 0 47 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g filter="url(#filter0_d)">
                            <path
                              d="M2 3C2 1.89543 2.89543 1 4 1H43C44.1046 1 45 1.89543 45 3V25C45 26.1046 44.1046 27 43 27H4C2.89543 27 2 26.1046 2 25V3Z"
                              fill="#EBEFF5"/>
                          </g>
                          <path fillRule="evenodd" clipRule="evenodd"
                                d="M4 0H43C44.6569 0 46 1.34315 46 3V25C46 26.6569 44.6569 28 43 28H4C2.34315 28 1 26.6569 1 25V3C1 1.34315 2.34315 0 4 0ZM4 1C2.89543 1 2 1.89543 2 3V25C2 26.1046 2.89543 27 4 27H43C44.1046 27 45 26.1046 45 25V3C45 1.89543 44.1046 1 43 1H4Z"
                                fill="#B8C6D9"/>
                          <path d="M2 6H45V10H2V6Z" fill="#B8C6D9"/>
                          <path d="M5 13H28V19H5V13Z" fill="white"/>
                          <path d="M31 13H42V19H31V13Z" fill="white"/>
                          <path fillRule="evenodd" clipRule="evenodd"
                                d="M29.5 13C29.5 12.1716 30.1716 11.5 31 11.5H42C42.8284 11.5 43.5 12.1716 43.5 13V19C43.5 19.8284 42.8284 20.5 42 20.5H31C30.1716 20.5 29.5 19.8284 29.5 19V13ZM31 12.5C30.7239 12.5 30.5 12.7239 30.5 13V19C30.5 19.2761 30.7239 19.5 31 19.5H42C42.2761 19.5 42.5 19.2761 42.5 19V13C42.5 12.7239 42.2761 12.5 42 12.5H31Z"
                                fill="#D8247F"/>
                          <path d="M7 15H8V17H7V15Z" fill="#738399"/>
                          <path d="M33 15H34V17H33V15Z" fill="#738399"/>
                          <path d="M36 15H37V17H36V15Z" fill="#738399"/>
                          <path d="M39 15H40V17H39V15Z" fill="#738399"/>
                          <path d="M10 15H11V17H10V15Z" fill="#738399"/>
                          <path d="M13 15H14V17H13V15Z" fill="#738399"/>
                          <path d="M16 15H17V17H16V15Z" fill="#738399"/>
                          <path d="M19 15H20V17H19V15Z" fill="#738399"/>
                          <path d="M22 15H23V17H22V15Z" fill="#738399"/>
                          <path d="M25 15H26V17H25V15Z" fill="#738399"/>
                          <defs>
                            <filter id="filter0_d" x="0" y="0" width="47" height="30" filterUnits="userSpaceOnUse"
                                    color-interpolation-filters="sRGB">
                              <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                              <feColorMatrix in="SourceAlpha" type="matrix"
                                             values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                              <feOffset dy="1"/>
                              <feGaussianBlur stdDeviation="1"/>
                              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"/>
                              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
                            </filter>
                          </defs>
                        </svg>

                      )
                    }}
                  />
                </div>
              </>
            }
          </div>

          <div className='smo-wallet-card-selection-actions-confirmation-container-actions'>
            <Button
              variant="contained"
              color="success"
              size="small"
              className="smo-wallet-card-selection-button"
              type="submit"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width={24}
                height={24}
                fill="currentColor"
              >
                <title>confirm</title>
                <path d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/>
              </svg>
            </Button>

            <Button
              variant="contained"
              color="error"
              size="small"
              className="smo-wallet-card-selection-button"
              onClick={handleCancelVerification}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width={24}
                height={24}
                fill="currentColor"
              >
                <title>cancel</title>
                <path
                  d="M12 2C17.5 2 22 6.5 22 12S17.5 22 12 22 2 17.5 2 12 6.5 2 12 2M12 4C10.1 4 8.4 4.6 7.1 5.7L18.3 16.9C19.3 15.5 20 13.8 20 12C20 7.6 16.4 4 12 4M16.9 18.3L5.7 7.1C4.6 8.4 4 10.1 4 12C4 16.4 7.6 20 12 20C13.9 20 15.6 19.4 16.9 18.3Z"/>
              </svg>
            </Button>
          </div>
        </div>
      </form>
    )
  }

  return (
    <div className={classNames(
      "smo-wallet-card-selection",
      selected && "smo-wallet-card-selection-selected",
    )}>
      {numberToken && !isExpired &&
        <Chip className="smo-wallet-card-selection-verified-badge" color="success" size="small" label="verificado"/>}
      {isExpired &&
        <Chip className="badge badge-danger smo-wallet-card-selection-verified-badge" color="error" size="small"
              label="expirado"/>}
      {hasSubs && !isExpired &&
        <Chip className="badge badge-warning smo-wallet-card-selection-verified-badge" color="warning" size="small" label={`recorrência${subs.length > 1 ? "s" : ""} ativa${subs.length > 1 ? "s" : ""}: ${subs.length}`}/>}
      <div
        className={classNames(
          "smo-wallet-card-selection-container",
          type === 'row' && "smo-wallet-card-selection-container-row"
        )}
      >
        <div className="smo-wallet-card-selection-row-card-info-container">
          <div className="smo-wallet-card-selection-row-card-info-brand">
            <div className="smo-wallet-card-selection-avatar">
              <PaymentIcon
                id={brand}
                className='payment-icon'
              />
            </div>
          </div>

          <div className="smo-wallet-card-selection-row-card-info">
            <Typography
              fontWeight="bold"
            >
              {cardNumber}
            </Typography>
            <Typography className="smo-wallet-card-selection-row-card-info-brand">
              {brand}
            </Typography>
          </div>

          <div className="smo-wallet-card-selection-row-card-info">
            <Typography
              fontWeight="bold"
            >
              Validade
            </Typography>
            <Typography className="smo-wallet-card-selection-row-card-info-brand">
              {expirationMonth}/{expirationYear}
            </Typography>
          </div>

          <div className="smo-wallet-card-selection-row-card-info">
            <Typography
              fontWeight="bold"
            >
              Titular
            </Typography>
            <Typography className="smo-wallet-card-selection-row-card-info-brand">
              {cardholderName}
            </Typography>
          </div>
        </div>

        <div className="smo-wallet-card-selection-actions">
          {showVerification ? renderConfirmForm() : (
            <>
              {!isExpired && (onClick || onClickChange) &&
                <LoadingButton
                  variant="contained"
                  className="smo-wallet-card-selection-button"
                  onClick={handleOperation(operations.select)}
                  disabled={!!(!showButton || isDeleting || isVerifying || isExpired || isLoadingSubs)}
                  loading={isVerifying}
                >
                  {buttonLabel}
                </LoadingButton>
              }

              {(showRemove && canRemove && !hasSubs) &&
                <LoadingButton
                  className="smo-wallet-card-selection-button"
                  variant="text"
                  color="error"
                  onClick={handleOperation(operations.delete)}
                  disabled={!!(!showButton || isDeleting | isLoadingSubs)}
                  loading={isDeleting || isLoadingSubs}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width={32}
                    height={32}
                    fill="currentColor"
                  >
                    <path d="M19,4H15.5L14.5,3H9.5L8.5,4H5V6H19M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19Z"/>
                  </svg>
                </LoadingButton>
              }

              {(showRemove && (canRemove || isExpired) && hasSubs) &&
                <Button
                  color="warning"
                  className="smo-wallet-card-selection-button"
                  onClick={handleOperation(operations.changeSubsCard)}
                  disabled={!showButton}
                >
                  Alterar recorrência{subs.length > 1 && "s"}
                </Button>
              }
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default CardSelection;
