import React, {useCallback} from 'react';
import ReactTooltip from "react-tooltip";
import Typography from "@mui/material/Typography";

function Version({color = "black"}) {
  const formatedTimestampDate = useCallback(
    () => {
      const timestamp = window.appVersionTimestamp;
      return new Date(timestamp).toLocaleDateString('pt-BR', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
      });
    },
    [],
  )();


  return (
    <React.Fragment>
      <ReactTooltip
        place="top"
        type="dark"
        effect="float"
        className="tooltip"
      />
      <Typography
        variant="caption"
        sx={{
          textAlign: "center",
          margin: '0 auto',
          color: color,
        }}
        data-tip={`Versão ${window.appVersion} - ${formatedTimestampDate}`}
      >
        v{window.appVersion}
      </Typography>
    </React.Fragment>
  );
}

export default Version;
