import React from 'react';
import History from "../../modules/Negotiation/History";
import Container from "@mui/material/Container";
import {useTheme} from "@mui/material/styles";
import {AcordoLayout} from "../../layouts/AcordoLayout";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import {CardContent} from "@mui/material";

function HomePortal(props) {
  const theme = useTheme();
  return (
    <AcordoLayout>
      <Container>
        <Card
          className="home-wallet-container-main-card"
          sx={{marginTop: theme.spacing(3)}}
        >
          <CardHeader
            title="Histórico de Acordos Financeiros"
            subheader="Aqui você pode visualizar o histórico de acordos financeiros realizados com a escola."
          />
          <Divider/>

          <CardContent>
            <History
              {...props}
            />
          </CardContent>
        </Card>
      </Container>
    </AcordoLayout>
  );
}

export default HomePortal;
