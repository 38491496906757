import React from 'react';
import {Container} from "react-bootstrap";
import "./styles.css";
import HomeIcon from "../../components/Icons/HomeIcon";
import {Link} from "react-router-dom";

function StatusPage(
  {
    match: {
      params: {
        code
      }
    }
  }
) {
  const getErrorMessage = () => {
    switch (code) {
      case "404":
        return "Página não encontrada";
      case "500":
        return "Erro interno do servidor";
      default:
        return "Erro desconhecido";
    }
  }

  return (
    <Container className="smo-status-page-container">
      <h1>
        {code} | {getErrorMessage()}
      </h1>

      <Link to="/" className="btn-primary medium">
        <HomeIcon/> Voltar para a página inicial
      </Link>
    </Container>
  );
}

export default StatusPage;