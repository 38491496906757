import {selector} from "recoil";
import matriculasSelector from "./matriculasSelector";

const mapProp = (list: any[], prop: string) => {
  const arr: string[] = [];
  (list || []).forEach(a => {
    const i = arr.indexOf(a[prop]);
    if (i !== -1)
      arr[i] = a[prop]
    else
      arr.push(a[prop]);
  })
  return arr
}


const homeCardFiltersSelectors = selector({
  key: 'homeCardFiltersSelectors',
  get: ({get}) => {
    const matriculas = get(matriculasSelector) || [] as any[];
    const ids = mapProp(matriculas, "estudante_Id")
    const estudanteName: any = [];

    ids.forEach(id => estudanteName.push((matriculas || []).find(a => a.estudante_Id === id)?.estudante_Name ?? ""))

    //Remove duplicates
    estudanteName.filter((item: string, index: number) => estudanteName.indexOf(item) === index);

    return {
      segmento_Name: mapProp(matriculas, "segmento_Name"),
      anoLetivo_Name: mapProp(matriculas, "anoLetivo_Name"),
      ids,
      estudanteName,
      serviceStatus: mapProp(matriculas, "razaoStatus_Value"),
      servicoTipoItem: mapProp(matriculas, "servicoTipoItem"),
    }
  },
});

export default homeCardFiltersSelectors;
