import {Fragment, useState} from "react";
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  Collapse,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useMediaQuery
} from "@mui/material";
import {useTheme} from '@mui/material/styles';
import {ExpandLess, ExpandMore} from "@mui/icons-material";
import {useFinancialSection} from "../../layouts/dashboard/config"
import {useStyles} from "./styles";
import {Theme} from "@mui/material/styles/createTheme";
import {RouterLink} from "../router-link";

const SideBar = () => {
  const [isHover, setIsHover] = useState(false)
  const sections = useFinancialSection();
  const initialOpenSections = sections.reduce((acc, _, index) => {
    acc[index] = true;
    return acc;
  }, {});
  const [openSections, setOpenSections] = useState(initialOpenSections);
  const theme = useTheme();
  const mobileMode = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const classes = useStyles();

  const handleClick = (index) => {
    setOpenSections((prevOpenSections) => ({
      ...prevOpenSections,
      [index]: !prevOpenSections[index]
    }));
  };

  const handleMouseEnter = () => {
    setIsHover(true)
  }

  const handleMouseLeave = () => {
    setIsHover(false)
  }
  if (!sections)
    return null

  if (mobileMode) {
    return null
  }

  const renderSection = (section, index) => {
    const isSelected = window.location.pathname === section.path;
    if (section.items) {
      const isChildSelected = section.items.some((item) => window.location.pathname === item.path);
      return (
        <Fragment key={index}>
          <ButtonGroup
            variant="contained"
            sx={{
              width: '100%',
              '& .MuiBox-root': {
                width: 'inherit'
              }
            }}
          >
            <ListItemButton
              sx={{
                pl: 2,
                backgroundColor: isSelected ? theme.palette.action.disabledBackground : 'transparent'
              }}
              component={RouterLink}
              to={section.path}
            >
              <ListItemIcon>
                {section.icon}
              </ListItemIcon>
              <ListItemText className={classes.textListItem} primary={section.title}/>
            </ListItemButton>
            <Button
              size="small"
              onClick={() => handleClick(index)}
              sx={{
                backgroundColor: isSelected ? theme.palette.action.disabledBackground : 'transparent',
                color: 'unset',
                '&:hover': {
                  backgroundColor: 'rgba(17, 25, 39, 0.04) !important',
                  borderColor: theme.palette.action.hover + ' !important',
                  outline: 'none !important',
                  color: 'unset !important',
                }
              }}
            >
              {openSections[index] ? <ExpandLess/> : <ExpandMore/>}
            </Button>
          </ButtonGroup>
          <Collapse in={openSections[index]} timeout="auto" unmountOnExit>
            <List
              component="div"
              disablePadding
              sx={{
                pl: 3
              }}
            >
              {section.items.map((sectionItem, itemIndex) => (
                <ListItemButton
                  key={itemIndex}
                  sx={{
                    pl: 2,
                    backgroundColor: isChildSelected && sectionItem.path === window.location.pathname ? theme.palette.action.disabledBackground : 'transparent',
                  }}
                  component={RouterLink}
                  to={sectionItem.path}
                >
                  <ListItemIcon>
                    {sectionItem.icon}
                  </ListItemIcon>
                  <ListItemText className={classes.textListItem} primary={sectionItem.title}/>
                </ListItemButton>
              ))}
            </List>
          </Collapse>
          <Divider/>
        </Fragment>
      )
    } else {
      return (
        <Fragment key={index}>
          {/* @ts-ignore */}
          <ListItemButton
            sx={{
              pl: 2,
              backgroundColor: isSelected ? theme.palette.action.disabledBackground : 'transparent',
            }}
            component={RouterLink}
            to={section.path}
          >
            <ListItemIcon>
              {section.icon}
            </ListItemIcon>
            <ListItemText className={classes.textListItem} primary={section.title}/>
          </ListItemButton>
          <Divider
            sx={{
              borderBottomWidth: 1,
            }}
          />
        </Fragment>
      )
    }
  }

  return (
    <Box className={classes.desktopBox}>
      <Box
        className={classes.maxHeight}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Box className={classes.box}>
          <Card
            elevation={0}
            className={classes.card}
          >
            <List
              className={classes.list}
              component="nav"
            >
              {sections.map((section, index) => renderSection(section, index))}
            </List>
          </Card>
        </Box>
      </Box>
    </Box>
  )
};

export default SideBar;
