import React from 'react';
import "./styles.css";
import Alert from '@mui/material/Alert';
function AlertCard(
  {
    children,
    iconPosition = "left",
    sx = {}
  }
) {
  return (
    <Alert
      severity="warning"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: iconPosition === "left" ? "row" : "row-reverse",
        gap: "1rem",
        padding: "1rem",
        borderRadius: "1rem",
        color: "#F79009",
        border: "1px solid #F79009",
        boxShadow: "0px 4px 4px #f7900963",
        fontSize: ".8rem",
        ...sx
      }}
    >
      {children}
    </Alert>
  );
}

export default AlertCard;
