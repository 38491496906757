import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import BannerNotificationContainer from './components/BannerNotificationContainer'
import StudentCard from './components/StudentCard'
import ServiceList from './components/ServiceList'
import {accessIdSelector, appIdSelector} from 'modules/Login/selectors/user'
import * as mensagem from '../../components/shared/Message.js'
import {useRecoilValue} from 'recoil'
import {avatarsState} from '../../recoil/atoms/avatarsState'
import {fetchRegistrationServices} from './services/registrationServices'
import {registrationServicesSelector} from './selectors/registrationServices'
import {razaoStatus} from '../../utils/razaoStatus'
import {useTheme} from '@mui/material/styles'
import {mdiBarcode} from '@mdi/js'
import Icon from '@mdi/react'
import {Box, Button, Card, CardContent, Divider, Skeleton, Stack, Typography, useMediaQuery} from '@mui/material'
import {FormattedMessage} from 'react-intl'
import {tokens} from '../../locales/tokens'
import {useTranslation} from '../../hooks/use-translation'
import matriculasSelector from "../../recoil/selectors/matriculasSelector";
import { Modal } from '../../components/modal'
import ModalBoleto from '../../components/ModalBoleto/ModalBoleto'

const defaultState = {
  alunos: [],
  boletos: [],
  exibeModalBoleto: false,
  urlBoleto: ""
}

const Home = props => {
  const [loading, setLoading] = useState(false)
  const [selectedStudent, setSelectedStudent] = useState(null)
  const [alunosMatriculados, setAlunosMatriculados] = useState([])
  const avatars = useRecoilValue(avatarsState)
  const theme = useTheme()
  const appId = useSelector(appIdSelector)
  const accessId = useSelector(accessIdSelector)
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'))
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const matriculas = useRecoilValue(matriculasSelector)
  const [state, setState] = useState(defaultState);

  useEffect(() => {
    GetbyIdResponsavel()
  }, [])

  const { data, loaded } = useSelector(registrationServicesSelector)
  const dataGrouped =
    data &&
    Array.isArray(data) &&
    data.reduce((acc, item) => {
      const existingItem = acc.find(i => i.itemid === item.itemid && i.matriculaid === item.matriculaid)
      if (existingItem) {
        existingItem.servicos.push(item)
      } else {
        acc.push({
          itemid: item.itemid,
          servicos: [item],
          matriculaid: item.matriculaid
        })
      }
      return acc
    }, [])

  const filteredServices = dataGrouped
    ? dataGrouped.filter(
        servico => servico.matriculaid === selectedStudent?.codigoMatriculaErp
      )
    : []

  const isMatriculaVigente = matricula => {
    const { razaoStatus_Value, anoLetivo_Name } = matricula
    const anoAtual = new Date().getFullYear()
    const isCancelada = razaoStatus_Value === razaoStatus.zero_12_cancelada
    const isAnoVigente = parseInt(anoLetivo_Name) >= anoAtual
    return !isCancelada && isAnoVigente
  }

  const GetbyIdResponsavel = async () => {
    setLoading(true)
    const listMatriculas = matriculas
    if (listMatriculas.length > 0) {
      const matriculasFiltradas = listMatriculas.filter(isMatriculaVigente)
      setAlunosMatriculados(matriculasFiltradas)
      setSelectedStudent(matriculasFiltradas[0])
      dispatch(fetchRegistrationServices(appId, accessId))
    } else {
      mensagem.ExibeMensagem(
        'não há matrículas cadastradas para este responsável',
        '3',
        true
      )
    }
    setLoading(false)
  }

  
  const btnClickAbrirModalBoleto = (
    {
      boletosDaMatricula = [],
      urlBoletoReserva,
      urlBoleto,
      codigobarrasBoletoReserva,
      codigobarrasBoleto,
      valorEntradaBoleto,
      valorEntradaBoletoReserva,
      dataVencimentoBoleto,
      dataVencimentoBoletoReserva
    }
  ) => {
    setState({
      ...state,
      exibeModalBoleto: true,
      boletos: boletosDaMatricula,
      urlBoleto: urlBoletoReserva || urlBoleto,
      codigoBarrasBoleto: codigobarrasBoletoReserva || codigobarrasBoleto,
      valorEntradaBoleto: valorEntradaBoletoReserva || valorEntradaBoleto,
      dataVencimentoBoleto: dataVencimentoBoletoReserva || dataVencimentoBoleto,
    });
  }

  const btnClickFecharModalBoleto = () => {
    setState({
      ...state,
      exibeModalBoleto: false,
      urlBoleto: ""
    });
  }
  const {
    exibeModalBoleto,
    boletos,
    urlBoleto,
    codigoBarrasBoleto,
    valorEntradaBoleto,
    dataVencimentoBoleto,
  } = state;

  if (!data || data.length === 0) {
    return (
      <Box component='main' sx={{ marginTop: theme.spacing(3) }}>
        <Typography gutterBottom component='p' color={'textPrimary'}>
          <FormattedMessage id='students-register' />
        </Typography>
      </Box>
    )
  }
  return (
    <Box component='main' sx={{ marginTop: theme.spacing(3) }}>
      <Modal
        open={exibeModalBoleto}
        onClose={btnClickFecharModalBoleto}
        title="Boleto(s)"
      >
        <ModalBoleto
          boletos={boletos}
          ticketUrl={urlBoleto}
          ticketCode={codigoBarrasBoleto}
          ticketExpiration={dataVencimentoBoleto}
          ticketValue={valorEntradaBoleto}
        />
      </Modal>
      <Card>
        <Stack
          sx={{
            paddingTop: 4,
            paddingLeft: 3,
            paddingRight: 3,
            paddingBottom: 2
          }}
        >
          {loading ? (
            <Skeleton variant='text' width='50%' height={40} />
          ) : alunosMatriculados.length > 0 ? (
            <Typography component='h5' variant='h5'>
              {t(tokens.common.portal) +
                ' ' +
                t(tokens.nav.financial) +
                ' > ' +
                t(tokens.common.selectStudent)}
            </Typography>
          ) : (
            <Typography component='h5' variant='h5'>
              {t(tokens.common.noValidEnrollments)}
            </Typography>
          )}
          {loading ? (
            <Skeleton variant='text' width='50%' height={40} />
          ) : (
            alunosMatriculados.length > 0 && (
              <Typography
                component={'h6'}
                variant={'h6'}
                color={'textSecondary'}
              >
                {t(tokens.common.displayFinancialServices)}
              </Typography>
            )
          )}
        </Stack>
        <Divider
          variant='middle'
          sx={{
            borderBottomWidth: 2,
            borderColor: '#c6bbbb !important'
          }}
        />
        <CardContent>
          {!loading && !loaded && (!filteredServices || filteredServices.length === 0) ? (
            <Typography
              variant='body2'
              sx={{
                textAlign: 'center',
                mt: 4
              }}
            >
              {t(tokens.common.noServicesContracted)}
            </Typography>
          ) : (
            <>
              <div>
                <BannerNotificationContainer />
              </div>
              <Stack
                direction='row'
                flexWrap='wrap'
                justifyContent='center'
                spacing={1}
                sx={{
                  maxWidth: 1200,
                  ml: 'auto',
                  mr: 'auto'
                }}
              >
                {loading
                  ? Array.from(new Array(3)).map((_, index) => (
                      <Skeleton
                        key={index}
                        variant='rectangular'
                        width='100%'
                        height={80}
                      />
                    ))
                  : alunosMatriculados.map((aluno, index) => (
                      <StudentCard
                        key={index}
                        data={aluno}
                        avatar={avatars[aluno.estudante_Id] ?? null}
                        service={
                          dataGrouped &&
                          dataGrouped.find(
                            servico =>
                              servico.matriculaid === aluno.codigoMatriculaErp
                          )?.servicos[0]
                        }
                        checked={selectedStudent?.id === aluno.id}
                        handleCheckboxChange={() => setSelectedStudent(aluno)}
                      />
                    ))}
              </Stack>
              <Divider
                sx={{
                  borderBottomWidth: 2,
                  borderColor: '#c6bbbb !important',
                  mt: 2
                }}
              />
              <Stack direction='column' spacing={2} sx={{ mt: 6 }}>
                <Stack 
                  direction='row' 
                  justifyContent='space-between' 
                  spacing={2} 
                  alignItems='center'
                  sx={isSmall ? {flexDirection: 'column'} : {}}
                >
                  <Typography variant='h5' color='primary' fullWidth>
                    {loading ? (
                      <Skeleton variant='text' width='70%' height={40} />
                    ) : (
                      <>
                        {t(tokens.common.contractedServicesAndProducts)} (
                        {
                          filteredServices.filter(
                            servico =>
                              servico.matriculaid ===
                              selectedStudent?.codigoMatriculaErp
                          ).length
                        }
                        )
                      </>
                    )}
                  </Typography>
                  {loading ? (
                      <Skeleton variant='text' width='30%' height={40} />
                    ) : (
                    selectedStudent.razaoStatus_Value === razaoStatus.ponto_2_reservada ? (
                      <Button
                        variant="contained"
                        color="primary"
                        sx={{
                          height: 40,
                          display: !(selectedStudent.urlBoletoReserva !== "" && selectedStudent.urlBoletoReserva != null) ? 'none' : undefined,
                          width: isSmall ? '100%' : 'auto'
                        }}
                        onClick={() => btnClickAbrirModalBoleto(selectedStudent)}
                        title={!selectedStudent.urlBoletoReserva ? "Boleto não disponível" : ""}
                        disabled={!(selectedStudent.urlBoletoReserva !== "" && selectedStudent.urlBoletoReserva != null)}
                        startIcon={(
                          (selectedStudent.urlBoletoReserva !== "" && selectedStudent.urlBoletoReserva != null) &&
                          <Icon path={mdiBarcode} size={1}/>
                        )}
                      >
                        {!(selectedStudent.urlBoletoReserva !== "" && selectedStudent.urlBoletoReserva != null) ? "" : "Imprimir Boleto"}
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        sx={{
                          height: 40,
                          display: !(selectedStudent.entradaPaga === false && (selectedStudent.urlBoleto !== "" && selectedStudent.urlBoleto != null && true || selectedStudent.boletosDaMatricula && selectedStudent.boletosDaMatricula.length > 0) && (selectedStudent.razaoStatus_Value === razaoStatus.zero_5_preMatriculado || selectedStudent.razaoStatus_Value === razaoStatus.zero_6_matriculado || selectedStudent.razaoStatus_Value === razaoStatus.ponto_2_reservada)) ? 'none' : undefined,
                          width: isSmall ? '100%' : 'auto'
                        }}
                        onClick={() => btnClickAbrirModalBoleto(selectedStudent)}
                        disabled={!(selectedStudent.entradaPaga === false && (selectedStudent.urlBoleto !== "" && selectedStudent.urlBoleto != null && true || selectedStudent.boletosDaMatricula && selectedStudent.boletosDaMatricula.length > 0) && (selectedStudent.razaoStatus_Value === razaoStatus.zero_5_preMatriculado || selectedStudent.razaoStatus_Value === razaoStatus.zero_6_matriculado || selectedStudent.razaoStatus_Value === razaoStatus.ponto_2_reservada))}
                        startIcon={(
                          (selectedStudent.entradaPaga === false && (selectedStudent.urlBoleto !== "" && selectedStudent.urlBoleto != null && true || selectedStudent.boletosDaMatricula && selectedStudent.boletosDaMatricula.length > 0) && (selectedStudent.razaoStatus_Value === razaoStatus.zero_5_preMatriculado || selectedStudent.razaoStatus_Value === razaoStatus.zero_6_matriculado || selectedStudent.razaoStatus_Value === razaoStatus.ponto_2_reservada)) &&
                          <Icon path={mdiBarcode} size={1}/>
                        )}
                      >
                        {!(selectedStudent.entradaPaga === false && (selectedStudent.urlBoleto !== "" && selectedStudent.urlBoleto != null && true || selectedStudent.boletosDaMatricula && selectedStudent.boletosDaMatricula.length > 0) && (selectedStudent.razaoStatus_Value === razaoStatus.zero_5_preMatriculado || selectedStudent.razaoStatus_Value === razaoStatus.zero_6_matriculado || selectedStudent.razaoStatus_Value === razaoStatus.ponto_2_reservada)) ? "" : "Imprimir Boleto"}
                      </Button>
                    ))}
                </Stack>

                <Typography variant='h6' fontWeight={500}>
                  {loading && !loaded ? (
                    <Skeleton variant='text' width='90%' height={25} />
                  ) : (
                    <>
                      {t(tokens.common.servicesContractedByStudent)}&nbsp;
                      <strong>{selectedStudent?.estudante_Name ?? ''}</strong>
                    </>
                  )}
                </Typography>
                <Divider
                  sx={{
                    borderBottomWidth: 2,
                    borderColor: '#c6bbbb !important',
                    mt: 1
                  }}
                />
              </Stack>
              <Stack spacing={2} sx={{ mt: 2 }}>
                {!loaded
                  ? Array.from(new Array(3)).map((_, index) => (
                      <Skeleton
                        key={index}
                        variant='rectangular'
                        width='100%'
                        height={80}
                      />
                    ))
                  : filteredServices
                      .filter(
                        servico =>
                          servico.matriculaid ===
                          selectedStudent?.codigoMatriculaErp
                      )
                      .map((s, index) => <ServiceList key={index} data={s} />)}
              </Stack>
            </>
          )}
        </CardContent>
        <Box sx={{marginBottom: 12}}></Box>
      </Card>
    </Box>
  )
}

export default Home
