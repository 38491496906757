import * as React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem, {timelineItemClasses} from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import Icon from '@mdi/react';
import {mdiNumeric1, mdiNumeric2, mdiNumeric3, mdiNumeric4, mdiNumeric5, mdiNumeric6} from '@mdi/js';
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import {Theme} from "@mui/material/styles/createTheme";
import {useRecoilValue} from "recoil";
import {matriculaTypeState} from "../../recoil/atoms/matriculaTypeState";

export default function InicioTimeline() {
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const matriculaType = useRecoilValue(matriculaTypeState);

  const getIcon = (index: number) => {
    const icons = {
      0: mdiNumeric1,
      1: mdiNumeric2,
      2: mdiNumeric3,
      3: mdiNumeric4,
      4: mdiNumeric5,
      5: mdiNumeric6,
    }

    return icons[index] || mdiNumeric1;
  }

  return (
    <>
      {(matriculaType === 'matricula' || matriculaType === null) && (
        <>
          <Typography
            variant="h6"
            fontWeight="normal"
            sx={{
              width: '100%',
              minWidth: '200px',
              maxWidth: !lgUp ? !mdUp ? '520px' : '520px' : '800px',
            }}
          >
            Antes de começar, vamos te orientar para tornar esse processo ainda mais fácil e rápido.
          </Typography>

          <Typography
            sx={{
              mb: 1,
              width: '100%',
              minWidth: '200px',
              maxWidth: !lgUp ? '720px' : '720px',
            }}
          >
            Para efetivar a matrícula, é necessário realizar o cadastro do responsável e do(s) estudante(s)
          </Typography>

          <Typography
            sx={{
              mb: 1,
              width: '100%',
              minWidth: '200px',
              maxWidth: !lgUp ? '720px' : '720px',
            }}
          >
            Tenha em mãos:
          </Typography>

          <Timeline
            sx={{
              mb: 2,
              pl: 1,
              [`& .${timelineItemClasses.root}:before`]: {
                flex: 0,
                padding: 0,
              },
            }}
          >
            <TimelineItem
              sx={{
                minHeight: 'auto',
              }}
            >
              <TimelineSeparator>
                <TimelineDot color="primary">
                  <Icon path={getIcon(0)} size={1}/>
                </TimelineDot>
              </TimelineSeparator>
              <TimelineContent
                sx={{
                  m: 'auto 0'
                }}
              >
                CPF ou CNH do responsável
              </TimelineContent>
            </TimelineItem>

            <TimelineItem
              sx={{
                minHeight: 'auto',
              }}
            >
              <TimelineSeparator>
                <TimelineDot color="primary">
                  <Icon path={getIcon(1)} size={1}/>
                </TimelineDot>
              </TimelineSeparator>
              <TimelineContent
                sx={{
                  m: 'auto 0'
                }}
              >
                RG ou Certidão de nascimento do(s) estudante(s) (documento válido para menores de 18 anos)
              </TimelineContent>
            </TimelineItem>
          </Timeline>

          <Typography
            sx={{
              mb: 2,
            }}
          >
            O que você vai encontrar no Nosso Portal:
          </Typography>

          <Timeline
            sx={{
              mb: 2,
              pl: 1,
              [`& .${timelineItemClasses.root}:before`]: {
                flex: 0,
                padding: 0,
              },
            }}
          >
            <TimelineItem
              sx={{
                minHeight: 'auto',
              }}
            >
              <TimelineSeparator>
                <TimelineDot color="primary">
                  <Icon path={getIcon(0)} size={1}/>
                </TimelineDot>
              </TimelineSeparator>
              <TimelineContent
                sx={{
                  m: 'auto 0'
                }}
              >
                <b>Novas Contratações</b>: Faça novas matrículas de forma simples e ágil.
              </TimelineContent>
            </TimelineItem>

            <TimelineItem
              sx={{
                minHeight: 'auto',
              }}
            >
              <TimelineSeparator>
                <TimelineDot color="primary">
                  <Icon path={getIcon(1)} size={1}/>
                </TimelineDot>
              </TimelineSeparator>
              <TimelineContent
                sx={{
                  m: 'auto 0'
                }}
              >
                <b>Histórico de Serviços</b>: Acompanhe todas as atividades e serviços utilizados.
              </TimelineContent>
            </TimelineItem>

            <TimelineItem
              sx={{
                minHeight: 'auto',
              }}
            >
              <TimelineSeparator>
                <TimelineDot color="primary">
                  <Icon path={getIcon(2)} size={1}/>
                </TimelineDot>
              </TimelineSeparator>
              <TimelineContent
                sx={{
                  m: 'auto 0'
                }}
              >
                <b>Portal Financeiro</b>: Gerencie suas finanças com facilidade.
              </TimelineContent>
            </TimelineItem>

            <TimelineItem
              sx={{
                minHeight: 'auto',
              }}
            >
              <TimelineSeparator>
                <TimelineDot color="primary">
                  <Icon path={getIcon(3)} size={1}/>
                </TimelineDot>
              </TimelineSeparator>
              <TimelineContent
                sx={{
                  m: 'auto 0'
                }}
              >
                <b>Métodos de Pagamento</b>: Escolha a forma de pagamento que melhor se adapta a você.
              </TimelineContent>
            </TimelineItem>

            <TimelineItem
              sx={{
                minHeight: 'auto',
              }}
            >
              <TimelineSeparator>
                <TimelineDot color="primary">
                  <Icon path={getIcon(4)} size={1}/>
                </TimelineDot>
              </TimelineSeparator>
              <TimelineContent
                sx={{
                  m: 'auto 0'
                }}
              >
                <b>Declarações</b>: Emita declarações sempre que precisar.
              </TimelineContent>
            </TimelineItem>

            <TimelineItem
              sx={{
                minHeight: 'auto',
              }}
            >
              <TimelineSeparator>
                <TimelineDot color="primary">
                  <Icon path={getIcon(5)} size={1}/>
                </TimelineDot>
              </TimelineSeparator>
              <TimelineContent
                sx={{
                  m: 'auto 0'
                }}
              >
                <b>Acordos</b>: Realize acordos financeiros de maneira prática.
              </TimelineContent>
            </TimelineItem>
          </Timeline>

          <Typography
            sx={{
              mb: 2,
            }}
          >
            Após concluir a matricula, você receberá um e-mail com todas as informações relacionadas a sua matricula.
          </Typography>

          <Typography>
            Aproveite a comodidade e a praticidade do nosso portal para garantir a melhor experiência na gestão
            educacional do seu filho. <br/>Estamos aqui para facilitar a sua vida!
          </Typography>
        </>
      )}

      {(matriculaType === 'reserva') && (
        <>
          <Typography
            sx={{
              mb: 2,
              width: '100%',
              minWidth: '200px',
              maxWidth: !lgUp ? '720px' : '800px',
            }}
          >
            Reservar uma vaga conosco garante a você:
          </Typography>

          <Timeline
            sx={{
              mb: 2,
              pl: 1,
              [`& .${timelineItemClasses.root}:before`]: {
                flex: 0,
                padding: 0,
              },
            }}
          >
            <TimelineItem
              sx={{
                minHeight: 'auto',
              }}
            >
              <TimelineSeparator>
                <TimelineDot color="primary">
                  <Icon path={getIcon(0)} size={1}/>
                </TimelineDot>
              </TimelineSeparator>
              <TimelineContent
                sx={{
                  m: 'auto 0'
                }}
              >
                Prioridade na matrícula
              </TimelineContent>
            </TimelineItem>

            <TimelineItem
              sx={{
                minHeight: 'auto',
              }}
            >
              <TimelineSeparator>
                <TimelineDot color="primary">
                  <Icon path={getIcon(1)} size={1}/>
                </TimelineDot>
              </TimelineSeparator>
              <TimelineContent
                sx={{
                  m: 'auto 0'
                }}
              >
                Comunicação rápida e eficaz
              </TimelineContent>
            </TimelineItem>

            <TimelineItem
              sx={{
                minHeight: 'auto',
              }}
            >
              <TimelineSeparator>
                <TimelineDot color="primary">
                  <Icon path={getIcon(2)} size={1}/>
                </TimelineDot>
              </TimelineSeparator>
              <TimelineContent
                sx={{
                  m: 'auto 0'
                }}
              >
                Tranquilidade e segurança
              </TimelineContent>
            </TimelineItem>
          </Timeline>

          <Typography
            sx={{
              mb: 2,
            }}
          >
            Você está iniciando o processo de reserva de vaga. Para facilitar, siga as instruções abaixo:
          </Typography>

          <Timeline
            sx={{
              mb: 2,
              pl: 1,
              [`& .${timelineItemClasses.root}:before`]: {
                flex: 0,
                padding: 0,
              },
            }}
          >
            <TimelineItem
              sx={{
                minHeight: 'auto',
              }}
            >
              <TimelineSeparator>
                <TimelineDot color="primary">
                  <Icon path={getIcon(0)} size={1}/>
                </TimelineDot>
              </TimelineSeparator>
              <TimelineContent
                sx={{
                  m: 'auto 0'
                }}
              >
                <b>Primeira Etapa</b>: Cadastre o responsável e o(s) estudante(s).
              </TimelineContent>
            </TimelineItem>

            <TimelineItem
              sx={{
                minHeight: 'auto',
              }}
            >
              <TimelineSeparator>
                <TimelineDot color="primary">
                  <Icon path={getIcon(1)} size={1}/>
                </TimelineDot>
              </TimelineSeparator>
              <TimelineContent
                sx={{
                  m: 'auto 0'
                }}
              >
                <b>Documentos Necessários</b>: Tenha em mãos o CPF ou CNH do responsável e o RG ou Certidão de nascimento do(s) estudante(s) (válido para menores de 18 anos).
              </TimelineContent>
            </TimelineItem>
          </Timeline>

          <Typography
            fontWeight="bold"
            sx={{
              mb: 2
            }}
          >
            Fique Atento!
          </Typography>

          <Typography>
             Assim que as novas contratações estiverem disponíveis, você receberá um e-mail de confirmação. <br/>Nossa equipe pode também entrar em contato diretamente para informar sobre a liberação e próximos passos
          </Typography>

          <Typography
            sx={{
              mb: 2
            }}
          >
            Estamos aqui para tornar este processo o mais simples e agradável possível.
          </Typography>

          <Typography
            fontWeight="bold"
          >
            Prepare-se para uma experiência educativa de excelência!
          </Typography>
        </>
      )}
    </>
  );
}
