import React from 'react'
import Dialog from "@mui/material/Dialog";
import CircularProgress from "@mui/material/CircularProgress";
import {useTheme} from "@mui/styles";

export default ({ loading }) => {
  const theme = useTheme();
  if(!loading) {
    return null
  }
  return (
    <Dialog
      open={true}
      onClose={() => {}}
      sx={{
        backgroundColor: 'initial',
        boxShadow: 'none',
        width: 150,
        height: 150,
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CircularProgress
        size={60}
        sx={{
          margin: theme.spacing(2),
        }}
      />
    </Dialog>
  )
}
