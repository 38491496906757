import {toast} from 'react-toastify'
import React from 'react';

var ultimaMsg = "";

const template = (titulo, texto, link, textoLink, tipo) => {
    return (
        <>
            {titulo != undefined && titulo != null && titulo != "" &&
                <div className="row">
                <div className={"col-md-12 toastMsg "+tipo}>
                    <h5><b>{titulo}</b></h5>
                    </div>
                </div>
            }
            <div className="row">
                <div className="col-md-1">
                </div>
                <div className="col-md-11">
                    {texto}
                </div>
            </div>
            {link != undefined && link != null && link != "" &&
                <>
                <br />
                <div className="row">
                    <div className="col-md-1">
                    </div>
                <div className="col-md-11">
                        <a href="#" onClick={event => onClickToastLink(link)}>{textoLink}</a>
                    </div>
                </div>
                </>
            }
        </>
    )
}

function onClickToastLink(link) {
    if (link.toString().indexOf("http://") != -1 || link.toString().indexOf("https://") != -1) {
        window.open(link, "_blank");
    } else {
        window.location.href = link;
    }
}

export function ExibeListMensagem(menssagens, autoClose = true, titulo = "") {
    if (menssagens != null && menssagens != undefined && menssagens.length > 0) {
        menssagens.map(mensagem => {
            let text = mensagem.text

            if (text === "Object reference not set to an instance of an object.") {
                text = "Erro inesperado. Por favor, tente novamente mais tarde."
            }

            ExibeMensagem(text, mensagem.typeMessage.toString(), autoClose, titulo);
        })
    }
}



export function ExibeMensagem(texto, tipo, autoClose = true, titulo = "", urlLink = "", textoLink = "", validaMsgRepetida = false) {


    if ((ultimaMsg == "" || ultimaMsg != texto) || validaMsgRepetida == false) {
        ultimaMsg = texto;

        var timeAutoClose = false;

        if (autoClose == true) {
            timeAutoClose = 10000;
        }


        if ((titulo != undefined && titulo != null && titulo != "") ||
            (urlLink != undefined && urlLink != null && urlLink != "")) {

            if (textoLink == undefined || textoLink == null || textoLink == "") {
                textoLink = "Clique Aqui";
            }

            var content = "";

            switch (tipo) {
                case '0' || 'success':
                    content = template(titulo, texto, urlLink, textoLink, 'toastMsgSuccess');
                    break;
                case '1' || 'warning':
                    content = template(titulo, texto, urlLink, textoLink, 'toastMsgWarning');
                    break;
                case '2' || 'error':
                    content = template(titulo, texto, urlLink, textoLink, 'toastMsgError');
                    break;
                default:
                    content = template(titulo, texto, urlLink, textoLink, 'toastMsgInfo');
                    break;
            }

            toast(content, { position: 'top-right', autoClose: timeAutoClose });

        } else {

            switch (tipo) {
                case '0' || 'success':
                    toast.success(texto, { position: 'top-right', autoClose: timeAutoClose });
                    break;
                case '1' || 'warning':
                    toast.warning(texto, { position: 'top-right', autoClose: timeAutoClose });
                    break;
                case '2' || 'error':
                    toast.error(texto, { position: 'top-right', autoClose: timeAutoClose });
                    break;
                default:
                    toast.info(texto, { position: 'top-right', autoClose: timeAutoClose });
                    break;
            }
        }
    }

}


