import React, {useEffect, useState} from "react";
import * as _calcService from "../../service/calc-service";
import {Layout as MomentoEscolarLayout} from "../../layouts/momento-escolar";
import StepCheckoutActionButtons from "../../components/StepCheckoutActionButtons/StepCheckoutActionButtons";
import {useRecoilValue} from "recoil";
import {selectedMatriculaState} from "../../recoil/atoms/selectedMatriculaState";
import ServicosComplementares from "../../components/ServicosComplementares/ServicosComplementares";
import ServicosObrigatorios from "../../components/ServicosObrigatorios/ServicosObrigatorios";
import ServicoRegular from "../../components/ServicoRegular/ServicoRegular";
import podeContratarServicoObrigatorioSelector from "../../recoil/selectors/podeContratarServicoObrigatorioSelector";
import Loading from "./loading";
import podeContratarServicoRegularSelector
  from "../../recoil/selectors/carrinho/servicoRegular/podeContratarServicoRegularSelector";
import podeContratarServicoComplementarSelector
  from "../../recoil/selectors/carrinho/servicosUpSelling/podeContratarServicoComplementarSelector";
import canFinishServicesSelector from "../../recoil/selectors/canFinishServicesSelector";

const MomentoEscolarServicos: React.FC = () => {
  const podeContratarServicoRegular = useRecoilValue(podeContratarServicoRegularSelector);
  const podeContratarServicoObrigatorio = useRecoilValue(podeContratarServicoObrigatorioSelector);
  const podeContratarServicoComplementar = useRecoilValue(podeContratarServicoComplementarSelector);
  const selectedMatricula = useRecoilValue(selectedMatriculaState);
  const [isLoaded, setIsLoaded] = useState(false);
  const canContinue = useRecoilValue(canFinishServicesSelector);

  useEffect(() => {
    const idMatricula = selectedMatricula?.id
    if (idMatricula && selectedMatricula?.servico_Id && selectedMatricula.id) {
      _calcService.listar({
        servicoId: selectedMatricula.servico_Id,
        matriculaId: selectedMatricula.id,
        saldoPago: 0
      }).then(() => setIsLoaded(true))
    }
  }, []);

  return (
    <MomentoEscolarLayout
      breads={[
        {to: "/CadastroConcluido", text: "Home"},
        {to: `/MomentoEscolar?idmatricula=${selectedMatricula?.id}`, text: "Momento Escolar"},
        {text: "Momento Escolar Serviços"}
      ]}
      loading={!isLoaded}
      skeleton={<Loading/>}
      actions={
        <StepCheckoutActionButtons
          previousStepPath={"/MomentoEscolar?idmatricula=" + selectedMatricula?.id}
          nextStepPath="/MomentoEscolarPagamento"
          nextStepDisabled={!canContinue || !isLoaded}
          showCart
        />
      }
      fullScreenPaper
    >
      {podeContratarServicoRegular &&
        <ServicoRegular/>
      }

      {podeContratarServicoObrigatorio &&
        <ServicosObrigatorios/>
      }

      {podeContratarServicoComplementar &&
        <ServicosComplementares/>
      }
    </MomentoEscolarLayout>
  )
}

export default MomentoEscolarServicos;
