import {selector} from "recoil";
import IModeloContratoResponse from "../../../../@types/IModeloContratoResponse";
import {currency} from "../../../../utils/utils";
import {contratosState} from "../../../atoms/contratosState";
import {responsavelState} from "../../../atoms/responsavelState";
import servicosUpSellingSelecionadosSelector from "./servicosUpSellingSelecionadosSelector";
import parcelasUpSellingSelecionadasSelector from "./parcelasUpSellingSelecionadasSelector";
import {selectedMeioPagamentoEntradaState} from "../../../atoms/selectedMeioPagamentoEntradaState";
import {selectedMeioPagamentoParcelamentoState} from "../../../atoms/selectedMeioPagamentoParcelamentoState";
import {meioPagamentoNome} from "../../../../utils/tipoPagamento";

const contratosUpSellingSelector = selector<{[servicoId: string]: IModeloContratoResponse}>({
  key: 'contratosUpSellingSelector',
  get: ({get}) => {
    const responsavel = get(responsavelState);
    const servicosUpSellingSelecionados = get(servicosUpSellingSelecionadosSelector);
    const parcelasUpSellingSelecionadas = get(parcelasUpSellingSelecionadasSelector);
    const contractos = get(contratosState);
    const selectedMeioPagamentoEntrada = get(selectedMeioPagamentoEntradaState);
    const selectedMeioPagamentoParcelamento = get(selectedMeioPagamentoParcelamentoState);
    const contratosPreenchidos = {};
    let meioPagamentoEntrada = "";
    let meioPagamentoParcelamento = "";

    if (selectedMeioPagamentoEntrada) {
      meioPagamentoEntrada = meioPagamentoNome[selectedMeioPagamentoEntrada];
    }

    if (selectedMeioPagamentoParcelamento) {
      meioPagamentoParcelamento = meioPagamentoNome[selectedMeioPagamentoParcelamento];
    }

    servicosUpSellingSelecionados.forEach(servico => {
      const contrato = JSON.parse(JSON.stringify(contractos[servico.id || ""] ?? {})) as IModeloContratoResponse;
      const parcela = parcelasUpSellingSelecionadas[servico.id];
      const parcelaEntradaRegular = parcela?.entradas[0]
      let descricaoEntrada = "";
      let descricaoServicos = "";

      if (parcelaEntradaRegular) {
        descricaoEntrada = `entrada, valor total: ${currency(parcelaEntradaRegular.valorTotal)} em ${parcelaEntradaRegular.totalParcela}x de ${currency(parcelaEntradaRegular?.valor)} mais`;
      }

      descricaoServicos = `${responsavel?.nome}, ${servico?.nome}, valor total: ${currency((parcelaEntradaRegular?.valorTotal ?? 0) + (parcela?.valorTotal ?? 0))} sendo ${descricaoEntrada} ${parcela?.totalParcela}x de ${currency(parcela?.valor)}.`

      if (contrato?.servicoUnidadeContratoHTML) {
        contrato.servicoUnidadeContratoHTML = contrato.servicoUnidadeContratoHTML
          ?.replace("#DescricaoServicosMatricula", descricaoServicos)
          ?.replaceAll("#NomeResponsavelContrato", responsavel?.nome || "")
          ?.replaceAll("#MeioPagamentoAdiantamento", meioPagamentoEntrada || "")
          ?.replaceAll("#FormaPagamentoPrimeiraParcela", meioPagamentoEntrada || "")
          ?.replaceAll("#MeioPagamentoRecorrencia", meioPagamentoParcelamento || "")
          ?.replaceAll("#FormaPagamentoDemaisParcelas", meioPagamentoParcelamento || "")
      }

      contratosPreenchidos[servico.id] = contrato;
    });

    return contratosPreenchidos;
  },
});

export default contratosUpSellingSelector;
