import CardSelection from "./CardSelection";
import React from "react";
import Stack from "@mui/material/Stack";
import {LoadingButton} from "@mui/lab";
import Typography from "@mui/material/Typography";

function WalletSelectionPreview(
  {
    card,
    onClick = () => {
    },
    label = "Clique aqui para selecionar um cartão",
    loadingCards = false
  }
) {

  const handleClick = () => {
    if (onClick) onClick();
  }

  return (
    <React.Fragment>
      {card ? (
        <>
          <Typography
            fontWeight="bold"
            sx={{
              mb: 3
            }}
          >
            Cartão selecionado:
          </Typography>
          <CardSelection
            card={card}
            onClickChange={handleClick}
            buttonLabel="Alterar cartão"
            showRemove={false}
            type="row"
          />
        </>
      ) : (
        <Stack direction="row">
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <LoadingButton
              variant="outlined"
              color="primary"
              onClick={handleClick}
              type="button"
              loading={loadingCards}
              startIcon={(
                <svg
                  className="mr-1"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  fill="currentColor"
                >
                  <path
                    d="M20 4H4A2 2 0 0 0 2 6V18A2 2 0 0 0 4 20H20A2 2 0 0 0 22 18V6A2 2 0 0 0 20 4M20 11H4V8H20Z"/>
                </svg>
              )}
            >
              {label}
            </LoadingButton>
          </Stack>
        </Stack>
      )}
    </React.Fragment>
  )
}

export default WalletSelectionPreview;
