import React from 'react'
import { Box, Button, Typography, useTheme, Dialog, useMediaQuery, Stack } from '@mui/material'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { currency } from '../../../../../utils/utils'
import { formatOwnName } from '../../../../../utils/formatString'

interface Item {
  descricao: string;
  valor: number;
  referencia?: boolean;
}

interface BilletDemonstrativeGroupDetails {
  itens: Item[];
}

interface PaymentItem {
  studentname: string;
  unityname: string;
  billetDemonstrativeGroupDetails: BilletDemonstrativeGroupDetails;
}

interface DetailPaymentModalProps {
  open: boolean;
  onClose: () => void;
  item: PaymentItem;
}

const detailColumns: GridColDef[] = [
  {
    field: 'descricao',
    headerName: 'Serviço',
    flex: 1,
    renderCell: (params) => (
      <Box component="span" sx={{ fontWeight: params.row.referencia === undefined ? 'bold' : 'normal' }}>
        {params.value}
      </Box>
    )
  },
  {
    field: 'valor',
    headerName: 'Valor',
    flex: 1,
    renderCell: (params) => (
      <Box component="span" sx={{ fontWeight: params.row.referencia === undefined ? 'bold' : 'normal' }}>
        {currency(params.value)}
      </Box>
    )
  },
  {
    field: 'referencia',
    headerName: 'Acordo',
    flex: 1,
    renderCell: (params) => (params.value === undefined ? '' : params.value ? 'Sim' : 'Não')
  }
]

export const DetailPaymentModal: React.FC<DetailPaymentModalProps> = ({ open, onClose, item }) => {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const data: Item[] =
    !item.billetDemonstrativeGroupDetails ||
    item.billetDemonstrativeGroupDetails.itens.length === 0
      ? []
      : item.billetDemonstrativeGroupDetails.itens

  if (data.length > 0 && !data.find(obj => obj.descricao === 'Total')) {
    data.push({
      descricao: 'Total',
      valor: data.reduce((prev, curr) => prev + Number(curr.valor), 0),
      referencia: undefined
    })
  }

  const indexedData = data.map((item, index) => ({ ...item, id: index + 1 }))

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={onClose}
      aria-labelledby='dialog-detail'
      sx={{
        '& .MuiDialog-paper': {
          maxHeight: '98%',
          maxWidth: '100%',
          [theme.breakpoints.up('sm')]: {
            maxWidth: 'none'
          }
        }
      }}
    >
      <Stack
        sx={{
          padding: '20px 10px',
          [theme.breakpoints.up('sm')]: {
            minWidth: 500
          },
          height: '100%'
        }}
      >
        <Stack>
          <Box sx={{ paddingBottom: 2, textAlign: 'center' }}>
            <Typography variant='h6'>
              {formatOwnName(item.studentname)}
            </Typography>
            <Typography sx={{ fontSize: '1rem' }} color='secondary' variant='h6'>
              {item.unityname}
            </Typography>
          </Box>
          <Box sx={{width: '100%', maxWidth: '600px' }}>
            <DataGrid
              rows={indexedData}
              columns={detailColumns}
              disableColumnMenu
              hideFooter
              isRowSelectable={() => false}
              autoHeight
            />
          </Box>
        </Stack>
        <Stack sx={{
          height: '100%',
          justifyContent: 'flex-end',
          padding: 2,
        }}>
          <Box
            sx={{
              marginTop: 2,
              margin: 1,
              width: '100%',
              justifyContent: 'flex-end',
              display: 'flex',
              alignItems: 'flex-end'
            }}
          >
            <Button
              color='primary'
              variant='contained'
              aria-label='close-payment'
              id='ico-mdl-pag-fch'
              onClick={onClose}
              sx={{height:'fit-content'}}
            >
              Fechar
            </Button>
          </Box>
        </Stack>
      </Stack>
    </Dialog>
  )
}

export default DetailPaymentModal
