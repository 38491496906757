import { useEffect, useState } from 'react';
import isValidCNPJ from "../utils/isValidCNPJ";
import isValidCPF from "../utils/isValidCPF";

export default function useCpfCnpjErroMsg(value: string) {
  const [errorMsg, setErrorMsg] = useState<string>('');

  useEffect(() => {
    if (value.length === 0) {
      setErrorMsg('');
      return;
    }
    if (value.length > 0 && value.length < 14) {
      setErrorMsg("CPF inválido");
      return;
    }
    if (value.length > 14 && value.length < 18) {
      setErrorMsg("CNPJ inválido");
      return;
    }
    if (value.length > 14) {
      if (!isValidCNPJ(value) && value.length === 18) {
        setErrorMsg("CNPJ inválido");
      } else if (isValidCNPJ(value)) {
        setErrorMsg('');
      }
    } else {
      if (!isValidCPF(value) && value.length === 14) {
        setErrorMsg("CPF inválido");
      } else if (isValidCPF(value)) {
        setErrorMsg('');
      }
    }
  }, [value]);

  return errorMsg;
}
