import {FC, useEffect} from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import {useTranslation} from "../../../hooks/use-translation";
import {tokens} from '../../../locales/tokens';
import useAuth from "../../../hooks/use-auth";

const abreviarSobreNomes = (nome?: string | null): string => {
  const nomes = (nome ?? "").split(" ");
  return nomes.map((n, i) => i === 0 || i === nomes.length - 1 ? n : `${n[0]}.`).join(" ");
}

interface AccountPopoverProps {
  anchorEl: null | Element;
  onClose?: () => void;
  open?: boolean;
  onlyExit?: boolean;
}

export const AccountPopover: FC<AccountPopoverProps> = (props) => {
  const { anchorEl, onClose, open, onlyExit, ...other } = props;
  const {user, logout} = useAuth();
  const { t } = useTranslation();

  useEffect(() => {
    if (open && onlyExit) {
      logout();
    }
  }, [open]);

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: 'center',
        vertical: 'bottom'
      }}
      disableScrollLock
      onClose={onClose}
      open={onlyExit ? false : !!open}
      PaperProps={{ sx: { width: 200 } }}
      {...other}
    >
      <Box sx={{ p: 2 }}>
        <Typography variant="body1">
          {abreviarSobreNomes(user?.nome)}
        </Typography>
        <Typography
          color="text.secondary"
          variant="body2"
        >
          {user?.email}
        </Typography>
      </Box>
      <Divider sx={{ my: '0 !important' }} />
      <Box
        sx={{
          display: 'flex',
          p: 1,
          justifyContent: 'center'
        }}
      >
        <Button
          fullWidth
          color="inherit"
          onClick={() => logout()}
          size="small"
        >
          {t(tokens.layout.topNav.account.logout)}
        </Button>
      </Box>
    </Popover>
  );
};

AccountPopover.propTypes = {
  anchorEl: PropTypes.any,
  onClose: PropTypes.func,
  open: PropTypes.bool
};
