import React from "react";
import { IServico } from "../../@types/IServicoDto";
import { useRecoilValue } from "recoil";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { currency } from "../../utils/utils";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import parcelasObrigatoriosSelecionadasSelector
  from "../../recoil/selectors/carrinho/servicosObrigatorios/parcelasObrigatoriosSelecionadasSelector";
import isPreReservaSemValorSelector from "../../recoil/selectors/isPreReservaSemValorSelector";
import Currency from "../Currency/Currency";
import { Info } from "@mui/icons-material";
import { Chip, Tooltip } from "@mui/material";

const ServicoObrigatorioResumo: React.FC<{ servico: IServico }> = props => {
  const isReservaSemValor = useRecoilValue(isPreReservaSemValorSelector);
  const parcelasSelecionadas = useRecoilValue(parcelasObrigatoriosSelecionadasSelector);
  const parcelaRegularSelecionada = parcelasSelecionadas[props.servico.id];
  const {
    entradas
  } = parcelaRegularSelecionada || {};

  if (!parcelaRegularSelecionada && !isReservaSemValor)
    return null;

  const servicoPrincipal = props.servico;
  const { valorBase } = servicoPrincipal

  const parcelaEntradaRegularSelecionada = entradas ? entradas![0] : null;

  return (
    <Box
      sx={{
        pb: 2
      }}
    >
      <Stack
        direction="column"
        spacing={2}
      >
        <Typography
          fontWeight="bold"
        >
          {props.servico?.tipo === "MaterialDidatico" ? "Material Didático" : props.servico?.nome}
        </Typography>

        <Stack
          direction="column"
          spacing={1}
        >
          <Typography
            fontWeight="bold"
            fontSize="0.8rem"
          >
            Valor total do contrato {currency(props.servico?.valorContrato)}
          </Typography>

          {!isReservaSemValor && (
            <>
              {(!!parcelaRegularSelecionada && servicoPrincipal?.valorContrato !== valorBase) && (
                <>
                  {servicoPrincipal?.valorDescontoProporcionalidade! > 0 && (
                    <Tooltip title={"Abatimento proporcionalidade -" + currency(servicoPrincipal?.valorDescontoProporcionalidade)}>
                      <Chip
                        color="success"
                        label={"Abatimento proporcionalidade -" + currency(servicoPrincipal?.valorDescontoProporcionalidade)}
                        icon={<Info />}
                        size="small"
                        sx={{
                          width: 'fit-content',
                      fontSize: '0.8rem'
                        }}
                      />
                    </Tooltip>
                  )}

                  {servicoPrincipal?.valorDescontoMovimentacao! > 0 && (
                    <Tooltip title={"Abatimento movimentação -" + currency(servicoPrincipal?.valorDescontoMovimentacao)}>
                      <Chip
                        color="success"
                        label={"Abatimento movimentação -" + currency(servicoPrincipal?.valorDescontoMovimentacao)}
                        icon={<Info />}
                        size="small"
                        sx={{
                          width: 'fit-content',
                      fontSize: '0.8rem'
                        }}
                      />
                    </Tooltip>
                  )}

                  <Typography
                    fontWeight="bold"
                    fontSize="0.8rem"
                    sx={{
                      pt: 1
                    }}
                  >
                    Subtotal após abatimentos <Currency value={valorBase} />
                  </Typography>
                </>
              )}

              {(!!parcelaRegularSelecionada && !!parcelaEntradaRegularSelecionada && parcelaEntradaRegularSelecionada?.valorTotal.toFixed(0) !== parcelaEntradaRegularSelecionada?.valorTotalBruto.toFixed(0)) &&
                <Typography
                  fontWeight="bold"
                  fontSize="0.8rem"
                  sx={{
                    pt: 1
                  }}
                >
                  Valor total da entrada {currency(parcelaEntradaRegularSelecionada?.valorTotalBruto)}
                </Typography>
              }

              {parcelaEntradaRegularSelecionada?.valorDescontoComercial! > 0 && (
                <Tooltip title={"Descontos comerciais -" + parcelaEntradaRegularSelecionada?.percentualDescontoComercial + "% " + currency(parcelaEntradaRegularSelecionada?.valorDescontoComercial)}>
                  <Chip
                    color="success"
                    label={"Descontos comerciais -" + parcelaEntradaRegularSelecionada?.percentualDescontoComercial + "% " + currency(parcelaEntradaRegularSelecionada?.valorDescontoComercial)}
                    icon={<Info />}
                    size="small"
                    sx={{
                      width: 'fit-content',
                      fontSize: '0.8rem'
                    }}
                  />
                </Tooltip>
              )}

              {parcelaEntradaRegularSelecionada?.valorDescontoAvista! > 0 && (
                <Tooltip title={"Descontos pagamento à vista -" + parcelaEntradaRegularSelecionada?.percentualDescontoAvista + "% " + currency(parcelaEntradaRegularSelecionada?.valorDescontoAvista)}>
                  <Chip
                    color="success"
                    label={"Descontos pagamento à vista -" + parcelaEntradaRegularSelecionada?.percentualDescontoAvista + "% " + currency(parcelaEntradaRegularSelecionada?.valorDescontoAvista)}
                    icon={<Info />}
                    size="small"
                    sx={{
                      width: 'fit-content',
                      fontSize: '0.8rem'
                    }}
                  />
                </Tooltip>
              )}

              {parcelaEntradaRegularSelecionada?.valorDescontoBolsa! > 0 && (
                <Tooltip title={"Descontos de bolsa na entrada -" + parcelaEntradaRegularSelecionada?.percentualDescontoBolsa + "% " + currency(parcelaEntradaRegularSelecionada?.valorDescontoBolsa)}>
                  <Chip
                    color="success"
                    label={"Descontos de bolsa na entrada -" + parcelaEntradaRegularSelecionada?.percentualDescontoBolsa + "% " + currency(parcelaEntradaRegularSelecionada?.valorDescontoBolsa)}
                    icon={<Info />}
                    size="small"
                    sx={{
                      width: 'fit-content',
                      fontSize: '0.8rem'
                    }}
                  />
                </Tooltip>
              )}

              {parcelaEntradaRegularSelecionada?.valorDescontoMetodo! > 0 && (
                <Tooltip title={"Descontos de metodo de pagamento -" + parcelaEntradaRegularSelecionada?.percentualDescontoMetodo + "% " + currency(parcelaEntradaRegularSelecionada?.valorDescontoMetodo)}>
                  <Chip
                    color="success"
                    label={"Descontos de metodo de pagamento -" + parcelaEntradaRegularSelecionada?.percentualDescontoMetodo + "% " + currency(parcelaEntradaRegularSelecionada?.valorDescontoMetodo)}
                    icon={<Info />}
                    size="small"
                    sx={{
                      width: 'fit-content',
                      fontSize: '0.8rem'
                    }}
                  />
                </Tooltip>
              )}

              {!!parcelaEntradaRegularSelecionada && (
                <Typography
                  fontSize="0.8rem"
                  sx={{
                    pt: 1
                  }}
                >
                  Entrada
                  de <Currency value={parcelaEntradaRegularSelecionada?.valorTotal} /> em {parcelaEntradaRegularSelecionada?.totalParcela}x <Currency value={parcelaEntradaRegularSelecionada?.valor} />
                </Typography>
              )}

              {(!!parcelaRegularSelecionada && parcelaRegularSelecionada?.valorTotal.toFixed(0) !== parcelaRegularSelecionada.valorTotalBruto.toFixed(0)) && (
                <Typography
                  fontWeight="bold"
                  fontSize="0.8rem"
                  sx={{
                    pt: 1
                  }}
                >
                  Valor total do parcelamento {currency(parcelaRegularSelecionada?.valorTotalBruto)}
                </Typography>
              )}

              {parcelaRegularSelecionada?.valorDescontoComercial! > 0 && (
                <Tooltip title={"Descontos comerciais -" + parcelaRegularSelecionada?.percentualDescontoComercial + "% " + currency(parcelaRegularSelecionada?.valorDescontoComercial)}>
                  <Chip
                    color="success"
                    label={"Descontos comerciais -" + parcelaRegularSelecionada?.percentualDescontoComercial + "% " + currency(parcelaRegularSelecionada?.valorDescontoComercial)}
                    icon={<Info />}
                    size="small"
                    sx={{
                      width: 'fit-content',
                      fontSize: '0.8rem'
                    }}
                  />
                </Tooltip>
              )}

              {parcelaRegularSelecionada?.valorDescontoAvista! > 0 && (
                <Tooltip title={"Descontos pagamento à vista -" + parcelaRegularSelecionada?.percentualDescontoAvista + "% " + currency(parcelaRegularSelecionada?.valorDescontoAvista)}>
                  <Chip
                    color="success"
                    label={"Descontos pagamento à vista -" + parcelaRegularSelecionada?.percentualDescontoAvista + "% " + currency(parcelaRegularSelecionada?.valorDescontoAvista)}
                    icon={<Info />}
                    size="small"
                    sx={{
                      width: 'fit-content',
                      fontSize: '0.8rem'
                    }}
                  />
                </Tooltip>
              )}

              {parcelaRegularSelecionada?.valorDescontoBolsa! > 0 && (
                <Tooltip title={"Descontos de bolsa na entrada -" + parcelaRegularSelecionada?.percentualDescontoBolsa + "% " + currency(parcelaRegularSelecionada?.valorDescontoBolsa)}>
                  <Chip
                    color="success"
                    label={"Descontos de bolsa na entrada -" + parcelaRegularSelecionada?.percentualDescontoBolsa + "% " + currency(parcelaRegularSelecionada?.valorDescontoBolsa)}
                    icon={<Info />}
                    size="small"
                    sx={{
                      width: 'fit-content',
                      fontSize: '0.8rem'
                    }}
                  />
                </Tooltip>
              )}

              {parcelaRegularSelecionada?.valorDescontoMetodo! > 0 && (
                <Tooltip title={"Descontos de metodo de pagamento -" + parcelaRegularSelecionada?.percentualDescontoMetodo + "% " + currency(parcelaRegularSelecionada?.valorDescontoMetodo)}>
                  <Chip
                    color="success"
                    label={"Descontos de metodo de pagamento -" + parcelaRegularSelecionada?.percentualDescontoMetodo + "% " + currency(parcelaRegularSelecionada?.valorDescontoMetodo)}
                    icon={<Info />}
                    size="small"
                    sx={{
                      width: 'fit-content',
                      fontSize: '0.8rem'
                    }}
                  />
                </Tooltip>
              )}

              {!!parcelaRegularSelecionada && (
                <Typography
                  fontSize="0.8rem"
                >
                  Parcelamento
                  de <Currency value={parcelaRegularSelecionada?.valorTotal} /> em {parcelaRegularSelecionada?.totalParcela}x <Currency value={parcelaRegularSelecionada?.valor} />
                </Typography>
              )}
            </>
          )}
        </Stack>
        <Divider />
      </Stack>
    </Box>
  )
}

export default ServicoObrigatorioResumo;
