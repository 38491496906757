import type { FC } from 'react';
import React from 'react';
import PropTypes from 'prop-types';
import Menu01Icon from '@untitled-ui/icons-react/build/esm/Menu01';
import { alpha } from '@mui/system/colorManipulator';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import SvgIcon from '@mui/material/SvgIcon';
import useMediaQuery from '@mui/material/useMediaQuery';
import type { Theme } from '@mui/material/styles/createTheme';
import RestoreIcon from '@mui/icons-material/Restore';
import FavoriteIcon from '@mui/icons-material/Favorite';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { AccountButton } from '../account-button';
import { ContactsButton } from '../contacts-button';
import { LanguageSwitch } from '../language-switch';
import { NotificationsButton } from '../notifications-button';
import { SearchButton } from '../search-button';
import {BottomNavigation, BottomNavigationAction} from "@mui/material";
import User01Icon from "@untitled-ui/icons-react/build/esm/User01";
import Avatar from "@mui/material/Avatar";
import {useMockedUser} from "../../../hooks/use-mocked-user";
import {RouterLink} from "../../../components/router-link";
import {paths} from "../../../paths";
import Redis01 from "../../../icons/untitled-ui/duocolor/redis-01";

const TOP_NAV_HEIGHT = 64;
const SIDE_NAV_WIDTH = 280;

interface TopNavProps {
  onMobileNavOpen?: () => void;
}

export const TopNav: FC<TopNavProps> = (props) => {
  const { onMobileNavOpen, ...other } = props;
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const smUP = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));
  const [value, setValue] = React.useState(0);
  const user = useMockedUser();

  if (!smUP) {
    return (
      <Box
        sx={{
          backdropFilter: 'blur(6px)',
          backgroundColor: (theme) => alpha(theme.palette.background.default, 0.8),
          width: "100%",
          position: "fixed",
          left: 0,
          bottom: 0,
          zIndex: (theme) => theme.zIndex.appBar
        }}
      >
        <BottomNavigation
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
        >
          <BottomNavigationAction
            label="menu"
            onClick={onMobileNavOpen}
            icon={
              <SvgIcon>
                <Menu01Icon />
              </SvgIcon>
            }
          />
          {/*<BottomNavigationAction*/}
          {/*  label="cache"*/}
          {/*  component={RouterLink}*/}
          {/*  href={paths.dashboard.cache}*/}
          {/*  icon={*/}
          {/*    <SvgIcon fontSize="small">*/}
          {/*      <Redis01 />*/}
          {/*    </SvgIcon>*/}
          {/*  }*/}
          {/*/>*/}
          <BottomNavigationAction
            label="user"
            icon={
              <Avatar
                sx={{
                  height: 24,
                  width: 24
                }}
                src={String(user?.avatar)}
              >
                <SvgIcon>
                  <User01Icon />
                </SvgIcon>
              </Avatar>
            }
            component={RouterLink}
            href={paths.dashboard.account}
          />
        </BottomNavigation>
      </Box>
    );
  }

  return (
    <Box
      component="header"
      sx={{
        backdropFilter: 'blur(6px)',
        backgroundColor: (theme) => alpha(theme.palette.background.default, 0.8),
        position: 'sticky',
        left: {
          lg: `${SIDE_NAV_WIDTH}px`
        },
        top: 0,
        width: {
          lg: `calc(100% - ${SIDE_NAV_WIDTH}px)`
        },
        zIndex: (theme) => theme.zIndex.appBar
      }}
      {...other}
    >
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        spacing={2}
        sx={{
          minHeight: TOP_NAV_HEIGHT,
          px: 2
        }}
      >
        <Stack
          alignItems="center"
          direction="row"
          spacing={2}
        >
          {!lgUp && (
            <IconButton onClick={onMobileNavOpen}>
              <SvgIcon>
                <Menu01Icon />
              </SvgIcon>
            </IconButton>
          )}
          {/*<SearchButton />*/}
        </Stack>
        <Stack
          alignItems="center"
          direction="row"
          spacing={2}
        >
          {/*<LanguageSwitch />*/}
          {/*<NotificationsButton />*/}
          {/*<ContactsButton />*/}
          <AccountButton />
        </Stack>
      </Stack>
    </Box>
  );
};

TopNav.propTypes = {
  onMobileNavOpen: PropTypes.func
};
