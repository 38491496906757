const tipoMatricula = {
  reservaComValor: 284360004,
  movimentacao: 936190001,
  nova: 284360000,
  rematricula: 284360001,
  upselling: 284360002,
    comunidade: 284360003,
    reservaSemValor: 284360005,
};

export default Object.freeze(tipoMatricula);