import React, {useMemo, useState} from 'react';
import {Col, Container, ListGroup, Row} from "react-bootstrap";
import "./styles.css";
import ButtonCopyToClipboard from "../ButtonCopyToClipboard/ButtonCopyToClipboard";
import {Badge} from "reactstrap";
import {DialogContent, DialogContentText, DialogActions, Button} from "@mui/material";

function ModalBoleto(
  {
    boletos = [],
    ticketValue,
    ticketExpiration,
    ticketCode,
    ticketUrl = "",
    ...props
  }
) {
  const formattedTicketValue = useMemo(() => {
    return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(ticketValue)
  }, [ticketValue]);
  const formattedTicketExpiration = useMemo(() => {
    return new Date(ticketExpiration).toLocaleDateString();
  }, [ticketExpiration]);
  const [downloaded, setDownloaded] = useState([]);

  const handleDownload = (id) => event => {
    setDownloaded([
      ...downloaded.filter(d => d !== id),
      id
    ])
  }

  const renderBoletos = (
    {
      id,
      codigoDeBarras,
      parcela,
      pago = false,
      url,
      valor,
      vencimento
    }
  ) => {
    return (
      <ListGroup.Item key={id}>
        <Row>
          {parcela && (
            <Col>
              <div className="form-group">
                <label htmlFor="ticketValue">Parcela</label>
                <Badge
                  color="primary"
                  pill
                >
                  {parcela}
                </Badge>
              </div>
            </Col>
          )}

          <Col>
            <div className="form-group">
              <label htmlFor="ticketValue">Pago</label>
              {pago ? <Badge color="success">Sim</Badge> : <Badge color="danger">Não</Badge>}
            </div>
          </Col>
        </Row>
        <Row>
          {valor && (
            <Col>
              <div className="form-group">
                <label htmlFor="ticketValue">Valor do boleto</label>
                <input
                  type="text"
                  disabled
                  id="ticketValue"
                  value={new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(valor)}
                />
              </div>
            </Col>
          )}

          {vencimento && (
            <Col>
              <div className="form-group">
                <label htmlFor="ticketExpiration">Vencimento</label>
                <input
                  type="text"
                  disabled
                  id="ticketExpiration"
                  value={new Date(vencimento).toLocaleDateString()}
                />
              </div>
            </Col>
          )}
        </Row>

        <Row>
          <Col>
            <div className="form-group seb-smo-modal-download-boleto-ticket-code-container">
              <label htmlFor="ticketCode">Código para pagamento</label>
              <span
                id="ticketCode"
              >
                {codigoDeBarras}
              </span>

              <ButtonCopyToClipboard
                content={codigoDeBarras}
                copyOnlyNumbers
              />
            </div>
          </Col>
        </Row>

        <Container>
          <Row className="justify-content-center">
            <Button
              id="btnDownloadTicket"
              href={url}
              variant="link"
              onClick={handleDownload(id)}
            >
              {downloaded.includes(id) ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  width={24}
                  height={24}
                >
                  <title>check-bold</title>
                  <path d="M9,20.42L2.79,14.21L5.62,11.38L9,14.77L18.88,4.88L21.71,7.71L9,20.42Z" />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  width={24}
                  height={24}
                >
                  <title>download</title>
                  <path d="M5,20H19V18H5M19,9H15V3H9V9H5L12,16L19,9Z" />
                </svg>
              )}
              baixar boleto
            </Button>
          </Row>
        </Container>
      </ListGroup.Item>
    )
  }

  return (
    <div className="seb-smo-modal-download-boleto">
      <DialogContent dividers>
        <DialogContentText
          id="scroll-dialog-description"
          tabIndex={-1}
        >
          {(boletos && boletos.length > 0) ? (
            <form>
              <ListGroup>
                {boletos.map(renderBoletos)}
              </ListGroup>
            </form>
          ) : (
            <form>
              <Row>
                {ticketExpiration && (
                  <Col>
                    <div className="form-group">
                      <label htmlFor="ticketValue">Valor do boleto</label>
                      <input
                        type="text"
                        disabled
                        id="ticketValue"
                        value={formattedTicketValue}
                      />
                    </div>
                  </Col>
                )}

                {ticketExpiration && (
                  <Col>
                    <div className="form-group">
                      <label htmlFor="ticketExpiration">Vencimento</label>
                      <input
                        type="text"
                        disabled
                        id="ticketExpiration"
                        value={formattedTicketExpiration}
                      />
                    </div>
                  </Col>
                )}
              </Row>

              {ticketCode && (
                <Row>
                  <Col>
                    <div className="form-group seb-smo-modal-download-boleto-ticket-code-container">
                      <label htmlFor="ticketCode">Código para pagamento</label>
                      <span
                        id="ticketCode"
                      >
                  {ticketCode}
                </span>

                      <ButtonCopyToClipboard
                        content={ticketCode}
                        copyOnlyNumbers
                      />
                    </div>
                  </Col>
                </Row>
              )}
            </form>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions
        style={{justifyContent: "center"}}
      >
        <Button
          id="btnDownloadTicket"
          onClick={props.onHide}
          href={ticketUrl}
          variant="contained"
          startIcon={(
            <svg width={24} height={24} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
              <title>download</title>
              <path d="M5,20H19V18H5M19,9H15V3H9V9H5L12,16L19,9Z"/>
            </svg>
          )}
        >
          Baixar {boletos.length > 1 ? "todos os boletos" : "boleto"}
        </Button>
      </DialogActions>
    </div>
  );
}

export default ModalBoleto;
