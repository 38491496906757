import type {FC} from 'react';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import SvgIcon from '@mui/material/SvgIcon';

import {useMockedUser} from '../../../hooks/use-mocked-user';
import {usePopover} from '../../../hooks/use-popover';

import {AccountPopover} from './account-popover';
import CloseIcon from "@mui/icons-material/Close";
import GerarSiglaNome from "../../../commons/functions/gerar-sigla-nome";

interface AccountButtonProps {
  onlyExit?: boolean;
}

export const AccountButton: FC<AccountButtonProps> = (props) => {
  const {onlyExit} = props;
  const user = useMockedUser();
  const popover = usePopover<HTMLButtonElement>();

  return (
    <>
      <Box
        data-tut="tur-icon-menu-user"
        component={ButtonBase}
        onClick={popover.handleOpen}
        ref={popover.anchorRef}
        sx={{
          alignItems: 'center',
          display: 'flex',
          height: 40,
          width: 40,
          borderRadius: '50%'
        }}
      >
        {onlyExit ? (
          <SvgIcon
            sx={{
              height: 30,
              width: 30,
              fill: 'text.primary'
            }}
          >
            <CloseIcon/>
          </SvgIcon>
        ) : (
          <Avatar
            sx={{
              height: 40,
              width: 40,
              background: 'linear-gradient(135deg, #4180f6, #1cfaef)'
            }}
            src={String(user?.avatar)}
          >
            {GerarSiglaNome(localStorage.getItem("@SMO20/nomeResponsavel") || "")}
          </Avatar>
        )}
      </Box>
      <AccountPopover
        anchorEl={popover.anchorRef.current}
        onClose={popover.handleClose}
        open={popover.open}
        onlyExit={onlyExit}
      />
    </>
  );
};
