import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import SimplePaymentDialog from './components/SimplePaymentDialog'
import RecurrencePaymentDialog from './components/RecurrencePaymentDialog'
import AutomaticDebitDialog from './components/AutomaticDebitDialog'
import {FormattedMessage, injectIntl} from 'react-intl'
import {paymentTypeSelectedDataSelector} from './selectors/paymentTypes'
import * as _movimentacao from '../../../service/movimentacao-service'
import tipoPagamentoEnum from './enum';
import Swal from 'sweetalert2'
import {hasLoadedInformationRecurrentCardSelector} from './selectors/informationRecurrentCard'
import ConfirmDialog from '../../../components/ConfirmDialog'
import {accessIdSelector, appIdSelector, isTourEnabledSelector} from '../../Login/selectors/user'
import {getPaymentTypeSelected} from './services/paymentTypes'
import {acceptTermAgreementPaymentType} from './services/acceptanceTerm'
import StudentList from './components/StudentList'
import SelectPayment from './components/SelectPayment'
import KnowMoreAutomaticDebitDialog from './components/KnowMoreDialogs/KnowMoreAutomaticDebitDialog'
import KnowMoreRecurrencePaymentDialog from './components/KnowMoreDialogs/KnowMoreRecurrencePaymentDialog'
import KnowMoreSimplePaymentDialog from './components/KnowMoreDialogs/KnowMoreSimplePaymentDialog'
import {getBankByCode} from '../../../utils/utils'
import {getAll} from '../services/unity'
import {GetBySistemaId} from '../services/blackList'
import {fetchContact} from '../../../layouts/services/contactInfo'
import blockedResourcesSystem from '../../../utils/blockedResourcesSystem'
import {blockedResourcesEntitiesValues} from '../../../utils/blockedResourcesEntities'
import Csat from '../../../components/Csat/Csat'
import {Box, Card, CardContent, Divider, Skeleton, Stack, Typography} from '@mui/material'
import {Scrollbar} from '../../../components/scrollbar'
import {useTheme} from '@mui/material/styles'
import {tokens} from '../../../locales/tokens'
import {useTranslation} from '../../../hooks/use-translation'
import useCsat from '../../../hooks/use-csat'
import csatList from '../../../utils/csatList'
import {useRecoilState, useRecoilValue} from 'recoil'
import {avatarsState} from "../../../recoil/atoms/avatarsState";
import {selectedMatriculaState} from '../../../recoil/atoms/selectedMatriculaState'
import {isLoadingRegistrationSelector, registrationSelector} from './selectors/registrations'
import {fetchRegistration} from './services/registration'
import moment from 'moment-timezone'
import {selectedUnidadeState} from "../../../recoil/atoms/selectedUnidadeState";
import useMediaQuery from "@mui/material/useMediaQuery";
import * as _metodoPagamentoService from "../../../service/metodo-pagamento-service";
import matriculasSelector from "../../../recoil/selectors/matriculasSelector";

export default injectIntl((props) => {
  const dispatch = useDispatch()
  const theme = useTheme()
  const { t } = useTranslation()
  const { data: registrationData } = useSelector(registrationSelector)
  const isLoadingRegistration = useSelector(isLoadingRegistrationSelector)
  const appid = useSelector(appIdSelector)
  const accessId = useSelector(accessIdSelector)
  const isTourOpen = useSelector(isTourEnabledSelector)
  const [paymentTypes, setPaymentTypes] = useState([])
  const avatars = useRecoilState(avatarsState);
  const [isLoadingMatriculas, setIsLoadingMatriculas] = useState(true)
  const matriculas = useRecoilValue(matriculasSelector)
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'))
  useEffect(() => {
    dispatch(fetchContact(appid))
  }, [dispatch, appid, accessId, isTourOpen])

  const fetchAndLogPaymentType = async (
    appid,
    registrationid,
    dataareaid,
    responsibleid
  ) => {
    try {
      return dispatch(
        getPaymentTypeSelected(appid, registrationid, dataareaid, responsibleid)
      )
    } catch (error) {
      console.error(error)
    }
  }

  const [openModalRecurrencePayment, setOpenModalRecurrentPayment] =
    useState(false)
  const [openModalAutomaticDebit, setOpenModalAutomaticDebit] = useState(false)
  const [openModalSimplePayment, setOpenModalSimplePayment] = useState(false)

  const [openModalKnowMoreAutomaticDebit, setOpenModalKnowMoreAutomaticDebit] =
    useState(false)
  const [
    openModalKnowMoreRecurrencePayment,
    setOpenModalKnowMoreRecurrencePayment
  ] = useState(false)
  const [openModalKnowMoreSimplePayment, setOpenModalKnowMoreSimplePayment] =
    useState(false)

  const [isLoading, setIsLoading] = useState(true)
  const [changePaymentHasError, setChangePaymentHasError] = useState(false)
  const [changePaymentError, setChangePaymentError] = useState('')
  const [changePaymentHasSuccess, setChangePaymentHasSuccess] = useState(false)
  const [recurrencyIsEditable, setRecurrencyIsEditable] = useState(true)

  const [selectedMatriculaId, setSelectedMatriculaId] = useState(0)
  const [selectedDataAreaId, setSelectedDataAreaId] = useState('')
  const [selectedResponsibleId, setSelectedResponsibleId] = useState('')
  const [selectedMatricula, setSelectedMatricula] = useRecoilState(selectedMatriculaState)
  const [selectedUnidade, setSelectedUnidade] = useRecoilState(selectedUnidadeState)
  const [loadingMatriculas, setLoadingMatriculas] = useState({})
  const [blockedResources, setBlockedResources] = useState([])
  const [boletoLock, setBoletoLock] = useState({})
  const [cartaoLock, setCartaoLock] = useState({})
  const [daLock, setDaLock] = useState({})

  const [registrationHasError, setRegistrationHasError] = useState(false)

  const [acceptanceTermObj, setAcceptanceTermObj] = useState(null)

  const data = useSelector(paymentTypeSelectedDataSelector)

  const [bank, setBank] = useState(null)


  useEffect(() => {
    const fetchPaymentTypes = async () => {
      try {
        setIsLoadingMatriculas(true)
        if (registrationData && registrationData.length === 0) {
          dispatch(fetchRegistration(appid, accessId))
        } else if (!registrationData) {
          setRegistrationHasError(true)
        } else {
          const promises = registrationData.map(async student => {
            try {
              const matricula = matriculas?.find(x => x.dadosAluno.codigoErp === student.studentid)
              const studentId = matricula?.id
              const unidade_Id = matricula?.unidade_Id
              const { registrationid, dataareaid, responsibles } = student
              const responsibleid = responsibles[responsibles.length - 1].responsibleid
              const descontoPagamento = await _metodoPagamentoService.GetDadosMeiosPagamentos(unidade_Id, studentId)
              const tiposPagamento = await fetchAndLogPaymentType(appid,registrationid,dataareaid,responsibleid)
              return {
                ...descontoPagamento,
                ...tiposPagamento
              }
            } catch (error) {
              return null
            } finally {
              setLoadingMatriculas(prevLoading => ({
                ...prevLoading,
                [student.registrationid]: false
              }));
            }
          })
          await Promise.allSettled(promises).then((results) => {
            const successfulResults = results
              .map(result => result.value)
              .filter(result => result !== null)
            setPaymentTypes(successfulResults)
            setIsLoadingMatriculas(false)
          })
        }
      } catch (error) {
        console.error(error)
      }
    }
    fetchPaymentTypes()
  }, [registrationData, dispatch, appid, accessId,changePaymentHasSuccess, changePaymentHasError])

  const hasLoadedDataCard = useSelector(
    hasLoadedInformationRecurrentCardSelector
  )

  const { addAnsweredCsat, isCsatVisible, handleShowCsat } = useCsat(
    csatList.trocaMetodoPagamento
  )

  useEffect(() => {
    setIsLoading(selectedMatricula === null)

    if (selectedMatricula) {
      setIsLoading(true)
      const { unitycode } = selectedMatricula
      getAll(unitycode).then(response => {
        if (response && response.length) {
          const unity = response.find(r => r.codigoERP === unitycode)
          if (unity) {
            setSelectedUnidade(unity)
          }
        }
      })

      GetBySistemaId(blockedResourcesSystem.SMO)
        .then(response => {
          if (response && response.length) {
            setBlockedResources(response)
          }
          setIsLoading(false)
        })
        .catch(() => {
          setIsLoading(false)
        })
    }
  }, [selectedMatricula])

  useEffect(() => {
    if (selectedUnidade && blockedResources && blockedResources.length) {
      const { id: idUnidade } = selectedUnidade
      const locksFiltered = blockedResources.filter(
        r =>
          r?.unidadeId === idUnidade &&
          r.type === blockedResourcesEntitiesValues.Unidade
      )
      if (locksFiltered) {
        const verifylock = ({ lockName, action, setLock, defaultMessage }) => {
          const lockFound = locksFiltered.find(x => x.action === action)

          if (lockFound) {
            setLock({
              [lockName]: true,
              [`${lockName}Message`]:
                lockFound?.mensagemBloqueio &&
                lockFound?.mensagemBloqueio !== ''
                  ? lockFound?.mensagemBloqueio
                  : defaultMessage
            })
          } else {
            setLock({})
          }
        }

        verifylock({
          lockName: 'mudancaBoleto',
          setLock: setBoletoLock,
          action: 'MudancaDeMetodoDePagamento/Unidade/Boleto',
          defaultMessage: 'Bloqueado para esta unidade'
        })

        verifylock({
          lockName: 'mudancaCartao',
          setLock: setCartaoLock,
          action:
            'MudancaDeMetodoDePagamento/Unidade/CartaoDeCreditoRecorrente',
          defaultMessage: 'Bloqueado para esta unidade'
        })

        verifylock({
          lockName: 'mudancaDa',
          setLock: setDaLock,
          action:
            'MudancaDeMetodoDePagamento/Unidade/DebitoAutomaticoRecorrente',
          defaultMessage: 'Bloqueado para esta unidade'
        })
      }
    }
  }, [selectedUnidade, blockedResources])

  const isLimitDate = async (idUnidade) => {
    const { diaLimiteMes } = await _movimentacao.GetTemplateMovimentacao(
      idUnidade,
      284360005
    )
    const diaAtual = parseInt(
      moment().tz('America/Sao_Paulo').format('DD/MM/YYYY').split('/')[0]
    )
    const passouDiaLimite = diaAtual >= diaLimiteMes ? true : false
    if (!passouDiaLimite) return true
    return await Swal.fire({
      title:
        'A data limite para alteração de método de pagamento para a parcela vigente já venceu, sendo assim, não será possível alterar o método para a parcela vigente (demais serão alteradas).',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Sim, Continuar!',
      showLoaderOnConfirm: true,
      allowOutsideClick: () => !Swal.isLoading()
    }).then(async result => {
      if (result.isConfirmed) {
        return true
      } else {
        return false
      }
    })
  }

  const openSimplePayment = async (selectedMatricula, unidadeId) => {
    setSelectedMatricula(selectedMatricula)
    setSelectedMatriculaId(selectedMatricula.registrationid)
    setSelectedDataAreaId(selectedMatricula.dataareaid)
    setSelectedResponsibleId(selectedMatricula.responsibles[selectedMatricula.responsibles.length - 1].responsibleid)
    if (await isLimitDate(unidadeId)) {
      if (isSimplePayment(Array.isArray(paymentTypes) && paymentTypes.find(x => x?.matricula_id === selectedMatricula.registrationid)))
        return
      setOpenModalSimplePayment(true)
    }
  }

  const openRecurrentPayment = async (selectedMatricula, unidadeId) => {
    setSelectedMatricula(selectedMatricula)
    setSelectedMatriculaId(selectedMatricula.registrationid)
    setSelectedDataAreaId(selectedMatricula.dataareaid)
    setSelectedResponsibleId(selectedMatricula.responsibles[selectedMatricula.responsibles.length - 1].responsibleid)
    if (await isLimitDate(unidadeId)) {
      setRecurrencyIsEditable(true)
      setOpenModalRecurrentPayment(true)
    }
  }

  const openRecurrentPaymentViewOnly = async (selectedMatricula) => {
    setSelectedMatricula(selectedMatricula)
    setSelectedMatriculaId(selectedMatricula.registrationid)
    setSelectedDataAreaId(selectedMatricula.dataareaid)
    setSelectedResponsibleId(selectedMatricula.responsibles[selectedMatricula.responsibles.length - 1].responsibleid)
    setRecurrencyIsEditable(false)
    setOpenModalRecurrentPayment(true)
  }

  const openAutomaticDebit = async (selectedMatricula, unidadeId) => {
    setSelectedMatricula(selectedMatricula)
    setSelectedMatriculaId(selectedMatricula.registrationid)
    setSelectedDataAreaId(selectedMatricula.dataareaid)
    setSelectedResponsibleId(selectedMatricula.responsibles[selectedMatricula.responsibles.length - 1].responsibleid)
    if (await isLimitDate(unidadeId)) setOpenModalAutomaticDebit(true)
  }

  const closeModalSimplePayment = () => {
    setOpenModalSimplePayment(false)
  }

  const closeModalRecurrentPayment = () => {
    setOpenModalRecurrentPayment(false)
  }

  const closeModalAutomaticDebit = () => {
    setOpenModalAutomaticDebit(false)
  }

  const isSimplePayment = (service = data) => {
    return service?.current_payment_type === tipoPagamentoEnum.BOLETO_BANCARIO;;
  }

  const isRecurrentPayment = (service = data) => {
    return service?.current_payment_type === tipoPagamentoEnum.CREDITO_RECORRENTE;
  }

  const isAutomaticDebit = (service = data) => {
    return service?.current_payment_type === tipoPagamentoEnum.DEBITO_AUTOMATICO;
  }

  const onSuccessChange = () => {
    setChangePaymentHasSuccess(true)
    dispatch(
      acceptTermAgreementPaymentType(appid, JSON.stringify(acceptanceTermObj))
    )
    dispatch(
      getPaymentTypeSelected(
        appid,
        selectedMatriculaId,
        selectedDataAreaId,
        selectedResponsibleId
      )
    )
  }

  const onErrorChange = errorMessage => {
    setChangePaymentHasError(true)
    setChangePaymentError(errorMessage)
  }

  const onSuccessChangeSimplePayment = () => {
    closeModalSimplePayment()
    onSuccessChange()
  }

  const onErrorChangeSimplePayment = errorMessage => {
    closeModalSimplePayment()
    onErrorChange(errorMessage)
  }

  const onSuccessChangeAutomaticDebit = () => {
    closeModalAutomaticDebit()
    onSuccessChange()
  }

  const onErrorChangeAutomaticDebit = errorMessage => {
    closeModalAutomaticDebit()
    onErrorChange(errorMessage)
  }

  const onSuccessChangeRecurrencePayment = () => {
    closeModalRecurrentPayment()
    onSuccessChange()
  }

  const onErrorChangeRecurrencePayment = errorMessage => {
    closeModalRecurrentPayment()
    onErrorChange(errorMessage)
  }

  useEffect(() => {
    if (data && isAutomaticDebit() && data.debito_automatico_banco) {
      setBank(getBankByCode(data.debito_automatico_banco))
    }
  }, [dispatch, data])

  const handleCloseCsat = () => {
    const body = document.querySelector('body')
    if (body) body.classList.remove('csatBlockScroll')
    addAnsweredCsat(csatList.trocaMetodoPagamento)
  }

  const toolTipDescription = {
    title: 'modalClickInformation',
    displayText: 'knowMore'
  }

  const getButtonText = (type, paymentType) => {
    switch (type) {
      case 'creditCard':
        if (paymentType) return 'changeCreditCard'
        return 'select'
      case 'bill':
        if (paymentType) return 'selected'
        return 'select'
      case 'recurringBill':
        if (paymentType) return 'changeBankDetails'
        return 'select'
      default:
        return 'select'
    }
  }

  const isAnyMatriculaLoaded = loadingMatriculas && Object.values(loadingMatriculas).some(x => x === false)
  return (
    <Box component='main' sx={{ marginTop: theme.spacing(3), width: '100%' }}>
      <Scrollbar>
        <Card sx={{ padding: 2 }}>
          <Stack
            spacing={2}
            sx={isSmall ? {} : { padding: '1.5rem 2rem' }}
          >
            <Typography variant='h4'>
              {!isAnyMatriculaLoaded ? (
                <Skeleton variant='text' width='100%' height={40} />
              ) : (
                <>{t(tokens.common.paymentMethod)}</>
              )}
            </Typography>
            <Typography variant='body1'>
              {!isAnyMatriculaLoaded ? (
                <Skeleton variant='text' width='200px' height={30} />
              ) : (
                <>{t(tokens.common.displayFinancialServicesPaymentMethod)}</>
              )}
            </Typography>
            {isAnyMatriculaLoaded && (
              <Divider
                sx={{
                  borderBottomWidth: 1,
                  borderColor: '#c6bbbb !important'
                }}
              />
            )}
            <div data-tut='tur-table-payment-types'>
              <ConfirmDialog
                setOpen={setChangePaymentHasError}
                open={changePaymentHasError}
                title='Erro ao alterar forma de pagamento'
                message={changePaymentError}
                onClose={() => handleShowCsat(csatList.trocaMetodoPagamento)}
              />

              <ConfirmDialog
                setOpen={setChangePaymentHasSuccess}
                open={changePaymentHasSuccess}
                title='Método de Pagamento'
                onClose={() => handleShowCsat(csatList.trocaMetodoPagamento)}
                message={
                  'A solicitação de alteração de método de pagamento foi realizada com sucesso!'
                }
              />

              <Csat
                show={isCsatVisible}
                scoreTitle='O quanto você ficou satisfeito com: Troca de método de pagamento?'
                onSubmit={handleCloseCsat}
                onClosed={handleCloseCsat}
              />
              <Card
                sx={{
                  mb: 2
                }}
              >
                <CardContent>
                  {registrationData &&
                    registrationData.length > 0 &&
                    registrationData.map((student, index) => {
                      const matricula = matriculas.find(
                        x => x.dadosAluno.codigoErp === student.studentid
                      )
                      let imageUrl = null;
                      for (const avatar of avatars) {
                          if (avatar[matricula?.dadosAluno?.id]) {
                              imageUrl = avatar[matricula?.dadosAluno?.id];
                              break;
                          }
                      }
                      const loadedMatricula = loadingMatriculas[student.registrationid] === false ? false : true
                      return (
                        <StudentList
                          key={index}
                          isLoadingData={loadedMatricula}
                          matricula={student}
                          avatar={imageUrl}
                        >
                          <>
                            {(!boletoLock?.mudancaBoleto) &&
                              <SelectPayment
                                paymentType='bill'
                                matricula={student}
                                selectLock={boletoLock}
                                isLoading={loadedMatricula}
                                discount={paymentTypes.find(x => x?.matricula_id === student.registrationid)?.metodosDePagamentoRecorrente.find(m => m.tipoPagamento === 0).desconto}
                                buttonText={t(tokens.common[getButtonText('bill', isSimplePayment(Array.isArray(paymentTypes) && paymentTypes.find(x => x?.matricula_id === student.registrationid)))])}
                                onClick={() => openSimplePayment(student, matricula.unidade_Id)}
                                tooltipData={toolTipDescription}
                                tooltipOnClick={() => {
                                  setOpenModalKnowMoreSimplePayment(true)
                                  setSelectedMatricula(matricula)
                                  setSelectedMatriculaId(student.registrationid)
                                }}
                                disabled={isSimplePayment(Array.isArray(paymentTypes) && paymentTypes.find(x => x?.matricula_id === student.registrationid))}
                                selected={isSimplePayment(Array.isArray(paymentTypes) && paymentTypes.find(x => x?.matricula_id === student.registrationid))}
                              />
                            }
                            {(!cartaoLock?.mudancaCartao) &&
                              <SelectPayment
                                paymentType='creditCard'
                                matricula={student}
                                cardlLock={cartaoLock}
                                isLoading={loadedMatricula}
                                discount={paymentTypes.find(x => x?.matricula_id === student.registrationid)?.metodosDePagamentoRecorrente.find(m => m.tipoPagamento === 3).desconto}
                                buttonText={t(tokens.common[getButtonText('creditCard',isRecurrentPayment(Array.isArray(paymentTypes) && paymentTypes.find(x => x?.matricula_id === student.registrationid)))])}
                                redundantButtonText={t(tokens.common.viewCurrentCard)}
                                redudantButtonClick={() => openRecurrentPaymentViewOnly(student)}
                                onClick={() => openRecurrentPayment(student, matricula.unidade_Id)}
                                tooltipData={toolTipDescription}
                                hasLoadedDataCard={hasLoadedDataCard}
                                showRedudantButton={isRecurrentPayment(Array.isArray(paymentTypes) && paymentTypes.find(x => x?.matricula_id === student.registrationid))}
                                tooltipOnClick={() => {
                                  setOpenModalKnowMoreRecurrencePayment(true)
                                  setSelectedMatricula(student)
                                  setSelectedMatriculaId(student.registrationid)
                                }}
                                selected={isRecurrentPayment(Array.isArray(paymentTypes) && paymentTypes.find(x => x?.matricula_id === student.registrationid))}
                                disabled={false}
                              />
                            }
                            {(!daLock?.mudancaDa) &&
                              <SelectPayment
                                paymentType='recurringBill'
                                matricula={student}
                                cardLock={daLock}
                                isLoading={loadedMatricula}
                                discount={paymentTypes.find(x => x?.matricula_id === student.registrationid)?.metodosDePagamentoRecorrente.find(m => m.tipoPagamento === 2).desconto}
                                buttonText={t(tokens.common[getButtonText('recurringBill',isAutomaticDebit(Array.isArray(paymentTypes) && paymentTypes.find(x => x?.matricula_id === student.registrationid)))])}
                                onClick={() => openAutomaticDebit(student, matricula.unidade_Id)}
                                tooltipData={toolTipDescription}
                                tooltipOnClick={() => {
                                  setOpenModalKnowMoreAutomaticDebit(true)
                                  setSelectedMatricula(student)
                                  setSelectedMatriculaId(student.registrationid)
                                }}
                                disabled={isAutomaticDebit(Array.isArray(paymentTypes) && paymentTypes.find(x => x?.matricula_id === student.registrationid))}
                                selected={isAutomaticDebit(Array.isArray(paymentTypes) && paymentTypes.find(x => x?.matricula_id === student.registrationid))}
                              />
                            }
                          </>
                        </StudentList>
                      )
                    })}
                </CardContent>
              </Card>
              <KnowMoreAutomaticDebitDialog
                open={openModalKnowMoreAutomaticDebit}
                onClose={() => {
                  setOpenModalKnowMoreAutomaticDebit(false)
                }}
                discount={paymentTypes.find(x => x?.matricula_id === selectedMatriculaId)?.metodosDePagamentoRecorrente.find(m => m.tipoPagamento === 2).desconto}
                selectedUnidade={
                  selectedMatricula != null ? selectedMatricula?.unitycode : 0
                }
              />

              <KnowMoreRecurrencePaymentDialog
                open={openModalKnowMoreRecurrencePayment}
                discount={paymentTypes.find(x => x?.matricula_id === selectedMatriculaId)?.metodosDePagamentoRecorrente.find(m => m.tipoPagamento === 3).desconto}
                onClose={() => {
                  setOpenModalKnowMoreRecurrencePayment(false)
                }}
                selectedUnidade={
                  selectedMatricula != null ? selectedMatricula?.unitycode : 0
                }
              />

              <KnowMoreSimplePaymentDialog
                open={openModalKnowMoreSimplePayment}
                discount={paymentTypes.find(x => x?.matricula_id === selectedMatriculaId)?.metodosDePagamentoRecorrente.find(m => m.tipoPagamento === 0).desconto}
                onClose={() => {
                  setOpenModalKnowMoreSimplePayment(false)
                }}
              />

              <SimplePaymentDialog
                open={openModalSimplePayment}
                onClose={closeModalSimplePayment}
                setIsLoading={setIsLoading}
                onSuccess={onSuccessChangeSimplePayment}
                onError={onErrorChangeSimplePayment}
                selectedMatricula={selectedMatricula}
                setAcceptanceTermObj={setAcceptanceTermObj}
              />

              <RecurrencePaymentDialog
                open={openModalRecurrencePayment}
                onClose={closeModalRecurrentPayment}
                setIsLoading={setIsLoading}
                onSuccess={onSuccessChangeRecurrencePayment}
                onError={onErrorChangeRecurrencePayment}
                selectedMatricula={selectedMatricula}
                editable={recurrencyIsEditable}
                showInstallments={isRecurrentPayment()}
                isCardChanging={isRecurrentPayment()}
                selectedInfo={{
                  selectedMatriculaId,
                  selectedDataAreaId,
                  selectedResponsibleId,
                  unitycode: selectedMatricula?.unitycode
                }}
                setAcceptanceTermObj={setAcceptanceTermObj}
              />

              <AutomaticDebitDialog
                open={openModalAutomaticDebit}
                onClose={closeModalAutomaticDebit}
                setIsLoading={setIsLoading}
                onSuccess={onSuccessChangeAutomaticDebit}
                onError={onErrorChangeAutomaticDebit}
                selectedMatricula={selectedMatricula}
                setAcceptanceTermObj={setAcceptanceTermObj}
                data={data}
              />

              {registrationHasError && (
                <Card>
                  <CardContent>
                    <Typography
                      gutterBottom
                      component='p'
                      color={'textPrimary'}
                    >
                      <FormattedMessage id='finance-negotiation-payment-type-not-found' />
                    </Typography>
                  </CardContent>
                </Card>
              )}
            </div>
          </Stack>
        </Card>
      </Scrollbar>
    </Box>
  )
})
