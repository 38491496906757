import React, {Fragment, useEffect, useRef, useState} from 'react'
import Tour from 'reactour'
import {findLastKey} from 'lodash'
import {useDispatch, useSelector} from 'react-redux'
import {isTourEnabledSelector, permissionsSelector} from '../../modules/Login/selectors/user'
import {tourList, tourListMobile} from './constants'
import {deactiveTour} from '../actions'
import {useHistory} from 'react-router-dom'
import {makeStyles} from "@mui/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {useTheme} from "@mui/material/styles";
import {ArrowBack, ArrowForward} from "@mui/icons-material";
import useMock from "../../hooks/use-mock";
import {setRecoil} from "recoil-nexus";
import {isMobileNavOpenState} from "../../recoil/atoms/isMobileNabOpenState";

const useStyles = makeStyles({
  rootTour: {
    opacity: 0.7
  }
})

const saveHistorySteps = (step, current, history, tourRef) => {
  if (step.nextTo && step.nextTo !== window.location.pathname) {
    if (!tourRef.current.state.navegation || !tourRef.current.state.navegation[step.nextTo]) {
      const lastNavegation = tourRef.current.state.navegation && tourRef.current.state.navegation[findLastKey(tourRef.current.state.navegation, 'lastTour')]
      tourRef.current.state.navegation = {
        ...tourRef.current.state.navegation,
        [step.nextTo]: {
          initialTour: lastNavegation ? lastNavegation.lastTour + 1 : 0,
          lastTour: current
        }
      }
    }
    history.push(step.nextTo)
  }
}

const setFocusElement = (current, tours, history, tourRef) => {
  const step = tours[current]
  setRecoil(isMobileNavOpenState, step?.isMenuOpen);
  if (current && current > 1) {
    const selector = document.querySelector(step.selector)
    if (selector) {
      selector.scrollIntoView();
    } else {
      setInterval(() => {
        const selector = document.querySelector(step.selector)
        if (selector) {
          selector.scrollIntoView();
          tourRef.current.showStep()
        }
      }, 200)
    }
  }
}

export default () => {
  const isTourOpen = useSelector(isTourEnabledSelector)
  const theme = useTheme()
  const [tours, setTours] = useState([])
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'))
  const dispatch = useDispatch()
  const permissions = useSelector(permissionsSelector)
  const tourRef = useRef()
  const history = useHistory()
  const classes = useStyles()
  const {enableMock, disableMock} = useMock();

  useEffect(() => {
    if (isSmall) {
      setTours(tourListMobile);
    } else {
      setTours(tourList)
    }
  }, [permissions, isSmall])

  useEffect(() => {
    if (isTourOpen) {
      enableMock()
    }
  }, [isTourOpen])

  const closeTour = () => {
    dispatch(deactiveTour())
    // enableBodyScroll(document.body)
    disableMock()
  }

  const onAfterOpen = target => {
    tourRef.current.gotoStep(0)
    // disableBodyScroll(target)
  }

  const onBeforeClose = target => {
    // enableBodyScroll(target)
    // history.push(PATH_ROUTE.CADASTRO_CONCLUIDO)
  }

  const prevStep = () => {
    const step = tours[tourRef.current.state.current]

    if (step.prevTo) {
      const navegation = tourRef.current.state.navegation[window.location.pathname]
      history.push(step.prevTo)
      if (navegation) {
        tourRef.current.gotoStep(navegation.initialTour)
        return
      }
    }

    if (step && step.prevAction) {
      step.prevAction(history)
    }
    tourRef.current.prevStep()
  }

  const nextStep = () => {
    const step = tours[tourRef.current.state.current]
    saveHistorySteps(step, tourRef.current.state.current, history, tourRef)
    if (step && step.nextAction) {
      step.nextAction(history)
    }
    tourRef.current.nextStep()
  }

  if (tours.length < 1) {
    return null
  }

  return (
    <Fragment>
      <Tour
        ref={tourRef}
        closeWithMask={false}
        accessibilityOptions={{
          showNavigationScreenReaders: false,
        }}
        getCurrentStep={(current) => setFocusElement(current, tours, history, tourRef)}
        onRequestClose={closeTour}
        steps={tours}
        isOpen={isTourOpen}
        maskClassName={classes.rootTour}
        nextButton={<ArrowForward />}
        prevButton={<ArrowBack style={{ paddingLeft: 85, width: 'auto' }} />}
        disableInteraction={true}
        nextStep={nextStep}
        prevStep={prevStep}
        rounded={5}
        accentColor={theme.palette.primary.main}
        onAfterOpen={onAfterOpen}
        onBeforeClose={onBeforeClose}
        showNumber={false}
        showNavigation={false}
      />
    </Fragment>)
}
