import React, {useState} from 'react';
import {useHistory, useLocation} from "react-router-dom";
import Version from "../../components/Version/Version";
import Layout from "../../layouts/auth/layout";
import {LoadingButton} from "@mui/lab";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import * as Yup from 'yup';
import {useFormik} from "formik";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import env from "../../env";
import {toast} from "react-toastify";
import PasswordStrengthBar from "react-password-strength-bar";
import Link from "@mui/material/Link";

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .required("Por favor digite sua senha.")
    .min(3, 'A senha deve ter no mínimo 4 caracteres.')
    .max(100, 'A senha deve ter no máximo 100 caracteres.')
    .matches(
      /^(?=.*[a-z])/,
      "A senha deve conter pelo menos uma letra minúscula."
    )
    .matches(
      /^(?=.*[A-Z])/,
      "A senha deve conter pelo menos uma letra maiúscula."
    )
    .matches(
      /^(?=.*[0-9])/,
      "A senha deve conter pelo menos um número."
    )
    .matches(
      /^(?=.*[!@#\$%\^&\*])/,
      "A senha deve conter pelo menos um caractere especial."
    ),
  passwordConfirm: Yup.string().oneOf([Yup.ref('password'), null], 'As senhas não conferem.').required("Por favor digite novamente sua senha."),
});

const CriarSenha: React.FC = () => {
  const history = useHistory();
  const { search } = useLocation();
  const [loading, setLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      password: '',
      passwordConfirm: ''
    },
    validationSchema,
    enableReinitialize: false,
    onSubmit: async (values): Promise<void> => {
      if (loading) {
        return;
      }
      setLoading(true);
      const params = new URLSearchParams(search);
      const id = params.get('id');

      fetch(env.apiUrl+"api/RedefinirSenha/" + id, {
        method: "PATCH",
        mode: "cors",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ SenhaSite: values.password })
      })
        .then(res => res.json())
        .then((result) => {
          if (result != null && result.id != null) {
            history.push('/?mensagemCriada=true');
          }
        }).catch(() => {
          toast.error('Erro ao criar/atualizar senha!', {autoClose: 5000});
        }).finally(() => setLoading(false));
    }
  });
  const [showPassword, setShowPassword] = React.useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleClickShowPasswordConfirm = () => setShowPasswordConfirm((show) => !show);

  const handleMouseDownPasswordConfirm = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <Box
      sx={{
        height: '100%',
        backgroundImage: 'url(/img/login-bg.webp)',
        backgroundSize: 'cover',
      }}
    >
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={2}
        sx={{
          height: '100%',
        }}
      >
        <Layout hideHeader>
          <form
            onSubmit={formik.handleSubmit}
          >
            <Typography
              variant="h5"
              component="h1">
              Crie sua senha
            </Typography>

            <Typography
              variant="subtitle1"
              component="p"
              mt={2}
            >
              Agora crie e confirme qual será sua senha de acesso
            </Typography>

            <Stack
              spacing={2}
              sx={{
                mt: 2,
                mb: 2
              }}
            >
              <TextField
                type={showPassword ? 'text' : 'password'}
                error={!!(formik.touched.password && formik.errors.password)}
                fullWidth
                helperText={formik.touched.password && formik.errors.password}
                name="password"
                autoComplete='new-password'
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.password}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? (
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={24} height={24}>
                            <path
                              d="M12 17.5C8.2 17.5 4.8 15.4 3.2 12H1C2.7 16.4 7 19.5 12 19.5S21.3 16.4 23 12H20.8C19.2 15.4 15.8 17.5 12 17.5Z"/>
                          </svg>
                        ) : (
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={24} height={24}>
                            <path
                              d="M12,9A3,3 0 0,1 15,12A3,3 0 0,1 12,15A3,3 0 0,1 9,12A3,3 0 0,1 12,9M12,4.5C17,4.5 21.27,7.61 23,12C21.27,16.39 17,19.5 12,19.5C7,19.5 2.73,16.39 1,12C2.73,7.61 7,4.5 12,4.5M3.18,12C4.83,15.36 8.24,17.5 12,17.5C15.76,17.5 19.17,15.36 20.82,12C19.17,8.64 15.76,6.5 12,6.5C8.24,6.5 4.83,8.64 3.18,12Z"/>
                          </svg>
                        )}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                label="Senha"
              />

              <PasswordStrengthBar
                password={formik.values.password}
                scoreWords={['Muito fraca', 'Fraca', 'Regular', 'Forte', 'Muito forte']}
                shortScoreWord="Muito curta"
              />

              <TextField
                type={showPasswordConfirm ? 'text' : 'password'}
                error={!!(formik.touched.passwordConfirm && formik.errors.passwordConfirm)}
                fullWidth
                helperText={formik.touched.passwordConfirm && formik.errors.passwordConfirm}
                name="passwordConfirm"
                autoComplete='new-password'
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.passwordConfirm}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPasswordConfirm}
                        onMouseDown={handleMouseDownPasswordConfirm}
                        edge="end"
                      >
                        {showPasswordConfirm ? (
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={24} height={24}>
                            <path
                              d="M12 17.5C8.2 17.5 4.8 15.4 3.2 12H1C2.7 16.4 7 19.5 12 19.5S21.3 16.4 23 12H20.8C19.2 15.4 15.8 17.5 12 17.5Z"/>
                          </svg>
                        ) : (
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={24} height={24}>
                            <path
                              d="M12,9A3,3 0 0,1 15,12A3,3 0 0,1 12,15A3,3 0 0,1 9,12A3,3 0 0,1 12,9M12,4.5C17,4.5 21.27,7.61 23,12C21.27,16.39 17,19.5 12,19.5C7,19.5 2.73,16.39 1,12C2.73,7.61 7,4.5 12,4.5M3.18,12C4.83,15.36 8.24,17.5 12,17.5C15.76,17.5 19.17,15.36 20.82,12C19.17,8.64 15.76,6.5 12,6.5C8.24,6.5 4.83,8.64 3.18,12Z"/>
                          </svg>
                        )}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                label="Repita a senha"
              />
            </Stack>

            <LoadingButton
              fullWidth
              variant="contained"
              color="primary"
              id="btnLogin"
              type="submit"
              loading={loading}
              disabled={!formik.isValid}
            >
              Confirmar
            </LoadingButton>

            {process.env.REACT_APP_HELP_URL && (
              <Box
                sx={{
                  mt: 2,
                  textAlign: 'center'
                }}
              >
                <Link
                  href={process.env.REACT_APP_HELP_URL ?? "https://gruposeb.zendesk.com/hc/pt-br"}
                  target="_blank"
                >
                  Preciso de ajuda
                </Link>
              </Box>
            )}
          </form>
        </Layout>

        <Stack
          mt={4}
        >
          <Version
            color="white"
          />
        </Stack>
      </Stack>
    </Box>
  )
}


export default CriarSenha;
