import React, {ChangeEvent, useCallback, useEffect} from "react";
import SvgIcon from "@mui/material/SvgIcon";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import AccordionDetails from "@mui/material/AccordionDetails";
import {Checkbox, FormControlLabel, FormGroup, Skeleton} from "@mui/material";
import {ListSidebar} from "../../components/list-sidebar";
import useMediaQuery from "@mui/material/useMediaQuery";
import {Theme} from "@mui/material/styles/createTheme";
import Stack from "@mui/material/Stack";
import XIcon from '@untitled-ui/icons-react/build/esm/X';
import IconButton from '@mui/material/IconButton';
import Button from "@mui/material/Button";
import {useRecoilState, useRecoilValue} from "recoil";
import homeCardFiltersSelectors from "../../recoil/selectors/homeCardFiltersSelector";
import {homeCardAppliedFiltersState} from "../../recoil/atoms/homeCardAppliedFiltersState";
import {razaoStatus, razaoStatusNome} from "../../utils/razaoStatus";
import Box from "@mui/material/Box";
import Zoom from "@mui/material/Zoom";
import {default as enumServicoTipoItem} from "../../utils/servicoTipoitem";
import FooterMenu from "../../layouts/FooterMenu";
import useMock from "../../hooks/use-mock";

interface Props {
  container?: HTMLDivElement | null;
  openSidebar: boolean
  setOpenSidebar: (value: boolean) => void
  loading?: boolean
}

const Filtros: React.FC<Props> = (props) => {
  const {
    container,
    openSidebar,
    setOpenSidebar,
    loading
  } = props
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const [filters, setFilters] = useRecoilState(homeCardAppliedFiltersState)
  const {isMocked} = useMock()

  const {
    estudanteName,
    anoLetivo_Name,
    serviceStatus,
    servicoTipoItem
  } = useRecoilValue(homeCardFiltersSelectors);

  const handleFiltersClose = useCallback((): void => setOpenSidebar(false), []);

  const handleCustomerToggle = useCallback(
    (event: ChangeEvent<HTMLInputElement>): void => {
      setFilters(prevFilters => ({
        ...prevFilters,
        [event.target.name]: event.target.checked
          ? [...(prevFilters[event.target.name] || []), event.target.value]
          : (prevFilters[event.target.name] || []).filter((value: string) => value !== event.target.value)
      }))
    },
    []
  );

  useEffect(() => {
    const applyFilter = (name: string, value: string | number) => {
      setFilters((prevFilters) => ({
        ...prevFilters,
        [name]: [value]
      }))
    }
    if (isMocked) return

    if (anoLetivo_Name.find(a => a === (new Date().getFullYear()).toString())) {
      applyFilter("anoLetivo_Name", (new Date().getFullYear()).toString())
    }
    if (anoLetivo_Name.find(a => a === (new Date().getFullYear() + 1).toString())) {
      applyFilter("anoLetivo_Name", (new Date().getFullYear() + 1).toString())
    }
    if (serviceStatus.find(a => String(a) === String(razaoStatus.zero_6_matriculado))) {
      applyFilter("razaoStatus_Value", String(razaoStatus.zero_6_matriculado))
    }
    if (serviceStatus.find(a => String(a) === String(razaoStatus.zero_1_iniciada))) {
      applyFilter("razaoStatus_Value", String(razaoStatus.zero_1_iniciada))
    }
    if (serviceStatus.find(a => String(a) === String(razaoStatus.ponto_1_preReserva))) {
      applyFilter("razaoStatus_Value", String(razaoStatus.ponto_1_preReserva))
    }
    if (serviceStatus.find(a => String(a) === String(razaoStatus.ponto_2_reservada))) {
      applyFilter("razaoStatus_Value", String(razaoStatus.ponto_2_reservada))
    }
  }, [anoLetivo_Name, serviceStatus, isMocked]);

  useEffect(() => {
    if (isMocked) {
      setFilters({})
    }
  }, [isMocked]);

  const renderCheck = (name: string, value: string) => {
    return (
      <Checkbox
        checked={(filters[name] || []).includes(String(value))}
        onChange={handleCustomerToggle}
      />
    )
  }

  if (loading) {
    return (
      <Box
        sx={{
          pt: lgUp ? 2 : 0,
        }}
      >
        <ListSidebar
          container={container}
          onClose={handleFiltersClose}
          open={openSidebar}
        >
          <Stack
            spacing={0}
            sx={{p: 0}}
          >
            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon/>}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{
                  color: 'primary'
                }}
              >
                <Typography>
                  <Skeleton variant="text" width={100}/>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Skeleton variant="rectangular" width="100%" height={40}/>
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon/>}
                aria-controls="panel1b-content"
                id="panel1b-header"
                sx={{
                  color: 'primary'
                }}
              >
                <Typography>
                  <Skeleton variant="text" width={100}/>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Skeleton variant="rectangular" width="100%" height={40}/>
              </AccordionDetails>
            </Accordion>
          </Stack>
        </ListSidebar>
      </Box>
    )
  }


  return (
    <Box
      sx={{
        pt: lgUp ? 2 : 0,
        height: '100%'
      }}
    >
      <ListSidebar
        container={container}
        onClose={handleFiltersClose}
        open={openSidebar}
      >
        <Stack
          direction="column"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            height: '100%',
            overflowY: 'auto',
          }}
        >
          <Box
            data-tut="tur-menu-home-filter"
          >
            <Stack
              alignItems="center"
              justifyContent="space-between"
              direction="row"
              sx={{
                width: '100%',
              }}
            >
              <Button
                fullWidth
                startIcon={(
                  <SvgIcon>
                    <FilterAltOffIcon/>
                  </SvgIcon>
                )}
                variant="text"
                onClick={() => setFilters({})}
              >
                Limpar Filtros
              </Button>
              {!lgUp && (
                <IconButton onClick={handleFiltersClose}>
                  <SvgIcon>
                    <XIcon/>
                  </SvgIcon>
                </IconButton>
              )}
            </Stack>
            <Stack
              spacing={0}
              sx={{p: 0}}
            >
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon/>}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{
                    color: 'primary'
                  }}
                >
                  <Typography>Tipo de Item</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <FormGroup>
                    {servicoTipoItem.map(s => (
                      <Zoom key={s} in={true}>
                        <FormControlLabel
                          control={renderCheck("servicoTipoItem", s)}
                          label={enumServicoTipoItem[parseInt(s)]}
                          value={s}
                          name="servicoTipoItem"
                        />
                      </Zoom>
                    ))}
                  </FormGroup>
                </AccordionDetails>
              </Accordion>

              <Accordion defaultExpanded>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon/>}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                  color="primary"
                >
                  <Typography>Ano Letivo</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <FormGroup>
                    {anoLetivo_Name.map((y, i) => (
                      <Zoom
                        key={y}
                        in={true}
                        timeout={i * 200}
                      >
                        <FormControlLabel
                          control={renderCheck("anoLetivo_Name", y)}
                          label={y}
                          value={y}
                          name="anoLetivo_Name"
                        />
                      </Zoom>
                    ))}
                  </FormGroup>
                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon/>}
                  aria-controls="panel2c-content"
                  id="panel2c-header"
                  color="primary"
                >
                  <Typography>Estudantes</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <FormGroup>
                    {estudanteName.map((name) => (
                      <Zoom key={name} in={true}>
                        <FormControlLabel
                          control={renderCheck("estudante_Name", name)}
                          label={name}
                          value={name}
                          name="estudante_Name"
                        />
                      </Zoom>
                    ))}
                  </FormGroup>
                </AccordionDetails>
              </Accordion>

              <Accordion defaultExpanded>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon/>}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                  color="primary"
                >
                  <Typography>Status do Serviço</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <FormGroup>
                    {serviceStatus.map(s => (
                      <Zoom key={s} in={true}>
                        <FormControlLabel
                          control={renderCheck("razaoStatus_Value", s)}
                          label={razaoStatusNome[s]}
                          value={s}
                          name="razaoStatus_Value"
                        />
                      </Zoom>
                    ))}
                  </FormGroup>
                </AccordionDetails>
              </Accordion>
            </Stack>
          </Box>

          <FooterMenu />
        </Stack>
      </ListSidebar>
    </Box>
  )
}

export default Filtros
