import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import CloseIcon from '@mui/icons-material/Close';
import {Button, Dialog, DialogContent, DialogContentText, DialogTitle, IconButton, SvgIcon, useTheme, Zoom} from '@mui/material';
import MuiAccordion, {AccordionProps} from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary, {AccordionSummaryProps,} from '@mui/material/AccordionSummary';
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from '@mui/material/Typography';
import {styled} from '@mui/material/styles';
import {Theme} from "@mui/material/styles/createTheme";
import useMediaQuery from "@mui/material/useMediaQuery";
import * as React from 'react';
import {useRecoilState, useRecoilValue} from "recoil";
import {IMeioPagamento} from "../../@types/IServicoResumoQuery";
import DebitoMetodoForm from '../../components/DebitoMetodoForm/DebitoMetodoForm';
import WalletModal from "../../components/WalletModal/WalletModal";
import { metodoPagamentoState } from "../../recoil/atoms/metodoPagamentoState";
import {selectedDiaVencimentoPagamentoState} from "../../recoil/atoms/selectedDiaVencimentoPagamentoState";
import {selectedEntradaCreditCardState} from '../../recoil/atoms/selectedEntradaCreditCardState';
import {selectedRecorrenciaCreditCardState} from '../../recoil/atoms/selectedRecorrenciaCreditCardState';
import metodosBloqueadosSelector from "../../recoil/selectors/metodosBloqueadosSelector";
import tipoPagamento, { meiosPagamento, meioDePagamentoString } from "../../utils/tipoPagamento";
import DescontoMetodoFlag from "./DescontoMetodoFlag";
import {TransitionProps} from '@mui/material/transitions';
import {selectedMeioPagamentoEntradaState} from "../../recoil/atoms/selectedMeioPagamentoEntradaState";
import {selectedMeioPagamentoParcelamentoState} from "../../recoil/atoms/selectedMeioPagamentoParcelamentoState";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Zoom ref={ref} {...props} />;
});



const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({theme}) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{fontSize: '0.9rem'}}/>}
    {...props}
  />
))(({theme}) => ({
  backgroundColor: theme.palette.primary.lightest,
  color: 'black',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper': {
    color: "black",
    '& .checked': {
      display: 'none',
    },
    '& .unchecked': {
      display: 'block',
    },
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(0deg)',
    color: "black",
    '& .checked': {
      display: 'block',
    },
    '& .unchecked': {
      display: 'none',
    },
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
    color: "black",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({theme}) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const CheckIcon = ({required}: { required?: boolean }) => {
  const theme = useTheme();
  return (
    <Box>
      <svg
        className="checked"
        fill={theme.palette.primary.main}
        width={24}
        height={24}
        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <path
          d="M12 2C6.5 2 2 6.5 2 12S6.5 22 12 22 22 17.5 22 12 17.5 2 12 2M10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z"/>
      </svg>

      <svg
        className="unchecked"
        fill={required ? theme.palette.error.main : theme.palette.primary.main}
        width={24}
        height={24}
        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <path
          d="M12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4A8,8 0 0,1 20,12A8,8 0 0,1 12,20M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z"/>
      </svg>
    </Box>
  )
}

interface MetodoAccordionProps {
  entrada?: boolean
  onSelectMeioPagamento?: (meioPagamento: IMeioPagamento) => void
}

const MetodoAccordion: React.FC<MetodoAccordionProps> = props => {
  const smUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));
  const metodoPagamento = useRecoilValue(metodoPagamentoState);
  const metodos = props.entrada ? metodoPagamento?.entrada : metodoPagamento?.mensais;
  const boletoMetodo = metodos?.find(metodo => metodo.meioDePagamento === meioDePagamentoString.Boleto_Bancario);
  const creditoMetodo = metodos?.find(metodo => metodo.meioDePagamento === meioDePagamentoString.Cartao_Credito);
  const debitoMetodo = metodos?.find(metodo => metodo.meioDePagamento === meioDePagamentoString.Debito_automatico);
  const creditoRecorrenteMetodo = metodos?.find(metodo => metodo.meioDePagamento === meioDePagamentoString.Cartao_Credito_Recorrente);
  const possuiBoleto = metodos?.some(metodo => metodo.meioDePagamento === meioDePagamentoString.Boleto_Bancario);
  const possuiCredito = metodos?.some(metodo => metodo.meioDePagamento === meioDePagamentoString.Cartao_Credito);
  const possuiCreditoRecorrente = metodos?.some(metodo => metodo.meioDePagamento === meioDePagamentoString.Cartao_Credito_Recorrente);
  const possuiDebito = metodos?.some(metodo => metodo.meioDePagamento === meioDePagamentoString.Debito_automatico);
  const [expanded, setExpanded] = React.useState<number | false>(false);
  const locks = useRecoilValue(metodosBloqueadosSelector);
  const [selectedDiaVencimentoPagamento, setSelectedDiaVencimentoPagamento] = useRecoilState(selectedDiaVencimentoPagamentoState);
  const [selectedEntradaCard, setSelectedEntradaCreditCard] = useRecoilState(selectedEntradaCreditCardState);
  const [selectedRecorrenciaCard, setSelectedRecorrenciaCreditCard] = useRecoilState(selectedRecorrenciaCreditCardState);
  const [showModal, setShowModal] = React.useState<null | number>(null);
  const selectedMetodoEntrada = useRecoilValue(selectedMeioPagamentoEntradaState);
  const selectedMetodoParcelamento = useRecoilValue(selectedMeioPagamentoParcelamentoState);

  const modalsContent = [
    {
      type: tipoPagamento.Cartao_Credito_Recorrente,
      title: 'Cartão de Crédito Recorrente',
      content: [
        //TODO: Precisa ser dinâmico
        creditoRecorrenteMetodo?.servicosUnidade.some(x => x.percentualDesconto > 0) ? `Ganhe desconto nas parcelas do serviço regular pagas em recorrência.` : '',
        "Não comprometa seu limite. Essa opção é como uma assinatura recorrente. O pagamento é incluído mês a mês na data de vencimento da fatura do seu cartão, sem comprometer o limite total.",
        "Ganhe tempo, o pagamento é automático.",
        "Ganhe conveniência, sem se preocupar com datas de vencimento.",
        "Acumule pontos em programas de milhagens que participar."
      ]
    },
    {
      type: tipoPagamento.Cartao_Debito,
      title: 'Débito em Conta',
      content: [
        //TODO: Precisa ser dinâmico
        debitoMetodo?.servicosUnidade.some(x => x.percentualDesconto > 0) ? `Ganhe desconto nas parcelas do serviço regular pagas em débito automático.` : '',
        "Ganhe conveniência, sem se preocupar com datas de vencimento.",
        "Ganhe tempo, o pagamento é automático e vem debitado mês a mês na sua conta.",
      ]
    },
    {
      type: tipoPagamento.Cartao_Credito,
      title: 'Cartão de Crédito',
      content: [
        creditoMetodo?.servicosUnidade.some(x => x.percentualDesconto > 0) ? `Ganhe desconto nas parcelas do serviço regular pagas em cartão de crédito.` : '',
        "Mensalmente você acessa nosso portal financeiro e insere os dados do seu cartão.",
        "Não há desconto para essa forma de pagamento.",
        "Como o pagamento não é automático, demanda atenção com a data de pagamento.",
      ]
    },
    {
      type: tipoPagamento.Boleto_Bancario,
      title: 'Boleto Bancário',
      content: [
        boletoMetodo?.servicosUnidade.some(x => x.percentualDesconto > 0) ? `Ganhe desconto nas parcelas do serviço regular pagas em boleto.` : '',
        "Os boletos podem ser pagos em qualquer rede bancária.",
        "Como não é um pagamento automático, demanda atenção com a data de pagamento.",
        "Mensalmente você acessa nosso portal financeiro e faz o download do seu boleto.",
        "Não há desconto para essa forma de pagamento.",
        "O prazo de compensação do boleto é de 3 dias úteis."
      ]
    }
  ]

  const handleChange = (panel: number) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    if (panel === expanded) return;
    setExpanded(panel ?? false);
    if (props.onSelectMeioPagamento && Object.entries(meiosPagamento)[panel][1]) {
      props.onSelectMeioPagamento(Object.entries(meiosPagamento)[panel][1]);
    }
  };

  const handleClickShowDetails = (tipoPagamento: number) => (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();

    setShowModal(tipoPagamento);
  }

  const handleCloseModal = () => {
    setShowModal(null);
  }

  return (
    <div>
    
      {(possuiBoleto && !(props.entrada && locks.entradaBoletoLock) && !(!props.entrada && locks.recorrenteBoletoLock)) && (
        <Accordion
          expanded={expanded === tipoPagamento.Boleto_Bancario}
          onChange={handleChange(tipoPagamento.Boleto_Bancario)}
          sx={{
            position: 'relative',
            overflow: 'show',
          }}
        >
          <AccordionSummary
            expandIcon={
              <CheckIcon
                required={props.entrada ? selectedMetodoEntrada === null : selectedMetodoParcelamento === null}
              />
            }
            aria-controls="panel1d-content"
            id="panel1d-header"
            sx={{
              pt: 2
            }}
          >
            {boletoMetodo && boletoMetodo?.servicosUnidade.some(x => x.percentualDesconto > 0) && (
              <DescontoMetodoFlag
                valor={0}
                melhor={true}
              />
            )}
            <Stack
              direction="column"
            >
              <Typography
                fontWeight="bold"
              >
                Boleto Bancário
              </Typography>

              <Typography>
                Os boletos podem ser pagos mensalmente em qualquer banco na data combinada, demandando mais atenção e
                tempo com vencimentos e pagamentos.
              </Typography>

              <Box
                sx={{
                  ml: 'auto'
                }}
              >
                <Button
                  variant="text"
                  sx={{
                    color: "black",
                    textDecoration: "underline"
                  }}
                  onClick={handleClickShowDetails(tipoPagamento.Boleto_Bancario)}
                >
                  Entenda mais...
                </Button>
              </Box>
            </Stack>
          </AccordionSummary>

          {!props.entrada && (
            <AccordionDetails>
              <TextField
                fullWidth
                label="Datas de vencimento"
                select
                error={selectedDiaVencimentoPagamento === null}
                helperText={selectedDiaVencimentoPagamento === null ? "Selecione uma data de vencimento" : ""}
                onChange={event => setSelectedDiaVencimentoPagamento(event.target.value as string)}
                value={selectedDiaVencimentoPagamento ?? ""}
                required
                disabled={boletoMetodo?.diasVencimento?.length === 1 && selectedDiaVencimentoPagamento !== null}
              >
                {boletoMetodo?.diasVencimento?.map((vencimento, i) => (
                  <MenuItem
                    key={vencimento ?? i}
                    value={vencimento ?? i}
                  >
                    Todo dia {vencimento}
                  </MenuItem>
                ))}
              </TextField>
            </AccordionDetails>
          )}
        </Accordion>
      )}

      {(possuiCredito && props.entrada && !(props.entrada && locks.entradaCartaoDeCreditoLock)) && (
        <Accordion
          expanded={expanded === tipoPagamento.Cartao_Credito}
          onChange={handleChange(tipoPagamento.Cartao_Credito)}
          sx={{
            position: 'relative',
            overflow: 'show',
          }}
        >
          <AccordionSummary
            expandIcon={
              <CheckIcon
                required={props.entrada ? selectedMetodoEntrada === null : selectedMetodoParcelamento === null}
              />
            }
            aria-controls="panel2d-content"
            id="panel2d-header"
            sx={{
              pt: 2
            }}
          >
            {creditoMetodo && creditoMetodo?.servicosUnidade.some(x => x.percentualDesconto > 0) && (
              <DescontoMetodoFlag
                valor={0}
                melhor={true}
              />
            )}
            <Stack
              direction="column"
            >
              <Typography
                fontWeight="bold"
              >
                Cartão de Crédito
              </Typography>
            </Stack>
          </AccordionSummary>

          <AccordionDetails>
            <Stack
              direction="row"
              justifyContent="center"
            >
              <WalletModal
                show={expanded === tipoPagamento.Cartao_Credito}
                onSelectCard={card => {
                  if (props.entrada)
                    setSelectedEntradaCreditCard(card);
                  else
                    setSelectedRecorrenciaCreditCard(card);
                }}
                selectedCard={props.entrada ? selectedEntradaCard?.card_id : selectedRecorrenciaCard?.card_id}
              />
            </Stack>
          </AccordionDetails>
        </Accordion>
      )}

      {(possuiDebito && !(!props.entrada && locks.recorrenteDALock)) && (
        <Accordion
          expanded={expanded === tipoPagamento.Cartao_Debito}
          onChange={handleChange(tipoPagamento.Cartao_Debito)}
          sx={{
            position: 'relative',
            overflow: 'show',
          }}
        >
          <AccordionSummary
            expandIcon={
              <CheckIcon
                required={props.entrada ? selectedMetodoEntrada === null : selectedMetodoParcelamento === null}
              />
            }
            aria-controls="panel3d-content"
            id="panel3d-header"
            sx={{
              pt: 2
            }}
          >
            {debitoMetodo && debitoMetodo?.servicosUnidade.some(x => x.percentualDesconto > 0) && (
              <DescontoMetodoFlag
                valor={0}
                melhor={true}
              />
            )}

            <Stack
              direction="column"
            >
              <Typography
                fontWeight="bold"
              >
                {!smUp && debitoMetodo && debitoMetodo?.servicosUnidade.some(x => x.percentualDesconto > 0) 
                  ? "Débito aut. rec." : "Débito Automático Recorrente"}
              </Typography>

              <Typography>
                O pagamento é automático e o serviço vem debitado mês a mês na sua conta. Além da comodidade, você ganha
                desconto.
              </Typography>

              <Box
                sx={{
                  ml: 'auto'
                }}
              >
                <Button
                  variant="text"
                  sx={{
                    color: "black",
                    textDecoration: "underline"
                  }}
                  onClick={handleClickShowDetails(tipoPagamento.Cartao_Debito)}
                >
                  Veja todos os benefícios...
                </Button>
              </Box>
            </Stack>
          </AccordionSummary>

          <AccordionDetails>
            <DebitoMetodoForm/>
          </AccordionDetails>
        </Accordion>
      )}

      {(possuiCreditoRecorrente && !(!props.entrada && locks.recorrenteCartaoDeCreditoLock)) && (
        <Accordion
          expanded={expanded === tipoPagamento.Cartao_Credito_Recorrente}
          onChange={handleChange(tipoPagamento.Cartao_Credito_Recorrente)}
          sx={{
            position: 'relative',
            overflow: 'show',
          }}
        >
          <AccordionSummary
            expandIcon={
              <CheckIcon
                required={props.entrada ? selectedMetodoEntrada === null : selectedMetodoParcelamento === null}
              />
            }
            aria-controls="panel4d-content"
            id="panel4d-header"
            sx={{
              pt: 2
            }}
          >
            {creditoRecorrenteMetodo && creditoRecorrenteMetodo?.servicosUnidade.some(x => x.percentualDesconto > 0) && (
              <DescontoMetodoFlag
                valor={0}
                melhor={true}
              />
            )}

            <Stack
              direction="column"
            >
              <Typography
                fontWeight="bold"
              >
                {!smUp && creditoRecorrenteMetodo && creditoRecorrenteMetodo?.servicosUnidade.some(x => x.percentualDesconto > 0) 
                  ? "Cartão cred. rec." : "Cartão de Crédito Recorrente"}
              </Typography>

              <Typography>
                Além de conceder desconto nas parcelas do serviço regular, esta opção não compromete o limite total do
                cartão, o pagamento é automático, cai no vencimento da sua fatura e ainda pode gerar pontos em programas
                de milhagens.
              </Typography>

              <Box
                sx={{
                  ml: 'auto'
                }}
              >
                <Button
                  variant="text"
                  sx={{
                    color: "black",
                    textDecoration: "underline"
                  }}
                  onClick={handleClickShowDetails(tipoPagamento.Cartao_Credito_Recorrente)}
                >
                  Veja todos os benefícios...
                </Button>
              </Box>
            </Stack>
          </AccordionSummary>

          <AccordionDetails>
            <Stack
              direction="row"
              justifyContent="center"
            >
              <WalletModal
                show={expanded === tipoPagamento.Cartao_Credito_Recorrente}
                onSelectCard={card => {
                  setSelectedRecorrenciaCreditCard(card);
                }}
                selectedCard={selectedRecorrenciaCard?.card_id}
              />
            </Stack>
          </AccordionDetails>
        </Accordion>
      )}


      <Dialog
        open={showModal !== null}
        onClose={handleCloseModal}
        maxWidth="md"
        fullWidth
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        TransitionComponent={Transition}
      >
        <DialogTitle id="scroll-dialog-title">
          {modalsContent.find(content => content.type === showModal)?.title}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseModal}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon/>
        </IconButton>

        <DialogContent dividers>
          <DialogContentText
            id="scroll-dialog-description"
            tabIndex={-1}
          >
            <Stack
              direction="column"
              spacing={2}
            >
              {modalsContent.find(content => content.type === showModal)?.content.filter(i => i.length).map((item, i) => (
                <Zoom
                  key={i}
                  in={true}
                  style={{
                    transitionDelay: `${i * 100}ms`,
                  }}
                  timeout={500}
                >
                  <Stack
                    direction="row"
                    spacing={2}
                  >
                    <SvgIcon
                      color="primary"
                      sx={{
                        width: 24,
                        height: 24,
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width={24}
                        height={24}
                        fill="currentColor"
                      >
                        <path
                          d="M12 2C6.5 2 2 6.5 2 12S6.5 22 12 22 22 17.5 22 12 17.5 2 12 2M12 20C7.59 20 4 16.41 4 12S7.59 4 12 4 20 7.59 20 12 16.41 20 12 20M16.59 7.58L10 14.17L7.41 11.59L6 13L10 17L18 9L16.59 7.58Z"/>
                      </svg>
                    </SvgIcon>

                    <Typography
                      key={i}
                    >
                      {item}
                    </Typography>
                  </Stack>
                </Zoom>
              ))}
            </Stack>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default MetodoAccordion;
