import React, {useState} from 'react';
import {Link, useHistory, useLocation} from "react-router-dom";
import Version from "../../components/Version/Version";
import Layout from "../../layouts/auth/layout";
import {LoadingButton} from "@mui/lab";
import TextField from "@mui/material/TextField";
import * as Yup from 'yup';
import {useFormik} from "formik";
import Typography from "@mui/material/Typography";
import useCpfCnpjMask from "../../hooks/use-cpf-cnpj-mask";
import useCpfCnpjErroMsg from "../../hooks/use-cpf-cnpj-erro-msg";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import {toast} from "react-toastify";
import {PATH_ROUTE} from "../../routes";
import env from "../../env";

const validationSchema = Yup.object().shape({
  username: Yup.string().required("Por favor digite seu CPF."),
});

const RedefinirSenha: React.FC = () => {
  const history = useHistory();
  const {search} = useLocation();
  const params = new URLSearchParams(search);
  const hash = params.get('hash');
  let titulo = "Redefinir Senha";
  let mensagem = "Informe o número do seu CPF para prosseguir com a redefinição da senha."
  let linkUrl = 'https://gruposeb.zendesk.com/hc/pt-br';
  let linkTitulo = 'Preciso de ajuda';
  if (hash) {
    titulo = "Olá!";
    mensagem = "Para começar a matrícula on-line, informe o número do seu CPF";
  } else {
    linkUrl = '/';
    linkTitulo = 'Sistema de matrícula Online';
  }

  const [loading, setLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      username: ''
    },
    validationSchema,
    enableReinitialize: false,
    onSubmit: async (values): Promise<void> => {
      if (loading) {
        return;
      }
      setLoading(true);

      let url = `${env.apiUrl}api/RedefinirSenha/get/${values.username}`;
      if (hash) {
        url = `${env.apiUrl}api/RedefinirSenha/get/${values.username}/${hash}`;
      }
      try {
        const resp = await fetch(url)

        if (resp.ok) {
          const result = await resp.json();
          if (result != null && result.id) {
            if (hash) {
              if (result.senha != null) {
                if (result.senha === hash) {
                  history.push(`${PATH_ROUTE.CRIAR_SENHA}?id=${result.id}`);
                } else {
                  toast.error('Hash invalida!');
                }
              }
            } else {
              history.push(`${PATH_ROUTE.HOME_SMO}?mensagem=true`);
            }
          }
        }
      } catch (e) {
        toast.error('Use o mesmo CPF que você cadastrou no site.', {autoClose: 5000});
      }
      setLoading(false);
    }
  });
  const maskedUsername = useCpfCnpjMask(formik.values.username);
  const errorUsername = useCpfCnpjErroMsg(formik.values.username);

  return (
    <Box
      sx={{
        height: '100%',
        backgroundImage: 'url(/img/login-bg.webp)',
        backgroundSize: 'cover',
      }}
    >
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={2}
        sx={{
          height: '100%',
        }}
      >
        <Layout hideHeader>
          <form
            onSubmit={formik.handleSubmit}
          >
            <Typography
              variant="h5"
              component="h1">
              {titulo}
            </Typography>

            <Typography
              variant="subtitle1"
              component="p"
              mt={2}
            >
              {mensagem}
            </Typography>

            <Stack
              spacing={2}
              sx={{
                mt: 2,
                mb: 2
              }}
            >
              <TextField
                id="cpf"
                error={!!(formik.touched.username && formik.errors.username || errorUsername)}
                fullWidth
                helperText={formik.touched.username && formik.errors.username || errorUsername}
                label="CPF"
                name="username"
                autoComplete={'username'}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={maskedUsername}
                placeholder="000.000.000-00"
              />
            </Stack>

            <LoadingButton
              fullWidth
              variant="contained"
              color="primary"
              id="btnLogin"
              type="submit"
              loading={loading}
              disabled={!formik.isValid}
            >
              Confirmar
            </LoadingButton>

            <Box
              sx={{
                mt: 2,
                textAlign: 'center'
              }}
            >
              <Link to={linkUrl}>
                {linkTitulo}
              </Link>
            </Box>
          </form>
        </Layout>

        <Stack
          mt={4}
        >
          <Version
            color="white"
          />
        </Stack>
      </Stack>
    </Box>
  )
}


export default RedefinirSenha;
