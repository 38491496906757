import React, {ReactElement} from "react";
import Card from "@mui/material/Card";
import {CardContent} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import {Theme} from "@mui/material/styles/createTheme";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import Slide from "@mui/material/Slide";
import { Logo } from "../../components/logo";
import Box from "@mui/material/Box";

interface Props {
  children?: ReactElement
  hideHeader?: boolean
}

const Layout: React.FC<Props> = ({children, hideHeader, ...rest}) => {
  const smUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));
  return (
    <Slide direction="up" in mountOnEnter unmountOnExit timeout={500}>
      <Card
        elevation={smUp ? 8 : 0}
        sx={{
          maxWidth: smUp ? 434 : 'calc(100% - 12px)',
        }}
        {...rest}
      >
        {!hideHeader && (
          <CardHeader
            title="Portal da família"
            // avatar={
            //   <Box
            //     sx={{
            //       backgroundImage: 'url(/img/logo-bg.png)',
            //       width: '176px',
            //       height: '200px',
            //       position: 'absolute',
            //       display: 'flex',
            //       transform: 'scale(0.5) translateY(-176px)',
            //       justifyContent: 'center',
            //       backgroundSize: 'cover',
            //       left: 0
            //     }}
            //   >
            //     <Logo/>
            //   </Box>
            // }
            sx={{
              position: 'relative'
            }}
            titleTypographyProps={{
              variant: 'h5',
              sx: {
                color: 'primary.main',
                textTransform: 'uppercase',
                textAlign: 'center',
                // pl: 12
              }
            }}
          />
        )}
        <CardContent>
          {children}
        </CardContent>
      </Card>
    </Slide>
  )
}

export default Layout;
