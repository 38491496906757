const servicoTipoItem = {
  284360004: "Complementar",
  284360006: "Eventos",
  284360005: "Material Complementar",
  284360001: "Material Didático",
  284360008: "Pré-vestibular",
  284360011: "Pré-vestibular Avulso",
  284360000: "Serviço Regular",
  284360002: "Taxa",
  284360007: "Taxa Complementar",
  284360003: "Upselling",
  284360010: "Upselling Avulso",
  284360009: "Upselling Externo",
};

export default Object.freeze(servicoTipoItem);
