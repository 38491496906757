import React, {useEffect, useRef, useState} from 'react';
import {useHistory} from 'react-router-dom'
import * as _matricula from '../../service/matricula-service.js';
import * as _checkout from '../../service/checkout-service.ts';
import * as _servico from '../../service/servico-service.js';
import * as mensagem from '../../components/shared/Message.js';
import "./styles.css";
import {useRecoilState, useRecoilValue, useSetRecoilState} from "recoil";
import ModalBoleto from "../../components/ModalBoleto/ModalBoleto";
import {showCsatMomentoEscolarMatriculadoState} from "../../recoil/atoms/showCsatMomentoEscolarMatriculadoState";
import Csat from "../../components/Csat/Csat";
import {Layout as DashboardLayout} from "../../layouts/dashboard";
import {useTranslation} from "../../hooks/use-translation";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Filtros from "./Filtros";
import {ListContainer} from "../../components/list-container";
import SvgIcon from "@mui/material/SvgIcon";
import {tokens} from "../../locales/tokens";
import isActiveColor from "../../utils/is-active-color";
import FilterFunnel01Icon from '@untitled-ui/icons-react/build/esm/FilterFunnel01';
import HomeCard from "./HomeCard";
import filteredHomeCardsSelector from "../../recoil/selectors/filteredHomeCardsSelector";
import {Scrollbar} from "../../components/scrollbar";
import {Modal} from "../../components/modal";
import useMediaQuery from "@mui/material/useMediaQuery";
import {razaoStatus} from "../../utils/razaoStatus";
import tipoMatricula from "../../utils/tipoMatricula";
import {Helmet} from 'react-helmet';
import {PATH_ROUTE} from "../../routes";
import {selectedMatriculaState} from "../../recoil/atoms/selectedMatriculaState";
import {responsavelState} from "../../recoil/atoms/responsavelState";
import matriculasSelector from "../../recoil/selectors/matriculasSelector";
import {setRecoil} from "recoil-nexus";
import {matriculaTypeState} from "../../recoil/atoms/matriculaTypeState";
import HomeCardSkeleton from "./HomeCardSkeleton";
import useAuth from "../../hooks/use-auth";
import PodeAbrirServicosDisponiveisSelector from "../../recoil/selectors/podeAbrirServicosDisponiveisSelector";
import {allowRedirectServicesState} from "../../recoil/atoms/allowRedirectServicesState";
import Cookies from "universal-cookie/cjs";
import {useDispatch, useSelector} from "react-redux";
import {activeTour} from "../../layouts/actions";
import {isTourEnabledSelector} from "../../modules/Login/selectors/user";
import {isCadastroConcluidoState} from "../../recoil/atoms/isCadastroConcluidoState";

const defaultState = {
  error: false,
  isLoadingUpselling: {id: "", value: false},
  alunos: [],
  anoMatricula: new Date().getFullYear(),
  boletos: [],
  errors: {},
  sigla: {},
  exibeModalBoleto: false,
  urlBoleto: "",
  openSidebar: false,
}

export default function CadastroConcluido() {
  const dispatch = useDispatch();
  const filteredHomeCards = useRecoilValue(filteredHomeCardsSelector);
  const matriculas = useRecoilValue(matriculasSelector);
  const {t} = useTranslation();
  const rootRef = useRef(null);
  const [showCsatMomentoEscolarMatriculado, setShowCsatMomentoEscolarMatriculado] = useRecoilState(showCsatMomentoEscolarMatriculadoState);
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));
  const smUp = useMediaQuery((theme) => theme.breakpoints.up('sm'));
  const [openSidebar, setOpenSidebar] = useState(lgUp);
  const setSelectedMatricula = useSetRecoilState(selectedMatriculaState);
  const responsavel = useRecoilValue(responsavelState);
  const [state, setState] = useState(defaultState);
  const history = useHistory();
  const {loadUserData, isPending} = useAuth();
  const [isLoadingMatId, setIsLoadingMatId] = useState("");
  const podeAbrirServicosDisponiveis = useRecoilValue(PodeAbrirServicosDisponiveisSelector);
  const fromLogin = useRecoilValue(allowRedirectServicesState);
  const setFromLogin = useSetRecoilState(allowRedirectServicesState);
  const isCadastroConcluido = useRecoilValue(isCadastroConcluidoState);
  const {
    exibeModalBoleto,
    boletos,
    urlBoleto,
    codigoBarrasBoleto,
    valorEntradaBoleto,
    dataVencimentoBoleto,
  } = state;
  const isTourOpen = useSelector(isTourEnabledSelector)
  const [firstLoad, setFirstLoad] = useState(false);

  useEffect(() => {
    if(!fromLogin) {
      loadUserData().then(() => {
        if (fromLogin && podeAbrirServicosDisponiveis && !showCsatMomentoEscolarMatriculado) {
          setFromLogin(false);
          history.push(PATH_ROUTE.SERVICOS_DISPONIVEIS);
        }
        setFirstLoad(true);
      })
    } else {
      setFirstLoad(true);
    }
    setFromLogin(false);
  }, [])

  useEffect(() => {
    if (!isPending && firstLoad) {
      const cookies = new Cookies();
      const showTour = cookies.get('tour');
      if (showTour === undefined || showTour === null || showTour === "true") {
        dispatch(activeTour());
      } else {
        if (fromLogin && podeAbrirServicosDisponiveis && !showCsatMomentoEscolarMatriculado) {
          history.push(PATH_ROUTE.SERVICOS_DISPONIVEIS);
        }
      }
    }
  }, [isPending, firstLoad]);

  useEffect(() => {
    const checkRedirect = () => {
      if (isCadastroConcluido) return;
      const cadastroPendenteRF = responsavel?.cadastroPendente || responsavel?.cadastropendente || false;
      const hasPreReserva = matriculas.some(x => x.razaoStatus_Value === razaoStatus.ponto_1_preReserva) && matriculas.length === 1;
      const hasRematricula = matriculas.some(x => x.tipoMatricula_Value === tipoMatricula.rematricula);
      const hasPreReservaSemPagamento = matriculas.some(x => x.razaoStatus_Value === tipoMatricula.reservaSemValor) && matriculas.length === 1;
      const hasAguardando = matriculas.some(x => x.razaoStatus_Value === razaoStatus.zero_13_aguardandoAprovacao);
      const hasIniciada = matriculas.some(x => x.razaoStatus_Value === razaoStatus.zero_1_iniciada);
      const hasCadastroPendente = cadastroPendenteRF || matriculas.some(x => x.documentosValidados != null && x.documentosValidados != undefined && x.documentosValidados === false);

      if (hasPreReserva || hasPreReservaSemPagamento) {
        setRecoil(matriculaTypeState, "reserva");
      }

      if (hasCadastroPendente && (hasRematricula || hasPreReserva || hasAguardando || hasIniciada)) {
        history.push(PATH_ROUTE.INICIO);
      }
    }

    const cookies = new Cookies();
    const showTour = cookies.get('tour');
    if (!(showTour === undefined || showTour === null || showTour === "true") && !isPending && !isTourOpen) {
      checkRedirect();
    }
  }, [isPending, isTourOpen])

  const btnClickAbrirModalBoleto = (
    {
      boletosDaMatricula = [],
      urlBoletoReserva,
      urlBoleto,
      codigobarrasBoletoReserva,
      codigobarrasBoleto,
      valorEntradaBoleto,
      valorEntradaBoletoReserva,
      dataVencimentoBoleto,
      dataVencimentoBoletoReserva
    }
  ) => {
    setState({
      ...state,
      exibeModalBoleto: true,
      boletos: boletosDaMatricula,
      urlBoleto: urlBoletoReserva || urlBoleto,
      codigoBarrasBoleto: codigobarrasBoletoReserva || codigobarrasBoleto,
      valorEntradaBoleto: valorEntradaBoletoReserva || valorEntradaBoleto,
      dataVencimentoBoleto: dataVencimentoBoletoReserva || dataVencimentoBoleto,
    });
  }

  const btnClickFecharModalBoleto = () => {
    setState({
      ...state,
      exibeModalBoleto: false,
      urlBoleto: ""
    });
  }

  const btnClickGerarContrato = (parametro) => {
    var urlContrato = parametro.target.value;
    window.open(urlContrato, '_blank');
  }

  const btnClickAvancar = async (matricula) => {
    var mat = matricula.id;
    const idResponsavel = responsavel?.id;
    setIsLoadingMatId(mat);
    var idServicoUnidade = matricula.servico_Id;

    const [
      verificaCadastro,
      result
    ] = await Promise.all([
      _checkout.VerificaCadastro(mat, idResponsavel),
      _matricula.VerificaVagaDosServicos(mat, idServicoUnidade, "Regular")
    ])

    if (verificaCadastro) {
      setIsLoadingMatId("");
      mensagem.ExibeMensagem(verificaCadastro, "2", true);
      return;
    }

    setIsLoadingMatId("");
    if (result != undefined && result.statusCode == 200) {
      setSelectedMatricula(matricula);
      history.push("/momentoescolar?idmatricula=" + mat)
    } else {
      if (result != undefined && result.value != undefined) {
        mensagem.ExibeMensagem(result.value, "2", true);
      } else {
        mensagem.ExibeMensagem("Erro ao verificar Turma", "2", true);
      }
    }
  }

  const btnClickUpSelling = async (matricula) => {
    var mat = matricula.id;
    const idResponsavel = responsavel?.id
    setState({...state, isLoadingUpselling: {id: mat, value: true}});

    const [
      verificaCadastro,
      listServicos
    ] = await Promise.all([
      _checkout.VerificaCadastro(mat, idResponsavel),
      _servico.GetServicosDisponiveis(mat)
    ])

    if (verificaCadastro) {
      setState({...state, isLoadingUpselling: {id: mat, value: false}});
      mensagem.ExibeMensagem(verificaCadastro, "2", true);
      return;
    }

    setState({...state, isLoadingUpselling: {id: mat, value: false}});

    if (listServicos.servicosComplementares != null && listServicos.servicosComplementares != undefined && listServicos.servicosComplementares.length > 0) {
      window.localStorage.setItem("idmatricula", mat);
      setSelectedMatricula(matricula);
      history.push("/momentoescolar?idmatricula=" + mat);
    } else {
      mensagem.ExibeMensagem("Não existem serviços de Upsellings para essa matrícula!", "2", true);
    }
  }

  return (
    <DashboardLayout>
      <Helmet>
        <title>
          Cadastro Concluído
        </title>
      </Helmet>
      <Box
        component="main"
        sx={{
          display: 'flex',
          flex: '1 1 auto',
          overflow: 'hidden',
          position: 'relative'
        }}
      >
        <Box
          ref={rootRef}
          sx={{
            bottom: 0,
            display: 'flex',
            left: 0,
            position: 'absolute',
            right: 0,
            top: 0,
            pt: 0
          }}
        >
          <Modal
            open={exibeModalBoleto}
            onClose={btnClickFecharModalBoleto}
            title="Boleto(s)"
          >
            <ModalBoleto
              boletos={boletos}
              ticketUrl={urlBoleto}
              ticketCode={codigoBarrasBoleto}
              ticketExpiration={dataVencimentoBoleto}
              ticketValue={valorEntradaBoleto}
            />
          </Modal>

          <Filtros
            container={rootRef?.current}
            openSidebar={openSidebar}
            setOpenSidebar={setOpenSidebar}
            loading={isPending}
          />

          <ListContainer open={openSidebar}>
            <Scrollbar>
              <Stack
                alignItems="center"
                justifyContent="space-between"
                direction="row"
                sx={{
                  p: 0,
                  pb: 1,
                  pr: 3,
                  pl: 3,
                  pt: smUp ? 3 : 0
                }}
              >
                <Typography
                  color="primary"
                  sx={{
                    fontWeight: "bold"
                  }}
                >
                  {filteredHomeCards?.length} resultado(s) {filteredHomeCards?.length !== matriculas?.length && filteredHomeCards?.length !== 0 ? `de ${matriculas?.length}` : ""}
                </Typography>

                <Button
                  data-tut="tur-menu-home-filter"
                  color={isActiveColor(openSidebar)}
                  startIcon={(
                    <SvgIcon color={isActiveColor(openSidebar)}>
                      <FilterFunnel01Icon/>
                    </SvgIcon>
                  )}
                  onClick={() => setOpenSidebar(true)}
                  sx={{
                    display: {lg: "none"},
                  }}
                >
                  {t(tokens.common.filters)}
                </Button>
              </Stack>

              <Box
                data-tut="tur-menu-home-cards"
                sx={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "flex-start",
                  gap: "10px",
                  p: smUp ? 3 : 1
                }}
              >
                {isPending ? Array.from({length: 4}).map((_, index) => (
                  <HomeCardSkeleton key={index}/>
                )) : null}

                {!isPending ? (filteredHomeCards || []).map((matricula, i) => (
                  <HomeCard
                    index={i}
                    key={matricula?.id}
                    matricula={matricula}
                    onClickGerarContrato={btnClickGerarContrato}
                    onClickAvancar={btnClickAvancar}
                    onClickUpSelling={btnClickUpSelling}
                    onClickGerarBoleto={btnClickAbrirModalBoleto}
                    isLoadingTurma={isLoadingMatId === matricula.id}
                    isLoadingUpselling={state.isLoadingUpselling}
                  />
                )) : null}
              </Box>
            </Scrollbar>
          </ListContainer>

          <Csat
            show={showCsatMomentoEscolarMatriculado}
            scoreTitle="O quanto você ficou satisfeito com: Matrícula digital?"
            onSubmit={() => setShowCsatMomentoEscolarMatriculado(false)}
            onClosed={() => setShowCsatMomentoEscolarMatriculado(false)}
          />
        </Box>
      </Box>
    </DashboardLayout>
  )
}
