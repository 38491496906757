import * as React from 'react';
import {styled} from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepConnector, {stepConnectorClasses} from '@mui/material/StepConnector';
import {StepIconProps} from '@mui/material/StepIcon';
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";

const ColorlibConnector = styled(StepConnector)(({theme}) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: theme.breakpoints.down('sm') ? 14 : 18,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: theme.palette.primary.main,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: theme.palette.primary.main,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled('div')<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({theme, ownerState}) => ({
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
  zIndex: 1,
  color: 'white',
  width: theme.breakpoints.down('sm') ? 30 : 40,
  height: theme.breakpoints.down('sm') ? 30 : 40,
  display: 'flex',
  borderRadius: '50%',
  fontSize: theme.breakpoints.down('sm') ? 12 : undefined,
  fontWeight: 'bold',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    backgroundColor: 'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    color: theme.palette.primary.main,
    border: '2px solid #784af4',
    borderColor: theme.palette.primary.main,
    // boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  }),
  ...(ownerState.completed && {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    border: '2px solid #784af4',
    borderColor: theme.palette.primary.main,
  }),
}));

function ColorlibStepIcon(props: StepIconProps) {
  const {active, completed, className} = props;

  const icons: { [index: string]: React.ReactElement } = {
    1: <>1</>,
    2: <>2</>,
    3: <>3</>,
    4: <>4</>,
  };

  return (
    <ColorlibStepIconRoot ownerState={{completed, active}} className={className}>
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

const steps = ['Substitua o cartão atual', 'Aceite'];

interface FirstAccessStepsProps {
  activeStep?: number;
}

export default function WalletChangeSubsSteps(props: FirstAccessStepsProps) {
  const {
    activeStep = 0
  } = props;

  return (
    <Box
      sx={{
        backgroundColor: 'background.paper',
        borderBottom: '1px solid #80808052',
        position: 'relative',
        pb: 4,
      }}
    >
      <Container
        maxWidth="lg"
        sx={{
          p: 0
        }}
      >
        <Stack sx={{width: '100%'}} spacing={4}>
          <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector/>}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel
                  StepIconComponent={ColorlibStepIcon}
                >
                  {label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Stack>
      </Container>
    </Box>
  );
}
