import {selector} from "recoil";
import {selectedMatriculaState} from "../atoms/selectedMatriculaState";
import {razaoStatus} from "../../utils/razaoStatus";
import isPreReservaSelector from "./isPreReservaSelector";
import isPreReservaSemValorSelector from "./isPreReservaSemValorSelector";

const podeContratarServicoObrigatorioSelector = selector({
  key: 'podeContratarServicoObrigatorioSelector', // unique ID (with respect to other atoms/selectors)
  get: ({get}) => {
    const selectedMatricula = get(selectedMatriculaState);
    const isPreReserva = get(isPreReservaSelector);
    const isPreReservaSemValor = get(isPreReservaSemValorSelector);
    return selectedMatricula &&
      selectedMatricula.razaoStatus_Value !== razaoStatus.zero_6_matriculado && !(isPreReserva && !isPreReservaSemValor)
  },
});

export default podeContratarServicoObrigatorioSelector

