import React, { useEffect, useRef, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  appIdSelector,
  accessIdSelector,
  userSelectedSelector,
  cpfCnpjSelector
} from 'modules/Login/selectors/user'
import ReceiptDialog from '../components/ReceiptDialog'
import { fetchClosed, resetPaymentClosed } from './actions'
import { closedDataSelector, isLoadingClosedSelector } from './selectors/closed'
import {
  Box,
  Typography,
  Divider,
  Card,
  CardContent,
  Grid,
  Tooltip,
  Stack,
  OutlinedInput,
  SvgIcon,
  Skeleton
} from '@mui/material'
import { GridSearchIcon } from '@mui/x-data-grid'
import { tokens } from '../../../locales/tokens'
import { useTranslation } from '../../../hooks/use-translation'
import { ListTable } from '../../../components/customTable/list-table'
import { ListContainer } from '../../../components/customTable/list-container'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/styles'
import { RequestQuote, CreditScore, MoreHoriz } from '@mui/icons-material'

const defaultReceipt = { open: false, correctvalue: 0 }

const Paid = () => {
  const appid = useSelector(appIdSelector)
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const data = useSelector(closedDataSelector)
  const isLoadingData = useSelector(isLoadingClosedSelector)
  const accessId = useSelector(accessIdSelector)
  const cpfCnpj = useSelector(cpfCnpjSelector)
  const theme = useTheme()
  const [search, setSearch] = useState('')
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'))
  const isMedium = useMediaQuery(theme => theme.breakpoints.down('md'))
  const [state, setState] = useState({
    rowsPerPage: 10,
    page: 0
  })
  const tableRef = useRef()
  const [openModal, setOpenModal] = useState(defaultReceipt)
  const [isFirst, setIsFirst] = useState(true)
  const userSelected = useSelector(userSelectedSelector)

  useEffect(() => {
    if (isFirst) {
      if (tableRef && tableRef.current) tableRef.current.unity = 0
      setIsFirst(false)
      dispatch(resetPaymentClosed())
      return
    }
  }, [appid, data])

  // It control the refresh when change the accessId
  useEffect(() => {
    dispatch(fetchClosed({ accessId, cpfCnpj }))
  }, [accessId, dispatch, userSelected])

  const handlePageChange = (event: MouseEvent<HTMLButtonElement> | null, page: number): void => {
    setState(prevState => ({
      ...prevState,
      page
    }))
  }

  const handleRowsPerPageChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setState(prevState => ({
      ...prevState,
      rowsPerPage: parseInt(event.target.value, 10)
    }))
  }

  const renderPaymentTypeIcon = type => {
    switch (type.toLowerCase()) {
      case 'boleto':
        return (
          <Stack direction='row' spacing={1}>
            <Typography variant='body2'>{t(tokens.common.billet)}</Typography>
            <RequestQuote />
          </Stack>
        )
      case 'cartão':
        return (
          <Stack direction='row' spacing={1}>
            <Typography variant='body2'>{t(tokens.common.card)}</Typography>
            <CreditScore />
          </Stack>
        )
      default:
        return (
          <Stack direction='row' spacing={1}>
            <Typography variant='body2'>{t(tokens.common.other)}</Typography>
            <MoreHoriz />
          </Stack>
        )
    }
  }

  const smColumns = [
    {
      field: 'liquidation',
      headerName: t(tokens.common.liquidation) ?? '',
      headerAlign: 'center',
      flex: 1,
      minWidth: 120
    },
    {
      field: 'invoice',
      headerName: t(tokens.common.invoice) ?? '',
      headerAlign: 'center',
      minWidth: 120
    },
    {
      field: 'valueliquidateFormatted',
      headerName: t(tokens.common.settledValue) ?? '',
      headerAlign: 'center',
      minWidth: 120
    },
  ]

  const otherMediumColumns = [
    {
      field: 'valueFormatted',
      headerName: t(tokens.common.documentValue) ?? '',
      headerAlign: 'center',
      minWidth: 120
    },
    {
      field: 'automaticdebitFormated',
      headerName: t(tokens.common.automaticDebit) ?? '',
      headerAlign: 'center',
      minWidth: 120
    },
  ]

  const otherColumns = [
    {
      field: 'studentnameFormated',
      headerName: t(tokens.common.student) ?? '',
      headerAlign: 'center',
      flex: 1,
      minWidth: 120
    }
  ]

  const lastColumn = [
    {
      field: 'paymenttype',
      headerName: t(tokens.common.paymentMethod) ?? '',
      headerAlign: 'center',
      minWidth: 120,
      flex: 1,
      renderCell: params => renderPaymentTypeIcon(params.row.paymenttype)
    }
  ]

  const columns = [
    ...smColumns,
    ...(isMedium ? [] : otherMediumColumns),
    ...(isSmall ? [] : otherColumns),
    ...lastColumn
  ]

  const rowsFiltered =
    data && data.length > 0
      ? data.map((item, index) => ({
          id: index,
          ...item
        }))
      : []

  return (
    <Box
      sx={{
        marginTop: theme.spacing(3)
      }}
    >
      <ReceiptDialog
        {...openModal}
        onClose={() => setOpenModal(defaultReceipt)}
      />
      <Card>
        <CardContent sx={isMedium ? { padding: 0 } : {}}>
          <Grid
            container
            sx={{
              paddingTop: 4,
              paddingLeft: 3,
              paddingRight: 3,
              paddingBottom: 2
            }}
            spacing={2}
          >
            <Grid item xs={12} sm={8}>
              <Typography component='h5' variant='h5'>
                {t(tokens.nav.financialPaidQuotas)}
              </Typography>
              <Tooltip title={t(tokens.common.overdueInstallments)}>
                <Typography
                  component={'h6'}
                  variant={'h6'}
                  color={'textSecondary'}
                >
                  {t(tokens.common.overdueInstallments)}
                </Typography>
              </Tooltip>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Stack alignItems='center' direction='row' spacing={1}>
                <OutlinedInput
                  fullWidth
                  placeholder={t(tokens.common.search) ?? ''}
                  defaultValue={search}
                  onChange={e => setSearch(e.target.value)}
                  autoComplete='off'
                  startAdornment={
                    <SvgIcon>
                      <GridSearchIcon />
                    </SvgIcon>
                  }
                />
              </Stack>
            </Grid>
          </Grid>
          <Divider
            variant='middle'
            sx={{
              borderBottomWidth: 2,
              borderColor: '#c6bbbb !important'
            }}
          />
          <ListContainer>
            <ListTable
              ref={tableRef}
              columns={columns}
              loading={isLoadingData}
              selectable={false}
              count={rowsFiltered?.length ?? 0}
              page={state.page}
              rowsPerPage={state.rowsPerPage}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleRowsPerPageChange}
              disableColumnMenu
              items={rowsFiltered ?? []}
            />
          </ListContainer>
        </CardContent>
      </Card>
    </Box>
  )
}

export default Paid
