import React from 'react'
import RecurrencePaymentDialogCheckout from './components/RecurrencePaymentDialogCheckout'
import {Close} from '@mui/icons-material'
import {paymentSelector} from '../../../Pay/selectors/payments'
import {useSelector} from 'react-redux'
import useMediaQuery from "@mui/material/useMediaQuery";
import Dialog from "@mui/material/Dialog";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";

export default (
  {
    open,
    onClose,
    setIsLoading,
    onSuccess,
    onError,
    editable,
    showInstallments = false,
    selectedMatricula,
    setAcceptanceTermObj,
    isCardChanging,
    selectedInfo,
  }
) => {
  const fullScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'))
  const {loading, waitingRede} = useSelector(paymentSelector)

  function handleClose() {
    onClose()
  }

  return (
    <Dialog
      maxWidth={'md'}
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      aria-labelledby='payment-dialog-title'
    >
      <Box display='flex' justifyContent='flex-end'>
        <IconButton
          disabled={!!(loading || waitingRede)}
          aria-label='close-payment'
          id='ico-mdl-pag-fch'
          onClick={handleClose}
        >
          <Close/>
        </IconButton>
      </Box>
      <RecurrencePaymentDialogCheckout
        setIsLoading={setIsLoading}
        onSuccess={onSuccess}
        onError={onError}
        editable={editable}
        selectedInfo={selectedInfo}
        showInstallments={showInstallments}
        isCardChanging={isCardChanging}
        selectedMatricula={selectedMatricula}
        setAcceptanceTermObj={setAcceptanceTermObj}
        onBack={handleClose}
      />
    </Dialog>
  )
}
