import React from "react";
import {Skeleton} from "@mui/material";

const WalletLoading: React.FC = () => {
  return (
    <Skeleton
      variant="rounded"
      width="100%"
      height="100%"
      sx={{
        minHeight: 400,
        minWidth: 300,
        width: "100%",
        height: "100%",
      }}
    />
  );
}

export default WalletLoading;
