import Stack from "@mui/material/Stack";
import {CardContent, Skeleton} from "@mui/material";
import React from "react";

const Loading: React.FC = () => {
  return (
    <CardContent>
      <Stack spacing={2}>
        <Skeleton
          variant="rounded"
          height={55}
          width='100%'
        />

        <Stack
          direction="row"
          spacing={2}
        >
          <Skeleton
            variant="rounded"
            height={55}
            width='100%'
          />
          <Skeleton
            variant="rounded"
            height={55}
            width='100%'
          />
        </Stack>

        <Stack
          direction="row"
          spacing={2}
        >
          <Skeleton
            variant="rounded"
            height={55}
            width='100%'
          />
          <Skeleton
            variant="rounded"
            height={55}
            width='100%'
          />
        </Stack>

        <Skeleton
          variant="rounded"
          height={55}
          width='50%'
        />

        <Stack
          direction="row"
          justifyContent="space-between"
          spacing={2}
          sx={{
            pt: 4
          }}
        >
          <Skeleton
            variant="rounded"
            height={55}
            width={110}
          />
          <Skeleton
            variant="rounded"
            height={55}
            width={110}
          />
        </Stack>
      </Stack>
    </CardContent>
  )
}

export default Loading;
