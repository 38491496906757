const razaoStatus = {
  ponto_1_preReserva: 284360010,
  ponto_2_reservada: 284360011,
  zero_1_iniciada: 1,
  zero_2_aprovacaoBolsa: 284360000,
  zero_3_planejamentoFinanceiro: 284360001,
  zero_4_processamentoPagamento: 284360002,
  zero_5_preMatriculado: 284360005,
  zero_6_matriculado: 284360003,
  zero_7_cancelada: 284360004,
  zero_8_movimentacaoIniciada: 284360009,
  zero_9_emProcessoDeRevisao: 284360006,
  inativo: 2,
  zero_10_revisada: 284360008,
  zero_11_recusa: 956840002,
  zero_12_cancelada: 956840003,
  zero_13_aguardandoAprovacao: 284360012,
};

const razaoStatusNome = {
  284360010: "Pré-reserva",
  284360011: "Reservada",
  1: "Contratar",
  284360000: "Aprovação de bolsa",
  284360001: "Planejamento financeiro",
  284360002: "Processamento de pagamento",
  284360005: "Pré-matriculado",
  284360003: "Matriculado",
  284360004: "Cancelada",
  284360009: "Movimentação iniciada",
  284360006: "Em processo de revisão",
  2: "Inativo",
  284360008: "Revisada",
  956840002: "Recusa",
  956840003: "Cancelada",
  284360012: "Aguardando Aprovação"
};

export { razaoStatus, razaoStatusNome };

export default Object.freeze(razaoStatus);
