import fetch from "../utils/fetch";
import env from "../env";
import {setRecoil} from "recoil-nexus";
import {blackListState} from "../recoil/atoms/blackListState";
import IBlackListResponse from "../@types/IBlackListResponse";

export async function GetBySistemaId(sistemaId: string): Promise<IBlackListResponse> {
    return fetch(env.apiUrl+`api/BlackList/GetBySistemaId/${sistemaId}`, {
        method: "GET",
        mode: "cors",
    }).then((res) => res.json())
    .then((data) => {
        setRecoil(blackListState, data)
        return data;
    });
}
