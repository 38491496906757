import React, {useEffect, useState} from 'react';
import {Ajuda} from '../../components/Ajuda';
import {useHistory} from 'react-router-dom';
import StepCheckoutActionButtons from "../../components/StepCheckoutActionButtons/StepCheckoutActionButtons";
import env from "../../env";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import TextField from "@mui/material/TextField";
import fetch from "../../utils/fetch";
import {Layout as DashboardLayout} from "../../layouts/dashboard";
import {CardContent, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup} from "@mui/material";
import FirstAccessSteps from "../../components/FirstAccessSteps/FirstAccessSteps";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Loading from "./Loading";
import {useFormik} from "formik";
import {toast} from "react-toastify";
import * as Yup from "yup";
import {PATH_ROUTE} from "../../routes";
import Divider from "@mui/material/Divider";
import Avatar from "@mui/material/Avatar";
import GerarSiglaNome from "../../commons/functions/gerar-sigla-nome";
import * as _contactService from "../../service/contact-service";
import {useRecoilState, useRecoilValue} from "recoil";
import {avatarsState} from "../../recoil/atoms/avatarsState";
import {Scrollbar} from "../../components/scrollbar";
import Slide from "@mui/material/Slide";
import {slideDirectionState} from "../../recoil/atoms/slideDirectionState";
import {useTheme} from "@mui/material/styles";
import {cpfMask} from "../../utils/cpfMask";

const validationSchema = Yup.object().shape({
  alunos: Yup.array().of(Yup.object().shape({
    id: Yup.string().required("Campo obrigatório").nullable().typeError("Campo obrigatório"),
    nome: Yup.string().required("Campo obrigatório").nullable().typeError("Campo obrigatório"),
    dataNascimento: Yup.date().test(`test-minimum-date`, function (value) {
      const { path, createError } = this;
      return (
        new Date(value ?? "") >=  new Date('1753-01-01') ||
        createError({ path, message: "Data mínima permitida 01/01/1753" })
      );
    }).required("Campo obrigatório").nullable().typeError("Campo obrigatório"),
    email: Yup.string().email("Insira um email válido ").nullable(),
    cpf: Yup.string().matches(/^\d{3}\.\d{3}\.\d{3}-\d{2}$/, 'CPF inválido').nullable().typeError("Campo obrigatório"),
    rg: Yup.string().nullable().typeError("Campo obrigatório"),
    passaporte: Yup.string().nullable().typeError("Campo obrigatório"),
    docType: Yup.string().nullable().typeError("Campo obrigatório"),
    hasCPF: Yup.string().nullable().typeError("Campo obrigatório"),
    sexo: Yup.string().required("Campo obrigatório").nullable().typeError("Campo obrigatório"),
  }))
});

function stringAvatar(name: string) {
  return {
    sx: {
      background: 'linear-gradient(135deg, #4180f6, #1cfaef)',
      width: '100%',
      height: '100%',
      maxHeight: 100,
      maxWidth: 100,
      aspectRatio: 1,
      margin: '0 auto',
      fontSize: '2rem',
      mixBlendMode: 'multiply',
    },
    variant: 'rounded',
    children: GerarSiglaNome(name || ""),
  };
}

const InformeSeusDados: React.FC = () => {
  const theme = useTheme();
  const slideDirection = useRecoilValue(slideDirectionState);
  const history = useHistory();
  const [avatars, setAvatars] = useRecoilState(avatarsState);
  const [isLoaded, setIsLoaded] = useState(false);
  const [saving, setSaving] = useState(false);
  const [initialValues, setInitialValues] = useState({
    alunos: [
      {
        id: '',
        nome: '',
        cpf: '',
        rg: '',
        email: '',
        dataNascimento: '',
        passaporte: '',
        hasCPF: '',
        docType: '',
        sexo: ''
      }
    ]
  });
  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values): Promise<void> => {
      if (saving) return;
      setSaving(true);

      try {
        const promises = (values.alunos || []).map(async aluno => {
          return fetch(env.apiUrl + "api/estudante/" + aluno.id, {
            method: "PATCH",
            mode: "cors",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              ...aluno,
              cadastropendente: false,
              cadastroPendente: false,
              cpf: aluno.hasCPF === 'yes' ? aluno.cpf : null,
              rg: aluno.hasCPF === 'no' && aluno.docType === 'rg' ? aluno.rg : null,
              passaporte: aluno.hasCPF === 'no' && aluno.docType === 'passaporte' ? aluno.passaporte : "",
            }),
          }).then(async (res) => {
            if (!res.ok) {
              const error = await res.text();
              toast.error(error, {autoClose: 5000});
              throw new Error(aluno.nome);
            }
            return res.json()
          })
        });

        await Promise.all(promises);

        history.push(PATH_ROUTE.ANEXAR_DOCUMENTOS);
      } catch (e) {
        if (typeof e !== 'string') {
          toast.error(`Erro ao atualizar dados!`, {autoClose: 2000});
        }
      } finally {
        setSaving(false);
      }
    }
  });

  useEffect(() => {
    const idResponsavel = localStorage.getItem("@SMO20/idResponsavel");

    fetch(env.apiUrl + "api/estudante/GetbyIdResponsavel/" + idResponsavel, {
      method: "GET",
      mode: "cors",
    })
      .then((res) => res.json())
      .then((result) => {
        const promises = (result || []).map(async aluno => {
          if (aluno?.urlImagemPerfil) {
            let clone = {...avatars};
            clone[aluno?.id] = await _contactService.getSas(aluno?.urlImagemPerfil)
            setAvatars(clone)
          }

          aluno.hasCPF = aluno?.cpf ? 'yes' : 'no';

          if (aluno?.rg !== '') {
            aluno.docType = 'rg';
          } else if (aluno?.passaporte !== '') {
            aluno.docType = 'passaporte';
          } else {
            aluno.docType = 'rg';
          }

          return aluno;
        });

        return Promise.all(promises).then(a => {
          setInitialValues({
            alunos: a
          })
        })
      }).finally(() => {
      setIsLoaded(true);
    })
  }, []);

  const hasError = (field: string, index: number) => {
    try {
      const touched = formik.touched['alunos']![index]?.[field];
      const error = formik.errors['alunos']![index]?.[field];
      return !!(touched && error)
    } catch (e) {
      return undefined
    }
  }

  const helpText = (field: string, index: number) => {
    try {
      const touched = formik.touched['alunos']![index]?.[field];
      const error = formik.errors['alunos']![index]?.[field];
      return touched && error
    } catch (e) {
      return undefined
    }
  }

  return (
    <DashboardLayout backgroundColor={theme.palette.background.paper}>
      <Scrollbar>
        <FirstAccessSteps activeStep={1}/>

        <Slide in direction={slideDirection}>
          <Card
            elevation={0}
          >
            <Container
              maxWidth="lg"
              sx={{
                pb: 8,
              }}
            >
              <CardHeader
                title="Informe os dados do(s) estudante(s):"
                sx={{
                  position: 'relative',
                  "&:after": {
                    content: "''",
                    position: 'absolute',
                    zIndex: 0,
                    boxShadow: '0 0 40px rgb(0 0 0 / 34%)',
                    bottom: '0px',
                    width: '45%',
                    height: '100%',
                    borderRadius: '100%',
                    left: '10%',
                    right: '10%',
                    top: '-100%'
                  }
                }}
              />

              {!isLoaded && <Loading/>}

              {isLoaded && (
                <CardContent>
                  <form
                    onSubmit={formik.handleSubmit}
                  >
                    {formik.values.alunos.map((aluno, index) => (
                      <React.Fragment key={aluno?.id}>
                        <Stack
                          direction={{md: 'row', sm: 'column'}}
                          sx={{
                            gap: 2
                          }}
                          spacing={0}
                        >
                          {/* @ts-ignore */}
                          <Avatar
                            {...stringAvatar(aluno?.nome)}
                            src={avatars[aluno?.id] ?? undefined}
                          />

                          <Box
                            sx={{
                              width: '100%'
                            }}
                          >
                            <Stack
                              direction={{md: 'row', sm: 'column'}}
                              sx={{
                                mb: 2,
                                gap: 2
                              }}
                              spacing={0}
                            >
                              <TextField
                                fullWidth
                                label="Nome completo*"
                                name={`alunos[${index}].nome`}
                                error={hasError('nome', index)}
                                helperText={helpText('nome', index)}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={aluno.nome}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />

                              <TextField
                                fullWidth
                                label="Sexo*"
                                name={`alunos[${index}].sexo`}
                                select
                                error={hasError('sexo', index)}
                                helperText={helpText('sexo', index)}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={aluno.sexo}
                                SelectProps={{
                                  native: true,
                                }}
                              >
                                <option value="" disabled>Selecione</option>
                                <option value="956840001">Feminino</option>
                                <option value="956840000">Masculino</option>
                                <option value="0">Prefiro não informar</option>
                              </TextField>
                            </Stack>

                            <Stack
                              direction={{md: 'row', sm: 'column'}}
                              sx={{
                                mb: 2,
                                gap: 2
                              }}
                              spacing={0}
                            >
                              <TextField
                                fullWidth
                                label="Data de Nascimento*"
                                type="date"
                                name={`alunos[${index}].dataNascimento`}
                                error={hasError('dataNascimento', index)}
                                helperText={helpText('dataNascimento', index)}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={aluno.dataNascimento ? new Date(aluno.dataNascimento).toISOString().slice(0, 10) : ""}
                              />

                              <TextField
                                fullWidth
                                label="E-mail"
                                name={`alunos[${index}].email`}
                                type="email"
                                placeholder="(Opcional)"
                                error={hasError('email', index)}
                                helperText={helpText('email', index)}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={aluno.email}
                              />
                            </Stack>
                          </Box>
                        </Stack>

                        <FormControl>
                          <FormLabel id={`dados-do-estudante-${aluno?.id}`}>
                            Documento do estudante
                          </FormLabel>
                          <RadioGroup
                            aria-labelledby={`dados-do-estudante-${aluno?.id}`}
                            name={`alunos[${index}].hasCPF`}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={aluno.hasCPF}
                          >
                            <Box>
                              <FormControlLabel
                                value="yes"
                                control={<Radio/>}
                                label="CPF"
                              />
                              <FormControlLabel
                                value="no"
                                control={<Radio/>}
                                label="Não tem CPF"
                              />
                            </Box>
                          </RadioGroup>
                          <RadioGroup
                            aria-labelledby={`dados-do-estudante-${aluno?.id}`}
                            name={`alunos[${index}].docType`}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={aluno.docType}
                          >
                            {(aluno.hasCPF === 'no') && (
                              <Box>
                                <FormControlLabel
                                  value="rg"
                                  control={<Radio/>}
                                  label="RG"
                                />
                                <FormControlLabel
                                  value="passaporte"
                                  control={<Radio/>}
                                  label="Passaporte"
                                />
                              </Box>
                            )}
                          </RadioGroup>
                        </FormControl>

                        <Stack
                          direction="row"
                          sx={{
                            pb: 2
                          }}
                          spacing={2}
                        >
                          <Grid
                            container
                          >
                            <Grid
                              item
                              xs={12}
                              md={6}
                            >
                              {(aluno.hasCPF === 'no' && aluno.docType === 'passaporte') && (
                                <TextField
                                  fullWidth
                                  label="Passaporte"
                                  name={`alunos[${index}].passaporte`}
                                  placeholder="(Opcional)"
                                  error={hasError('passaporte', index)}
                                  helperText={helpText('passaporte', index)}
                                  onBlur={formik.handleBlur}
                                  onChange={formik.handleChange}
                                  value={aluno.passaporte}
                                />
                              )}

                              {(aluno.hasCPF === 'no' && aluno.docType === 'rg') && (
                                <TextField
                                  fullWidth
                                  label="RG"
                                  name={`alunos[${index}].rg`}
                                  placeholder="(Opcional)"
                                  error={hasError('rg', index)}
                                  helperText={helpText('rg', index)}
                                  onBlur={formik.handleBlur}
                                  onChange={formik.handleChange}
                                  value={aluno.rg?.replace(/^(\d{2})(\d{3})(\d{3})(\d{1}).*/, '$1.$2.$3-$4')}
                                />
                              )}

                              {(aluno.hasCPF === 'yes') && (
                                <TextField
                                  fullWidth
                                  label="CPF"
                                  name={`alunos[${index}].cpf`}
                                  placeholder="(Opcional)"
                                  error={hasError('cpf', index)}
                                  helperText={helpText('cpf', index)}
                                  onBlur={formik.handleBlur}
                                  onChange={formik.handleChange}
                                  value={cpfMask(aluno.cpf)}
                                />
                              )}
                            </Grid>
                          </Grid>
                        </Stack>

                        {(index < formik.values.alunos.length - 1) && (
                          <Divider
                            sx={{
                              mt: 6,
                              mb: 4
                            }}
                          >{formik.values.alunos[index + 1]?.nome}</Divider>
                        )}
                      </React.Fragment>
                    ))}

                    <Box
                      sx={{
                        mt: 6
                      }}
                    >
                      <StepCheckoutActionButtons
                        previousStepPath={PATH_ROUTE.INFORME_SEUS_DADOS}
                        nextStepPath={PATH_ROUTE.ANEXAR_DOCUMENTOS}
                        nextStepText="Avançar"
                        onClickNextStep={() => {
                        }}
                        nextStepDisabled={saving}
                        nextStepType="submit"
                        loading={saving}
                      />
                    </Box>
                  </form>
                </CardContent>
              )}
            </Container>
          </Card>
        </Slide>
        <Ajuda/>
      </Scrollbar>
    </DashboardLayout>
  )
}

export default InformeSeusDados;
