import {selector} from "recoil";
import parcelaRegularSelecionadaSelector from "./carrinho/servicoRegular/parcelaRegularSelecionadaSelector";
import parcelaEntradaRegularSelecionadaSelector
  from "./carrinho/servicoRegular/parcelaEntradaRegularSelecionadaSelector";
import servicosObrigatoriosSelector from "./carrinho/servicosObrigatorios/servicosObrigatoriosSelector";
import parcelasObrigatoriosSelecionadasSelector
  from "./carrinho/servicosObrigatorios/parcelasObrigatoriosSelecionadasSelector";
import podeContratarServicoRegularSelector from "./carrinho/servicoRegular/podeContratarServicoRegularSelector";
import podeContratarServicoObrigatorioSelector from "./podeContratarServicoObrigatorioSelector";
import podeContratarServicoComplementarSelector
  from "./carrinho/servicosUpSelling/podeContratarServicoComplementarSelector";
import servicosUpSellingSelector from "./carrinho/servicosUpSelling/servicosUpSellingSelector";
import servicosUpSellingSelecionadosSelector from "./carrinho/servicosUpSelling/servicosUpSellingSelecionadosSelector";
import parcelasUpSellingSelecionadasSelector from "./carrinho/servicosUpSelling/parcelasUpSellingSelecionadasSelector";

const canFinishServicesSelector = selector({
  key: 'canFinishServicesSelector',
  get: ({get}) => {
    const parcelaRegularSelecionada = get(parcelaRegularSelecionadaSelector);
    const parcelaEntradaRegularSelecionada = get(parcelaEntradaRegularSelecionadaSelector);
    const servicosObrigatorios = get(servicosObrigatoriosSelector);
    const parcelasObrigatoriosSelecionadas = get(parcelasObrigatoriosSelecionadasSelector);
    const podeContratarServicoRegular = get(podeContratarServicoRegularSelector);
    const podeContratarServicoObrigatorio = get(podeContratarServicoObrigatorioSelector);

    const servicosUpSelling = get(servicosUpSellingSelector);
    const servicosUpSellingSelecionados = get(servicosUpSellingSelecionadosSelector);
    const parcelasUpSellingSelecionadas = get(parcelasUpSellingSelecionadasSelector);
    const podeContratarServicoUpSelling = get(podeContratarServicoComplementarSelector);

    const selectedRegular = parcelaRegularSelecionada && ((parcelaRegularSelecionada.entradas.length > 0 && parcelaEntradaRegularSelecionada) || parcelaRegularSelecionada.entradas.length === 0)
    const selectedObrigatorios = servicosObrigatorios.length === 0 || (servicosObrigatorios?.length > 0 && servicosObrigatorios.length === Object.keys(parcelasObrigatoriosSelecionadas).map(key => parcelasObrigatoriosSelecionadas[key]).filter(value => value !== null).length)
    const selectedUpSelling = servicosUpSelling?.length === 0 || servicosUpSellingSelecionados?.length === 0 || (servicosUpSelling?.length > 0 && servicosUpSellingSelecionados.length > 0 && servicosUpSellingSelecionados.length === Object.keys(parcelasUpSellingSelecionadas).map(key => parcelasUpSellingSelecionadas[key]).filter(value => value !== null).length)

    console.log("obriga", (servicosObrigatorios?.length > 0 && servicosObrigatorios.length === Object.keys(parcelasObrigatoriosSelecionadas).map(key => parcelasObrigatoriosSelecionadas[key]).filter(value => value !== null).length))
    console.log("servicosObrigatorios?.length ", servicosObrigatorios?.length )
    console.log("parcelasObrigatoriosSelecionadas", parcelasObrigatoriosSelecionadas)
    return selectedRegular && selectedObrigatorios && selectedUpSelling
  },
});

export default canFinishServicesSelector;
