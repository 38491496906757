import { apiRequestActionType, apiSuccessActionType, apiErrorActionType, apiResetActionType } from '../../../utils/API/action-types'
import visa from '../../../assets/img/brands/visa.png'
import master from '../../../assets/img/brands/master.png'
import hipercard from '../../../assets/img/brands/hipercard.png'
import amex from '../../../assets/img/brands/amex.jpg'
import elo from '../../../assets/img/brands/elo.png'

export const RECEIPT = 'RECEIPT'
export const REQUEST_RECEIPT = apiRequestActionType(RECEIPT)
export const SUCCESS_RECEIPT = apiSuccessActionType(RECEIPT)
export const ERROR_RECEIPT = apiErrorActionType(RECEIPT)
export const RESET_RECEIPT = apiResetActionType(RECEIPT)

export const PAYMENT_TYPE = {
  BOL_RIBEIR: 'Bol_Ribeir',
  CARD: 'Cartão',
  BILLET: 'Boleto'
}

export const PAYMENT_TYPE_CONFIG = {
  [PAYMENT_TYPE.BOL_RIBEIR]: 'Boleto',
  [PAYMENT_TYPE.CARD]: 'Cartão',
  [PAYMENT_TYPE.BILLET]: 'Boleto'
}

export const BRANDS = {
  VISA: 'visa',
  MASTERCARD: 'mastercard',
  HIPERCARD: 'hipercard',
  AMEX: 'amex',
  ELO: 'elo'
}

export const BRANDS_CONFIG = (isDebit) => {

  const brands = {
    [BRANDS.VISA]: { img: visa, idComponent: 'img-mdl-pag-crt-vis' },
    [BRANDS.MASTERCARD]: { img: master, idComponent: 'img-mdl-pag-crt-mst' },
  }

  return isDebit ?
    brands :
    {
      ...brands,
      [BRANDS.HIPERCARD]: { img: hipercard, idComponent: 'img-mdl-pag-crt-hpc' },
      [BRANDS.AMEX]: { img: amex, idComponent: 'img-mdl-pag-crt-ame' },
      [BRANDS.ELO]: { img: elo, idComponent: 'img-mdl-pag-crt-elo' },
    }
}


export const STYLE_CELLS = {
  width: '10%',
  paddingRight: 30,
  paddingLeft: 0
}
