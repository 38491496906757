import React from 'react'
import { useSelector, useDispatch }  from 'react-redux'
import { FormattedMessage } from 'react-intl'
import classNames from 'classnames'
import {Typography, Grid, Box, CircularProgress, Button } from '@mui/material'
import { setWithConfig } from 'layouts/actions'

import { isLoadingBilletSelector } from 'modules/Finance/Pay/selectors/billet'
import { postFinancialDataSelector } from 'modules/Negotiation/Finance/selectors/postFinancial'
import { appIdSelector, cpfCnpjSelector } from 'modules/Login/selectors/user'
import { billetRequest } from 'modules/Finance/services/installment'
import { SnackBillet } from 'modules/Finance/Pay/components/SnackBillet'
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles(() => ({
  ckeckoutLoading: {
    minHeight: 200
  },
  ckeckoutError: {
    color: 'red'
  },
  checkoutButton: {
    marginTop: 20,
    minWidth: 150,
    minHeight: 36,
  }
}))

export default ({ message, title, error, isBillet = false }) => {
  const isLoadingBillet = useSelector(isLoadingBilletSelector)
  const postAgreement = useSelector(postFinancialDataSelector)
  const appid = useSelector(appIdSelector)
  const cpfCnpj = useSelector(cpfCnpjSelector)
  const classes = useStyles()
  const dispatch = useDispatch()

  const billet = () => {
    const params = {
      appid,
      journaltransrecids: [postAgreement.listOpenInstallment && postAgreement.listOpenInstallment[0].recIdTransOpen]
    }

    dispatch(billetRequest(params, cpfCnpj)).then(result => {
      const { fileurl: url } = result
      if (url) {
        const button = document.getElementById('btn-mdl-billet')
        const listener = () => {
          window.open(url, '_blank', 'noopener')
        }
        dispatch(setWithConfig({
          customMessage: () => <FormattedMessage id='opened-payment-billet' values={{ count: 1 }} />,
          autoHideDuration: null,
          action: SnackBillet
        }))

        button.addEventListener('click', listener, false)
        button.dispatchEvent(new CustomEvent('click'))
        button.removeEventListener('click', listener, false)
      }
    })
  }

  return (
    <React.Fragment>
      <Box justifyContent='center' >
        <Grid container className={classes.ckeckoutLoading} alignContent='center' >
          <Grid item xs={12} align='center'>
            <Typography className={classNames(error && classes.ckeckoutError)} variant='h6' gutterBottom color='primary'>
              {title}
            </Typography>
            <Grid item xs={12} align='center'>
              <Typography variant='subtitle1'>
                {message}
              </Typography>
            </Grid>
            {postAgreement && postAgreement.financialAgreementId && isBillet && <Grid item xs={12} align='center'>
              <Button
                id='btn-mdl-billet'
                variant='contained'
                color='primary'
                target='_blank'
                onClick={billet}
                className={classes.checkoutButton}
                disabled={isLoadingBillet}
              >
                {isLoadingBillet ?
                  <CircularProgress size={20} /> :
                  <FormattedMessage id='finance-negotiation-comp-message' />
                }
              </Button>
            </Grid>}
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  )
}
