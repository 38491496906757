import {selector} from "recoil";
import {homeCardAppliedFiltersState} from "../atoms/homeCardAppliedFiltersState";
import matriculasSelector from "./matriculasSelector";

const filteredHomeCardsSelector = selector({
  key: 'filteredHomeCardsSelector',
  get: ({get}) => {
    let matriculas = get(matriculasSelector);
    const filters = get(homeCardAppliedFiltersState) || {};

    const sortByAnoLetivoDesc = (a: any, b: any) => {
      const aY = parseInt(a.anoLetivo_Name);
      const bY = parseInt(b.anoLetivo_Name);
      if (aY < bY) {
        return 1;
      }
      if (aY > bY) {
        return -1;
      }
      return 0;
    }

    const mat = [...matriculas].sort(sortByAnoLetivoDesc);

    if (Object.keys(filters).length) {
      return mat.filter(matricula => {
        const keys = Object.keys(filters)
        return keys.every(key => (filters[key] || []).length ? (filters[key] || []).includes(String(matricula[key])) : true);
      });
    }

    return mat
  },
});

export default filteredHomeCardsSelector;
