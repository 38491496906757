import type {FC} from 'react';
import {useTheme} from "@mui/material/styles";
import {useRecoilValue} from "recoil";
import unidadeGetResponseState from "../recoil/atoms/unidadeGetResponseState";

export const Logo: FC = () => {
  const theme = useTheme();
  const unidade = useRecoilValue(unidadeGetResponseState);
  return (
    <svg
      viewBox="0 0 66 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      style={{
        borderRadius: theme.shape.borderRadius,
        width: '123px',
      }}
    >
      <rect
        width="66"
        height="42"
        fill="url(#pattern0)"
      />
      <defs>
        <pattern
          id="pattern0"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use
            xlinkHref="#image0_702_20576"
            transform="matrix(0.00497159 0 0 0.0078125 -0.00213068 0)"
          />
        </pattern>
        <image
          id="image0_702_20576"
          width="202"
          height="128"
          xlinkHref={unidade?.logo ? unidade?.logo : "/img/logo-md.png"}
        />
      </defs>
    </svg>
  );
};
