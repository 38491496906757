import {atom} from "recoil";
import IMatriculaResponse from "../../@types/IMatriculaResponse";
import {persistAtomEffect} from "../effects/persistAtom";
import * as _contactService from "../../service/contact-service";
import {getRecoil, setRecoil} from "recoil-nexus";
import {avatarsState} from "./avatarsState";

export const matriculasByResponsavelResponseState = atom<IMatriculaResponse | null>({
  key: 'matriculasByResponsavelResponseState',
  default: null,
  effects: [
    persistAtomEffect,
    ({onSet}) => {
      onSet((newValue, oldValue) => {
        if (newValue === oldValue) return;

        const avatars = getRecoil(avatarsState);
        const inProgress: Array<string> = [];
        const sas = {};

        const estudantes = (newValue?.value || []).map(matricula => matricula?.estudante_Id)
          .filter((value, index, self) => self.indexOf(value) === index) as string[];

        const promises = (estudantes || []).map(id => {
          if (inProgress.includes(id)) return;
          if (Object.keys(avatars).includes(id))
            return;
          else
            sas[id] = null;
          inProgress.push(id)
          return _contactService.getById(id)
            .then(async contato => {
              if (contato && contato?.urlImagemPerfil) {
                sas[id] = await _contactService.getSas(contato?.urlImagemPerfil)
              }
            })
        })

        Promise.all(promises).then(() => {
          setRecoil(avatarsState, {
            ...avatars,
            ...sas
          });
        });
      })
    }
  ]
});

export default matriculasByResponsavelResponseState;
