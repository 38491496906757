import {selector} from "recoil";
import {selectedMatriculaState} from "../atoms/selectedMatriculaState";
import {calcListServicesState} from "../atoms/calcListServicesState";

const selectedCalcListServiceSelector = selector({
  key: 'selectedCalcListServiceSelector',
  get: ({get}) => {
    const matricula = get(selectedMatriculaState);
    const services = get(calcListServicesState);
    return services.find(s => s.matriculaId === matricula?.id);
  },
});

export default selectedCalcListServiceSelector;
