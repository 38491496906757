import { DEACTIVE_TOUR, ACTIVE_TOUR } from '../constants'
import Cookies from "universal-cookie/cjs";

const defaultState = {
  // usado quando o usuario ja viu o tutorial
  // e quer ver novamente quando clica no menu
  active: false,
}

export default (state = defaultState, action) => {
  const cookies = new Cookies();
  switch (action.type) {
  case ACTIVE_TOUR:
    cookies.set('tour', 'true', { path: '/' });
    return {
      ...state,
      active: true,
    }
  case DEACTIVE_TOUR:
    cookies.set('tour', 'false', { path: '/' });
    return {
      ...state,
      active: false,
    }
  default:
    return state
  }
}
