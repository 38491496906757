export const paths = {
  index: '/',
  auth: {
    ad: {
      login: '/auth/ad/login',
      register: '/auth/ad/register'
    },
  },
  dashboard: {
    services: '/ServicosDisponiveis',
    index: '/CadastroConcluido',
    account: '/dashboard/account',
    accountSecurity: '/dashboard/account?tab=security',
    financial: {
      home: '/portal-financeiro',
      paymentChange: '/portal-financeiro/mudanca-metodo-de-pagamento',
      declarations: '/portal-financeiro/declaracoes',
      history: '/portal-financeiro/negociacoes/historico',
      openQuotas: '/portal-financeiro/parcelas/abertas',
      paidQuotas: '/portal-financeiro/parcelas/pagas',
      agreement: '/portal-financeiro/negociacoes/acordo',
      myCards: '/portal-financeiro/meus-cartoes',
    },
    chat: '/dashboard/chat',
    users: {
      index: '/dashboard/users',
      create: '/dashboard/users/create',
      details: '/dashboard/users/:userId'
    },
    roles: {
      index: '/dashboard/roles',
      create: '/dashboard/roles/create',
      details: '/dashboard/roles/:roleId'
    },
    permissions: {
      index: '/dashboard/permissions',
      details: '/dashboard/permissions/:permissionId'
    },
  },
  docs: 'https://sistemaseb.sharepoint.com.mcas.ms/_layouts/15/sharepoint.aspx',
  notAuthorized: '/401',
  notFound: '/404',
  serverError: '/500'
};
