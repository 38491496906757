import { always } from 'ramda'
import { trim } from 'lodash'
import { apiErrorAction, apiRequestedAction, apiSuccessAction } from '../../utils/API/actions'
import { API_REQUEST } from '../../utils/API/constants'
import { baseAPIRequest } from '../../utils/API/request'
import { ENTITY_VERSION, VERSIONS } from '../../utils/version'
import axios from '../../utils/axios'
import { tokenUserSelector } from '../../modules/Login/selectors/user'

const addAuthorizationConfig = (config = {}, requiresAuthentication = true, token) => ({
  ...config,
  headers: {
    ...(requiresAuthentication && token ? { Authorization: `Bearer ${token}` } : { Authorization: undefined }),
    ...config.headers,
  },
})

const apiRequestMiddleware = store => next => action => {
  if (action.type === API_REQUEST) {
    const {
      entity,
      meta,
      request: { method, url, data, config },
      shouldStoreData,
      requiresAuthentication,
      onSuccess,
      onError,
      hasPagination,
      error: previousError,
      validation,
    } = action.payload

    const shouldStoreDataFn = shouldStoreData || always(true)
    const validationFn = validation || always(true)

    store.dispatch(apiRequestedAction(entity, meta))

    const token = tokenUserSelector(store.getState())
    const version = ENTITY_VERSION[entity] || VERSIONS.V1

    const createUrl = version.concat(url)
    const requestPromise = previousError
      ? Promise.reject(previousError)
      : baseAPIRequest(axios, method, trim(createUrl), data, addAuthorizationConfig(config, requiresAuthentication, token))

    requestPromise
      .then(result => {
        const { elements, pagination } = result
        validationFn(data)
        const resultData = elements && !hasPagination ? elements : result
        store.dispatch(
          apiSuccessAction(entity, shouldStoreDataFn(resultData, pagination) ? resultData : null, pagination, meta)
        )

        if (onSuccess) onSuccess(resultData)
      })
      .catch(error => {
        store.dispatch(apiErrorAction(entity, error.response && error.response.data, meta))

        if (onError) onError(error)
      })
  }

  return next(action)
}

export default apiRequestMiddleware
