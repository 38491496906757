import {selector} from "recoil";
import selectedCalcListServiceSelector from "../../selectedCalcListServiceSelector";

const servicosObrigatoriosSelector = selector({
  key: 'servicosObrigatoriosSelector',
  get: ({get}) => {
    let servicos = get(selectedCalcListServiceSelector);
    return servicos?.servicosObrigatorios ?? [];
  },
});

export default servicosObrigatoriosSelector;
