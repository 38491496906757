import React, {useEffect, useState} from "react";
import * as _portalCompanyService from "../../service/portal-company-service";
import PageLoading from "../PageLoading/PageLoading";
import {useRecoilState} from "recoil";
import {walletContractsPeriodState} from "../../recoil/atoms/walletContractsPeriodState";
import {walletContractsCheckedState} from "../../recoil/atoms/walletContractsCheckedState";
import * as _portalTermService from '../../service/portal-term-service';
import fetch from "../../utils/fetch";
import {Checkbox, FormControlLabel} from "@mui/material";
const nowDate = new Date();
const nowDay = nowDate.getDate();
const nowMonth = nowDate.getMonth() + 1;
const nowYear = nowDate.getFullYear();

function WalletContract(
  {
    segmentoAluno = "",
    codigoMatricula = "",
    cursoNome = "",
    valorPeriodo,
    aluno = "",
    username = localStorage.getItem("@SMO20/nome"),
    year = new Date().getFullYear(),
    subId,
  }
) {
  const [companyInfo, setCompanyInfo] = useState(null);
  const {
    address,
    city,
    cnpj,
    companyName,
    urlZenDesk
  } = companyInfo || {};
  const [loading, setLoading] = useState(false);
  const [periodo, setPeriodo] = useRecoilState(walletContractsPeriodState);
  let periodoName = periodo.find(p => p.valor === valorPeriodo)?.label ?? "";
  periodoName = cursoNome.includes(periodoName) ? "" : "- " + periodoName;
  const [checkeds, setChecked] = useRecoilState(walletContractsCheckedState);
  const checked = checkeds.includes(subId);

  const handleCheck = (event) => {
    if (event.target.checked) {
      setChecked([
        ...checkeds,
        subId
      ])
      _portalTermService.PortalAcceptanceTermChangePaymentMode({
        RazaoSocialUnidade: companyName,
        CNPJUnidade: cnpj,
        EnderecoUnidade: address,
        SiteUnidade: urlZenDesk,
        NomeAluno: aluno,
        Matricula: codigoMatricula,
        TipoPagamento: 'Crédito Recorrente',
        DescricaoCurso: cursoNome,
        ResponsavelPessoaFisica: username,
        SegmentoAluno: segmentoAluno,
        AnoLetivo: year,
        CidadeEscola: city,
        DataMatricula: `${nowDay}/${nowMonth}/${nowYear}`,
        NomeResponsavelContrato: username,
        AssinaturaResponsaveisContratual: username,
      })
    } else {
      setChecked(checkeds.filter(c => c !== subId))
    }
  }

  useEffect(() => {
    if (!loading && !periodo.length) {
      if (loading) return;
      setLoading(true);

      fetch("api/ConjuntoOpcao/Get/ptr_cursodaunidade/seb_periodo", {
        method: "GET",
        mode: "cors",
      }).then(res => res.json())
        .then(resp => setPeriodo(resp))
        .finally(() => setLoading(false))
    }
  }, [periodo])

  useEffect(() => {
    if (companyInfo === null) {
      if (loading) return;

      setLoading(true);

      _portalCompanyService.GetCompany("3302")
        .then(async resp => {
          if (resp.ok) {
            const json = await resp.json();
            setCompanyInfo(json?.response);
          }
        }).finally(() => {
          setLoading(false);
        })
    }
  }, [companyInfo])

  if (loading) return <div className="smo-wallet-contract-container"><PageLoading/></div>

  return (
    <React.Fragment>
      <div className="smo-wallet-contract-container">
        <p><b>1. PARTES</b></p>

        <p><b>1.1 CONTRATADA:</b></p>

        <p>{companyName}</p>

        <p><b>CNPJ/MF</b>: {cnpj}</p>

        <p><b>Endereço</b>: {address}</p>

        <p><b>Endereço Eletrônico / Site</b>: {urlZenDesk}</p>

        <br/>
        <br/>

        <p><b>1.2 ALUNO:</b></p>

        <p><b>Nome do Aluno:</b> <span className="smo-wallet-contract-uppercase">{aluno}</span>&nbsp;&nbsp;&nbsp;<b>Matricula:</b> {codigoMatricula}</p>

        <p><b>Curso/Série:</b> {cursoNome} {periodoName}</p>

        <p className="smo-wallet-contract-uppercase">{username}</p>

        <br/>
        <br/>

        <p><b>2. CURSO CONTRATADO E PERÍODO:</b></p>

        <p><b>Curso/Série - Turno:</b> {cursoNome} {periodoName}</p>

        <p><b>Ano Letivo:</b> {year}</p>

        <br/>
        <br/>

        <p><b>3. NOVA FORMA DE PAGAMENTO</b></p>

        <p>Crédito Recorrente</p>

        <p><b>RESOLVEM</b> , os signatários do presente instrumento, estipular as seguintes cláusulas e condições que
          passam a regular, supletivamente, o contrato de prestação de serviços educacionais firmado em para o ano letivo
          de {year}.</p>

        <br/>
        <br/>

        <p><b>CLÁUSULA PRIMEIRA</b></p>

        <p><b>1.1.</b> Considerando o interesse do <b>Aluno/Responsável</b> Contratual manifestado nesta data,em alterar a
          forma de pagamento inicialmente contratada, bem como a anuência da <b>Instituição de Ensino</b> em acolher sua
          pretensão, as Partes formalizam a referida alteração mediante as condições previstas neste instrumento.</p>

        <p><b>1.2.</b> O <b>Aluno/Responsável</b> Contratual está ciente que a troca da forma de pagamento está, nos termos do referido
          contrato de prestação de serviços educacionais, condicionada à comprovação documental das razões pelas quais
          ele, <b>Aluno/Responsável Contratual</b>, se encontra impedido de permanecer na forma inicialmente contratada, cujo
          critério de avaliação de procedência de suas alegações será feito exclusivamente pela <b>Instituição de Ensino</b>.</p>

        <p><b>1.3</b> Ainda nos termos do quanto previsto no contrato de prestação de serviços educacionais, o <b>Aluno/Responsável
          Contratual</b> está ciente, também, que a troca da forma de pagamento será realizada após decorridos 90 (noventa)
          dias contados da presente data, permanecendo ele, <b>Aluno/Responsável Contratual</b>, obrigado ao pagamento na forma
          inicialmente contratada durante esse período.</p>

        <br/>
        <br/>

        <p><b>CLÁUSULA SEGUNDA</b></p>

        <p><b>2.1.</b> As demais cláusulas estipuladas no Contrato para Prestação de Serviços Educacionais que não foram objeto
          do presente termo de aditamento, ou não receberam outra interpretação, permanecem inalteradas, devendo as Partes
          respeitá-las na íntegra.</p>

        <p className="smo-wallet-contract-container-dashed">Neste ato, assinei o <b>TERMO ADITIVO AO CONTRATO DE PRESTAÇÃO DE SERVIÇOS EDUCACIONAIS</b>, tomei ciência de todas
          suas cláusulas, com as quais concordei, inclusive após releitura, e reitero minha concordância para os devidos
          fins e direitos.</p>

        <p>E por estarem justos e contratados, assinam o presente instrumento em duas vias de igual teor e forma, na
          presença de duas testemunhas, para que se produzam todos os efeitos legais.</p>

        <div className="smo-wallet-contract-container-flex">
          <p><b>LOCAL:</b> {city}</p>
          <p><b>DATA:</b> {nowDay}/{nowMonth}/{nowYear}</p>
        </div>

        <br/>
        <br/>

        <div className="smo-wallet-contract-container-flex">
        <span className="smo-wallet-contract-signature-container">
          <hr/>
          <p><b>Responsável pela Instituição de Ensino</b></p>
        </span>

          <span className="smo-wallet-contract-signature-container">
          <hr/>
          <p className="smo-wallet-contract-uppercase">{username}</p>
          <p><b>Responsável Contratual</b></p>
        </span>
        </div>

        <br/>
        <br/>

        <div className="smo-wallet-contract-signature-container">
          <hr/>
          <p><b>Cônjuge</b></p>
          <p><b>RG:</b></p>
          <p><b>CPF:</b></p>
        </div>

        <br/>
        <br/>

        <div className="smo-wallet-contract-container-flex">
        <span className="smo-wallet-contract-signature-container">
          <hr/>
          <p><b>Testemunha</b></p>
          <p><b>RG:</b></p>
          <p><b>CPF:</b></p>
        </span>

          <span className="smo-wallet-contract-signature-container">
          <hr/>
          <p><b>Testemunha</b></p>
          <p><b>RG:</b></p>
          <p><b>CPF:</b></p>
        </span>
        </div>

        <br/>
        <br/>

        <p>Neste ato, declaro que assinei o <b>TERMO ADITIVO AO CONTRATO DE PRESTAÇÃO DE SERVIÇOS EDUCACIONAIS</b> e tomei
          ciência de todas as suas Cláusulas, com as quais concordo para todos os fins contratuais e legais.</p>

        <br/>
        <br/>

        <div className="smo-wallet-contract-signature-container">
          <hr/>
          <p className="smo-wallet-contract-uppercase"><b>{username}</b></p>
        </div>

        <br/>
      </div>

      <div className="smo-wallet-contract-checkbox-container">
        <FormControlLabel
          control={
            <Checkbox
              checked={checked}
              onChange={handleCheck}
              disabled={loading}
            />
          }
          label="Li e concordo com o termo de acordo estabelecido"
        />
      </div>
    </React.Fragment>
  )
}

export default WalletContract;
