import {atom} from "recoil";
import {persistAtomEffect} from "../effects/persistAtom";

interface GlobalLoadingState {
    showLoading: boolean;
    textToDisplay: string;
}

export const globalLoadingState = atom<GlobalLoadingState>({
  key: 'globalLoadingState',
  default: {
    showLoading: false,
    textToDisplay: "Carregando"
  },
  effects: [
    persistAtomEffect
  ]
});
