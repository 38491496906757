import React, {Fragment, useState} from "react";
import {useDispatch} from "react-redux";
import useStyles from "./style";
import {injectIntl} from "react-intl";
import AutomaticDebitDialogForm from "../AutomaticDebitDialogForm";
import AutomaticDebitDialogSummary from "../AutomaticDebitDialogSummary";
import {changePaymentTypeToAutomaticDebit} from "../../../../../../../modules/Finance/PaymentTypes/actions/index";
import TermAgreement from "../../../../../../../modules/Finance/PaymentTypes/components/TermAgreement";
import {getBankByCode} from "../../../../../../../utils/utils";
import {Grid} from "@mui/material";
import Typography from "@mui/material/Typography";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";

const steps = [
  {
    label: "Dados Bancários",
    id: "lbl-mdl-pag-pag",
  },
  {
    label: "Aceite",
    id: "lbl-mdl-pag-agr",
    isVoltar: true,
    isAvancar: true,
  },
  {
    label: "Revisão",
    isVoltar: true,
    isConfirmar: true,
    id: "lbl-mdl-pag-rev",
  },
];

export default injectIntl(
  ({
    intl: { messages },
    setIsLoading,
    onSuccess,
    onError,
    selectedMatricula,
    setAcceptanceTermObj,
    data,
  }) => {
    const classes = useStyles();
    const [activeStep, setActiveStep] = useState(0);
    const dispatch = useDispatch();
    const [disabled, setDisabled] = useState(false);

    const [automaticDebit, setAutomaticDebit] = useState({
      cc: data && data.debito_automatico_conta ? data.debito_automatico_conta : "",
      bank: data && data.debito_automatico_banco ? getBankByCode(data.debito_automatico_banco) : null,
      agency: data && data.debito_automatico_agencia
        ? data.debito_automatico_agencia
        : "",
    });

    const loading = false;
    let error;

    const finishPayment = () => {
      setIsLoading(true);

      dispatch(
        changePaymentTypeToAutomaticDebit(
          automaticDebit.bank.value,
          automaticDebit.cc,
          automaticDebit.agency
        )
      )
        .then(() => {
          setIsLoading(false);
          onSuccess();
        })
        .catch((errorMessage) => {
          setIsLoading(false);
          onError(errorMessage);
        });
    };

    const handleNext = () => {
      setDisabled(false);
      setActiveStep(activeStep + 1);
    };

    const handleBack = () => {
      setActiveStep(activeStep - 1);
    };

    const getStepContent = () => {
      switch (activeStep) {
        case 0:
          return (
            <AutomaticDebitDialogForm
              setAutomaticDebit={setAutomaticDebit}
              handleNext={handleNext}
              automaticDebit={automaticDebit}
            />
          );
        case 1:
          return (
            <TermAgreement
              setDisabled={setDisabled}
              selectedMatricula={selectedMatricula}
              newPaymentType="Débito Automático"
              setAcceptanceTermObj={setAcceptanceTermObj}
            />
          );
        case 2:
          return (
            <AutomaticDebitDialogSummary automaticDebit={automaticDebit} />
          );
        default:
          return;
      }
    };

    const controllActiveStep =
      activeStep !== 2 || loading || (activeStep === 2 && error)
        ? activeStep
        : 3;

    return (
      <Grid
        container
        justify="space-between"
        direction="row"
        alignItems="center"
        className={classes.content}
      >
        <Grid item xs={12}>
          <Typography component="h1" variant="h4" align="center">
            Débito Automático
          </Typography>
          <Stepper
            activeStep={controllActiveStep}
            className={classes.stepper}
            alternativeLabel
          >
            {steps.map((step) => (
              <Step key={step.label}>
                <StepLabel id={step.id}>{step.label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Grid>
        <Fragment>
          <Grid item xs={12} className={classes.contentStep}>
            {getStepContent()}
          </Grid>
          <Buttons
            activeStep={activeStep}
            error={error}
            finishPayment={finishPayment}
            handleBack={handleBack}
            disabled={disabled}
            handleNext={handleNext}
          />
        </Fragment>
        {/* } */}
      </Grid>
    );
  }
);

const Buttons = ({
  activeStep,
  finishPayment,
  handleBack,
  disabled,
  handleNext,
}) => {
  const classes = useStyles();
  return (
    <Grid item xs={12} className={classes.buttons}>
      {steps[activeStep].isVoltar && (
        <Button
          id="btn-mdl-pag-vlt"
          onClick={handleBack}
          className={classes.button}
        >
          Voltar
        </Button>
      )}
      {steps[activeStep].isConfirmar && (
        <Button
          id="btn-mdl-pag-cfm"
          variant="contained"
          color="primary"
          onClick={finishPayment}
          className={classes.button}
        >
          Confirmar
        </Button>
      )}
      {steps[activeStep].isAvancar && (
        <Button
          id="btn-mdl-pag-cfm"
          variant="contained"
          color="primary"
          disabled={!disabled}
          className={classes.button}
          onClick={handleNext}
        >
          Avançar
        </Button>
      )}
    </Grid>
  );
};
