import React from 'react'
import Checkout from './components/Checkout'
import { paymentSelector } from '../../selectors/payments'
import { useDispatch, useSelector } from 'react-redux'
import { PAYMENT, IS_SUCCESS_REDE } from '../../constants'
import { apiResetAction } from '../../../../../utils/API/actions'
import { deleteCookie } from '../../../../../utils/persistCookie'
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import useMediaQuery from "@mui/material/useMediaQuery";
import CloseIcon from "@mui/icons-material/Close";
import DialogTitle from "@mui/material/DialogTitle";

export const PaymentDialog = ({ open, onClose, items }) => {
  const fullScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'))
  const dispatch = useDispatch()
  const { loading, waitingRede } = useSelector(paymentSelector)

  function handleClose() {
    onClose()
    dispatch(apiResetAction(PAYMENT))
    deleteCookie(IS_SUCCESS_REDE)
  }

  return (
    <Dialog
      maxWidth="lg"
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      aria-labelledby='payment-dialog-title'
    >
      <DialogTitle
        id="scroll-dialog-title">
        Pagamento
      </DialogTitle>
      <IconButton
        disabled={loading || waitingRede}
        aria-label='close-payment'
        id='ico-mdl-pag-fch'
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>

      <Checkout items={items} handleClose={handleClose} />
    </Dialog>
  )
}

export default PaymentDialog