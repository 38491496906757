import React from "react";
import ServicoRegularResumo from "./ServicoRegularResumo";
import Card from "@mui/material/Card";
import Avatar from "@mui/material/Avatar";
import GerarSiglaNome from "../../commons/functions/gerar-sigla-nome";
import {useRecoilValue} from "recoil";
import Divider from "@mui/material/Divider";
import CardHeader from "@mui/material/CardHeader";
import {selectedMatriculaState} from "../../recoil/atoms/selectedMatriculaState";
import {avatarsState} from "../../recoil/atoms/avatarsState";
import podeContratarServicoObrigatorioSelector from "../../recoil/selectors/podeContratarServicoObrigatorioSelector";
import ServicoComplementarResumo from "./ServicoComplementarResumo";
import podeContratarServicoRegularSelector
  from "../../recoil/selectors/carrinho/servicoRegular/podeContratarServicoRegularSelector";
import PodeContratarServicoComplementarSelector
  from "../../recoil/selectors/carrinho/servicosUpSelling/podeContratarServicoComplementarSelector";
import servicosUpSellingSelecionadosSelector
  from "../../recoil/selectors/carrinho/servicosUpSelling/servicosUpSellingSelecionadosSelector";
import servicosObrigatoriosSelector
  from "../../recoil/selectors/carrinho/servicosObrigatorios/servicosObrigatoriosSelector";
import ServicoObrigatorioResumo from "./ServicoObrigatorioResumo";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {CardContent} from "@mui/material";


const Carrinho: React.FC = () => {
  const servicosObrigatorios = useRecoilValue(servicosObrigatoriosSelector);
  const podeContratarServicoRegular = useRecoilValue(podeContratarServicoRegularSelector);
  const podeContratarServicoObrigatorio = useRecoilValue(podeContratarServicoObrigatorioSelector);
  const podeContratarServicoComplementar = useRecoilValue(PodeContratarServicoComplementarSelector);
  const servicosUpSellingSelecionados = useRecoilValue(servicosUpSellingSelecionadosSelector);
  const matricula = useRecoilValue(selectedMatriculaState);
  const avatars = useRecoilValue(avatarsState);
  const avatar = matricula?.estudante_Id ? avatars[matricula?.estudante_Id] : null

  return (
    <Box
      className="smo-carrinho-container"
    >
      <Card
        elevation={0}
        sx={{
          pb: 10
        }}
      >
        <CardHeader
          title={localStorage.getItem("@SMO20/nomeAluno") || ""}
          titleTypographyProps={{
            sx: {
              maxWidth: '320px',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              fontWeight: 700
            }
          }}
          avatar={(
            <Avatar
              sx={{
                width: 48,
                height: 48,
                background: 'linear-gradient(135deg, #4180f6, #1cfaef)'
              }}
              src={avatar}
            >
              {GerarSiglaNome(matricula?.estudante_Name || "")}
            </Avatar>
          )}
        />

        <Divider/>

        <CardContent>
          <Box
            sx={{
              pb: 2
            }}
          >
            <Typography
              fontWeight="bold"
              sx={{
                pb: 1
              }}
            >
              Momento Escolar
            </Typography>

            <Typography
              fontSize="0.8rem"
            >
              {matricula?.nomeLandingPage}
            </Typography>
          </Box>

          <Divider/>

          {podeContratarServicoRegular &&
            <ServicoRegularResumo/>
          }

          {podeContratarServicoObrigatorio && servicosObrigatorios?.map((servicoObrigatorio) => (
            <ServicoObrigatorioResumo
              key={servicoObrigatorio.id}
              servico={servicoObrigatorio}
            />
          ))}

          {podeContratarServicoComplementar && servicosUpSellingSelecionados?.map((servicos) => (
            <ServicoComplementarResumo
              key={servicos.id}
              servico={servicos}
            />
          ))}
        </CardContent>
      </Card>
    </Box>
  )
}

export default Carrinho;
