import React, {createRef} from 'react';
import {useHistory} from "react-router-dom";
import "./styles.css";
import Box from "@mui/material/Box";
import {LoadingButton} from "@mui/lab";
import useMediaQuery from "@mui/material/useMediaQuery";
import {Theme} from "@mui/material/styles/createTheme";
import Button from "@mui/material/Button";
import Menu from '@mui/material/Menu';
import Carrinho from "../Carrinho/Carrinho";
import {useSetRecoilState} from "recoil";
import Slide from "@mui/material/Slide";
import ReceiptIcon from '@mui/icons-material/Receipt';
import {Stack, useTheme, Zoom} from '@mui/material';
import {slideDirectionState} from "../../recoil/atoms/slideDirectionState";
import Typography from "@mui/material/Typography";
import CheckIcon from '@mui/icons-material/Check';
import SvgIcon from "@mui/material/SvgIcon";
import classNames from "../../utils/classNames";

interface StepCheckoutActionButtonsProps {
  previousStepPath?: string | null;
  previousStepText?: string;
  previousStepDisabled?: boolean;
  previousStepType?: "button" | "submit";
  nextStepPath?: string;
  nextStepText?: string;
  nextStepDisabled?: boolean;
  nextStepType?: "button" | "submit";
  onClickNextStep?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  loading?: boolean;
  showCart?: boolean;
  showSuccessAnimation?: boolean;
  successChildren?: React.ReactNode;
}

const StepCheckoutActionButtons: React.FC<StepCheckoutActionButtonsProps> = (
  {
    previousStepPath = "/CadastroConcluido",
    previousStepText = "Voltar",
    previousStepDisabled = false,
    previousStepType = "button",
    nextStepPath = "/MomentoEscolarResponsavel",
    nextStepText = "Tudo certo, avançar",
    nextStepDisabled = false,
    nextStepType = "button",
    onClickNextStep,
    loading = false,
    showCart = false,
    showSuccessAnimation = false,
  }
) => {
  const menuRef = createRef<HTMLDivElement>();
  const smUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const setSlideDirection = useSetRecoilState(slideDirectionState);
  const theme = useTheme();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(menuRef.current);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleBackButton = (event: any) => {
    event.preventDefault();
    setSlideDirection("right");
    if (previousStepPath === null) return;
    history.push(previousStepPath);
  }

  const handleNextButton = (event: any) => {
    event.preventDefault();
    setSlideDirection("left");
    if (onClickNextStep && typeof onClickNextStep === "function") {
      onClickNextStep(event);
    } else {
      history.push(nextStepPath);
    }
  }

  return (
    <Stack
      ref={menuRef}
      id="smo-checkout-actions"
      direction="row"
      justifyContent="center"
      alignItems="center"
    >
      <Stack
        id="smo-checkout-actions-container"
        direction="row"
        spacing={1}
        justifyContent="space-between"
        sx={{
          pl: 1,
          pr: 1,
          width: '100%',
          maxWidth: theme.breakpoints.values.xl,
        }}
      >
        <span className="smo-checkout-actions-bg"/>
        <LoadingButton
          id="voltar"
          variant="outlined"
          color="inherit"
          size={smUp ? 'large' : 'small'}
          disabled={previousStepDisabled || loading}
          onClick={handleBackButton}
          type={previousStepType}
          sx={{
            opacity: previousStepPath === null ? 0 : 1,
            pointerEvents: previousStepPath === null ? 'none' : 'auto'
          }}
          startIcon={(
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                rotate: '180deg'
              }}
            >
              <svg className="seb seb-arrow-right" width="24" height="24" viewBox="0 0 24 24" fill="currentColor"
                   xmlns="http://www.w3.org/2000/svg">
                <path d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z"/>
              </svg>
            </Box>
          )}
        >
          {previousStepText}
        </LoadingButton>

        {(!smUp && showCart) && (
          <Box
            sx={{
              position: 'relative'
            }}
          >
            <Box
              sx={{
                position: 'absolute',
                top: '32%',
                right: '-50%',
                transform: 'translate(50%, -50%)',
                borderRadius: '50%',
                aspectRatio: '1/1',
                width: 80,
                height: 80,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                bgcolor: 'white',
                color: 'white',
                fontSize: 16,
                fontWeight: 'bold',
              }}
            />
            <Button
              color="primary"
              variant="contained"
              size="large"
              onClick={handleClick}
              sx={{
                position: 'absolute',
                top: '33%',
                right: '-50%',
                transform: 'translate(50%, -50%)',
                borderRadius: '50%',
                aspectRatio: '1/1',
                p: 0
              }}
            >
              <Stack
                direction="column"
                alignItems="center"
              >
                <ReceiptIcon
                  sx={{
                    fontSize: 22
                  }}
                />
                <Typography
                  variant="caption"
                  sx={{
                    fontSize: 8
                  }}
                >
                  Resumo
                </Typography>
              </Stack>
            </Button>
          </Box>
        )}

        {showCart && (
          <Menu
            dense
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            slotProps={{
              paper: {
                sx: {
                  maxHeight: 'calc(100vh - 200px)',
                  filter: 'drop-shadow(0px 1px 2px rgba(0,0,0,0.32))',
                }
              }
            }}
            TransitionComponent={Slide}
            TransitionProps={{
              // @ts-ignore
              direction: "up"
            }}
          >
            <Carrinho/>
          </Menu>
        )}

        <LoadingButton
          className={classNames(
            smUp && showSuccessAnimation ? "smo-checkout-actions-success-animation-button" : ""
          )}
          variant="outlined"
          color="primary"
          size={smUp ? 'large' : 'small'}
          disabled={nextStepDisabled}
          onClick={nextStepType !== 'submit' ? handleNextButton : () => setSlideDirection("left")}
          type={nextStepType}
          endIcon={((smUp && !showSuccessAnimation) || !smUp) && (
            <svg className="seb seb-arrow-right" width="24" height="24" viewBox="0 0 24 24" fill="currentColor"
                 xmlns="http://www.w3.org/2000/svg">
              <path d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z"/>
            </svg>
          )}
          loading={loading}
          sx={{
            transition: 'all 0.3s',
          }}
        >
          {(smUp && showSuccessAnimation) && (
            <Box
              className={classNames(
                "smo-checkout-actions-success-animation-container",
                showSuccessAnimation ? "show" : ""
              )}
            >
              <CheckIcon
                fontSize={smUp ? "large" : "small"}
                className="smo-checkout-actions-success-icon"
                color="inherit"
              />
            </Box>
          )}
          {((smUp && !showSuccessAnimation) || !smUp) && (
            <>
              {!smUp ? "Avançar" : nextStepText}
            </>
          )}
        </LoadingButton>
      </Stack>
    </Stack>
  );
}

export default StepCheckoutActionButtons;
