import type {FC} from 'react';
import {useMemo} from 'react';
import PropTypes from 'prop-types';
import Menu01Icon from '@untitled-ui/icons-react/build/esm/Menu01';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import SvgIcon from '@mui/material/SvgIcon';
import useMediaQuery from '@mui/material/useMediaQuery';
import type {Theme} from '@mui/material/styles/createTheme';
import {useTheme} from '@mui/material/styles';

import {Logo} from '../../../components/logo';
import {RouterLink} from '../../../components/router-link';
import {usePathname} from '../../../hooks/use-pathname';
import {paths} from '../../../paths';
import type {NavColor} from '../../../types/settings';

import {AccountButton} from '../account-button';
import type {Section} from '../config';
import {TopNavSection} from './top-nav-section';
import Typography from "@mui/material/Typography";

const useCssVars = (color: NavColor): Record<string, string> => {
  const theme = useTheme();

  return useMemo(
    (): Record<string, string> => {
      switch (color) {
        case 'blend-in':
          if (theme.palette.mode === 'dark') {
            return {
              '--nav-bg': theme.palette.background.default,
              '--nav-color': theme.palette.neutral[100],
              '--nav-divider-color': theme.palette.neutral[800],
              '--nav-border-color': theme.palette.neutral[700],
              '--nav-logo-border': theme.palette.neutral[700],
              '--nav-item-color': theme.palette.neutral[400],
              '--nav-item-hover-bg': 'rgba(255, 255, 255, 0.04)',
              '--nav-item-active-bg': 'rgba(255, 255, 255, 0.04)',
              '--nav-item-active-color': theme.palette.text.primary,
              '--nav-item-disabled-color': theme.palette.neutral[600],
              '--nav-item-icon-color': theme.palette.neutral[500],
              '--nav-item-icon-active-color': theme.palette.primary.main,
              '--nav-item-icon-disabled-color': theme.palette.neutral[700],
              '--nav-item-chevron-color': theme.palette.neutral[700],
              '--nav-scrollbar-color': theme.palette.neutral[400]
            };
          } else {
            return {
              '--nav-bg': theme.palette.background.default,
              '--nav-color': theme.palette.text.primary,
              '--nav-divider-color': theme.palette.divider,
              '--nav-border-color': theme.palette.neutral[100],
              '--nav-logo-border': theme.palette.neutral[100],
              '--nav-section-title-color': theme.palette.neutral[400],
              '--nav-item-color': theme.palette.text.secondary,
              '--nav-item-hover-bg': theme.palette.action.hover,
              '--nav-item-active-bg': theme.palette.action.selected,
              '--nav-item-active-color': theme.palette.text.primary,
              '--nav-item-disabled-color': theme.palette.neutral[400],
              '--nav-item-icon-color': theme.palette.neutral[400],
              '--nav-item-icon-active-color': theme.palette.primary.main,
              '--nav-item-icon-disabled-color': theme.palette.neutral[400],
              '--nav-item-chevron-color': theme.palette.neutral[400],
              '--nav-scrollbar-color': theme.palette.neutral[900]
            };
          }

        case 'discrete':
          if (theme.palette.mode === 'dark') {
            return {
              '--nav-bg': theme.palette.neutral[900],
              '--nav-color': theme.palette.neutral[100],
              '--nav-divider-color': theme.palette.neutral[800],
              '--nav-border-color': theme.palette.neutral[700],
              '--nav-logo-border': theme.palette.neutral[700],
              '--nav-item-color': theme.palette.neutral[400],
              '--nav-item-hover-bg': 'rgba(255, 255, 255, 0.04)',
              '--nav-item-active-bg': 'rgba(255, 255, 255, 0.04)',
              '--nav-item-active-color': theme.palette.text.primary,
              '--nav-item-disabled-color': theme.palette.neutral[600],
              '--nav-item-icon-color': theme.palette.neutral[500],
              '--nav-item-icon-active-color': theme.palette.primary.main,
              '--nav-item-icon-disabled-color': theme.palette.neutral[700],
              '--nav-item-chevron-color': theme.palette.neutral[700],
              '--nav-scrollbar-color': theme.palette.neutral[400]
            };
          } else {
            return {
              '--nav-bg': theme.palette.neutral[50],
              '--nav-color': theme.palette.text.primary,
              '--nav-divider-color': theme.palette.neutral[200],
              '--nav-border-color': theme.palette.divider,
              '--nav-logo-border': theme.palette.neutral[200],
              '--nav-section-title-color': theme.palette.neutral[500],
              '--nav-item-color': theme.palette.neutral[500],
              '--nav-item-hover-bg': theme.palette.action.hover,
              '--nav-item-active-bg': theme.palette.action.selected,
              '--nav-item-active-color': theme.palette.text.primary,
              '--nav-item-disabled-color': theme.palette.neutral[400],
              '--nav-item-icon-color': theme.palette.neutral[400],
              '--nav-item-icon-active-color': theme.palette.primary.main,
              '--nav-item-icon-disabled-color': theme.palette.neutral[400],
              '--nav-item-chevron-color': theme.palette.neutral[400],
              '--nav-scrollbar-color': theme.palette.neutral[900]
            };
          }

        case 'evident':
          if (theme.palette.mode === 'dark') {
            return {
              '--nav-bg': theme.palette.neutral[800],
              '--nav-color': theme.palette.common.white,
              '--nav-divider-color': theme.palette.neutral[700],
              '--nav-border-color': 'transparent',
              '--nav-logo-border': theme.palette.neutral[700],
              '--nav-item-color': theme.palette.neutral[400],
              '--nav-item-hover-bg': 'rgba(255, 255, 255, 0.04)',
              '--nav-item-active-bg': 'rgba(255, 255, 255, 0.04)',
              '--nav-item-active-color': theme.palette.common.white,
              '--nav-item-disabled-color': theme.palette.neutral[500],
              '--nav-item-icon-color': theme.palette.neutral[400],
              '--nav-item-icon-active-color': theme.palette.primary.main,
              '--nav-item-icon-disabled-color': theme.palette.neutral[500],
              '--nav-item-chevron-color': theme.palette.neutral[600],
              '--nav-scrollbar-color': theme.palette.neutral[400]
            };
          } else {
            return {
              '--nav-bg': theme.palette.primary.main,
              '--nav-color': theme.palette.common.white,
              '--nav-divider-color': theme.palette.primary.main,
              '--nav-border-color': 'transparent',
              '--nav-logo-border': theme.palette.primary.main,
              '--nav-item-color': theme.palette.common.white,
              '--nav-item-hover-bg': 'rgba(255, 255, 255, 0.04)',
              '--nav-item-active-bg': 'rgba(255, 255, 255, 0.04)',
              '--nav-item-active-color': theme.palette.common.white,
              '--nav-item-disabled-color': theme.palette.neutral[500],
              '--nav-item-icon-color': theme.palette.common.white,
              '--nav-item-icon-active-color': theme.palette.common.white,
              '--nav-item-icon-disabled-color': theme.palette.neutral[500],
              '--nav-item-chevron-color': theme.palette.common.white,
              '--nav-scrollbar-color': theme.palette.neutral[400]
            };
          }

        default:
          return {};
      }
    },
    [theme, color]
  );
};

interface TopNavProps {
  color?: NavColor;
  onMobileNav?: () => void;
  sections?: Section[];
  onlyExit?: boolean;
}

export const TopNav: FC<TopNavProps> = (props) => {
  const {color = 'evident', onMobileNav, sections = [], onlyExit} = props;
  const pathname = usePathname();
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const cssVars = useCssVars(color);
  const theme = useTheme();

  return (
    <Box
      sx={{
        backgroundColor: 'background.paper',
        zIndex: 1,
        pb: 0
      }}
    >
      <Box
        sx={{
          position: 'relative',
          minHeight: 80,
          boxShadow: '0 1px 4px rgba(0, 0, 0, 0.3)',
          "&:after": {
            content: "''",
            position: 'absolute',
            zIndex: -1,
            boxShadow: '0 0 34px rgb(0 0 0 / 70%)',
            bottom: '0px',
            width: '40%',
            height: '100%',
            borderRadius: '100%',
            left: '10%',
            right: '10%',
          }
        }}
      >
        <Box
          component="header"
          sx={{
            ...cssVars,
            backgroundColor: 'var(--nav-bg)',
            color: 'var(--nav-color)',
            left: 0,
            position: 'sticky',
            top: 0,
            minHeight: 80,
            zIndex: (theme) => theme.zIndex.appBar,
          }}
        >
          <Stack
            alignItems="center"
            direction="row"
            justifyContent="space-between"
            spacing={2}
            sx={{
              px: 3,
              py: 1,
              minHeight: 80,
              maxWidth: theme.breakpoints.values.xl,
              margin: '0 auto'
            }}
          >
            <Stack
              alignItems="center"
              direction="row"
              spacing={2}
            >
              {!mdUp && (
                <IconButton onClick={onMobileNav}>
                  <SvgIcon sx={{
                    color: "white"
                  }}>
                    <Menu01Icon/>
                  </SvgIcon>
                </IconButton>
              )}
              {mdUp && (
                <Box
                  data-tut="tur-icon-menu-home"
                  component={RouterLink}
                  to={paths.dashboard.index}
                  sx={{
                    display: 'inline-flex',
                    height: 60,
                    p: '4px',
                    position: 'relative',
                  }}
                >
                  <Box
                    sx={{
                      backgroundImage: 'url(/img/logo-bg.webp)',
                      width: '156px',
                      height: '170px',
                      position: 'absolute',
                      display: 'flex',
                      transform: 'scale(0.6) translateY(-86px) translateX(-104px)',
                      justifyContent: 'center',
                      backgroundSize: 'cover',
                    }}
                  >
                    <Logo/>
                  </Box>
                  <Typography
                    color="white"
                    sx={{
                      fontWeight: 'bold',
                      pl: '84px',
                      mt: 'auto',
                      mb: 'auto',
                    }}
                  >
                    Portal da Família
                  </Typography>
                </Box>
              )}
            </Stack>
            <Stack
              alignItems="center"
              direction="row"
              spacing={2}
            >
              {(mdUp && !onlyExit) && (
                <Box>
                  <Stack
                    alignItems="flex-start"
                    component="nav"
                    direction="row"
                    spacing={1}
                    sx={{
                      px: 2,
                      py: 1.5
                    }}
                    data-tut="tur-icon-menu"
                  >
                    {sections.map((section, index) => (
                      <TopNavSection
                        items={section.items}
                        key={index}
                        pathname={pathname}
                        subheader={section.subheader}
                      />
                    ))}
                  </Stack>
                </Box>
              )}
              <AccountButton
                onlyExit={onlyExit}
              />
            </Stack>
          </Stack>
        </Box>
      </Box>
    </Box>
  );
};

TopNav.propTypes = {
  color: PropTypes.oneOf<NavColor>(['blend-in', 'discrete', 'evident']),
  onMobileNav: PropTypes.func,
  sections: PropTypes.array
};
