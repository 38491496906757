import {selector} from "recoil";
import {parcelasSelecionadasState} from "../../../atoms/parcelasSelecionadasState";
import parcelaRegularSelecionadaSelector from "./parcelaRegularSelecionadaSelector";
import {ParcelaEntrada} from "../../../../@types/IServicoDto";
import selectedCalcListServiceSelector from "../../selectedCalcListServiceSelector";

const parcelaEntradaRegularSelecionadaSelector = selector<ParcelaEntrada | null>({
  key: 'parcelaEntradaRegularSelecionadaSelector', // unique ID (with respect to other atoms/selectors)
  get: ({get}) => {
    const servico = get(selectedCalcListServiceSelector);
    const parcelasSelecionadas = get(parcelasSelecionadasState);
    const parcelaRegularSelecionada = get(parcelaRegularSelecionadaSelector);

    if (servico?.servicoPrincipal)
      return parcelasSelecionadas?.servicoPrincipal[servico?.servicoPrincipal?.id+parcelaRegularSelecionada?.id] as ParcelaEntrada
    else
      return null
  },
});

export default parcelaEntradaRegularSelecionadaSelector

