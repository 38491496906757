//@ts-nocheck
import React from 'react';
import {
  Stack,
  Avatar,
  Typography,
  Checkbox,
  Skeleton,
  Tooltip,
  SvgIcon
} from "@mui/material";
import { Person } from "@mui/icons-material";
import useStyles from '../../style'
import Button from "@mui/material/Button";
import {useTheme} from "@mui/material/styles";

interface Data {
  estudante_Name: string;
  unidade_Name: string;
  segmento_Name: string;
}

interface Service {
  condicaopagamento: string;
  configuracaoid: string;
  dataareaid: string;
  descontoprogressivo: number;
  descriptionitemid: string;
  itemid: string;
  linenum: number;
  matriculaid: string;
  obrigatorio: string;
  porcentagemdesconto: number;
  precounitario: number;
  quantidade: number;
  selecionado: string;
  servicosecundario: string;
  turma: string;
  turmaid: string;
  unidadeid: string;
}

interface StudentCardProps {
  data: Data;
  checked: boolean;
  avatar: string;
  service: Service;
  handleCheckboxChange: () => void;
}

const StudentCard = ({data, checked, handleCheckboxChange, avatar, service} : StudentCardProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const { estudante_Name, unidade_Name } = data;
  if (!service) return
  const { descriptionitemid } = service;
  return (
    <Stack
      component={Button}
      alignItems="center"
      onClick={handleCheckboxChange}
      direction="row"
      flexWrap="nowrap"
      justifyContent="space-evenly"
      spacing={1}
      classes={classes.studentCard}
      sx={{
        maxWidth: 420,
        width: '100%',
        marginLeft: '8px !important',
        mt: '16px !important',
        backgroundColor: checked ? theme.palette.action.hover : '',
        borderRadius: 2,
        color: checked ? theme.palette.primary.main : theme.palette.text.secondary,
        [theme.breakpoints.down('sm')]: {
          padding: '0px !important'
        },
      }}
      startIcon={(
        <Checkbox
          checked={checked}
          sx={{
            pointerEvents: 'none'
          }}
        />
      )}
    >
      <Avatar
        src={avatar}
        variant="circular"
        sx={{
          width: 'auto',
          height: 64,
          aspectRatio: '1/1',
          border: '2px solid white',
          [theme.breakpoints.down('sm')]: {
            height: 48,
            aspectRatio: '1/1',
          }
        }}
      >
        <SvgIcon
          component={Person}
          sx={{
            fontSize: 44,
            [theme.breakpoints.down('sm')]: {
              fontSize: 32,
            }
          }}
        />
      </Avatar>
      <Stack
        sx={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
        }}
      >
        <Typography
          variant="body2"
          sx={{
            fontWeight: 700,
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          }}
        >
          {estudante_Name}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          }}
        >
          {unidade_Name}
        </Typography>
        {descriptionitemid ? (
        <Tooltip title={descriptionitemid}>
          <Typography
            variant="body2"
            sx={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            }}
          >
            {descriptionitemid}
          </Typography>
        </Tooltip>
        ) : (
          <Skeleton variant='text' width='70%' height={40} />
        )}
      </Stack>
    </Stack>
  );
}

export default StudentCard
