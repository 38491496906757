import React from 'react'
import {makeStyles} from "@mui/styles";
import {Grid} from "@mui/material";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";

const useStyles = makeStyles(() => ({
  loading: {
    minHeight: 200
  },
}))

export default ({ message }) => {
  const classes = useStyles()
  return (
    <React.Fragment>
      <Grid container className={classes.loading}>
        <Grid item xs={12} align='center'>
          <Typography variant='h5' gutterBottom>
            {message}
          </Typography>
        </Grid>
        <Grid item xs={12} align='center'>
          <CircularProgress />
        </Grid>
      </Grid>
    </React.Fragment>
  )
}
