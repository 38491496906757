import React, {useEffect, useState} from 'react';
import {Link, useHistory, useLocation} from "react-router-dom";
import Version from "../../components/Version/Version";
import Layout from "../../layouts/auth/layout";
import {LoadingButton} from "@mui/lab";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import {Checkbox, FormControlLabel} from "@mui/material";
import * as Yup from 'yup';
import {useFormik} from "formik";
import Typography from "@mui/material/Typography";
import useCpfCnpjMask from "../../hooks/use-cpf-cnpj-mask";
import useCpfCnpjErroMsg from "../../hooks/use-cpf-cnpj-erro-msg";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import {useRecoilState, useSetRecoilState} from "recoil";
import {homeState} from "../../recoil/atoms/homeState";
import {toast} from "react-toastify";
import useAuth from "../../hooks/use-auth";
import {allowRedirectServicesState} from "../../recoil/atoms/allowRedirectServicesState";
import {PATH_ROUTE} from "../../routes";

const validationSchema = Yup.object().shape({
  username: Yup.string().required("Por favor digite seu CPF."),
  password: Yup.string().required("Por favor digite sua senha."),
});

const Home: React.FC = () => {
  const {login, logout} = useAuth();
  const { search } = useLocation();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [initialValues, setState] = useRecoilState(homeState);
  const setFromLogin = useSetRecoilState(allowRedirectServicesState);
  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: false,
    onSubmit: async (values): Promise<void> => {
      if (loading) {
        return;
      }
      setLoading(true);
      setFromLogin(true);

      login(values, initialValues.manterDados)
        .then(() => {
          history.push(`${PATH_ROUTE.CADASTRO_CONCLUIDO}${search}`)
        })
        .catch(() => {
        toast.error('Login ou senha inválidos.', {autoClose: 5000});
        formik.setFieldValue('password', '');
      }).finally(() => setLoading(false));
    }
  });
  const maskedUsername = useCpfCnpjMask(formik.values.username);
  const errorUsername = useCpfCnpjErroMsg(formik.values.username);
  const [showPassword, setShowPassword] = React.useState(false);

  useEffect(() => {
    const params = new URLSearchParams(search);
    const mensagem = params.get('mensagem');
    const mensagemCriada = params.get('mensagemCriada');
    const mensagemError = params.get('mensagemError');

    if (mensagem) {
      toast.success("Acabamos de te enviar um link para redefinição de senha, verifique seu e - mail.");
    } else if (mensagemCriada) {
      toast.success("Agora é só fazer seu login e começar a matricula!");
    } else if (mensagemError) {
      toast.error("Sua senha já foi definida. Caso tenha esquecido, clique abaixo em Esqueci minha senha para redefinir.");
    }
  }, [search]);

  useEffect(() => {
    logout();
  }, []);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleManterDados = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setState({
        ...formik.values,
        manterDados: true
      })
    } else {
      setState({
        username: '',
        password: '',
        manterDados: false
      })
    }
  }

  return (
    <Box
      sx={{
        height: '100%',
        backgroundImage: 'url(/img/login-bg.webp)',
        backgroundSize: 'cover',
      }}
    >
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={2}
        sx={{
          height: '100%',
        }}
      >
        <Layout>
          <form
            onSubmit={formik.handleSubmit}
          >
            <Typography
              variant="h5"
              component="h1">
              Faça seu login
            </Typography>

            <Typography
              variant="subtitle1"
              component="p"
              mt={2}
            >
              Preencha os campos abaixo para entrar no sistema
            </Typography>

            <Stack
              spacing={2}
              sx={{
                mt: 2,
                mb: 2
              }}
            >
              <TextField
                id="cpf"
                error={!!(formik.touched.username && formik.errors.username || errorUsername)}
                fullWidth
                helperText={formik.touched.username && formik.errors.username || errorUsername}
                label="CPF"
                name="username"
                autoComplete={'username'}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={maskedUsername}
                placeholder="000.000.000-00"
              />

              <TextField
                type={showPassword ? 'text' : 'password'}
                error={!!(formik.touched.password && formik.errors.password)}
                helperText={formik.touched.password && formik.errors.password}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.password}
                fullWidth
                name="password"
                autoComplete={'current-password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? (
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={24} height={24}>
                            <path
                              d="M12 17.5C8.2 17.5 4.8 15.4 3.2 12H1C2.7 16.4 7 19.5 12 19.5S21.3 16.4 23 12H20.8C19.2 15.4 15.8 17.5 12 17.5Z"/>
                          </svg>
                          ) : (
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={24} height={24}>
                            <path
                              d="M12,9A3,3 0 0,1 15,12A3,3 0 0,1 12,15A3,3 0 0,1 9,12A3,3 0 0,1 12,9M12,4.5C17,4.5 21.27,7.61 23,12C21.27,16.39 17,19.5 12,19.5C7,19.5 2.73,16.39 1,12C2.73,7.61 7,4.5 12,4.5M3.18,12C4.83,15.36 8.24,17.5 12,17.5C15.76,17.5 19.17,15.36 20.82,12C19.17,8.64 15.76,6.5 12,6.5C8.24,6.5 4.83,8.64 3.18,12Z"/>
                          </svg>
                        )}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                label="Senha"
              />

              <FormControlLabel
                control={(
                  <Checkbox
                    checked={initialValues.manterDados}
                    onChange={handleManterDados}
                  />
                )}
                label="Lembrar meus dados"
              />
            </Stack>

            <LoadingButton
              fullWidth
              variant="contained"
              color="primary"
              id="btnLogin"
              type="submit"
              loading={loading}
              disabled={!formik.isValid}
            >
              Entrar
            </LoadingButton>

            <Box
              sx={{
                mt: 2,
                textAlign: 'center'
              }}
            >
              <Link to="/RedefinirSenha">
                Esqueci minha senha
              </Link>
            </Box>
          </form>
        </Layout>

        <Stack
          mt={4}
        >
          <Version
            color="white"
          />
        </Stack>
      </Stack>
    </Box>
  )
}


export default Home;
