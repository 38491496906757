import {atom} from "recoil";
import {persistAtomEffect} from "../effects/persistAtom";

export const avatarsState = atom({
  key: 'avatarsState',
  default: {},
  effects: [
    persistAtomEffect
  ]
});
