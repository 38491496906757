import React from "react";
import Close from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import {Grid, Theme} from "@mui/material";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import useMediaQuery from "@mui/material/useMediaQuery";
import {useTheme} from "@mui/material/styles";

export const KnowMoreRecurrencePaymentDialog = ({ open, onClose, selectedUnidade, discount }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  function handleClose() {
    onClose();
  }

  return (
    <Dialog
      maxWidth={"sm"}
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      aria-labelledby="automatic-debit-dialog-title"
      sx={{ maxHeight: !fullScreen ? '98%' : undefined }}
    >
      <Box display="flex" justifyContent="flex-end">
        <IconButton
          sx={{position: !fullScreen ? 'absolute' : undefined, right: !fullScreen ? 0 : undefined}}
          aria-label="close-payment"
          id="ico-mdl-pag-fch"
          onClick={handleClose}
        >
          <Close />
        </IconButton>
      </Box>
      <Grid
        container
        justifyContent="space-between"
        direction="row"
        alignItems="center"
        sx={{
          padding: theme.spacing(2),
          paddingBottom: theme.spacing(1),
          boxShadow: "none",
          display: "inline",
        }}
      >
        <Grid item xs={12} marginBottom={2}>
          <Typography component="h1" variant="h4" align="center">
            Cartão de Crédito Recorrente
          </Typography>
        </Grid>

        <Grid item xs={12} >
          <Typography sx={{ fontWeight: 'bold' }}>
            Crédito recorrente
          </Typography>

          <br />

          <Typography>
            Funciona como uma assinatura recorrente. O pagamento é inserido mês
            a mês na data de vencimento da fatura do seu cartão,{" "}
            <b>sem comprometer o limite total de crédito.</b>
          </Typography>

          <br />

          {(selectedUnidade !== 1304) && discount > 0 && (
            <Typography>
              Com esta opção, você ganha <b>{discount}% de desconto</b> na parcela do serviço
              regular e não precisa se preocupar com datas de vencimento, afinal o
              pagamento é <b>automático</b>!
            </Typography>
          )}
          <br />

          <Typography>
          <b>✈</b> E mais: você ainda <b>acumula pontos</b> em programas de milhagens que participar.
          </Typography>

          <br />

          <Typography>
          Após a conclusão da operação haverá período de carência para nova alteração.
          </Typography>
        </Grid>

        <Grid item xs={12} sx={{display: 'flex', justifyContent: 'flex-end'}}>
          <Button
            onClick={handleClose}
            sx={{ marginTop: theme.spacing(3), marginLeft: theme.spacing(1)}}
            variant="outlined"
            color="secondary"
          >
            Fechar
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default KnowMoreRecurrencePaymentDialog;