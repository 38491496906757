import {fetchLoginRedirectSMO} from "../modules/Login/actions";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";
import {useRecoilValue, useSetRecoilState} from "recoil";
import {sidebarHiddenMenusState} from "../recoil/atoms/sidebarHiddenMenusState";
import {errorLoginPortalState} from "../recoil/atoms/errorLoginPortalState";
import * as menssagem from '../components/shared/Message.js';
import {setRecoil} from "recoil-nexus";
import {portalAuthState} from "../recoil/atoms/portalAuthState";
import unidadeGetResponseState from "../recoil/atoms/unidadeGetResponseState";

export default function useLoginPortal() {
  const dispatch = useDispatch();
  const history = useHistory();
  const setSidebarHiddenMenus = useSetRecoilState(sidebarHiddenMenusState);
  const setErrorLoginPortal = useSetRecoilState(errorLoginPortalState);
  const unidade = useRecoilValue(unidadeGetResponseState);
  const doLogin = async () => {
    let cpf = localStorage.getItem('@SMO20/cpf');
    let dataareaid = unidade?.dataAreaId;

    localStorage.setItem('@SMO20/acessouPortal', "acessouPortal");
    localStorage.setItem('@SMO20/homeHidden', true);
    localStorage.setItem('@SMO20/portalHidden', false);

    try {
      dispatch(fetchLoginRedirectSMO(`cpf=${cpf};dataareaid=${dataareaid}`, history, false, true))
      setErrorLoginPortal(false);
      setSidebarHiddenMenus([]);
    } catch (e) {
      setErrorLoginPortal(true);
      setRecoil(portalAuthState, null);
      menssagem.ExibeMensagem("Portal financeiro não disponível", "3", true);
    }
  }

  return {
    doLogin
  }
}
