import React from 'react'
import {formatOwnName} from '../../../../../utils/formatString.js'
import {FormattedMessage} from 'react-intl'
import {Avatar, Card, Divider, Skeleton, Stack, Typography, useMediaQuery} from '@mui/material'
import {tokens} from '../../../../../locales/tokens'
import {useTranslation} from '../../../../../hooks/use-translation'

export const StudentList = ({
  isLoadingData = false,
  matricula,
  avatar,
  children
}) => {
  const isLarge = useMediaQuery(theme => theme.breakpoints.down('lg'))
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'))
  const { t } = useTranslation()

  if (!matricula) {
    return (
      <Typography gutterBottom component='p' color={'textPrimary'}>
        <FormattedMessage id='finance-negotiation-comp-student-not-found' />
      </Typography>
    )
  } else {
    if (isSmall) {
      return (
        <Card
          elevation={0}
          sx={{
            mb: 8,
            mt: 2,
            backgroundColor: '#f6f6f6',
            width: '100%'
          }}
        >
          <Stack
            justifyContent='center'
            sx={{
              padding: 2,
              textAlign: 'center'
            }}
          >
            <Stack
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'center',
                padding: 2,
                minWidth: 80
              }}
            >
              {isLoadingData ? (
                <Skeleton
                  animation='wave'
                  variant='circle'
                  width={'80%'}
                  height={75}
                />
              ) : (
                <Avatar
                  src={avatar}
                  variant='circular'
                  sx={{
                    width: 'auto',
                    height: 75,
                    aspectRatio: '1/1',
                    border: '2px solid white'
                  }}
                />
              )}
            </Stack>
            {isLoadingData ? (
              <Stack
                spacing={2}
                direction='row'
                sx={{
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}
              >
                <Skeleton animation='wave' width='90%' height={20} />
              </Stack>
            ) : (
              <Stack
                spacing={2}
                direction='row'
                sx={{
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}
              >
                <Stack
                  sx={{
                    flexGrow: 1
                  }}
                >
                  <Typography variant='body2' color={'textPrimary'}>
                    <strong>{formatOwnName(matricula.studentname)}</strong>
                  </Typography>
                </Stack>
              </Stack>
            )}
            {isLoadingData ? (
              <Stack
                spacing={2}
                direction='row'
                sx={{
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}
              >
                <Skeleton animation='wave' width='90%' height={20} />
              </Stack>
            ) : (
              <Stack
                sx={{
                  flexGrow: 1
                }}
              >
                <Typography variant='body2' color={'textPrimary'}>
                  {matricula.unity.name}
                </Typography>
              </Stack>
            )}
            {isLoadingData ? (
              <Stack>
                <Skeleton animation='wave' width='90%' height={20} />
              </Stack>
            ) : (
              <Stack
                sx={{
                  paddingBottom: 2
                }}
              >
                <Typography variant='body2' gutterBottom color={'textPrimary'}>
                  {matricula.services.map((service, i) => service.productname).join('   ')}
                </Typography>
              </Stack>
            )}
            {isLoadingData ? (
              <Stack>
                <Skeleton animation='wave' width='90%' height={30} />
              </Stack>
            ) : (
              <Stack
                sx={{
                  padding: 0
                }}
              >
                <Divider
                  sx={{
                    borderBottomWidth: 1,
                    borderColor: '#c6bbbb !important',
                    mb: 2
                  }}
                />
                <Stack>
                  <Typography variant='h6'>
                    {t(tokens.common.availablePaymentMethods)}
                  </Typography>
                </Stack>
              </Stack>
            )}
          </Stack>
          <Stack
            direction='row'
            flexWrap='wrap'
            justifyContent='center'
          >
            {children}
          </Stack>
        </Card>
      )
    } else
      return (
        <Card
          elevation={0}
          sx={{
            mb: 2,
            mt: 8,
            backgroundColor: '#f6f6f6',
            width: 'fit-content'
          }}
        >
          <Stack>
            <Stack
              direction='row'
              sx={{
                padding: 2
              }}
            >
              <Stack
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  padding: 2,
                  minWidth: 100
                }}
              >
                {isLoadingData ? (
                  <Skeleton
                    animation='wave'
                    variant='circle'
                    width={120}
                    height={120}
                  />
                ) : (
                  <Avatar
                    src={avatar}
                    variant='circular'
                    sx={{
                      width: 'auto',
                      height: 90,
                      aspectRatio: '1/1',
                      border: '2px solid white'
                    }}
                  />
                )}
              </Stack>
              <Stack>
                {isLoadingData ? (
                  <Stack
                    spacing={2}
                    direction='row'
                    sx={{
                      justifyContent: 'space-between',
                      alignItems: 'center'
                    }}
                  >
                    <Stack>
                      <Skeleton animation='wave' width={40} height={30} />
                      <Skeleton animation='wave' width={100} height={20} />
                    </Stack>
                    <Stack>
                      <Skeleton animation='wave' width={40} height={30} />
                      <Skeleton animation='wave' width={100} height={20} />
                    </Stack>
                  </Stack>
                ) : (
                  <Stack
                    spacing={2}
                    direction='row'
                    sx={{
                      justifyContent: 'space-between',
                      alignItems: 'center'
                    }}
                  >
                    <Stack
                      sx={{
                        flexGrow: 1
                      }}
                    >
                      <Typography
                        variant='h6'
                        gutterBottom
                        color={'textPrimary'}
                      >
                        {t(tokens.common.student)}
                      </Typography>
                      <Typography variant='body2' color={'textPrimary'}>
                        {formatOwnName(matricula.studentname)}
                      </Typography>
                    </Stack>
                    <Stack
                      sx={{
                        flexGrow: 1
                      }}
                    >
                      <Typography
                        variant='h6'
                        gutterBottom
                        color={'textPrimary'}
                      >
                        {t(tokens.common.school)}
                      </Typography>
                      <Typography variant='body2' color={'textPrimary'}>
                        {matricula.unity.name}
                      </Typography>
                    </Stack>
                  </Stack>
                )}
                {isLoadingData ? (
                  <Stack>
                    <Skeleton animation='wave' width={40} height={30} />
                    <Skeleton animation='wave' width={100} height={20} />
                  </Stack>
                ) : (
                  <Stack
                    sx={{
                      paddingTop: 2,
                      paddingBottom: 2
                    }}
                  >
                    <Typography variant='h6' gutterBottom color={'textPrimary'}>
                      {t(tokens.common.enrollment)}
                    </Typography>
                    <Typography
                      variant='body2'
                      gutterBottom
                      color={'textPrimary'}
                    >
                      {matricula.services.map((service, i) => service.productname).join('   ')}
                    </Typography>
                  </Stack>
                )}
                {isLoadingData ? (
                  <Stack>
                    <Skeleton animation='wave' width={60} height={30} />
                  </Stack>
                ) : (
                  !isLarge && (
                    <Stack>
                      <Divider
                        sx={{
                          borderBottomWidth: 1,
                          borderColor: '#c6bbbb !important',
                          mb: 2
                        }}
                      />
                      <Stack
                        sx={{
                          mb: 2
                        }}
                      >
                        <Typography variant='h6'>
                          {t(tokens.common.availablePaymentMethods)}
                        </Typography>
                      </Stack>
                    </Stack>
                  )
                )}
                {!isLarge && (
                  <Stack direction='row' spacing={3}>
                    {children}
                  </Stack>
                )}
              </Stack>
            </Stack>
            {isLarge && (
              <Stack
                sx={{
                  padding: 2
                }}
              >
                <Divider
                  sx={{
                    borderBottomWidth: 1,
                    borderColor: '#c6bbbb !important',
                    mb: 2
                  }}
                />
                {isLoadingData ? (
                  <Stack>
                    <Skeleton animation='wave' width={60} height={30} />
                  </Stack>
                ) : (
                <Stack
                  sx={{
                    mb: 2
                  }}
                >
                  <Typography variant='h6'>
                    {t(tokens.common.availablePaymentMethods)}
                  </Typography>
                </Stack>
                )}
                <Stack
                  direction='row'
                  flexWrap='wrap'
                  justifyContent='center'
                  sx={{
                    padding: 2
                  }}
                >
                  {children}
                </Stack>
              </Stack>
            )}
          </Stack>
        </Card>
      )
  }
}

export default StudentList
