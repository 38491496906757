import { apiRequestActionType, apiSuccessActionType, apiErrorActionType, apiResetActionType } from '../../../../utils/API/action-types'

export const OPENED = 'OPENED'
export const REQUEST_OPENED = apiRequestActionType(OPENED)
export const SUCCESS_OPENED = apiSuccessActionType(OPENED)
export const ERROR_OPENED = apiErrorActionType(OPENED)
export const RESET_OPENED = apiResetActionType(OPENED)
export const REFRESH_OPENED = 'REFRESH_OPENED'

export const INFO = 'INFO'
export const REQUEST_INFO = apiRequestActionType(INFO)
export const SUCCESS_INFO = apiSuccessActionType(INFO)
export const ERROR_INFO = apiErrorActionType(INFO)
export const RESET_INFO = apiResetActionType(INFO)
export const REFRESH_INFO = 'REFRESH_INFO'

export const BILLET = 'BILLET'
export const REQUEST_BILLET = apiRequestActionType(BILLET)
export const SUCCESS_BILLET = apiSuccessActionType(BILLET)
export const RESET_BILLET = apiResetActionType(BILLET)

export const SEND_PAYMENT = 'SEND_PAYMENT'
export const REQUEST_SEND_PAYMENT = apiRequestActionType(SEND_PAYMENT)
export const ERROR_SEND_PAYMENT = apiErrorActionType(SEND_PAYMENT)

export const PAYMENT = 'PAYMENT'
export const RESET_PAYMENT = apiResetActionType(PAYMENT)

export const PAYMENT_DEBIT = 'PAYMENT_DEBIT'
export const REQUEST_PAYMENT_DEBIT = apiRequestActionType(PAYMENT_DEBIT)
export const SUCCESS_PAYMENT_DEBIT = apiSuccessActionType(PAYMENT_DEBIT)
export const ERROR_PAYMENT_DEBIT = apiSuccessActionType(PAYMENT_DEBIT)

export const PAYMENT_CREDIT = 'PAYMENT_CREDIT'
export const REQUEST_PAYMENT_CREDIT = apiRequestActionType(PAYMENT_CREDIT)
export const SUCCESS_PAYMENT_CREDIT = apiSuccessActionType(PAYMENT_CREDIT)
export const ERROR_PAYMENT_CREDIT = apiSuccessActionType(PAYMENT_CREDIT)

export const WAITING_REDE_PAYMENT = 'WAITING_REDE_PAYMENT'

export const CONFIRM_PAYMENT = 'CONFIRM_PAYMENT'
export const REQUEST_CONFIRM_PAYMENT = apiRequestActionType(CONFIRM_PAYMENT)
export const SUCCESS_CONFIRM_PAYMENT = apiSuccessActionType(CONFIRM_PAYMENT)

export const MESSAGE_ERROR_PAYMENT_CREDIT = 'Erro PTC-5 - Não foi  possível realizar o pagamento por cartão de crédito.'
export const MESSAGE_ERROR_PAYMENT_DEBIT = 'Erro PTC-5 - Não foi  possível realizar o pagamento por cartão de débito.'

export const IS_SUCCESS_REDE = 'isSuccessRede'

export const PROCESSING_DEBIT = '220'
