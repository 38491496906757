import React from "react";
import Box from "@mui/material/Box";
import {Fade, Grid, Stack, Zoom} from "@mui/material";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {PATH_ROUTE} from "../../routes";
import {useHistory} from "react-router-dom";
//@ts-ignore
import background from "../../assets/img/checkout.png";
import {useTheme} from "@mui/material/styles";
import ConfettiExplosion, {ConfettiProps} from "react-confetti-explosion";
import SvgIcon from "@mui/material/SvgIcon";
import {useRecoilValue, useSetRecoilState} from "recoil";
import servicoRegularSelector from "../../recoil/selectors/carrinho/servicoRegular/servicoRegularSelector";
import Slide from "@mui/material/Slide";
import {selectedMatriculaState} from "../../recoil/atoms/selectedMatriculaState";
import {Layout as MomentoEscolarLayout} from "../../layouts/momento-escolar";
import {razaoStatus} from "../../utils/razaoStatus";
import servicosUpSellingSelecionadosSelector
  from "../../recoil/selectors/carrinho/servicosUpSelling/servicosUpSellingSelecionadosSelector";
import {showCsatMomentoEscolarMatriculadoState} from "../../recoil/atoms/showCsatMomentoEscolarMatriculadoState";
import isPreReservaSemValorSelector from "../../recoil/selectors/isPreReservaSemValorSelector";
import isPreReservaSelector from "../../recoil/selectors/isPreReservaSelector";
import {selectedUnidadeState} from "../../recoil/atoms/selectedUnidadeState";
import useAuth from "../../hooks/use-auth";

const CheckoutConcluido: React.FC = () => {
  const history = useHistory();
  const theme = useTheme();
  const servicoRegular = useRecoilValue(servicoRegularSelector);
  const selectedMatricula = useRecoilValue(selectedMatriculaState);
  const servicosUpsellingSelecionados = useRecoilValue(servicosUpSellingSelecionadosSelector);
  const seletedUnidade = useRecoilValue(selectedUnidadeState);
  const isPreReserva = useRecoilValue(isPreReservaSelector);
  const isPreReservaSemValor = useRecoilValue(isPreReservaSemValorSelector);
  const smallProps: ConfettiProps = {
    force: 0.8,
    duration: 3000,
    particleCount: 250,
    width: 1600,
    zIndex: theme.zIndex.modal,
    colors: [theme.palette.primary.main, theme.palette.secondary.main]
  };
  const setShowCsatMomentoEscolarMatriculado = useSetRecoilState(showCsatMomentoEscolarMatriculadoState);
  const {loadUserData} = useAuth();

  const handleClickHome = () => {
    setShowCsatMomentoEscolarMatriculado(true);
    loadUserData();
    history.push(PATH_ROUTE.CADASTRO_CONCLUIDO);
  }

  return (
    <MomentoEscolarLayout
      breads={[
        {to: PATH_ROUTE.CADASTRO_CONCLUIDO, text: "Home"},
        {to: `${PATH_ROUTE.MOMENTO_ESCOLAR}?idmatricula=${selectedMatricula?.id}`, text: "Momento Escolar"},
        {to: PATH_ROUTE.MOMENTO_ESCOLAR_SERVICOS, text: "Momento Escolar Serviços"},
        {to: PATH_ROUTE.MOMENTO_ESCOLAR_PAGAMENTO, text: "Momento Escolar Pagamento"},
        {text: "Concluído"}
      ]}
      disableCart
      fullScreenPaper
    >
      <Fade in appear={false} timeout={{
        enter: 1000,
        exit: 800,
        appear: 800
      }}>
        <Box
          component="img"
          src={background}
          alt="concluído"
          sx={{
            position: 'fixed',
            width: '100%',
            height: 'auto',
            objectFit: 'cover',
            right: 0,
            bottom: 0,
            maxWidth: "1000px",
            zIndex: -1,
            [theme.breakpoints.down("sm")]: {
              maxWidth: "80%",
            },
          }}
        />
      </Fade>

      <Grid
        container
        spacing={2}
        direction={{sm: 'row'}}
        sx={{
          [theme.breakpoints.down("sm")]: {
            flexWrap: 'wrap-reverse',
          }
        }}
      >
        <Grid
          item
          md={6}
          sm={10}
        >
          <Stack
            direction="column"
            spacing={4}
          >
            <Zoom in timeout={800}>
              <Typography
                variant="h5"
                fontWeight="normal"
              >
                Parabéns, {isPreReservaSemValor || isPreReserva ? "reserva" : "matrícula"} <br/>
                realizada com sucesso!
              </Typography>
            </Zoom>

            {(servicoRegular && selectedMatricula.razaoStatus_Value !== razaoStatus.zero_6_matriculado) && (
              <Slide
                in
                direction="left"
                timeout={{
                  enter: 800,
                }}
              >
                <Typography
                  variant="h4"
                >
                  {servicoRegular?.nome}
                </Typography>
              </Slide>
            )}

            {(servicosUpsellingSelecionados.length === 1 && selectedMatricula.razaoStatus_Value === razaoStatus.zero_6_matriculado) && (
              <Slide
                in
                direction="left"
                timeout={{
                  enter: 800,
                }}
              >
                <Typography
                  variant="h4"
                >
                  {servicosUpsellingSelecionados[0].nome}
                </Typography>
              </Slide>
            )}

            <Zoom in timeout={500}>
              <Stack
                direction="column"
                spacing={8}
                sx={{
                  pt: 3
                }}
              >
                {isPreReservaSemValor ? (
                  <span>
                    <p><strong>Ficamos felizes em saber que você está considerando ingressar em nossos serviços e fazer
                      parte
                      da nossa escola.</strong></p>

                    <div className="checkout-reserva-texts">
                      <p>Confirmamos que sua reserva foi concluída com sucesso e, em breve, você receberá informações
                        detalhadas sobre como efetivar a matrícula e escolher o plano de pagamento das mensalidades e
                        materiais. Fique de olho na sua caixa de entrada, pois as informações serão enviadas para o
                        endereço
                        de e-mail cadastrado em nosso sistema. </p>
                      {seletedUnidade?.telefone &&
                        <p>Caso tenha alguma dúvida ou precise de mais informações, não hesite em entrar em contato
                          conosco
                          através telefone <a href={`tel:${seletedUnidade?.telefone}`}>{seletedUnidade?.telefone}</a>.
                        </p>}

                      <p className="checkout-reserva-greetings">
                        <strong>Agradecemos pela sua confiança e estamos ansiosos para ajudá-lo(a) em sua jornada de
                          aprendizado!</strong>
                      </p>
                    </div>
                  </span>
                ) : isPreReserva ? (
                  <div>
                    <Typography
                      fontWeight="bold"
                      sx={{
                        mb: 1
                      }}
                    >
                      Fique atento!
                    </Typography>
                    <Typography
                      sx={{
                        mb: 1
                      }}
                    >
                      Assim que as novas contratações estiverem disponíveis, você receberá um e-mail de confirmação. Nossa
                      equipe pode também entrar em contato diretamente para informar sobre a liberação e próximos passos.
                    </Typography>
                    <Typography
                      fontWeight="bold"
                      sx={{
                        mb: 1
                      }}
                    >
                      Precisa de ajuda? estamos aqui para você!
                    </Typography>
                    <Typography>
                      Se surgir qualquer dúvida ou você precisar de mais informações, nossa equipe está à disposição para
                      ajudá-lo. Basta entrar em contato com a unidade escolar, que estará pronta para atender e esclarecer
                      todas as suas questões.
                    </Typography>
                  </div>
                ) : (
                  <Typography>
                    Você irá receber em seu <br/>e-mail cadastrado, o <br/>contrato e comprovante <br/>de
                    matrícula.
                  </Typography>
                )}

                <Box>
                  <Button
                    variant="contained"
                    onClick={handleClickHome}
                    endIcon={(
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={24} height={24}
                           fill="currentColor">
                        <path d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z"/>
                      </svg>
                    )}
                  >
                    Ir para a Home
                  </Button>
                </Box>
              </Stack>
            </Zoom>
          </Stack>
        </Grid>
        <Grid
          item
          md={6}
          sm={2}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            [theme.breakpoints.down("sm")]: {
              width: "100%"
            }
          }}
        >
          <ConfettiExplosion {...smallProps} />

          <Zoom
            in
            timeout={500}
          >
            <SvgIcon
              color="primary"
              sx={{
                width: 150,
                height: 150,
                backgroundColor: theme.palette.background.paper,
                borderRadius: '50%',
                [theme.breakpoints.down("md")]: {
                  display: 'none'
                },
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width={150}
                height={150}
                fill="currentColor"
              >
                <path
                  d="M12 2C6.5 2 2 6.5 2 12S6.5 22 12 22 22 17.5 22 12 17.5 2 12 2M12 20C7.59 20 4 16.41 4 12S7.59 4 12 4 20 7.59 20 12 16.41 20 12 20M16.59 7.58L10 14.17L7.41 11.59L6 13L10 17L18 9L16.59 7.58Z"/>
              </svg>
            </SvgIcon>
          </Zoom>
        </Grid>
      </Grid>
    </MomentoEscolarLayout>
  );
}


export default CheckoutConcluido;
