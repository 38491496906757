import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Zoom from "@mui/material/Zoom";
import React, {useEffect} from "react";
import {useRecoilValue} from "recoil";
import {IServico} from "../../@types/IServicoDto";
import {selectedMatriculaState} from "../../recoil/atoms/selectedMatriculaState";
import parcelaEntradaRegularSelecionadaSelector
  from "../../recoil/selectors/carrinho/servicoRegular/parcelaEntradaRegularSelecionadaSelector";
import parcelaRegularSelecionadaSelector
  from "../../recoil/selectors/carrinho/servicoRegular/parcelaRegularSelecionadaSelector";
import servicoRegularSelector from "../../recoil/selectors/carrinho/servicoRegular/servicoRegularSelector";
import carrinho from "../../utils/carrinho";
import {razaoStatus} from "../../utils/razaoStatus";
import {currency} from "../../utils/utils";
import {Select} from "@mui/material";

const ServicoRegular: React.FC = () => {
  const matricula = useRecoilValue(selectedMatriculaState);
  const servicoPrincipal = useRecoilValue(servicoRegularSelector);
  const parcelaRegularSelecionada = useRecoilValue(parcelaRegularSelecionadaSelector);
  const parcelaEntradaRegularSelecionada = useRecoilValue(parcelaEntradaRegularSelecionadaSelector);

  useEffect(() => {
    if (parcelaRegularSelecionada) {
      if (parcelaRegularSelecionada?.entradas?.length === 1) {
        carrinho.addParcelaEntrada(
          servicoPrincipal,
          parcelaRegularSelecionada,
          parcelaRegularSelecionada.entradas[0]
        )
      } else if (servicoPrincipal) {
        carrinho.removeParcelaEntrada(
          servicoPrincipal,
          parcelaRegularSelecionada
        )
      }
    }
  }, [parcelaRegularSelecionada]);

  const handleSelectParcela = (servico: IServico) => (e: any) => {
    const parcela = servico?.mensais?.find(p => p.id === e.target.value);
    carrinho.addParcela(
      servico,
      parcela
    )
  }

  const handleSelectParcelaEntrada = (e: any) => {
    if (parcelaRegularSelecionada !== null && servicoPrincipal) {
      const parcela = parcelaRegularSelecionada?.entradas?.find(p => p.id === e.target.value);
      carrinho.addParcelaEntrada(
        servicoPrincipal,
        parcelaRegularSelecionada,
        parcela
      )
    }
  }

  return (
    <>
      <Typography variant="h4" component="h3">
        {matricula.razaoStatus_Value == razaoStatus.ponto_1_preReserva
          ? "Serviço reserva de vaga"
          : "Serviço regular"
        }
      </Typography>
      <p>Escolha como deseja realizar o pagamento</p>
      <div className="row p-b-15">
        <div className="col-sm-12 pb-3">
          <TextField
            fullWidth
            size="small"
            label={matricula?.razaoStatus_Value == razaoStatus.ponto_1_preReserva
              ? "Serviço reserva de vaga"
              : "Serviço regular"
            }
            error={!parcelaRegularSelecionada}
            helperText={!parcelaRegularSelecionada && "Selecione uma opção de pagamento"}
            value={parcelaRegularSelecionada?.id ?? ""}
            onChange={handleSelectParcela(servicoPrincipal as IServico)}
            select
            required
            SelectProps={{
              native: true,
            }}
          >
            <option value="" disabled>Selecione</option>
            {servicoPrincipal
              ?.mensais?.map((opcao, i) => {
                const {
                  percentualDescontoComercial,
                  percentualDescontoAvista,
                  percentualDescontoBolsa,
                  percentualDescontoMetodo,
                  fullAvista
                } = opcao;
                const percentualDesconto = percentualDescontoComercial + percentualDescontoAvista + percentualDescontoBolsa + percentualDescontoMetodo;
                return (
                  <option key={opcao.id} value={opcao.id}>
                    {opcao.fullAvista && opcao.totalParcela === 1
                      ? percentualDesconto > 0 ?
                        <>Pagamento à vista {currency(opcao.valorTotal)} - {percentualDesconto}%
                          = {currency(opcao.valor)}</>
                        : <>Pagamento à vista {currency(opcao.valor)}</>
                      : <>{opcao?.valorEntrada > 0 ? <>Entrada
                        de {currency(opcao?.valorEntrada)} mais</> : ""} {opcao.totalParcela}x {currency(opcao.valor)}</>
                    }
                    {fullAvista && opcao.totalParcela === 1 && percentualDesconto > 0
                      ? <Chip size="small" label={` (${percentualDesconto}% Desconto)`} color="success"/>
                      : <Chip size="small" label=" (sem juros)" color="primary" variant="outlined"/>
                    }
                  </option>
                )
              })}
          </TextField>
        </div>
      </div>

      <Divider/>

      <Zoom
        in={!!parcelaRegularSelecionada?.entradas && parcelaRegularSelecionada?.entradas.length > 0}
        unmountOnExit
        mountOnEnter
      >
        <Box
          sx={{pt: '15px'}}
        >
          <Typography variant="h4" component="h3">
            Serviço regular{" "}<Chip
            label="entrada" color="primary"/>
          </Typography>
          <p>Escolha como deseja realizar o pagamento</p>

          <div className="row">
            <div className="col-sm-12 pb-3">
              <TextField
                fullWidth
                size="small"
                label="Entrada"
                error={!parcelaEntradaRegularSelecionada && !!parcelaRegularSelecionada?.entradas}
                helperText={!parcelaEntradaRegularSelecionada && !!parcelaRegularSelecionada?.entradas && "Selecione uma opção de entrada"}
                value={parcelaEntradaRegularSelecionada?.id ?? ""}
                onChange={handleSelectParcelaEntrada}
                select
                required
                SelectProps={{
                  native: true,
                }}
              >
                <option value="" disabled>Selecione</option>
                {parcelaRegularSelecionada
                  ?.entradas?.map((opcao) => {
                    return (
                      <option key={opcao.id} value={opcao.id}>
                        {opcao.totalParcela}x {currency(opcao.valor)}
                        {opcao.percentualDescontoAvista > 0 &&
                          <Chip size="small" label={` (${opcao.percentualDescontoAvista}% Desconto)`} color="success"/>}
                      </option>
                    )
                  })}
              </TextField>
            </div>
          </div>
          <Divider/>
        </Box>
      </Zoom>
    </>
  )
}

export default ServicoRegular;
