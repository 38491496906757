import React from 'react'
import {
  Stack,
  Typography,
  Card,
  CardContent,
  CardActions,
  Button,
  SvgIcon,
  Tooltip,
  Skeleton,
  useMediaQuery,
  Chip
} from '@mui/material'
import { PAYMENT_METHODS } from './data/paymentTypes'
import { CreditCard, Description, CalendarMonth } from '@mui/icons-material'
import { tokens } from '../../../../../locales/tokens'
import { useTranslation } from '../../../../../hooks/use-translation'
import useStyles from './style'
import {Theme} from "@mui/material/styles/createTheme";

interface TooltipData {
  title: string;
  displayText: string;
}
interface SelectPaymentProps {
  buttonText: string;
  paymentType: 'creditCard' | 'boleto' | 'boletoRecorrente';
  tooltipOnCLick: () => void;
  onClick: () => void;
  tooltipData: TooltipData;
  matricula: any;
  disabled: boolean;
  redundantButtonText: any;
  redudantButtonClick: any;
  isLoading: boolean;
  tooltipOnClick: any;
  selected: boolean;
  showRedudantButton: boolean;
}

const renderIcon = (paymentType: string, selected?: boolean) => {
  switch (paymentType) {
    case 'creditCard':
      return <CreditCard color={selected ? 'disabled' : 'primary'} />
    case 'bill':
      return <Description color={selected ? 'disabled' : 'primary'} />
    case 'recurringBill':
      return <CalendarMonth color={selected ? 'disabled' : 'primary'} />
    default:
      return <CreditCard color={selected ? 'disabled' : 'primary'} />
  }
}

const SelectPayment = (props: SelectPaymentProps)  => {
  const classes = useStyles()
  const { t } = useTranslation()
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
  const {
    disabled,
    paymentType,
    tooltipData,
    redundantButtonText,
    redudantButtonClick,
    isLoading,
    tooltipOnClick,
    matricula,
    onClick,
    buttonText,
    selected,
    showRedudantButton = false
  } = props
  if (isSmall) {
    return (
      <Card
        sx={{
          textAlign: 'center',
          overflowX: 'auto',
          overflow: 'visible',
          margin: '8px !important',
          backgroundColor: '#f9f9f9',
          width: '100%',
          height: 'fit-content',
          border: !isLoading && selected ? 'solid 2px #3f51b5' : 'solid 1px #e0e0e0',
          boxShadow: !isLoading && selected ? 'box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px' : 'box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08)',
          position: 'relative'
        }}
      >
        {!isLoading && selected && (
            <Chip
                label={t(tokens.common.currentMethod)}
                color='success'
                sx={{
                    position: 'absolute',
                    top: '-13%',
                    left: 0,
                    zIndex: 1
                }}
            />
        )}
        <CardContent>
          <Stack spacing={3} sx={{padding: 1}} justifyContent='center' direction='row'>
            {isLoading ? (
              <Skeleton variant='circular' width={'20%'} height={60} />
            ) : (
              <SvgIcon>{renderIcon(paymentType, selected)}</SvgIcon>
            )}
            {isLoading ? (
              <Skeleton variant='text' width={'65%'} />
            ) : (
              <Typography
                variant='h6'
                fontWeight='bold'
                color={!isLoading && selected ? 'text.disabled' : 'textSecondary'}
              >
                {t(tokens.common[PAYMENT_METHODS[paymentType]?.title ?? ''])}
              </Typography>
            )}
          </Stack>
          <Stack spacing={2} justifyContent='center' direction='row'>
            {isLoading ? (
                <Skeleton variant='text' width={120} />
            ) : (
                matricula.unitycode !== 1304 && (
                <Typography
                    variant='h6'
                    fontWeight='unset'
                    color={!isLoading && selected ? 'text.disabled' : 'textSecondary'}
                >
                    {t(tokens.common[PAYMENT_METHODS[paymentType]?.subtitle ?? ''])}
                </Typography>
                )
            )}
            {isLoading ? (
                <Skeleton variant='text' width={120} />
            ) : (
                <Tooltip title={t(tokens.common[tooltipData.title])}>
                <Typography
                    variant='h6'
                    color={!isLoading && selected ? 'text.disabled' : 'textSecondary'}
                    className={classes.paymentKnowMore}
                    onClick={() => tooltipOnClick(true)}
                >
                    {t(tokens.common[tooltipData.displayText])}
                </Typography>
                </Tooltip>
            )}
          </Stack>
        </CardContent>
        <CardActions>
          {showRedudantButton &&
            (isLoading ? (
              <Skeleton variant='text' width={120} />
            ) : (
              <Button
                variant='contained'
                size='small'
                fullWidth
                onClick={() => redudantButtonClick()}
                disabled={disabled}
              >
                {redundantButtonText}
              </Button>
            ))}
          {isLoading ? (
            <Skeleton variant='text' width={120} />
          ) : (
            <Button
              variant='contained'
              size='small'
              fullWidth
              onClick={() => onClick()}
              disabled={disabled}
            >
              {buttonText}
            </Button>
          )}
        </CardActions>
      </Card>
    )
  }
  return (
    <Card
      sx={{
        textAlign: 'center',
        width: 200,
        overflow: 'visible',
        mt: '16px !important',
        ml: '16px !important',
        backgroundColor: '#f9f9f9',
        position: 'relative',
        height: 'fit-content',
        border: !isLoading && selected ? 'solid 2px #3f51b5' : 'solid 1px #e0e0e0'
      }}
    >
        {!isLoading && selected && (
            <Chip
                label={t(tokens.common.selected)}
                color='success'
                sx={{
                    position: 'absolute',
                    top: '-5%',
                    left: 0,
                    fontSize: '0.7rem',
                    zIndex: 1
                }}
            />
        )}
      <CardContent>
        <Stack spacing={2} alignItems='center'>
          {isLoading ? (
            <Skeleton variant='circular' width={60} height={60} />
          ) : (
            <SvgIcon>{renderIcon(paymentType)}</SvgIcon>
          )}
          {isLoading ? (
            <Skeleton variant='text' width={120} />
          ) : (
            <Typography
                variant='h6'
                color={!isLoading && selected ? 'text.disabled' : 'textSecondary'}
                fontWeight='bold'
            >
              {t(tokens.common[PAYMENT_METHODS[paymentType]?.title ?? ''])}
            </Typography>
          )}
          {isLoading ? (
            <Skeleton variant='text' width={120} />
          ) : (
            matricula.unitycode !== 1304 && (
              <Typography
                variant='h6'
                fontWeight='unset'
                color={!isLoading && selected ? 'text.disabled' : 'textSecondary'}
            >
                {t(tokens.common[PAYMENT_METHODS[paymentType]?.subtitle ?? ''])}
              </Typography>
            )
          )}
          {isLoading ? (
            <Skeleton variant='text' width={120} />
          ) : (
            <Tooltip title={t(tokens.common[tooltipData.title])}>
              <Typography
                variant='h6'
                color={!isLoading && selected ? 'text.disabled' : 'textSecondary'}
                className={classes.paymentKnowMore}
                onClick={() => tooltipOnClick(true)}
              >
                {t(tokens.common[tooltipData.displayText])}
              </Typography>
            </Tooltip>
          )}
        </Stack>
      </CardContent>
      {showRedudantButton &&
        (isLoading ? (
          <Skeleton variant='text' width={120} />
        ) : (
          <CardActions>
            <Button
              variant='contained'
              size='small'
              fullWidth
              onClick={() => redudantButtonClick()}
              disabled={disabled}
            >
              {redundantButtonText}
            </Button>
          </CardActions>
        ))}
      {isLoading ? (
        <Skeleton variant='text' width={120} />
      ) : (
        <CardActions>
          <Button
            variant='contained'
            size='small'
            fullWidth
            onClick={() => onClick()}
            disabled={disabled}
          >
            {buttonText}
          </Button>
        </CardActions>
      )}
    </Card>
  )
}

export default SelectPayment
