/* eslint-disable indent */
import React, { Fragment, useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import { isEmpty, map, has } from "lodash";
import useStyles from "./style";
import PaymentForm from "../../../../../../modules/Finance/components/PaymentForm";
import BilletForm from "../BilletForm";
import TermAgreement from "../TermAgreement";
import {
  financialAgreementDataSelector,
  financialAgreementIdDataSelector,
  hasErrorFinancialAgreementSelector,
  mensagemErrorAcordoSelector,
} from "../../../selectors/financialAgreement";
import InstallmentForm from "../InstallmentForm";
import {
  fetchFinancial,
  sendPaymentCredit,
  postAgreement,
  createJsonTermAgreement
} from "../../../actions";
import Loading from "../../../../../../components/loading";
import Message from "../message";
import Buttons from "../buttons";
import { PAY_WAYS } from "../../../utils";
import { getCompanyInformations } from "../../../services/company";
import { appIdSelector } from "../../../../../Login/selectors/user";
import { paymentDataSelector, hasErrorPaymentSelector } from "../../../selectors/payment";
import {
  companyInformationDataSelector,
  hasLoadedCompanyInformationsSelector,
} from "../../../selectors/companyInformations";
import { set } from "../../../../../../layouts/actions";
import { injectIntl, FormattedMessage } from "react-intl";
import { MESSAGE_ERROR_PAYMENT_CREDIT } from "../../../../../Finance/Pay/constants";
import {
  isLoadingPaymentSelector,
  paymentSelector,
  NSUSelector,
  orderNumberSelector,
  paymentNegotiationIdSelector,
  isTourEnabledPaymentSelector,
} from "../../../selectors/payment";
import { userCpfCnpjSelector, cpfCnpjSelector } from "../../../../../Login/selectors/user";
import NewWindow from 'react-new-window';
import Alert from "@mui/material/Alert";
import {Grid} from "@mui/material";
import Typography from "@mui/material/Typography";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

export default injectIntl(
  ({
    intl,
    handleClose,
    isBillet,
    activeStep,
    setActiveStep,
    items,
    steps,
  }) => {
    const classes = useStyles();

    const [disabled, setDisabled] = useState(true);
    const [windowTermAgreementList, setWindowTermAgreementList] = useState([]);

    const dispatch = useDispatch();

    const financialAgreement = useSelector(financialAgreementDataSelector);
    const agreementId = useSelector(financialAgreementIdDataSelector);
    const ref = useRef({ fetchInitial: true });
    const appId = useSelector(appIdSelector);
    const postPaymentData = useSelector(paymentDataSelector);
    const companyInformations = useSelector(companyInformationDataSelector);
    const hasLoadedCompanyInformations = useSelector(
      hasLoadedCompanyInformationsSelector
    );
    const nsuNumber = useSelector(NSUSelector);
    const orderNumber = useSelector(orderNumberSelector);
    const paymentNegotiationId = useSelector(paymentNegotiationIdSelector);
    const isEnableTourModal = useSelector(isTourEnabledPaymentSelector);
    const cpfcnpj = useSelector(userCpfCnpjSelector);
    const cpfCnpjAdmin = useSelector(cpfCnpjSelector);

    const total =
      financialAgreement &&
      financialAgreement.list &&
      financialAgreement.list.reduce(
        (prev, curr) => prev + Number(curr.amountCur),
        0
      );
    const [item] = items;
    const [infos, setInfos] = useState({
      items,
      total,
      installment: 1,
      totalDiscount: 0,
      cardName: "",
      cardNumber: "",
      expiryDateMonth: "",
      expiryDateYear: "",
      cvv: "",
      brandType: {},
      isAgreement: false,
      cardType: "credit",
      isNegotiation: true,
      cpf: cpfcnpj,
    });

    const { loading, error } = useSelector(paymentSelector);
    const hasErrorPayment = useSelector(hasErrorPaymentSelector);
    const hasErrorFinance = useSelector(hasErrorFinancialAgreementSelector);
    const mensagemErrorAcordo = useSelector(mensagemErrorAcordoSelector);

    const controllActiveStep =
      activeStep !== steps.length ||
        loading ||
        (activeStep === steps.length && error)
        ? activeStep
        : steps.length;
    const isErroLoadUnity =
      hasLoadedCompanyInformations && !has(companyInformations, "unityId");

    let infoTerm;
    const getInfoTerm = (value) => {
      infoTerm = value;
    };

    useEffect(() => {
      if (isEnableTourModal && !isEmpty(financialAgreement)) {
        return;
      }
      if (ref.current.fetchInitial) {
        ref.current.fetchInitial = false;
        dispatch(
          fetchFinancial(
            1,
            items,
            isBillet ? 100 : 0,
            isBillet ? PAY_WAYS.BILLET : PAY_WAYS.CARD
          )
        );
        dispatch(getCompanyInformations(appId, item.unityid));
      }
      if (error && error.message) {
        dispatch(set((error && error.message) || MESSAGE_ERROR_PAYMENT_CREDIT));
      }
    }, [error]);

    useEffect(() => { }, [
      loading,
      financialAgreement,
      disabled,
      isEnableTourModal,
    ]);

    const openTermAgreement = () => {
      const windowTermAgreementListAdded = windowTermAgreementList;
      windowTermAgreementListAdded.push(1);
      setWindowTermAgreementList([...windowTermAgreementListAdded]);
    }

    const acceptFinance = () => {
      if (steps[activeStep].isAccept) {
        dispatch(
          postAgreement(
            {
              _agreementId: agreementId,
              _dataAreaId: item.dataareaid,
              installments: map(items, (item) => item.referenceid),
              acceptanceterm: JSON.stringify(infoTerm),
              nsu: nsuNumber,
              ordernumber: orderNumber,
              numberofinstallments: infos.installment,
              paymentnegotiationid: paymentNegotiationId,
            },
            appId,
            isBillet,
            cpfCnpjAdmin
          )
        )
          .then((result) => {
            if (result) {
              handleNext();
            }
          })
          .catch((error) => {
            const message =
              error.response &&
              error.response.data &&
              error.response.data.message;
            dispatch(set(message || intl.messages["message-error-unexpected"]));
          });
      } else {
        handleNext();
      }
    };

    const finishPayment = (infos) => {
      setInfos(infos);

      if (financialAgreement && financialAgreement.list) {
        const itemsAgreement = map(financialAgreement.list, (item) => {
          return {
            totalDiscount: 0,
            dataareaid: companyInformations.dataAreaId,
            accountresponsible: item.custAccount,
            proposalId: agreementId,
            correctvalue: item.amountCur,
          };
        });

        dispatch(createJsonTermAgreement(items, total, isBillet, intl)).then((value) => {
          getInfoTerm(value);

          dispatch(sendPaymentCredit(
            itemsAgreement,
            { ...infos, isAgreement: true, isFromNegotitation: true },
            items,
            infoTerm
          )).then(() => handleNext());
        });
      }
    };

    const handleNext = () => {
      setActiveStep(activeStep + 1);
      setDisabled(true);
    };

    const handleBack = () => {
      setActiveStep(activeStep - 1);
    };

    const getMyStringValue = () => {
      try {
        return localStorage.getItem('@PORTAL/ErroAcordo')
      } catch(e) {
        // read error
      }
      console.log('Done.')
    }

    if (hasErrorFinance || isErroLoadUnity) {
      return (
        <Content
          controllActiveStep={controllActiveStep}
          classes={classes}
          steps={steps}
        >
          <Alert severity="error">
            {
              isBillet ? (
                <Message
                  error
                  title={mensagemErrorAcordo.message} //"O Valor das parcelas não podem ser inferior que R$ 100,00 reais."
                  message="Entrar em contato com o departamento de cobrança/unidade para proposta."
                />
              ) : (
                <Message
                  error
                  title={
                    intl.messages[
                      `finance-negotiation-comp-checkout-title-error${isErroLoadUnity ? "-unity" : ""
                      }`
                      ]
                  }
                  message={
                    mensagemErrorAcordo?.message ? mensagemErrorAcordo.message : intl.messages[`finance-negotiation-comp-checkout-error${isErroLoadUnity ? "-unity" : ""}`]
                  }
                />
              )
            }
          </Alert>
        </Content>
      );
    }

    const getStepContent = () => {
      if (isBillet) {
        switch (activeStep) {
          case 0:
            return (
              <BilletForm
                infos={infos}
                setInfos={setInfos}
                handleNext={handleNext}
                items={items}
              />
            );
          case 1:
            return (
              <TermAgreement
                total={total}
                items={items}
                setDisabled={setDisabled}
                isBillet
                setInfos={(value) => getInfoTerm(value)}
              />
            );
          default:
            return (
              <Message
                error={error}
                message={intl.messages["finance-negotiation-comp-checkout-message"]}
                isBillet={isBillet}
                title={
                  intl.messages[
                  error
                    ? "finance-negotiation-comp-checkout-title-msg-error"
                    : "finance-negotiation-comp-checkout-title-msg"
                  ]
                }
              />
            );
        }
      }
      switch (activeStep) {
        case 0:
          return (
            <InstallmentForm
              total={total}
              setInfos={setInfos}
              setDisabled={setDisabled}
              infos={infos}
              items={items}
            />
          );
        case 1:
          return (
            <PaymentForm
              payment={{ ...infos, total }}
              setPayment={setInfos}
              handleNext={finishPayment}
              isLoadingSelector={isLoadingPaymentSelector}
              openTermAgreement={openTermAgreement}
              setDisabled={setDisabled}
            />
          );
        // case 2:
        //   return (
        //     <TermAgreement
        //       items={items}
        //       total={total}
        //       setDisabled={setDisabled}
        //       setInfos={(value) => getInfoTerm(value)}
        //     />
        //   );
        default:
          return (
            <Message
              error={hasErrorPayment}
              message={postPaymentData.message}
              title={
                intl.messages[
                hasErrorPayment
                  ? "finance-negotiation-comp-checkout-title-msg-error"
                  : "finance-negotiation-comp-checkout-title-msg"
                ]
              }
            />
          );
      }
    };

    return (
      <Fragment>
        {windowTermAgreementList.map((_, i) => (
          <NewWindow onUnload={() => setWindowTermAgreementList((prev) => prev.slice(1))} key={i}>
            <TermAgreement
              items={items}
              total={total}
              setDisabled={setDisabled}
              setInfos={(value) => getInfoTerm(value)}
              origin={window.location.origin}
            />
          </NewWindow>
        ))}

        <Content
          controllActiveStep={controllActiveStep}
          classes={classes}
          steps={steps}
        >
          {loading ? (
            <Loading
              message={intl.messages["finance-negotiation-comp-checkout-loading"]}
            />
          ) : (
            <Fragment>
              <Grid item xs={12}>
                {getStepContent()}
              </Grid>
              {financialAgreement &&
                !isEmpty(financialAgreement.financialAgreementId) && (
                  <Buttons
                    loading={loading}
                    activeStep={steps[activeStep]}
                    error={error}
                    handleBack={handleBack}
                    finish={acceptFinance}
                    handleClose={handleClose}
                    isBillet={isBillet}
                    disabled={disabled || loading}
                  />
                )}
            </Fragment>
          )}
        </Content>
      </Fragment>
    );
  }
);

const Content = ({ children, controllActiveStep, classes, steps }) => {
  return (
    <Grid
      data-tur="tut-payment-negotiation"
      container
      justify="space-between"
      direction="row"
      alignItems="center"
      className={classNames(classes.ckeckoutContent)}
    >
      <HeaderStepper
        controllActiveStep={controllActiveStep}
        classes={classes}
        steps={steps}
      />
      <Grid item xs={12}>
        {children}
      </Grid>
    </Grid>
  );
};

const HeaderStepper = ({ controllActiveStep, classes, steps }) => {
  return (
    <Grid item xs={12} className={classes.checkoutHeader}>
      <Typography component="h1" variant="h4" align="center">
        <FormattedMessage id="finance-negotiation-comp-checkout-header" />
      </Typography>
      <Stepper
        activeStep={controllActiveStep}
        className={classes.ckeckoutStepper}
        alternativeLabel
      >
        {steps.map((step, index) => (
          <Step key={index}>
            <StepLabel id={step.id}>{step.label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Grid>
  );
};
