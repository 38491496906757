import React from 'react';
import Registers from "../../modules/Home";
import { PortalFinanceiroLayout } from "../../layouts/PortalFinanceiroLayout";
import { Container } from "@mui/material";

const HomePortal = (props) => {
  return (
    <PortalFinanceiroLayout>
      <Container>
        <Registers {...props}/>
      </Container>
    </PortalFinanceiroLayout>
  );
}

export default HomePortal
