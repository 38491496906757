import ptBR from './pt-br.json'
import { createIntl } from 'react-intl'

let intl

export const textIntl = (id, values) => {
  if (!intl) {
    intl = createIntl({ locale: 'pt', messages: ptBR })
  }
  return intl.formatMessage({ id }, values)
}

export default {
  ptBR,
  textIntl
}
