export const PAYMENT_METHODS = {
    "creditCard": {
        "title": "recurringCreditCard",
        "subtitle": "recurringCreditCardDiscount"
    },
    "bill": {
        "title": "basicPayment",
        "subtitle": "paymentSlip"
    },
    "recurringBill":{
        "title": "automaticDebit",
        "subtitle": "automaticDebitDiscount"
    }
};