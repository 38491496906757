import {makeStyles} from "@mui/styles";

export default makeStyles(theme => ({
  footer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 20,
    marginTop: 20,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0
    },
    justifyContent: 'center',
    alignItems: 'center',
  },
  footerLogo: {
    width: 70,
    height: 50,
    opacity: 0.5,
  },
  footerIconsButton: {
    display: 'flex',
    justifyContent: 'center',
  },
  footerInfoText: {
    fontWeight: 'bold'
  },
  footerCopyright: {
    fontWeight: 100,
    fontSize: '0.70rem'
  },
  footerCallCenter: {
    display: 'flex',
    justifyContent: 'center',
    '& span': {
      lineHeight: '1.3'
    }
  },
  footerContainerLogo: {
    marginBottom: 5
  },
  footerTour: {
    marginBottom: 20,
  },
  footerTextTour: {
    '& span': {
      fontWeight: 500,
    }
  },
  footerIcon: {
    color: theme.palette.warning.main
  },
}))
