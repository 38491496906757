import {useRecoilState, useRecoilValue} from "recoil";
import {creditCardsState} from "../recoil/atoms/creditCardsState";
import {useMemo, useState} from "react";
import * as _walletService from "../service/wallet-service";
import * as mensagem from '../components/shared/Message.js';
import {selectedUnidadeState} from "../recoil/atoms/selectedUnidadeState";
import {responsavelState} from "../recoil/atoms/responsavelState";
export function useCardVerification(
  {
    cardId,
    handleCardSelection = () => {}
  }
) {
  const [creditCards, setCreditCards] = useRecoilState(creditCardsState);
  const card = useMemo(() => creditCards.find(c => c.cardId === cardId), [creditCards])
  const [isVerifying, setIsVerifying] = useState(false);
  const [cvv, setCvv] = useState("");
  const unidade = useRecoilValue(selectedUnidadeState);
  const responsavel = useRecoilValue(responsavelState);

  const handleVerifyCard = () => {
    if (isVerifying) return;

    setIsVerifying(true);

    _walletService.cartaoVerificar(cardId, {
      sellerId: unidade?.id,
      customerId: responsavel?.id,
      SecurityCode: cvv,
    }).then(async (resp) => {
      if (resp.ok) {
        const body = await resp.json();
        const cardIndex = creditCards.indexOf(card);
        const newCard = {
          ...card,
          numberToken: body?.numberToken ?? null
        };
        let list = [...creditCards];
        list[cardIndex] = newCard
        setCreditCards(list);
        handleCardSelection(newCard)
        mensagem.ExibeMensagem("Cartão verificado com sucesso!", '0')
      } else {
        mensagem.ExibeMensagem("Cartão não verificado. Tente novamente.", '2')
      }
    }).catch(() => {
      mensagem.ExibeMensagem("Cartão não verificado. Tente novamente.", '2')
    }).finally(() => {
      setIsVerifying(false)
    })
  }

  return {
    isVerifying,
    cvv,
    setCvv,
    handleVerifyCard,
  }
}
