import env from "../env";
import fetch from "../utils/fetch";

export function getById(id) {
  return fetch(env.apiUrl + `api/contact/getById?id=${id}`, {
    method: "GET",
    mode: "cors",
  }).then(async res => await res.json());
}

export function getSas(url) {
  return fetch(env.apiUrl + `api/contact/getSas?url=${url}`, {
    method: "GET",
    mode: "cors",
  }).then(async res => await res.text());
}
