import {getRecoil} from "recoil-nexus";
import {portalAuthState} from "../recoil/atoms/portalAuthState";

export function GetCompany(unidadeCodigoERP, appId = "e24b77aa-da82e5427477") {
  const auth = getRecoil(portalAuthState);
  return fetch(`${process.env.REACT_APP_API}v1/company/${appId}/${unidadeCodigoERP}`, {
    method: "GET",
    mode: "cors",
    headers: {
      Authorization: `Bearer ${auth?.token}`,
      "Content-Type": "application/json"
    },
  })
}
