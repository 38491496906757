import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IconButton, Button } from '@mui/material'
import CloseIcon from "@mui/icons-material/Close";
import { hide } from '../../../../layouts/actions'
import { billetFileUrlSelector } from '../selectors/billet'

export const SnackBillet = () => {

  const dispatch = useDispatch()
  const urlFile = useSelector(billetFileUrlSelector)

  const onClick = () => {
    window.open(urlFile, '_blank', 'noopener')
    dispatch(hide())
  }

  return ([
    <Button key='clique-aqui' color='secondary' size='small' onClick={onClick}>
      CLIQUE AQUI!
    </Button>,
    <IconButton
      key='close'
      aria-label='close'
      color='inherit'
      onClick={() => dispatch(hide())}
    >
      <CloseIcon />
    </IconButton>,
  ])
}
