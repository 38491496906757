import {selector} from "recoil";
import {unidadeGetResponseState} from "../atoms/unidadeGetResponseState";
import {selectedUnidadeState} from "../atoms/selectedUnidadeState";

const contactInfoSelector = selector({
  key: 'contactInfoSelector',
  get: ({get}) => {
    const unidade = get(unidadeGetResponseState);
    const selectedUnidade = get(selectedUnidadeState);
    const telefone = selectedUnidade?.telefone ?? unidade?.telefone;
    return {
      telefone: {
        label: selectedUnidade?.telefone ?? unidade?.telefone,
        href: `tel:${telefone}`,
      },
    }
  },
});

export default contactInfoSelector;
