import WalletContract from "./WalletContract";
import React from "react";
import {useRecoilValue} from "recoil";
import {walletCardSubsState} from "../../recoil/atoms/walletCardSubsState";
import AccordionContracts from "./AccordionContracts/AccordionContracts";
import Box from "@mui/material/Box";

function WalletContractList({matriculas}) {
  const subs = useRecoilValue(walletCardSubsState);

  return (
    <div className="smo-wallet-contract-list-container">
      <Box
        id="accordionContracts"
        sx={{
          mb: 4
        }}
      >
        {(subs || []).map((sub, i) => {
          const {
            order_id,
            subscription: {
              subscription_id
            }
          } = sub;

          let nome = " - ";
          let matricula = {};

          if (matriculas && matriculas.length) {
            matricula = (matriculas || []).find(m => m?.codigoMatricula === order_id);

            if (matricula) {
              nome = matricula?.nomeLandingPage || matricula?.servico_Name;
            }
          }

          return (
            <AccordionContracts
              id={i}
              title={nome}
              key={i}
              opened={i === 0}
            >
              <WalletContract
                segmentoAluno={matricula?.segmento_Name}
                codigoMatricula={matricula?.codigoMatricula}
                cursoNome={matricula?.nomeLandingPage ?? matricula?.curso_Name}
                valorPeriodo={matricula?.periodo_Value}
                year={matricula?.anoLetivo_Name}
                aluno={matricula?.estudante_Name}
                subId={subscription_id}
              />
            </AccordionContracts>
          )
        })}
      </Box>
    </div>
  )
}

export default WalletContractList;
