import React from 'react'
import {FINANCIAL} from '../../../utils/permissions'
import ContentTour from '../components/ContentTour'
import {textIntl} from '../../../utils/intl'

export default [
  {
    permissions: [],
    selector: '[data-tut="tur-icon-menu"]',
    content: () => (
      <ContentTour
        title={textIntl('tour-main-menu')}
        content={textIntl('tour-main-menu-description')}
      />
    ),
    offSetPlus: 500,
  },
  {
    permissions: [],
    selector: '[data-tut="tur-icon-menu-servicos"]',
    content: () => (
      <ContentTour
        title={textIntl('tour-main-menu-servicos')}
        content={textIntl('tour-main-menu-servicos-description')}
      />
    ),
    offSetPlus: 500,
  },
  {
    permissions: [],
    selector: '[data-tut="tur-icon-menu-matricula"]',
    content: () => (
      <ContentTour
        title={textIntl('tour-main-menu-matricula')}
        content={textIntl('tour-main-menu-matricula-description')}
      />
    ),
    offSetPlus: 500,
  },
  {
    permissions: [],
    selector: '[data-tut="tur-icon-menu-portal"]',
    content: () => (
      <ContentTour
        title={textIntl('tour-main-menu-portal')}
        content={textIntl('tour-main-menu-portal-description')}
      />
    ),
    offSetPlus: 500,
  },
  {
    permissions: [],
    selector: '[data-tut="tur-icon-menu-acordo"]',
    content: () => (
      <ContentTour
        title={textIntl('tour-main-menu-acordo')}
        content={textIntl('tour-main-menu-acordo-description')}
      />
    ),
    offSetPlus: 500,
  },
  {
    permissions: [],
    selector: '[data-tut="tur-icon-menu-user"]',
    content: () => (
      <ContentTour
        title={textIntl('tour-main-menu-user')}
        content={textIntl('tour-main-menu-user-description')}
      />
    ),
    offSetPlus: 500,
  },
  {
    permissions: [],
    selector: '[data-tut="tur-icon-menu-home"]',
    content: () => (
      <ContentTour
        title={textIntl('tour-main-menu-home')}
        content={textIntl('tour-main-menu-home-description')}
      />
    ),
    offSetPlus: 500,
  },
  {
    permissions: [FINANCIAL],
    selector: '[data-tut="tur-menu-home-filter"]',
    content: () => (
      <ContentTour
        title={textIntl('tour-main-menu-home-filter')}
        content={textIntl('tour-main-menu-home-filter-description')}
      />
    ),
    offSetPlus: 500
  },
  {
    permissions: [FINANCIAL],
    selector: '[data-tut="tur-menu-footer"]',
    content: () => (
      <ContentTour
        title={textIntl('tour-main-menu-contact')}
        content={textIntl('tour-main-menu-contact-description')}
      />
    ),
    offSetPlus: 0
  },
  {
    permissions: [FINANCIAL],
    selector: '[data-tut="tur-menu-home-cards"]',
    content: () => (
      <ContentTour
        title={textIntl('tour-main-menu-home-cards')}
        content={textIntl('tour-main-menu-home-cards-description')}
      />
    ),
    offSetPlus: 500
  },
]
