import React, {FC, useEffect, useState} from 'react';
import IconButton from '@mui/material/IconButton';
import {ModalProps} from "@mui/material/Modal/Modal";
import useMediaQuery from "@mui/material/useMediaQuery";
import {Theme} from "@mui/material/styles/createTheme";
import {Dialog, DialogTitle, Zoom} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {TransitionProps} from "@mui/material/transitions";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Zoom ref={ref} {...props} />;
});

interface Props extends ModalProps {
  defaultExpanded?: boolean
  title?: string
  disableExpand?: boolean
  backgroundImage?: string
  onClose?: (e: any) => void
}

export const Modal: FC<Props> = (props) => {
  const {
    defaultExpanded = false,
    title = '',
    children,
    onClose,
    disableExpand,
    backgroundImage,
    ...rest
  } = props;
  const [expanded, setExpanded] = useState(defaultExpanded)
  const smUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));

  useEffect(() => {
    if (!expanded && !smUp) {
      setExpanded(true)
    } else {
      setExpanded(false)
    }
  }, [smUp])

  return (
    <Dialog
      maxWidth="md"
      fullWidth
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      TransitionComponent={Transition}
      onClose={onClose}
      {...rest}
    >
      <DialogTitle id="scroll-dialog-title">
        {title}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>

      {rest?.open && children}
    </Dialog>
  )
}
