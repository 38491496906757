export const useMockedUser = () => {
  // To get the user from the authContext, you can use
  // `const { user } = useAuth();`
  return {
    id: localStorage.getItem("@SMO20/idResponsavel"),
    avatar: localStorage.getItem("@SMO20/avatar"),
    name: localStorage.getItem("@SMO20/nomeResponsavel"),
    email: localStorage.getItem("@SMO20/email")
  };
};
