import {atom} from "recoil";
import {persistAtomEffect} from "../effects/persistAtom";
import IMatriculaResponse, {Matricula} from "../../@types/IMatriculaResponse";
import * as _unidadeService from "../../service/unidade-service";
import {setRecoil} from "recoil-nexus";
import {selectedUnidadeState} from "./selectedUnidadeState";
import {selectedDiaVencimentoPagamentoState} from "./selectedDiaVencimentoPagamentoState";
import {getAll} from "../../modules/Finance/services/unity";

export const selectedMatriculaState = atom<Matricula>({
  key: 'selectedMatriculaState',
  default: {},
  effects: [
    persistAtomEffect,
    ({onSet}) => {
      onSet((newValue, oldValue) => {
        if (newValue === oldValue) return
        if (newValue && newValue.unidade_Id) {
          _unidadeService.getUnidade(newValue.unidade_Id).then(unidade => {
            setRecoil(selectedUnidadeState, unidade);

            if (unidade?.diasVencimentoPagamento && unidade?.diasVencimentoPagamento?.length === 1) {
              setRecoil(selectedDiaVencimentoPagamentoState, unidade.diasVencimentoPagamento[0]);
            } else {
              setRecoil(selectedDiaVencimentoPagamentoState, null);
            }
          });
          // @ts-ignore
        } else if (newValue && (newValue?.unitycode || newValue?.unityId)) {
          // @ts-ignore
          const { unitycode, unityId } = newValue
          getAll(unitycode ?? unityId).then((response) => {
            // @ts-ignore
            if (response && response.length) {
              // @ts-ignore
              const unity = response.find(r => r.codigoERP === (unitycode ?? unityId))
              if (unity) {
                setRecoil(selectedUnidadeState, unity);
              }
            }
          })
        }
      });
    }
  ]
});
