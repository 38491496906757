import React from "react";
import {useRecoilState, useRecoilValue, useSetRecoilState} from "recoil";
import {creditCardsState} from "../../recoil/atoms/creditCardsState";
import acceptedCards from "../../utils/acceptedCards";
import Card from "react-credit-cards";
import {BRANDS_CONFIG} from "../../modules/Finance/constants";
import * as _walletService from "../../service/wallet-service";
import * as mensagem from '../../components/shared/Message.js';
import {walletCardReloadState} from "../../recoil/atoms/walletCardReloadState";
import * as _matricula from "../../service/matricula-service";
import Button from "@mui/material/Button";
import {LoadingButton} from "@mui/lab";
import {Container} from "@mui/material";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";
import {cpfMask} from "../../utils/cpfMask";
import Typography from "@mui/material/Typography";
import {responsavelState} from "../../recoil/atoms/responsavelState";
import {selectedMatriculaState} from "../../recoil/atoms/selectedMatriculaState";
import {selectedUnidadeState} from "../../recoil/atoms/selectedUnidadeState";
import {unidadeGetResponseState} from "../../recoil/atoms/unidadeGetResponseState";
import matriculasSelector from "../../recoil/selectors/matriculasSelector";

const isDuplicatedNewCard = (new_number) => savedCard => savedCard.cardNumber === new_number
const isDuplicatedTokenizedCard = (new_number, values) => savedCard => {
  const tokenizedNumber = `${savedCard.bin}${savedCard.lastFourDigits}`;
  const newCardBin = new_number.slice(0, savedCard.bin.length);
  const newCardLastFour = new_number.slice(new_number.length - 4, new_number.length);
  const newCardNumber = `${newCardBin}${newCardLastFour}`;
  const expirationMonth = values.expirationMonth.padStart(2, '0');
  const expirationYear = values.expirationYear.padStart(2, '0');
  const tokenizedExpiration = `${savedCard.expirationMonth}${savedCard.expirationYear}`;
  const newCardExpiration = `${expirationMonth}${expirationYear}`;

  return tokenizedNumber === newCardNumber && tokenizedExpiration === newCardExpiration;
}

const creditCardMask = value => {
  return value
    .trim()
    .replace(/\D/g, '')
    .replace(/(\d{4})(\d)/, '$1 $2')
    .replace(/(\d{4})\s(\d{4})(\d)/, '$1 $2 $3')
    .replace(/(\d{4})\s(\d{4})\s(\d{4})(\d)/, '$1 $2 $3 $4')
    // captura digitos seguintes e não deixa digitar mais que 16
    .replace(/(\d{4})\s(\d{4})\s(\d{4})\s(\d{4}).*/, '$1 $2 $3 $4')
}

const isTokenizedCard = c => 'bin' in c;

function WalletSaveCard({onCancel = () => {}, onClose = () => {}}) {
  const formRef = React.useRef(null);
  const responsavel = useRecoilValue(responsavelState);
  const defaultValues = {
    cardholderName: '',
    cardholderIdentification: responsavel?.cpf ?? '',
    cardNumber: '',
    expirationMonth: '',
    expirationYear: '',
    securityCode: '',
  };
  const [cardValues, setCardValues] = React.useState(defaultValues);
  const [focused, setFocused] = React.useState("");
  const [brand, setBrand] = React.useState("");
  const [errors, setErrors] = React.useState([]);
  const [creditCards, setCreditCards] = useRecoilState(creditCardsState);
  const [isSaving, setIsSaving] = React.useState(false);
  const setReload = useSetRecoilState(walletCardReloadState);
  const matriculas = useRecoilValue(matriculasSelector);
  const selectedMatricula = useRecoilValue(selectedMatriculaState);
  const selectedUnidade = useRecoilValue(selectedUnidadeState);
  const unidade = useRecoilValue(unidadeGetResponseState);

  const handleInputChange = (event) => {
    let {name, value} = event.target;

    if (name === 'cardholderName') {
      value = (String(value)).replace(new RegExp("[^a-zA-ZáéíóúÁÉÍÓÚàÀâÂãÃêÊíÍóÓôÔúÚçÇâêîôûÂÊÎÔÛäëïöüÄËÏÖÜñÑ ]"), "")
      value = String(value).slice(0, 26)
    }

    setCardValues({
      ...cardValues, [name]: value,
    });
  }

  const handleChangeCVV = (event) => {
    const {name, value} = event.target;
    if (value.length > 4) {
      event.target.value = value.slice(0, 4);
      return;
    }
    setCardValues({
      ...cardValues, [name]: value.replace(/[^0-9]/g, ''),
    });
  }

  const handleChangeMonth = (event) => {
    const {name, value} = event.target;
    let formated = value !== '0' && value !== '' ? String(value).padStart(2, '0').replace(/[^0-9]/g, '') : value.replace(/[^0-9]/g, '');

    if (value.startsWith('0') && value.length >= 2) {
      formated = formated.slice(1);
    } else if (value.length >= 2 && !value.startsWith('0')) {
      event.target.value = value.slice(0, 2);
      formated = value.slice(0, 2);
    }
    if (Number(formated) > 12) {
      event.target.value = '12';
      formated = '12';
    }

    if (formated.length < 2) {
      formated = formated.padStart(2, '0');
    }

    setCardValues({
      ...cardValues, [name]: formated,
    });
  }

  const handleChangeYear = (event) => {
    const {name, value} = event.target;
    if (value.length > 2) {
      event.target.value = value.slice(0, 2);
      return;
    }
    setCardValues({
      ...cardValues, [name]: value.replace(/[^0-9]/g, ''),
    });
  }

  const handleInputFocus = (focused = '') => () => {
    setFocused(focused)
  }

  const handleInputBlur = () => {
    setFocused('');
  }

  const onSubmit = async (event) => {
    event.preventDefault();

    if (isSaving) return;

    setIsSaving(true);

    const cardNumber = cardValues.cardNumber.replace(/\D/g, '').replaceAll(" ", "");
    const cardholderIdentification = cardValues.cardholderIdentification.replace(/\D/g, '').replaceAll(" ", "");
    const duplicatedNewCard = !!creditCards.find(isDuplicatedNewCard(cardNumber));
    const duplicatedTokenizedCard = !!creditCards.filter(isTokenizedCard)
      .find(isDuplicatedTokenizedCard(cardNumber, cardValues));

    if (duplicatedNewCard || duplicatedTokenizedCard) {
      setErrors(['Cartão já cadastrado']);
      mensagem.ExibeMensagem('Cartão já cadastrado', '2')
      setIsSaving(false);
      return false;
    }

    if (cardValues?.securityCode.length < 3 || cardValues?.securityCode.length > 4) {
      setIsSaving(false);
      return false;
    }

    if (cardValues?.expirationMonth.length < 2 || cardValues?.expirationMonth.length > 2) {
      setIsSaving(false);
      return false;
    }

    if (cardValues?.expirationYear.length < 2 || cardValues?.expirationYear.length > 2) {
      setIsSaving(false);
      return false;
    }

    if (cardValues?.cardholderName.length < 3) {
      setIsSaving(false);
      return false;
    }

    if (cardValues?.cardholderIdentification.length < 11) {
      setIsSaving(false);
      return false;
    }

    if (cardValues?.cardNumber.length < 15) {
      setIsSaving(false);
      return false;
    }

    try {
      let matriculaID = '00000000-0000-0000-0000-000000000000';

      if (matriculas[0]?.id) {
        matriculaID = matriculas[0]?.id;
      } else {
        const idResponsavelCookie = responsavel?.id;
        const result = await _matricula.GetbyMatriculasByResponsavel(idResponsavelCookie, false);
        if (result.value !== undefined && result.value.length > 0) {
          matriculaID = result.value[0].id;
        }
      }

      const resp = await _walletService.cartaoSalvar({
        ...cardValues,
        cardNumber,
        cardholderIdentification,
        sellerId: selectedMatricula?.unidade_Id ?? selectedUnidade?.id ?? unidade?.id ?? '',
        customerId: responsavel?.id,
        matriculaId: selectedMatricula?.id ?? matriculaID
      })

      setIsSaving(false);

      if (resp.ok) {
        const card = await resp.json();

        setCreditCards([...creditCards, card])

        setCardValues(defaultValues);
        setErrors([]);
        mensagem.ExibeMensagem(`Cartão final ${card?.lastFourDigits} cadastrado com sucesso!`, '0')

        setReload(true);

        if (onCancel) onCancel(); //volta para a tela de listagem

        return true;
      } else {
        mensagem.ExibeMensagem('Erro ao salvar cartão', '2');
      }
    } catch (e) {
      mensagem.ExibeMensagem('Erro ao salvar cartão', '2')
      console.error(e);
      setIsSaving(false);
    }

    return false;
  }

  return (
    <span>
      <form
        ref={formRef}
        onSubmit={onSubmit}
      >
        <Container
          sx={{
            mt: 3
          }}
        >
          <Stack
            direction={{xs: 'column', sm: 'row'}}
            spacing={2}
            sx={{
              width: '100%'
            }}
          >
            <Stack>
              <Box data-dd-privacy="hidden">
                <Card
                  number={cardValues.cardNumber}
                  name={cardValues.cardholderName}
                  expiry={`${cardValues.expirationMonth}/${cardValues.expirationYear}`}
                  cvc={cardValues.securityCode}
                  acceptedCard={acceptedCards}
                  placeholders={{name: 'Nome impresso no cartão'}}
                  locale={{valid: 'BR'}}
                  focused={focused}
                  callback={({issuer}) => {
                    if ((cardValues.brand === '' && issuer !== 'unknown') || (cardValues.brand !== issuer && issuer !== 'unknown')) {
                      setBrand(issuer);
                    }
                  }}
                />
              </Box>

              <Box className="smo-wallet-modal-brands-col">
                <Stack
                  direction="column"
                  spacing={1}
                  justifyContent="flex-start"
                  sx={{
                    mt: 2
                  }}
                >
                  <Typography>
                    Bandeiras aceitas
                  </Typography>
                  <Stack
                    direction="row"
                    spacing={1}
                  >
                    {Object.values(BRANDS_CONFIG(false)).map((brand) => (
                      <img
                        key={brand.img}
                        id={brand.idComponent}
                        src={brand.img}
                        width="auto"
                        height="30"
                        alt='brand'
                      />
                    ))}
                  </Stack>
                </Stack>
              </Box>
            </Stack>

            <Box
              sx={{
                width: '100%',
                minWidth: '300px'
              }}
            >
              <Stack
                direction="column"
                sx={{
                  mb: 1, width: '100%'
                }}
                spacing={1}
              >
                <TextField
                  id="cardholderName"
                  type="text"
                  size="small"
                  label="Nome do titular (Nome que está no cartão)"
                  value={cardValues.cardholderName}
                  onChange={handleInputChange}
                  onFocus={handleInputFocus('name')}
                  onBlur={handleInputBlur}
                  required
                  name="cardholderName"
                  autoComplete="cc-given-name"
                  fullWidth
                  error={cardValues.cardholderName.length < 3}
                  helperText={cardValues.cardholderName.length && cardValues.cardholderName.length < 3 ? 'Nome inválido' : ''}
                />

                <TextField
                  id="cardholderIdentification"
                  type="text"
                  size="small"
                  label="Documento do titular (CPF)"
                  value={cpfMask(cardValues.cardholderIdentification)}
                  onChange={handleInputChange}
                  onFocus={handleInputFocus('number')}
                  onBlur={handleInputBlur}
                  required
                  name="cardholderIdentification"
                  autoComplete="cc-number"
                  fullWidth
                  error={cardValues.cardholderIdentification.length < 11}
                  helperText={cardValues.cardholderIdentification.length && cardValues.cardholderIdentification.length < 11 ? 'CPF inválido' : ''}
                  InputProps={{
                    inputProps: {
                      maxLength: 14,
                    }
                  }}
                />
              </Stack>

              <TextField
                id="cardNumber"
                type="text"
                size="small"
                label="Número do cartão"
                value={creditCardMask(cardValues.cardNumber)}
                onChange={handleInputChange}
                onFocus={handleInputFocus('number')}
                onBlur={handleInputBlur}
                required
                name="cardNumber"
                autoComplete="cc-number"
                fullWidth
                error={cardValues.cardNumber.length < 15}
                helperText={cardValues.cardNumber.length && cardValues.cardNumber.length < 15 ? 'Número inválido' : ''}
              />

              <Stack
                direction={{xs: 'column', sm: 'row'}}
                spacing={1}
                sx={{
                  width: '100%',
                  mt: 1,
                  mb: 1
                }}
              >
                <TextField
                  id="expirationMonth"
                  type="number"
                  size="small"
                  label="Mês"
                  placeholder="MM"
                  value={cardValues.expirationMonth}
                  onChange={handleChangeMonth}
                  onFocus={handleInputFocus('expiry')}
                  onBlur={handleInputBlur}
                  required
                  fullWidth
                  error={(Number(cardValues.expirationMonth) < 1 || Number(cardValues.expirationMonth) > 12)}
                  helperText={cardValues.expirationMonth.length && (Number(cardValues.expirationMonth) < 1 || Number(cardValues.expirationMonth) > 12) ? 'Mês inválido' : ''}
                  name="expirationMonth"
                  autoComplete="cc-exp-month"
                  InputProps={{
                    inputProps: {
                      maxLength: 2,
                      min: 1,
                      max: 12
                    }
                  }}
                />

                <TextField
                  id="expirationYear"
                  type="number"
                  size="small"
                  label="Ano"
                  placeholder="AA"
                  error={(Number(cardValues.expirationYear) < 10 || Number(cardValues.expirationYear) > 50)}
                  helperText={cardValues.expirationYear.length && (Number(cardValues.expirationYear) < 10 || Number(cardValues.expirationYear) > 50) ? 'Ano inválido' : ''}
                  value={cardValues.expirationYear}
                  onChange={handleChangeYear}
                  onFocus={handleInputFocus('expiry')}
                  onBlur={handleInputBlur}
                  required
                  fullWidth
                  name="expirationYear"
                  autoComplete="cc-exp-year"
                  InputProps={{
                    inputProps: {
                      maxLength: 2,
                      min: 10,
                      max: 50
                    }
                  }}
                />
              </Stack>

              <TextField
                id="securityCode"
                type="number"
                size="small"
                label="Código de segurança"
                value={cardValues.securityCode}
                onChange={handleChangeCVV}
                onFocus={handleInputFocus('cvc')}
                onBlur={handleInputBlur}
                required
                error={(cardValues.securityCode.length < 3 || cardValues.securityCode.length > 4)}
                helperText={cardValues.securityCode.length && (cardValues.securityCode.length < 3 || cardValues.securityCode.length > 4) ? 'Código inválido' : ''}
                name="securityCode"
                autoComplete="cc-csc"
                fullWidth
                InputProps={{
                  inputProps: {
                    maxLength: 4,
                    minLength: 3,
                  }
                }}
              />
            </Box>
          </Stack>
        </Container>

        {(errors.length > 0) && (<Alert
          severity="error"
          sx={{
            mt: 3
          }}
        >
          <ul>
            {errors.map((error) => (<li key={error}>{error}</li>))}
          </ul>
        </Alert>)}

        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{
            mt: 5, width: '100%'
          }}
        >
          <Box>
            {creditCards.length > 0 ? (
              <Button
                variant="outlined"
                color="secondary"
                onClick={onCancel}
                type="button"
                disabled={isSaving}
                startIcon={(
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      rotate: '180deg'
                    }}
                  >
                    <svg className="seb seb-arrow-right" width="24" height="24" viewBox="0 0 24 24" fill="currentColor"
                         xmlns="http://www.w3.org/2000/svg">
                      <path d="M5 12H19" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                            strokeLinejoin="round"/>
                      <path d="M15 16L19 12" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                            strokeLinejoin="round"/>
                      <path d="M15 8L19 12" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                            strokeLinejoin="round"/>
                    </svg>
                  </Box>
                )}
              >
                Voltar
              </Button>
            ) : (
              <Button
                color="secondary"
                onClick={onClose}
                type="button"
                disabled={isSaving}
              >
                Cancelar
              </Button>
            )}
          </Box>

          <Box
            className="text-right"
          >
            <LoadingButton
              variant="contained"
              color="primary"
              type="submit"
              loading={isSaving}
            >
              Adicionar
            </LoadingButton>
          </Box>
        </Stack>
      </form>
    </span>
  )
}

export default WalletSaveCard;
