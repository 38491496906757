import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { monthsEnum } from '../../../utils/enums/date'
import { GRID_CHECKBOX_SELECTION_COL_DEF, GRID_CHECKBOX_SELECTION_FIELD } from '@mui/x-data-grid';
import { isEmpty } from 'lodash'
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  OutlinedInput,
  Skeleton,
  Stack,
  SvgIcon,
  Tooltip,
  Typography,
  useMediaQuery
} from '@mui/material'
import {
  InstallmentStatusIcon,
  InstallmentStatusType
} from '../../../utils/InstallmentStatus'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faFileInvoice,
  faFileInvoiceDollar,
  faReceipt
} from '@fortawesome/free-solid-svg-icons'
import {
  accessIdSelector,
  appIdSelector,
  cpfCnpjSelector,
  isTourEnabledSelector,
  userSelectedSelector
} from '../../Login/selectors/user'
import { DateBanner } from '../../../components/dateBanner'
import { GridSearchIcon } from '@mui/x-data-grid'
import { useTranslation } from '../../../hooks/use-translation'
import { tokens } from '../../../locales/tokens'
import { ListTable } from '../../../components/customTable/list-table'
import { ListContainer } from '../../../components/customTable/list-container'
import { hide, set, setWithConfig } from '../../../layouts/actions'
import PaymentDialog from './components/PaymentDialog/index'
import { isLoadingOpenedSelector, openedDataSelector } from './selectors/opened'
import { fetchOpened } from './actions'
import ReceiptDialog from '../components/ReceiptDialog'
import { SnackBillet } from './components/SnackBillet'
import { FormattedMessage } from 'react-intl'
import { isLoadingBilletSelector } from './selectors/billet'
import { billetRequest } from '../services/installment'
import { receiptPayment } from '../services/financial'
import { ERROR_UNEXPECTED } from '../../../utils/message'
import { apiAnalyticsValue } from '../../../utils/GTM/action'
import { OPENED, PAYMENT } from './constants'
import { isCardWay } from '../../Negotiation/Finance/utils'
import { apiResetAction } from '../../../utils/API/actions'
import { makeStyles, useTheme } from '@mui/styles'
import { CreditCardOutlined, Help } from '@mui/icons-material'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { avatarsState } from '../../../recoil/atoms/avatarsState'
import {selectedMatriculaState} from '../../../recoil/atoms/selectedMatriculaState'
import matriculasSelector from '../../../recoil/selectors/matriculasSelector'
import DetailPaymentModal from './components/DetailPaymentModal';

const useStyles = makeStyles(theme => ({
  payButton: {
    width: 32,
    height: 32,
    padding: 5,
    '&:hover': {
      color: theme.palette.primary.main
    }
  },
  payDiscount: {
    margin: 0,
    color: 'green',
    fontWeight: 500
  }
}))

const SmallCircularProgress = () => (
  <span style={{ fontSize: 18 }}>
    <CircularProgress size={18} />
  </span>
)

const Action = ({
  tooltip,
  className,
  value,
  onClick,
  icon: Icon,
  id,
  dataTur,
  loading
}) => {
  return (
    <Tooltip title={tooltip}>
      <span>
        <IconButton
          size='small'
          data-tut={
            Array.isArray(value) ? `${dataTur}-actions` : `${dataTur}-action`
          }
          className={className}
          id={id}
          disabled={
            (!Array.isArray(value) && value.tableData.checked) || loading
          }
          onClick={ev => onClick(ev, value, id)}
          aria-label='action'
        >
          {loading ? <SmallCircularProgress /> : <Icon />}
        </IconButton>
      </span>
    </Tooltip>
  )
}

const defaultReceipt = { open: false, correctvalue: 0 }

const Pay = () => {
  const classes = useStyles()
  const tableRef = useRef()
  const dispatch = useDispatch()
  const theme = useTheme()
  const { t } = useTranslation()

  const [items, setItems] = useState([])
  const [openModal, setOpenModal] = useState(false)
  const [openModalReceipt, setOpenModalReceipt] = useState(defaultReceipt)
  const [search, setSearch] = useState('')
  const [openDetails, setOpenDetails] = useState(false)
  const [selectedItem, setSelectedItem] = useState({})
  const [loadingActions, seLoadingActions] = useState([])
  const setSelectedMatricula = useSetRecoilState(selectedMatriculaState);
  const [isFirst, setIsFirst] = useState(true)

  const [state, setState] = useState({
    rowsPerPage: 10,
    page: 0
  })

  const isTourOpen = useSelector(isTourEnabledSelector)
  const appid = useSelector(appIdSelector)
  const accessId = useSelector(accessIdSelector)
  const cpfCnpj = useSelector(cpfCnpjSelector)
  const data = useSelector(openedDataSelector)
  const isLoadingData = useSelector(isLoadingOpenedSelector)
  const avatars = useRecoilValue(avatarsState)
  const matriculas = useRecoilValue(matriculasSelector)
  const isLoadingBillet = useSelector(isLoadingBilletSelector)
  const userSelected = useSelector(userSelectedSelector)
  const [isLoadingReceipt, setLoading] = useState(false)
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'))
  const isMedium = useMediaQuery(theme => theme.breakpoints.down('md'))

  const setMessage = message => dispatch(message ? set(message) : hide())

  useEffect(() => {
    if (isFirst) {
      cleanSelection()
      setIsFirst(false)
      if (!isTourOpen) {
        dispatch(apiResetAction(OPENED))
      }
      return
    }
  }, [appid, isTourOpen, data])

  useEffect(() => {
    if (isTourOpen && !isEmpty(data)) {
      return
    }
    dispatch(fetchOpened({ accessId, cpfCnpj }))
    cleanSelection()
  }, [accessId, dispatch, isTourOpen, userSelected])

  const handleRowSelection = (event, rowData) => {
    if (tableRef.current && tableRef.current.unity === 0) {
      tableRef.current.unity = rowData.unityid
      tableRef.current.financialType = rowData.paymentWayId
    } else if (
      rowData.isRowSelectable &&
      tableRef.current?.dataManager?.selectedCount === 1
    ) {
      cleanSelection()
    }
  }

  const cleanSelection = () => {
    if (tableRef && tableRef.current) {
      tableRef.current.unity = 0
      tableRef.current.financialType = undefined
    }
  }

  const closeModal = () => {
    setOpenModal(false)
    dispatch(fetchOpened({ accessId, cpfCnpj }))
    cleanSelection()
  }

  const isHiddenAction = (rowData, isTour, isHeader, check) => {
    if (isTour) {
      return false
    }
    if (Array.isArray(rowData)) {
      return (
        (isHeader && isEmpty(rowData)) || !rowData.some(item => item[check])
      )
    }
    return !rowData[check]
  }

  const isNegotiation = (rowData, isTour) => {
    if (isTour) {
      return false
    }
    if (Array.isArray(rowData)) {
      return rowData.some(
        item => item.paymentWayId && isCardWay(item.paymentWayId)
      )
    }
    return rowData.paymentWayId && isCardWay(rowData.paymentWayId)
  }

  const handlePageChange = (event: MouseEvent<HTMLButtonElement> | null, page: number): void => {
    setState(prevState => ({
      ...prevState,
      page
    }))
  }

  const handleRowsPerPageChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setState(prevState => ({
      ...prevState,
      rowsPerPage: parseInt(event.target.value, 10)
    }))
  }

  const dateLabe = date => {
    const [day, month, year] = date.split('/')
    let monthLabel = monthsEnum[parseInt(month)]
    return `${day} ${t(tokens.common.of)} ${monthLabel} ${t(
      tokens.common.of
    )} ${year}`
  }

  const smColumns = isSmall => [
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      renderHeader: params => <></>,
      minWidth: 70,
      renderCell: params => {
        return (
          <Stack
            direction='row'
          >
            {(isTourOpen || (params.row.billetDemonstrativeGroupDetails && params.row.billetDemonstrativeGroupDetails.itens.length > 0)) &&
              <IconButton
                onClick={() => {
                  setSelectedItem(params.row)
                  setOpenDetails(!openDetails)
                }}
                size='small'
              >
                <Tooltip title={t(tokens.common.clickToSeePaymentDetails) ?? ''}>
                    <Help
                      color={'primary'}
                    />
                </Tooltip>
              </IconButton>
            }
            {GRID_CHECKBOX_SELECTION_COL_DEF.renderCell(params)}
          </Stack>
        )
      }
    },
    {
      field: 'expirationdate',
      headerName: t(tokens.common.monthYear) ?? '',
      headerAlign: 'center',
      flex: 1,
      minWidth: isSmall ? 110 : 150,
      renderCell: params => (
        <DateBanner date={params.row.expirationdate} showMonth />
      )
    },
    {
      field: 'expirationdateFormatted',
      headerName: t(tokens.common.dueDate) ?? '',
      headerAlign: 'center',
      flex: 1,
      minWidth: 130,
      renderCell: params => {
        return (
          <Stack direction='row' alignItems='center' spacing={1}>
            <Tooltip title={dateLabe(params.row.expirationdateFormatted)}>
              <Typography>{params.row.expirationdateFormatted}</Typography>
            </Tooltip>
          </Stack>
        )
      }
    },
    {
      field: 'invoice',
      headerName: t(tokens.common.invoice) ?? '',
      headerAlign: 'center',
      width: 130
    },
    {
      field: 'documentvalueFormatted',
      headerName: t(tokens.common.dueDateValue) ?? '',
      headerAlign: 'center',
      width: 170
    },
    {
      field: 'paymentavailabilityFormatted',
      headerName: t(tokens.common.situation) ?? '',
      headerAlign: 'center',
      width: 140,
      renderCell: params => {
        if (
          !params.row.paymentavailabilityFormatted ||
          !params.row.paymentavailability
        )
          return
        return (
          <Chip
            label={params.row.paymentavailabilityFormatted}
            variant='outlined'
            color={InstallmentStatusType[params.row.paymentavailability]}
            size='small'
            icon={InstallmentStatusIcon[params.row.paymentavailability]}
            sx={{
              width: 'fit-content',
              margin: '0 auto',
              height: 'auto',
              '& .MuiChip-label': {
                display: '-webkit-box',
                '-webkit-line-clamp': 3,
                '-webkit-box-orient': 'vertical',
                maxHeight: '100%',
                whiteSpace: 'normal'
              }
            }}
          />
        )
      }
    }
  ]

  const otherMediumColumns = () => [
    {
      field: 'automaticdebitFormatted',
      headerName: t(tokens.common.automaticDebit) ?? '',
      headerAlign: 'center',
      width: 100
    },
    {
      field: 'studentnameFormatted',
      headerName: t(tokens.common.student) ?? '',
      headerAlign: 'center',
      width: 150,
      renderCell: params => {
        return (
          <Stack
            direction='row'
            justifyContent='flex-start'
            width='100%'
            alignItems='center'
            spacing={1}
          >
            <Avatar
              src={
                avatars[
                  matriculas.find(
                    m => m.dadosAluno.codigoErp === params.row.studentid
                  )?.dadosAluno?.id
                ]
              }
              sx={{ width: 32, height: 32 }}
            />
            <Typography>{params.row.studentnameFormatted}</Typography>
          </Stack>
        )
      }
    }
  ]

  const newActions = (isTour, isHeader) => [
    {
      field: 'actions',
      headerName: t(tokens.common.actions) ?? '',
      headerAlign: 'center',
      width: isSmall ? 90 : 130,
      renderCell: params => {
        const rowData = params.row
        return (
          <Stack
            direction='row'
            width='100%'
            spacing={1}
            sx={
              isSmall
                ? {
                    flexWrap: 'wrap',
                    '& button': {
                      marginLeft: '0 !important'
                    }
                  }
                : {}
            }
          >
            {!isHiddenAction(rowData, isTour, isHeader, 'canPay') && (
              <IconButton
                key={'pay-' + rowData.id}
                onClick={event => onPayClick(event, rowData)}
                className={classes.payButton}
                title={t(tokens.common.pay) ?? ''}
              >
                <CreditCardOutlined />
              </IconButton>
            )}
            {!isHiddenAction(rowData, isTour, isHeader, 'canPrint') &&
              !isNegotiation(rowData, isTour) && (
                <IconButton
                  key={'download-' + rowData.id}
                  onClick={event =>
                    onDownloadBillet(event, rowData, rowData.id)
                  }
                  className={classes.payButton}
                  title={t(tokens.common.generateInvoice) ?? ''}
                >
                  <FontAwesomeIcon icon={faFileInvoiceDollar} />
                </IconButton>
              )}
            {((!isHiddenAction(rowData, isTour, isHeader, 'canPrint') &&
              !isNegotiation(rowData, isTour)) ||
              tableRef.current?.unity > 0) && (
              <IconButton
                key={'copyline-' + rowData.id}
                onClick={event => onCopylineBillet(event, rowData, rowData.id)}
                className={classes.payButton}
                title={t(tokens.common.copyTypeableLine) ?? ''}
              >
                <FontAwesomeIcon icon={faFileInvoice} />
              </IconButton>
            )}
            {((rowData.viewRecept && isTour) || isHeader) && (
              <IconButton
                key={'receipt-' + rowData.id}
                onClick={() => onReceipt(rowData)}
                className={classes.payButton}
                title={t(tokens.common.receipt) ?? ''}
              >
                <FontAwesomeIcon
                  icon={faReceipt}
                  style={{ width: 24, height: 24 }}
                />
              </IconButton>
            )}
          </Stack>
        )
      }
    }
  ]

  const columns = (isTour, isSmall) => {
    return [
      ...smColumns(isSmall),
      ...(isMedium ? [] : otherMediumColumns()),
      ...newActions(isTour, false)
    ]
  }

  const onCopylineBillet = async (event, item, id) => {
    const items = Array.isArray(item) ? item : [item]
    const params = {
      appid,
      journaltransrecids: items.map(i => i.journaltransrecid),
      linhaDigitavel: 'true'
    }

    if (!Array.isArray(item)) {
      seLoadingActions(item.journaltransrecid)
    }

    dispatch(billetRequest(params, cpfCnpj)).then(result => {
      const { fileurl: url, errors, linhadigitavelcopia } = result
      if (linhadigitavelcopia) {
        navigator.clipboard.writeText(linhadigitavelcopia)
        dispatch(set('Linha Digitável Copiada com sucesso!'))
      } else {
        dispatch(set('Erro ao copiar linha digitável!'))
      }
    })
  }

  const onDownloadBillet = async (event, item, id) => {
    const student = matriculas.find(m => m.dadosAluno.codigoErp === item.studentid)
    if(student)
      setSelectedMatricula(student)
    const items = Array.isArray(item) ? item : [item]
    const params = {
      appid,
      journaltransrecids: items.map(i => i.journaltransrecid)
    }

    if (!Array.isArray(item)) {
      seLoadingActions(item.journaltransrecid)
    }

    dispatch(billetRequest(params, cpfCnpj)).then(result => {
      const { fileurl: url, errors } = result
      if (url) {
        const button = document.getElementById(id)
        const listener = () => {
          window.open(url, '_blank', 'noopener')
        }
        if (Array.isArray(item)) {
          dispatch(
            setWithConfig({
              customMessage: () => (
                <FormattedMessage
                  id='opened-payment-billet'
                  values={{ count: item.length }}
                />
              ),
              autoHideDuration: null,
              action: SnackBillet
            })
          )
        }

        button.addEventListener('click', listener, false)
        button.dispatchEvent(new CustomEvent('click'))
        button.removeEventListener('click', listener, false)
        seLoadingActions([])
      } else if (!isEmpty(errors)) {
        dispatch(set(errors[0].message))
      }
    })
  }

  const onPayClick = async (event, items) => {
    const student = matriculas.find(m => m.dadosAluno.codigoErp === Array.isArray(items) ? items[0].studentid : items.studentid)
    if(student)
      setSelectedMatricula(student)
    dispatch(
      apiAnalyticsValue(PAYMENT, Array.isArray(items) ? items.length : 1)
    )
    setItems(Array.isArray(items) ? items : [items])
    setOpenModal(true)
  }

  const onReceipt = async (event, item) => {
    const student = matriculas.find(m => m.dadosAluno.codigoErp === item.studentid)
    if(student)
      setSelectedMatricula(student)
    seLoadingActions(item.journaltransrecid)
    setLoading(true)

    const params = {
      appId: appid,
      referenceId: item.referenceid,
      cpfCnpj: cpfCnpj,
      receiptNumber: item.receipt_number
    }

    dispatch(receiptPayment(params))
      .then(() => {
        setOpenModalReceipt({ open: true, correctvalue: item.correctvalue })
        setLoading(false)
      })
      .catch(() => {
        dispatch(set(ERROR_UNEXPECTED))
        setLoading(false)
      })
  }

  const isNotSameUnity = rowData => {
    return (
      tableRef.current &&
      tableRef.current?.unity > 0 &&
      rowData.unityid !== tableRef.current?.unity
    )
  }

  const isNotSameAgreement = rowData => {
    if (tableRef.current) {
      return (
        (tableRef.current?.financialType &&
          rowData.paymentWayId !== tableRef.current.financialType) ||
        (!tableRef.current?.financialType &&
          rowData.paymentWayId &&
          tableRef.current?.unity > 0)
      )
    }
    return false
  }

  const rowsFiltered =
    data && data.length > 0
      ? data
          .map((item, index) => ({
            id: index,
            isRowSelectable: item.canPay || isNotSameUnity(item) || isNotSameAgreement(item),
            ...item
          }))
          .filter(item => {
            return (
              item.studentnameFormatted
                .toLowerCase()
                .includes(search.toLowerCase()) ||
              item.invoice.toLowerCase().includes(search.toLowerCase()) ||
              item.expirationdateFormatted
                .toLowerCase()
                .includes(search.toLowerCase()) ||
              item.documentvalueFormatted
                .toLowerCase()
                .includes(search.toLowerCase())
            )
          })
      : []

  const initialSorting = [
    {
      field: 'expirationdate',
      sort: 'asc'
    }
  ]

  return (
    <Box
      sx={{
        marginTop: theme.spacing(3)
      }}
    >
      <PaymentDialog
        open={openModal}
        items={items}
        onError={setMessage}
        onClose={closeModal}
      />
      <ReceiptDialog
        {...openModalReceipt}
        onClose={() => setOpenModalReceipt(defaultReceipt)}
      />
      <DetailPaymentModal
        open={openDetails}
        onClose={() => setOpenDetails(!openDetails)}
        item={selectedItem || {}}
      />
      <Card>
        <CardContent sx={isMedium ? { padding: 0 } : {}}>
          <Grid
            container
            sx={{
              paddingTop: 4,
              paddingLeft: 3,
              paddingRight: 3,
              paddingBottom: 2
            }}
            spacing={2}
          >
            <Grid item xs={12} sm={8}>
              <Typography component='h5' variant='h5'>
                {t(tokens.nav.financialOpenQuotas)}
              </Typography>
              <Tooltip title={t(tokens.common.overdueInstallments)}>
                <Typography
                  component={'h6'}
                  variant={'h6'}
                  color={'textSecondary'}
                >
                  {t(tokens.common.overdueInstallments)}
                </Typography>
              </Tooltip>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Stack alignItems='center' direction='row' spacing={1}>
                <OutlinedInput
                  fullWidth
                  placeholder={t(tokens.common.search) ?? ''}
                  defaultValue={search}
                  onChange={e => setSearch(e.target.value)}
                  autoComplete='off'
                  startAdornment={
                    <SvgIcon>
                      <GridSearchIcon />
                    </SvgIcon>
                  }
                />
              </Stack>
            </Grid>
          </Grid>
          <Divider
            variant='middle'
            sx={{
              borderBottomWidth: 2,
              borderColor: '#c6bbbb !important'
            }}
          />
          <ListContainer>
            <ListTable
              ref={tableRef}
              columns={columns(isTourOpen, isSmall)}
              initialSorting={initialSorting}
              loading={isLoadingData || isLoadingBillet || isLoadingReceipt}
              count={rowsFiltered?.length ?? 0}
              page={state.page}
              rowsPerPage={state.rowsPerPage}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleRowsPerPageChange}
              disableColumnMenu
              items={rowsFiltered ?? []}
              handleRowSelection={handleRowSelection}
            />
          </ListContainer>
        </CardContent>
      </Card>
    </Box>
  )
}

export default Pay
