import React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import {useTheme} from "@mui/material/styles";

const DescontoMetodoFlag: React.FC<{valor: number; melhor?: boolean}> = ({valor, melhor}) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        right: 0,
        width: '154px',
        [theme.breakpoints.down('sm')]: {
          width: '104px',
        }
      }}
    >
      <Box
        sx={{
          position: 'relative',
          width: '100%'
        }}
      >
        <Box
          component="img"
          src={melhor ? "img/melhorDesconto.png" : "img/desconto.png"}
          sx={{
            position: 'absolute',
            top: 0,
            right: -3,
            width: '164px',
            height: '52px',
            zIndex: 1,
            [theme.breakpoints.down('sm')]: {
              width: '114px',
            }
          }}
        />
        <Stack
          direction='column'
          justifyContent='center'
          spacing={0}
          sx={{
            height: '52px',
          }}
        >
          <Typography
            fontWeight="bold"
            fontSize="1rem"
            sx={{
              textAlign: 'center',
              position: 'relative',
              zIndex: 2,
              width: '100%',
              p: 0,
              [theme.breakpoints.down('sm')]: {
                fontSize: '0.8rem',
              }
            }}
          >
            {(valor > 0 && valor.toString() + "% de desconto")}
          </Typography>
          {melhor && (
            <Typography
              fontSize="0.8rem"
              sx={{
                textAlign: 'center',
                position: 'relative',
                zIndex: 2,
                width: '100%',
                p: 0,
                [theme.breakpoints.down('sm')]: {
                  display: 'none',
                }
              }}
            >
              Melhor custo benefício
            </Typography>
          )}
        </Stack>
      </Box>
    </Box>
  )
}

export default DescontoMetodoFlag;
