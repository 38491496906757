import React, {useEffect, useState} from 'react';
import {useLocation} from "react-router-dom";
import AlertCard from "../../components/AlertCard/AlertCard";
import StepCheckoutActionButtons from "../../components/StepCheckoutActionButtons/StepCheckoutActionButtons";
import env from "../../env";
import {Skeleton} from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import Stack from "@mui/material/Stack";
import fetch from "../../utils/fetch";
import TextField from "@mui/material/TextField";
import {Layout as MomentoEscolarLayout} from "../../layouts/momento-escolar";
import {useRecoilValue, useResetRecoilState, useSetRecoilState} from "recoil";
import {selectedMatriculaState} from "../../recoil/atoms/selectedMatriculaState";
import {Theme} from "@mui/material/styles/createTheme";
import {parcelasSelecionadasState} from "../../recoil/atoms/parcelasSelecionadasState";
import IsPreReservaSemValorSelector from "../../recoil/selectors/isPreReservaSemValorSelector";
import * as _calcService from "../../service/calc-service";
import {selectedUnidadeState} from "../../recoil/atoms/selectedUnidadeState";
import {calcListServicesState} from "../../recoil/atoms/calcListServicesState";

const MomentoEscolar: React.FC = () => {
  const location = useLocation();
  const smUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));
  const matricula = useRecoilValue(selectedMatriculaState);
  const setSelectedMatricula = useSetRecoilState(selectedMatriculaState);
  const resetParcelasSelecionadas = useResetRecoilState(parcelasSelecionadasState);
  const [periodos, setPeriodos] = useState<Array<{valor: string; label: string}>>([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState("");
  const isReservaSemValor = useRecoilValue(IsPreReservaSemValorSelector);
  const seletedUnidade = useRecoilValue(selectedUnidadeState);
  const resetCalcList = useResetRecoilState(calcListServicesState);

  useEffect(() => {
    GetDadosMatricula();
    resetParcelasSelecionadas();
    resetCalcList();
  }, []);

  const GetByIdMatricula = async (idMatricula: string) => {
    if (matricula && matricula?.id === idMatricula) {
      localStorage.setItem('@SMO20/nomeLandingPage', String(matricula.nomeLandingPage));
      localStorage.setItem("@SMO20/nomeAluno", String(matricula.estudante_Name));
      return;
    }

    fetch(env.apiUrl + "api/Matricula/" + idMatricula, {
      method: "GET",
      mode: "cors",
    })
      .then(res => res.json())
      .then(
        (result) => {
          setSelectedMatricula(matricula);
          localStorage.setItem('@SMO20/nomeLandingPage', result.nomeLandingPage);
          localStorage.setItem("@SMO20/nomeAluno", result.estudante_Name);
        },
        (error) => {
          setIsLoaded(true);
          setError("Ocorreu um erro contate o administrador.");
          console.log(error);
        }
      )
      .catch(error => {
        console.log(error.message);
      });
  }

  const GetConjuntoOpcao = async () => {
    fetch(env.apiUrl + "api/ConjuntoOpcao/Get/ptr_cursodaunidade/seb_periodo", {
      method: "GET",
      mode: "cors",
    })
      .then(res => res.json())
      .then(
        (result) => {
          const idMatricula = matricula?.id
          if (isReservaSemValor && idMatricula && matricula?.servico_Id && matricula.id) {
            _calcService.listar({
              servicoId: matricula.servico_Id,
              matriculaId: matricula.id,
              saldoPago: 0
            }).then(() => setIsLoaded(true))
          } else {
            setIsLoaded(true);
          }
          setPeriodos(result);
        },
        (error) => {
          setIsLoaded(true);
          setError("Ocorreu um erro contate o administrador.");
          console.log(error);
        }
      )
      .catch(error => {
        console.log(error.message);
      });
  }

  const GetDadosMatricula = async () => {
    const query = new URLSearchParams(location.search);
    const idMatricula = query.get("idmatricula");

    if (!idMatricula) {
      setError("Matricula não localizada, ocorreu um erro contate o administrador.")
    } else {
      GetByIdMatricula(idMatricula);
      GetConjuntoOpcao();
    }
  }

  if (error)
    return <Box>Erro: {error}</Box>;

  return (
    <MomentoEscolarLayout
      title={`Esse é o momento escolar do(a) ${matricula.estudante_Name ?? ""}`}
      subtitle={`Confirme o ano letivo e a unidade e selecione o período`}
      breads={[
        {to: "/CadastroConcluido", text: "Home"},
        {text: "Momento Escolar"},
      ]}
      actions={
        <StepCheckoutActionButtons
          nextStepPath={isReservaSemValor ? "/MomentoEscolarPagamento" : "/MomentoEscolarServicos"}
          nextStepDisabled={!isLoaded}
          showCart
        />
      }
      fullScreenPaper
    >
      <Box>
        <Stack
          sx={{
            pb: 3
          }}
        >
          {!isLoaded ? <Skeleton variant="rounded" width="100%" height={55}/> : (
            <TextField
              id="unidade"
              fullWidth
              label={`Série em ${matricula.anoLetivo_Name}`}
              value={matricula.curso_Name + ', ' + matricula.segmento_Name}
              disabled
            />
          )}
        </Stack>

        <Stack
          spacing={2}
          direction={{md: 'row', sm: 'column'}}
          sx={{
            pb: 3
          }}
        >
          {!isLoaded ? <Skeleton variant="rounded" width="100%" height={55}/> : (
            <TextField
              id="unidade"
              fullWidth
              label="Unidade"
              value={matricula.unidade_Name}
              disabled
            />
          )}

          {!isLoaded ? <Skeleton variant="rounded" width="100%" height={55}/> : (
            <TextField
              id="Período"
              fullWidth
              label="Período"
              value={periodos.find(p => String(p.valor) === String(matricula.periodo_Value))?.label}
              disabled
              sx={{
                maxWidth: {md: 300, sm: '100%'},
                mt: smUp ? 0 : 3
              }}
            />
          )}
        </Stack>
      </Box>

      <Box
        sx={{
          mt: 1,
        }}
      >
        {!isLoaded ? <Skeleton variant="rounded" width="100%" height={100}/> : (
          <AlertCard>
            Se for necessário trocar o turno ou curso antes de se matricular, é necessário que entre
            em contato com a secretaria escolar antes de finalizar a matrícula.
            <Stack
              direction="row"
              spacing={2}
              alignItems="center"
              justifyContent="flex-end"
              sx={{mt: 2}}
            >
              <span>
                <svg width={24} height={24} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                     fill="currentColor"><title>phone</title><path
                  d="M6.62,10.79C8.06,13.62 10.38,15.94 13.21,17.38L15.41,15.18C15.69,14.9 16.08,14.82 16.43,14.93C17.55,15.3 18.75,15.5 20,15.5A1,1 0 0,1 21,16.5V20A1,1 0 0,1 20,21A17,17 0 0,1 3,4A1,1 0 0,1 4,3H7.5A1,1 0 0,1 8.5,4C8.5,5.25 8.7,6.45 9.07,7.57C9.18,7.92 9.1,8.31 8.82,8.59L6.62,10.79Z"/></svg>
              </span>
              <Typography variant="h5">
                {seletedUnidade?.telefone}
              </Typography>
            </Stack>
          </AlertCard>
        )}
      </Box>
    </MomentoEscolarLayout>
  );
}

export default MomentoEscolar
