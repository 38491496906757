import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory, withRouter} from 'react-router-dom'
import {isLoadingAuthenticationSelector, tryAgainButtonSelector} from './selectors/authentication'
import {fetchLoginRedirectSMO} from './actions'
import {getParamsRouter} from '../../utils/utils'
import {KEY_LOGIN_EXTERNAL} from './constants'
import LogoLoading from './componentes/LoadingLogin'
import {PATH_ROUTE} from '../../routes'
import {showAppBar} from "../../layouts/actions";
import {appBarShowSelector} from "../../layouts/selectors/appBar";

const Login = ({history, match: {params: {id}}}) => {
  const dispatch = useDispatch()
  const tryAgain = useSelector(tryAgainButtonSelector)
  const path = getParamsRouter('path', history.location)
  const loading = useSelector(isLoadingAuthenticationSelector)
  const isShowAppBar = useSelector(appBarShowSelector);
  const h = useHistory();

  const [firstAuth, setFirstAuth] = useState(true)

  useEffect(() => {
    if (firstAuth || tryAgain) {
      setFirstAuth(false)
      dispatch(showAppBar())
      if (history.location.pathname.includes(PATH_ROUTE.LOGIN_EXTERNALSMO)) {
        const value = getParamsRouter(KEY_LOGIN_EXTERNAL, history.location)
        dispatch(fetchLoginRedirectSMO(value, history, path, isShowAppBar))
      }
    }
  }, [firstAuth, setFirstAuth, dispatch, history, tryAgain, id, path])

  return (
    <LogoLoading isLoading={loading}/>
  )
}

export default withRouter(Login)
