import {selector} from "recoil";
import parcelasUpSellingSelecionadasSelector from "./parcelasUpSellingSelecionadasSelector";
import selectedCalcListServiceSelector from "../../selectedCalcListServiceSelector";

const servicosUpSellingSelecionadosSelector = selector({
  key: 'servicosUpSellingSelecionadosSelector',
  get: ({get}) => {
    let servicos = get(selectedCalcListServiceSelector);
    let parcelas = get(parcelasUpSellingSelecionadasSelector);
    let list = (servicos?.servicosUpSelling || []).filter(s => parcelas[s.id] !== undefined);
    list = [
      ...list,
      ...(servicos?.servicoPrincipal?.servicosRelacionados || []).filter(s => parcelas[s.id] !== undefined)
    ]
    return list;
  },
});

export default servicosUpSellingSelecionadosSelector;
