import {IServico, ParcelaEntrada, ParcelaMensal} from "../@types/IServicoDto";
import {getRecoil, setRecoil} from "recoil-nexus";
import {parcelasSelecionadasState} from "../recoil/atoms/parcelasSelecionadasState";
import {calcListServicesState} from "../recoil/atoms/calcListServicesState";

const getServicoTipo = (servico: IServico) => {
  const services = getRecoil(calcListServicesState);

  if (services?.find(service => service?.servicoPrincipal?.id === servico.id)) {
    return "servicoPrincipal";
  } else if (services?.find(service => service?.servicosObrigatorios?.find(servicoObrigatorio => servicoObrigatorio.id === servico.id))) {
    return "servicosObrigatorio";
  } else if (services?.find(service => service?.servicosUpSelling?.find(servicoUpSelling => servicoUpSelling.id === servico.id))) {
    return "servicosUpSelling";
  } else if (services?.find(service => service?.servicoPrincipal?.servicosRelacionados?.find(servicoRelacionado => servicoRelacionado.id === servico.id))) {
    return "servicosUpSelling";
  } else if (services?.find(service => service?.servicosObrigatorios?.find(servicoObrigatorio => servicoObrigatorio.servicosRelacionados?.find(servicoRelacionado => servicoRelacionado.id === servico.id)))) {
    return "servicosUpSelling";
  } else if (services?.find(service => service?.servicosUpSelling?.find(servicoUpSelling => servicoUpSelling.servicosRelacionados?.find(servicoRelacionado => servicoRelacionado.id === servico.id)))) {
    return "servicosUpSelling";
  }
  return "other";
}

const carrinho = {
  addParcela: (servico?: IServico, parcela?: ParcelaMensal | null) => {
    if (servico) {
      const parcelasSelecionadas = getRecoil(parcelasSelecionadasState);
      const {[servico?.id]: _, ...rest} = parcelasSelecionadas[getServicoTipo(servico)];
      const parcelas = {
        ...rest,
        [servico?.id]: parcela
      }
      setRecoil(parcelasSelecionadasState, (currVal) => ({
        ...currVal,
        [getServicoTipo(servico)]: parcelas
      }))

      if (servico?.servicosRelacionados) {
        servico.servicosRelacionados.forEach(servicoRelacionado => {
          const parcelaRelacionada = servicoRelacionado.mensais?.find(p => p.id === parcela?.id);
          carrinho.addParcela(servicoRelacionado, parcelaRelacionada)
        })
      }
    }
  },
  addParcelaEntrada: (servico?: IServico, parcela?: ParcelaMensal, entrada?: ParcelaEntrada | null) => {
    if (servico && parcela) {
      const parcelasSelecionadas = getRecoil(parcelasSelecionadasState);
      const parcelasOld = parcelasSelecionadas[getServicoTipo(servico)];
      try {
        delete parcelasOld[servico?.id + parcela?.id];
      } catch (e) {
        console.error(e);
      }
      const parcelas = {
        ...parcelasOld,
        [servico?.id + parcela?.id]: entrada
      }
      setRecoil(parcelasSelecionadasState, (currVal) => ({
        ...currVal,
        [getServicoTipo(servico)]: parcelas
      }))

      if (servico?.servicosRelacionados) {
        servico.servicosRelacionados.forEach(servicoRelacionado => {
          const parcelaRelacionada = servicoRelacionado.mensais?.find(p => p.id === parcela?.id);
          const entradaRelacionada = parcelaRelacionada?.entradas?.find(e => e.id === entrada?.id);
          carrinho.addParcelaEntrada(servicoRelacionado, parcelaRelacionada, entradaRelacionada)
        })
      }
    }
  },
  removeParcela: (servico: IServico) => {
    if (servico) {
      const parcelasSelecionadas = getRecoil(parcelasSelecionadasState);
      const {[servico?.id]: _, ...rest} = parcelasSelecionadas[getServicoTipo(servico)];
      setRecoil(parcelasSelecionadasState, (currVal) => ({
        ...currVal,
        [getServicoTipo(servico)]: rest
      }))

      if (servico?.servicosRelacionados) {
        servico.servicosRelacionados.forEach(servicoRelacionado => {
          carrinho.removeParcela(servicoRelacionado)
        })
      }
    }
  },
  removeParcelaEntrada: (servico: IServico, parcela?: ParcelaMensal | null) => {
    if (servico && parcela) {
      const parcelasSelecionadas = getRecoil(parcelasSelecionadasState);
      const {[servico?.id + parcela?.id]: _, ...rest} = parcelasSelecionadas[getServicoTipo(servico)];
      setRecoil(parcelasSelecionadasState, (currVal) => ({
        ...currVal,
        [getServicoTipo(servico)]: rest
      }))

      if (servico?.servicosRelacionados) {
        servico.servicosRelacionados.forEach(servicoRelacionado => {
          const parcelaRelacionada = servicoRelacionado.mensais?.find(p => p.id === parcela?.id);
          carrinho.removeParcelaEntrada(servicoRelacionado, parcelaRelacionada)
        })
      }
    }
  },
}

export default carrinho;
