import * as React from 'react';
import {useCallback, useState} from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {useRecoilValue} from "recoil";
import {calcResumoListState} from "../../recoil/atoms/calcResumoListState";
import {ResumoCobranca} from "../../@types/IServicoResumoDto";
import Typography from "@mui/material/Typography";
import {currency} from "../../utils/utils";
import {useTheme} from "@mui/material/styles";
import {isTabelaResumoLoadingState} from "../../recoil/atoms/isTabelaResumoLoadingState";
import {Button, Skeleton} from "@mui/material";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import Stack from "@mui/material/Stack";
import isPreReservaSelector from "../../recoil/selectors/isPreReservaSelector";
import possuiEntradaSelector from "../../recoil/selectors/possuiEntradaSelector";
const Row: React.FC<{ data: ResumoCobranca, defaultOpen?: boolean, loading: boolean, entrada?: boolean }> = (props) => {
  const [showDetail, setShowDetail] = useState(props.defaultOpen ?? false);
  const theme = useTheme();
  return (
    <>
      <TableRow
        role="button"
        onClick={() => setShowDetail(!showDetail)}
        sx={{
          cursor: 'pointer',
          '&:hover': {
            backgroundColor: theme.palette.primary.lightest
          },
          '&:last-child td, &:last-child th': {
            border: 0
          }
        }}
      >
        <TableCell component="th" scope="row">
          <Stack
            direction="row"
            justifyContent="space-between"
          >
            {showDetail ? <ArrowDropDownIcon/> : <ArrowRightIcon/>}
            {props.loading ? (
              <Skeleton
                variant="text"
              />
            ) : (
              <Typography
                fontWeight="bold"
              >
                {String(props?.data?.numero).padStart(2, '0')}
              </Typography>
            )}
          </Stack>
        </TableCell>
        <TableCell align="right">
          {props.loading ? (
            <Skeleton
              variant="text"
            />
          ) : (
            <Typography
              fontWeight="bold"
            >
              {props?.data?.mesAno ?? ""}
            </Typography>
          )}
        </TableCell>
        <TableCell align="right">
          {props.loading ? (
            <Skeleton
              variant="text"
            />
          ) : (
            <Typography
              fontWeight="bold"
            >
              Valor total da parcela
            </Typography>
          )}
        </TableCell>
        <TableCell align="right">
          {props.loading ? (
            <Skeleton
              variant="text"
            />
          ) : (
            <Typography
              fontWeight="bold"
            >
              {currency(props?.data?.total)}
            </Typography>
          )}
        </TableCell>
      </TableRow>

      {props?.data?.servicos.map((servico, j) => (
        <TableRow
          key={j}
          sx={{
            display: showDetail ? 'table-row' : 'none',
            '&:last-child td, &:last-child th': {
              border: 0
            }
          }}
        >
          <TableCell component="th" scope="row"></TableCell>
          <TableCell align="right"></TableCell>
          <TableCell align="right">
            {props.loading ? (
              <Skeleton
                variant="text"
              />
            ) : (
              <Typography
                fontSize="0.8rem"
              >
                {servico?.tipoServico === 'ServicoRegular'
                  ? `Serviço Regular${props?.entrada ? ' - Entrada' : ''}`
                  : servico?.tipoServico === 'MaterialDidatico'
                    ? `Material Didático${props?.entrada ? ' - Entrada' : ''}`
                    : servico?.nome
                }
              </Typography>
            )}
          </TableCell>
          <TableCell align="right">
            {props.loading ? (
              <Skeleton
                variant="text"
              />
            ) : currency(servico.valor)}
          </TableCell>
        </TableRow>
      ))}
    </>
  )
}


const TabelaResumo: React.FC<{ entrada?: boolean }> = props => {
  const {entrada} = props;
  const resumo = useRecoilValue(calcResumoListState);
  const isPreReserva = useRecoilValue(isPreReservaSelector);
  const possuiEntrada = useRecoilValue(possuiEntradaSelector);
  const rows = useCallback(() => {
    return entrada ?
      resumo?.resumoCobrancas?.filter(s => s.servicos.find(r => r?.tipoParcela === 1) !== undefined)
      : isPreReserva && !possuiEntrada ? resumo?.resumoCobrancas : resumo?.resumoCobrancas?.filter(s => s.servicos.find(r => r?.tipoParcela === 2) !== undefined)
  }, [entrada, resumo, isPreReserva, possuiEntrada])();
  const theme = useTheme();
  const isTabelaResumoLoading = useRecoilValue(isTabelaResumoLoadingState);
  const [showMore, setShowMore] = useState(false);


  return (
    <TableContainer component={Paper}>
      <Table
        sx={{
          minWidth: 650,
          '& .MuiTableRow-head .MuiTableCell-root': {
            backgroundColor: theme.palette.primary.lightest,
            color: 'black'
          }
        }}
      >
        <TableHead>
          <TableRow>
            <TableCell
              sx={{
                backgroundColor: theme.palette.primary.lightest,
                color: "black"
              }}
            >Parcela</TableCell>
            <TableCell align="right">Data</TableCell>
            <TableCell align="right">Descrição</TableCell>
            <TableCell align="right">Valores</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {!isTabelaResumoLoading && resumo == null && (
            <TableRow>
              <TableCell colSpan={4}>
                <Typography
                  align="center"
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: 1
                  }}
                >
                  <img
                    src="/img/empty.svg"
                    alt="Nenhum dado encontrado"
                    width="auto"
                    height={40}
                  />
                  Visualização do resumo de pagamento não disponível
                </Typography>
              </TableCell>
            </TableRow>
          )}
          {isTabelaResumoLoading && resumo == null && (
            <TableRow>
              <TableCell colSpan={4}>
                <Skeleton
                  variant="text"
                />
              </TableCell>
            </TableRow>
          )}
          {(rows || []).slice(0, (rows || []).length > 3 && !showMore ? 3 : (rows || []).length).map((row, i) => (
            <Row
              key={i}
              data={row as ResumoCobranca}
              defaultOpen={i === 0}
              loading={isTabelaResumoLoading}
              entrada={props.entrada}
            />
          ))}
          {(!isTabelaResumoLoading && resumo != null && !showMore && (rows || []).length > 3) && (
            <TableRow>
              <TableCell colSpan={4} sx={{p: 0}}>
                <Button
                  variant="text"
                  onClick={() => setShowMore(true)}
                  fullWidth
                >
                  ver mais...
                </Button>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default TabelaResumo;
