import React from 'react'
import useStyles from '../style'
import LoadingModal from '../../../components/LoadingModal'
import {CssBaseline, Grid} from "@mui/material";

export default (isLoading) => {
  const classes = useStyles()
  return (
    <div className={classes.container} >
      <Grid container justify='center'>
        <div className={classes.paper}>
          <LoadingModal loading={isLoading} />
        </div>
      </Grid>
      <CssBaseline />
    </div>
  )
}
