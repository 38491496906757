import { pipe, prop } from 'ramda'
import { isEmpty } from 'lodash'
import {
  getAPIDataSelector,
  hasErrorAPISelector,
  hasLoadedAPISelector,
  isLoadingAPISelector,
} from '../../../utils/API/selectors'
import { createSelector } from 'reselect'

const baseHomeSelector = prop('home')

export const registrationServicesSelector = pipe(baseHomeSelector, prop('registrationServices'))

export const registrationServicesDataSelector = getAPIDataSelector(registrationServicesSelector)

export const hasLoadedRegistrationServicesSelector = hasLoadedAPISelector(registrationServicesSelector)

export const hasErrorRegistrationServicesSelector = hasErrorAPISelector(registrationServicesSelector)

export const isLoadingRegistrationServicesSelector = isLoadingAPISelector(registrationServicesSelector)

export const isMockRegistrationServices = createSelector(
  hasLoadedRegistrationServicesSelector,
  hasErrorRegistrationServicesSelector,
  registrationServicesDataSelector,
  (hasLoaded, hasError, data) => (hasLoaded && isEmpty(data)) || hasError
)

export const isFetchRegistrationServices = createSelector(
  hasLoadedRegistrationServicesSelector,
  isLoadingRegistrationServicesSelector,
  hasErrorRegistrationServicesSelector,
  registrationServicesDataSelector,
  (hasLoaded, isLoading,  hasError, data) =>  isEmpty(data) && !hasLoaded && !isLoading && !hasError
)