import {selector} from "recoil";
import {razaoStatus} from "../../utils/razaoStatus";
import matriculasSelector from "./matriculasSelector";

const matriculasContratadasSelector = selector({
  key: 'matriculasContratadasSelector', // unique ID (with respect to other atoms/selectors)
  get: ({get}) => {
    const matriculas = get(matriculasSelector);
    const now = new Date();
    const ano = now.getFullYear();
    const nextYear = ano + 1;
    return (matriculas || []).filter(m => {
      return m.razaoStatus_Value === razaoStatus.zero_6_matriculado
        && m.adimplente
        && !m.bloqueioColaborador
        && !m.bloqueioPedagogico
        && !m.bloqueioContratacaoUnidade
        && parseInt(m?.servicoTipoItem ?? "") == 284360000 //Serviço Regular
        && (parseInt(m?.anoLetivo_Name ?? "") === ano || parseInt(m?.anoLetivo_Name ?? "") === nextYear)
    }) ?? [];
  },
});

export default matriculasContratadasSelector

