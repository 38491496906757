import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment'
import numero from 'numero-por-extenso'
import { map, groupBy, values, upperCase } from 'lodash'
import { currency, getSchoolYear } from 'utils/utils'
import { DialogContent, DialogContentText, DialogTitle, Paper, Grid, Checkbox, FormControlLabel, Typography } from '@mui/material'
import { FormattedHTMLMessage, FormattedMessage, injectIntl } from 'react-intl'
import logo from 'assets/img/logo-grupo-seb.svg'
import { financialAgreementDataSelector } from 'modules/Negotiation/Finance/selectors/financialAgreement'
import { companyInformationDataSelector } from 'modules/Negotiation/Finance/selectors/companyInformations'
import { isLoadingPostFinancialSelector } from 'modules/Negotiation/Finance/selectors/postFinancial'
import { isTourEnabledPaymentSelector } from 'modules/Negotiation/Finance/selectors/payment'
import useStyle from './style'
import state from 'utils/enums/states'

export default injectIntl(({ intl, setDisabled, items, total, isBillet, setInfos, origin }) => {
  const classes = useStyle()
  const [accept, setAccept] = useState(false)
  const isLoadingFinance = useSelector(isLoadingPostFinancialSelector)
  const [item,] = items
  const student = groupBy(items, (st) => st.registrationid)
  const financialAgreement = useSelector(financialAgreementDataSelector)
  const [account,] = financialAgreement.listCustTable
  const isEnableTourModal = useSelector(isTourEnabledPaymentSelector)

  const companyInformations = useSelector(companyInformationDataSelector)

  const acceptTerm = () => {
    setAccept(!accept)
    setDisabled(accept)
  }

  const getState = () => {
    if (!account.state) {
      return ''
    }
    return state[upperCase(account.state)] || account.state
  }

  const date = intl.formatDate(moment(), {
    year: 'numeric',
    month: 'long',
    day: '2-digit'
  });

  const dateHour = moment().format('DD/MM/YYYY');
  const weekday = intl.formatDate(moment(), { weekday: 'long' })
  const tipoPagto = isBillet ? "Boleto" : "Cartão de Crédito";
  const mesatual = moment().format('MM');
  const anoatual = moment().format('YYYY');

  if (isBillet) {
    setInfos({
      'responsible': {
        'custName': account.custName,
        'rgNumber': account.rgNumber,
        'cpfCnpj': account.cpfCnpj,
        'custAddress': account.custAddress,
        'cep': account.zipCode,
        'state': getState(),
      },
      'studentname': item.studentname,
      'courses': values(student).map(registration => {
        return {
          courseDescription: registration[0].courseDescription,
          contract: registration[0].registrationid,
          registrationDate: registration[0].registrationDate ? moment(registration[0].registrationDate).format('DD/MM/YYYY') : '',
          year: getSchoolYear(registration[0].schoolyear)
        }
      }),
      'invoices': map(items, (item) => {
        return {
          invoice: item.invoice,
          expirationDate: item.expirationdateFormatted,
          documentValue: item.correctvalueFormatted
        }
      }),
      'amount': currency(total),
      'descriptionAmount': `(${numero.porExtenso(total, numero.estilo.monetario)})`,
      'cardValue': currency(total),
      'cardExpiration': moment().format('DD/MM/YYYY'),
      'billets': isBillet ? map(financialAgreement.list, (item) => {
        return {
          expirationDate: moment(item.dueDate).format('DD/MM/YYYY'),
          value: currency(item.amountCur)
        }
      }) : [],
      'footerDescription': `${weekday}, ${date}`
    })
  }

  return (
    <>
      <Paper className={isBillet ? classes.termAgreementRoot : classes.termAgreementRootWindow} id='term-agreement-negotiation' elevation={2}>
        <DialogTitle id='alert-dialog-title'
          classes={{
            root: classes.termAgreementDialogTitle
          }}>
          <Grid container >
            <Grid item xs={12} md={3} align='center'>
              <img alt='logo' src={isBillet ? logo : `${origin}/${logo}`} id='btn-menu-logo-foot' className={classes.termAgreementLogo} />
            </Grid>
            <Grid item xs={12} md={9} className={classes.termAgreementTitle} >
              <FormattedMessage id='term-agreement-title' />
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent >
          <DialogContentText id='alert-dialog-description'>
            <FormattedHTMLMessage id={'term-agreement1'} values={{
              name: account.custName,
              rg: account.rgNumber,
              cpf: account.cpfCnpj,
              address: account.custAddress,
              cep: account.zipCode,
              state: getState(),
              student: item.studentname,
              nameUnity: companyInformations.companyName,
              cnpjUnity: companyInformations.cnpj,
              adressUnity: companyInformations.address,
              cityUnity: companyInformations.city,
              zipCodeUnity: companyInformations.zipCode
            }} />
            {values(student).map(registration =>
              <FormattedHTMLMessage key={registration[0].registrationid} id='term-agreement-student' values={{
                courseDescription: registration[0].courseDescription,
                contract: registration[0].registrationid,
                registrationDate: registration[0].registrationDate && moment(registration[0].registrationDate).format('DD/MM/YYYY'),
                year: getSchoolYear(registration[0].schoolyear)
              }} />
            )}
            <FormattedHTMLMessage id={'term-agreement2'} />
            {map(items, (item) =>
              <FormattedHTMLMessage key={item.invoice} id='term-agreement-installments' values={{
                invoice: item.invoice,
                expirationDate: item.expirationdateFormatted,
                documentValue: item.documentvalueFormatted,
              }} />
            )}
            <FormattedHTMLMessage id={'term-agreement3'}
              values={{
                amount: currency(total),
                amountDescription: numero.porExtenso(total, numero.estilo.monetario),
                total: currency(total),
                mesatual: mesatual,
                anoatual: anoatual
              }} />
            {isBillet && (
              // <FormattedHTMLMessage id={`term-agreement-type-payment-billet`}
              <FormattedHTMLMessage id="BOLETO:"
                values={{
                  valueCard: currency(total),
                  descriptionAmount: numero.porExtenso(total, numero.estilo.monetario),
                }}
              />
            )}
            {isBillet && map(financialAgreement.list, (item) =>
              <FormattedHTMLMessage key={item.invoice} id='term-agreement-installments-new' values={{
                expirationDate: moment(item.dueDate).format('DD/MM/YYYY'),
                documentValue: currency(item.amountCur)
              }} />
            )}
            <FormattedHTMLMessage id={'term-agreement4'} values={{
              tipoPagto: tipoPagto,
              total: currency(total),
              data: isBillet ? "" : " Data do Pagamento: " + dateHour
            }} />
            <Typography variant='caption' className={classes.termAgreementFinal} >
              <FormattedHTMLMessage id={'term-agreement-final'}
                values={{
                  weekday,
                  date,
                  cityUnity: companyInformations.city && companyInformations.city.split('-')[0].trim(),
                }}
              />
            </Typography>
            <FormattedHTMLMessage id={'term-agreement-sign'} values={{
               name: account.custName,
               nameUnity: companyInformations.companyName,
            }} />
          </DialogContentText>
        </DialogContent>
      </Paper>

      {isBillet && (
        <FormControlLabel
          control={
            <Checkbox
              checked={accept || isEnableTourModal}
              onChange={acceptTerm}
              value='accept'
              color='primary'
              disabled={isLoadingFinance}
              inputProps={{
                'aria-label': 'secondary checkbox',
              }}
            />
          }
          label={<FormattedMessage id='term-agreement-check' />}
        />
      )}
    </>
  )
})
