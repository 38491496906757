import React from 'react';
import {PortalFinanceiroLayout} from "../../layouts/PortalFinanceiroLayout";
import Paid from "../../modules/Finance/Paid";
import Container from "@mui/material/Container";

function HomePortal(props) {
  return (
    <PortalFinanceiroLayout>
      <Container
        sx={{
          overflowY: 'auto'
        }}
      >
        <Paid
          {...props}
        />
      </Container>
    </PortalFinanceiroLayout>
  );
}

export default HomePortal;
