import {atom} from "recoil";
import {persistAtomEffect} from "../effects/persistAtom";
import {IResponsavelResponse} from "../../@types/IResponsavelResponse";

export const responsavelState = atom<IResponsavelResponse|null>({
  key: 'responsavelState',
  default: null,
  effects: [
    persistAtomEffect
  ]
});
