import {selector} from "recoil";
import {selectedMatriculaState} from "../atoms/selectedMatriculaState";
import {razaoStatus} from "../../utils/razaoStatus";

const isPreReservaSelector = selector<boolean>({
  key: 'isPreReservaSelector', // unique ID (with respect to other atoms/selectors)
  get: ({get}) => {
    const selectedMatricula = get(selectedMatriculaState);
    return selectedMatricula.razaoStatus_Value === razaoStatus.ponto_1_preReserva;
  },
});

export default isPreReservaSelector

