import {selector} from "recoil";
import {selectedMatriculaState} from "../../../atoms/selectedMatriculaState";
import {razaoStatus} from "../../../../utils/razaoStatus";

const podeContratarServicoRegularSelector = selector<boolean>({
  key: 'podeContratarServicoRegularSelector', // unique ID (with respect to other atoms/selectors)
  get: ({get}) => {
    const selectedMatricula = get(selectedMatriculaState);
    return selectedMatricula &&
      // @ts-ignore
      selectedMatricula.razaoStatus_Value !== razaoStatus.zero_6_matriculado &&
      // @ts-ignore
      selectedMatricula.razaoStatus_Value !== razaoStatus.ponto_2_reservada;
  },
});

export default podeContratarServicoRegularSelector

