import env from "../env";
import Cookies from 'universal-cookie';

export function signIn(data: { username: string; password: string }): Promise<{
  accessToken: {
    accessToken: string;
    expiresIn: string;
  }
  loginResponse: {
    id: string;
    username: string;
    cadastropendente: boolean;
    primeiroacesso: boolean;
    lastLogon: string;
  }
}> {
  return fetch(env.apiUrl + "api/Login", {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(data)
  }).then(async resp => {
    if (resp.ok) {
      const body = await resp.json()
      const {
        accessToken,
      } = body?.accessToken
      new Cookies().set("accessToken", accessToken, {})
      return body;
    }
    return resp
  })
}

export function refreshToken(): Promise<{
  accessToken: {
    accessToken: string;
    expiresIn: string;
  }
  loginResponse: {
    id: string;
    username: string;
    cadastropendente: boolean;
    primeiroacesso: boolean;
    lastLogon: string;
  }
}> {
  return fetch(env.apiUrl + "api/Login/refresh", {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      accessToken: new Cookies().get("accessToken")
    })
  }).then(async resp => {
    if (resp.ok) {
      const body = await resp.json()
      const {
        accessToken,
      } = body?.accessToken
      new Cookies().set("accessToken", accessToken, {})
      return body;
    }
    return resp
  })
}
