import {selector, useSetRecoilState} from "recoil";
import IModeloContratoResponse from "../../../../@types/IModeloContratoResponse";
import { currency } from "../../../../utils/utils";
import { contratosState } from "../../../atoms/contratosState";
import { responsavelState } from "../../../atoms/responsavelState";
import parcelasObrigatoriosSelecionadasSelector from "../servicosObrigatorios/parcelasObrigatoriosSelecionadasSelector";
import servicosObrigatoriosSelector from "../servicosObrigatorios/servicosObrigatoriosSelector";
import parcelaEntradaRegularSelecionadaSelector from "./parcelaEntradaRegularSelecionadaSelector";
import parcelaRegularSelecionadaSelector from "./parcelaRegularSelecionadaSelector";
import servicoRegularSelector from "./servicoRegularSelector";
import {selectedMeioPagamentoEntradaState} from "../../../atoms/selectedMeioPagamentoEntradaState";
import {selectedMeioPagamentoParcelamentoState} from "../../../atoms/selectedMeioPagamentoParcelamentoState";
import {meioPagamentoNome} from "../../../../utils/tipoPagamento";

const contratoServicoRegularSelector = selector<IModeloContratoResponse>({
  key: 'contratoServicoRegularSelector',
  get: ({get}) => {
    const responsavel = get(responsavelState);
    const servicoRegular = get(servicoRegularSelector);
    const parcelaRegular = get(parcelaRegularSelecionadaSelector);
    const parcelaEntradaRegular = get(parcelaEntradaRegularSelecionadaSelector);
    const servicosObrigatorios = get(servicosObrigatoriosSelector);
    const parcelasObrigatoriosSelecionadas = get(parcelasObrigatoriosSelecionadasSelector);
    const contractos = get(contratosState);
    const contrato = JSON.parse(JSON.stringify(contractos[servicoRegular?.id || ""] ?? {})) as IModeloContratoResponse;
    const selectedMeioPagamentoEntrada = get(selectedMeioPagamentoEntradaState);
    const selectedMeioPagamentoParcelamento = get(selectedMeioPagamentoParcelamentoState);

    let descricaoEntrada = "";
    let descricaoServicos = "";
    let meioPagamentoEntrada = "";
    let meioPagamentoParcelamento = "";

    if (parcelaEntradaRegular) {
      descricaoEntrada = `entrada, valor total: R$ ${currency(parcelaEntradaRegular.valor)} em ${parcelaEntradaRegular.totalParcela}x mais`;
    }

    if (selectedMeioPagamentoEntrada) {
      meioPagamentoEntrada = meioPagamentoNome[selectedMeioPagamentoEntrada];
    }

    if (selectedMeioPagamentoParcelamento) {
      meioPagamentoParcelamento = meioPagamentoNome[selectedMeioPagamentoParcelamento];
    }

    descricaoServicos = `${responsavel?.nome}, ${servicoRegular?.nome}, Serviço Regular, valor total: ${currency(servicoRegular?.valorBase)} sendo ${descricaoEntrada} ${parcelaRegular?.totalParcela}x de ${currency(parcelaRegular?.valor)}.`

    if (servicosObrigatorios) {
      servicosObrigatorios.forEach(servico => {
        const parcela = parcelasObrigatoriosSelecionadas[servico.id];
        if (parcela) {
          const tipo = servico?.tipo === "MaterialDidatico" ? "Material Didático" : ""
          descricaoServicos += ` ${tipo}, valor total: ${currency(servico.valorContrato)} em ${parcela.totalParcela}x de ${currency(parcela.valor)}.`
        }
      })
    }

    if (contrato?.servicoUnidadeContratoHTML) {
      contrato.servicoUnidadeContratoHTML = contrato.servicoUnidadeContratoHTML
        ?.replaceAll("#DescricaoServicosMatricula", descricaoServicos)
        ?.replaceAll("#NomeResponsavelContrato", responsavel?.nome || "")
        ?.replaceAll("#MeioPagamentoAdiantamento", meioPagamentoEntrada || "")
        ?.replaceAll("#FormaPagamentoPrimeiraParcela", meioPagamentoEntrada || "")
        ?.replaceAll("#MeioPagamentoRecorrencia", meioPagamentoParcelamento || "")
        ?.replaceAll("#FormaPagamentoDemaisParcelas", meioPagamentoParcelamento || "")
    }

    return contrato;
  },
});

export default contratoServicoRegularSelector;
