import {toast} from "react-toastify";
import Cookies from 'universal-cookie';
import {setRecoil} from "recoil-nexus";
import {RecoilState} from "recoil";

const reset = () => {
  const cookies = new Cookies();
  cookies.remove('accessToken');
  toast.error('Sua sessão expirou, faça login novamente.', {autoClose: 5000});
  window.location.href = "/";
}

interface Config extends RequestInit {
  atom?: RecoilState<any>;
}

const fetch = async (url: RequestInfo | URL, conf?: Config): Promise<Response> => {
  // @ts-ignore
  const {atom, ...config} = conf ?? {};
  const {
    headers = []
  } = config || {}
  const cookies = new Cookies();
  const token = cookies.get('accessToken');

  const response = await window.fetch(url, {
    mode: "cors",
    ...config,
    headers: {
      ...headers,
      authorization: `Bearer ${token}`,
    }
  });
  if (response.status === 401) {
    reset();
  }

  if (atom && response.ok) {
    const clone = response.clone();
    const json = await clone.json();
    setRecoil(atom, json);
  }
  return response;
}

export default fetch;
