const csatList = {
  trocaDeTurma: "trocaDeTurma@v1",
  trocaDeCurso: "trocaDeCurso@v1",
  trocaResponsavelFinanceiro: "trocaResponsavelFinanceiro@v1",
  trocaPlanoPagamento: "trocaPlanoPagamento@v1",
  cancelamento: "cancelamento@v1",
  bolsas: "bolsas@v1",
  trocaMetodoPagamento: "trocaMetodoPagamento@v1",
  trocaResponsavelPedagogico: "trocaResponsavelPedagogico@v1",
  atualizarContrato: "atualizarContrato@v1",
  acordo: "acordo@v1",
}

export default csatList
