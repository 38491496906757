import { pipe, prop } from 'ramda'
import { isEmpty } from 'lodash'

import {
  getAPIDataSelector,
  hasErrorAPISelector,
  hasLoadedAPISelector,
  isLoadingAPISelector,
} from '../../../../utils/API/selectors'
import { createSelector } from 'reselect'

const baseFinanceSelector = prop('finance')

export const openedSelector = pipe(baseFinanceSelector, prop('opened'))

export const openedDataSelector = getAPIDataSelector(openedSelector)

export const hasLoadedOpenedSelector = hasLoadedAPISelector(openedSelector)

export const hasErrorOpenedSelector = hasErrorAPISelector(openedSelector)

export const isLoadingOpenedSelector = isLoadingAPISelector(openedSelector)

export const isMockInstallment = createSelector(
  openedDataSelector,
  hasErrorOpenedSelector,
  (data, hasError) => {
    return hasError || isEmpty(data)
  }
)

export const isFetchInstallment = createSelector(
  isLoadingOpenedSelector,
  hasErrorOpenedSelector,
  openedDataSelector,
  (isLoading,  hasError, data) => {
    return isEmpty(data) && !isLoading && !hasError
  }
)
