import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { currency } from 'utils/utils'
import { isEmpty } from 'lodash'

import useStyles from './style'
import { financialAgreementDataSelector, isLoadingFinancialAgreementSelector } from 'modules/Negotiation/Finance/selectors/financialAgreement'
import { FormattedMessage, injectIntl } from 'react-intl'
import Message from '../message'
import Loading from 'components/loading'
import Typography from "@mui/material/Typography";
import {Grid} from "@mui/material";
import Box from "@mui/material/Box";

export default injectIntl((props) => {
  const classes = useStyles()
  const {  intl: { messages } ,setDisabled, items } = props
  const financialAgreement = useSelector(financialAgreementDataSelector)
  const isLoadingFinancialAgreement = useSelector(isLoadingFinancialAgreementSelector)
  const numberInstallment = items.length >= 4 ? 10 : (items.length * 2)

  const total = financialAgreement && financialAgreement.list && financialAgreement.list.reduce((prev, curr) => prev + Number(curr.amountCur), 0)

  useEffect(() => {
    setDisabled(false)
  })

  if(isLoadingFinancialAgreement) {
    return <Loading message={messages['finance-negotiation-comp-installment-form-loaging']} />
  }

  if(!financialAgreement || isEmpty(financialAgreement.financialAgreementId)  || total === 0) {
    setDisabled(true)
    return <Message title={messages['finance-negotiation-comp-installment-form-title-error']} message={messages['finance-negotiation-comp-installment-form-error']} />
  }

  return (
    <React.Fragment>
      <Grid container className={classes.billetFormContainer} alignContent='flex-start' >
        <Grid item xs={12} className={classes.billetFormItem} md={12} >
          <Typography className={classes.formAmount}>
            <FormattedMessage id='intallment-form-negotiation-proposal' />
          </Typography>
          <Typography className={classes.formValueAmount}>
            {currency(total)}
          </Typography>
        </Grid>
        {financialAgreement.list && financialAgreement.list.length > 0 && <Grid item xs={12} className={classes.billetFormItem} >
          <Box classes={{
            root: classes.root
          }}>
            <FormattedMessage id='installment-form-negotiation' values={{ instalmments: numberInstallment }} />
          </Box>
        </Grid>}
      </Grid>
    </React.Fragment>
  )
})
