import React, {useMemo} from "react";
import SearchInput from "../SearchInput/SearchInput";
import classNames from "../../utils/classNames";
import CardSelection from "./CardSelection";
import includesSearch from "../../utils/includesSearch";
import {useRecoilState, useRecoilValue} from "recoil";
import {walletModalLayoutState} from "../../recoil/atoms/walletModalLayoutState";
import {creditCardsState} from "../../recoil/atoms/creditCardsState";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";

function WalletCardsList(
  {
    hidden = false,
    onClickAddCard = () => {
    },
    onClickSelectCard = (card) => {
    },
    selectedCreditCard,
    hiddenAddCard = false,
    showRemove
  }
) {
  const creditCards = useRecoilValue(creditCardsState);
  const [walletLayout, setWalletLayout] = useRecoilState(walletModalLayoutState);
  const [search, setSearch] = React.useState('');
  const filteredCreditCards = useMemo(() => creditCards.filter(card => {
    return includesSearch(JSON.stringify(card), search);
  }), [creditCards, search]);

  const handleSearch = (value) => {
    setSearch(value);
  }

  const handleClickAddCard = () => {
    if (onClickAddCard) onClickAddCard();
  }

  const handleClickSelectCard = card => {
    if (onClickSelectCard) onClickSelectCard(card);
  }

  return (
    <div style={{display: hidden ? "none" : "block"}}>
      <Stack
        className="smo-wallet-modal-header"
        direction="row"
        spacing={2}
      >
        {!hiddenAddCard && (
          <Button
            variant="contained"
            onClick={handleClickAddCard}
            type="button"
            fullWidth
            sx={{
              width: '100%',
              maxWidth: '200px',
            }}
          >
            Adicionar novo cartão
          </Button>
        )}

        <SearchInput
          onSearch={handleSearch}
        />
      </Stack>

      <div
        className={classNames(
          "smo-wallet-modal-body",
          walletLayout === 'row' && 'smo-wallet-modal-body-row',
        )}
      >
        {(creditCards.length > 0) && (
          <React.Fragment>
            {filteredCreditCards.map(card => (
              <CardSelection
                key={card.cardId}
                card={card}
                onClick={onClickSelectCard ? handleClickSelectCard : null}
                type={walletLayout}
                selected={selectedCreditCard && selectedCreditCard.cardId === card.cardId}
                showButton={!(selectedCreditCard && selectedCreditCard.cardId === card.cardId)}
                showRemove={showRemove}
                buttonLabel={selectedCreditCard && selectedCreditCard.cardId === card.cardId ? 'Selecionado' : 'Selecionar'}
              />
            ))}
          </React.Fragment>
        )}
      </div>
    </div>
  )
}

export default WalletCardsList;
