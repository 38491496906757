import {atom} from "recoil";
import {ParcelaEntrada, ParcelaMensal} from "../../@types/IServicoDto";
import {persistAtomEffect} from "../effects/persistAtom";

type Mensal = {[servicoId: string]: ParcelaMensal | ParcelaEntrada | null | undefined};

export const parcelasSelecionadasState = atom<{
  servicoPrincipal: Mensal,
  servicosObrigatorio: Mensal,
  servicosUpSelling: Mensal,
}>({
  key: 'parcelasSelecionadasState',
  default: {
    servicoPrincipal: {},
    servicosObrigatorio: {},
    servicosUpSelling: {},
  },
  effects: [
    persistAtomEffect,
    ({onSet}) => onSet(console.log)
  ]
});
