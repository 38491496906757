import {useRecoilState, useRecoilValue, useSetRecoilState} from "recoil";
import isSessionExpiredSelector from "../recoil/selectors/isSessionExpiredSelector";
import Cookies from 'universal-cookie';
import * as _authService from "../service/auth-service";
import {inicializaUsuarioDD} from "../utils/inicializaUsuarioDD";
import env from "../env";
import fetch from "../utils/fetch";
import {IResponsavelResponse} from "../@types/IResponsavelResponse";
import {setRecoil} from "recoil-nexus";
import {loadedCreditCardsState} from "../recoil/atoms/loadedCreditCardsState";
import {matriculasByResponsavelResponseState} from "../recoil/atoms/matriculasByResponsavelResponseState";
import {portalAuthState} from "../recoil/atoms/portalAuthState";
import {PATH_ROUTE} from "../routes";
import {homeState} from "../recoil/atoms/homeState";
import {responsavelState} from "../recoil/atoms/responsavelState";
import {useHistory} from "react-router-dom";
import {logout as portalLogout} from "../modules/Login/actions";
import {useDispatch} from "react-redux";
import * as _matricula from "../service/matricula-service";
import * as mensagem from "../components/shared/Message";
import unidadeGetResponseState from "../recoil/atoms/unidadeGetResponseState";
import useLoginPortal from "./useLoginPortal";
import {isPendingAuthState} from "../recoil/atoms/isPendingAuthState";
import {isCadastroConcluidoState} from "../recoil/atoms/isCadastroConcluidoState";


const useAuth = () => {
  const isExpired = useRecoilValue(isSessionExpiredSelector);
  const history = useHistory();
  const dispatch = useDispatch();
  const responsavel = useRecoilValue(responsavelState);
  const {doLogin} = useLoginPortal();
  const unidadeGetResponse = useRecoilValue(unidadeGetResponseState);
  const [isPending, setIsPending] = useRecoilState(isPendingAuthState);
  const setIsCadastroConcluido = useSetRecoilState(isCadastroConcluidoState);

  const logout = () => {
    localStorage.removeItem("@SMO20/idResponsavel");
    localStorage.removeItem("@SMO20/nomeResponsavel");

    //clear token cookie
    const cookie = new Cookies();
    cookie.remove("accessToken");
    dispatch(portalLogout());

    localStorage.clear();

    setIsPending(false);
    setIsCadastroConcluido(false);

    history.push(PATH_ROUTE.HOME_SMO)
  }

  const login = async (values?: { username: string; password: string }, manterDados?: boolean, refresh? :boolean) => {
    if (isPending) {
      return;
    }
    setIsPending(true);

    const operation = refresh ? _authService.refreshToken : () => _authService.signIn(values as any);

    return operation()
      .then(async (result) => {
        if (!manterDados) {
          setRecoil(homeState, {
            username: '',
            password: '',
            manterDados: false
          });
        } else {
          setRecoil(homeState, {
            username: values?.username || '',
            password: values?.password || '',
            manterDados: true
          });
        }

        const cookie = new Cookies();
        cookie.set(
          "accessToken",
          result.accessToken.accessToken,
          {expires: new Date(result.accessToken.expiresIn)}
        );

        inicializaUsuarioDD()

        return fetch(env.apiUrl + "api/Responsavel/Get/" + result.loginResponse?.id, {
          method: "GET",
          mode: "cors",
        })
          .then(res => res.json())
          .then((result: IResponsavelResponse) => {
            setRecoil(responsavelState, result);
            localStorage.setItem('@SMO20/cadastroPendente', String(result.cadastroPendente));
            localStorage.setItem('@SMO20/idResponsavel', result.id);
            localStorage.setItem('@SMO20/nomeResponsavel', result.nome);
            localStorage.setItem('@SMO20/cpf', result.cpf);
            localStorage.setItem('@SMO20/email', result.email);
            localStorage.setItem('@SMO20/nome', result.nome);
            setRecoil(loadedCreditCardsState, false);
            setRecoil(matriculasByResponsavelResponseState, null);
            setRecoil(portalAuthState, null);
            return loadUserData(result?.id)
          })
      }).finally(() => {
        setIsPending(false);
      });
  }

  const loadUserData = async (id?: string) => {
    if (isPending) {
      return;
    }
    setIsPending(true);
    let response = await _matricula.GetbyMatriculasByResponsavel(id ?? responsavel?.id, true);

    if (response.statusCode === 0) {
      if (response.value.length > 0) {
        const success = async () => {
          setIsPending(false);
          await doLogin();
        }

        if (unidadeGetResponse && unidadeGetResponse.id === response.value[0].unidade_Id) {
          await success();
          return;
        }

        if (isPending) {
          return;
        }

        await fetch(env.apiUrl + "api/unidade/get/" + response.value[0].unidade_Id, {
          method: "GET",
          mode: "cors",
          atom: unidadeGetResponseState
        })
          .then(res => res.json())
          .then(success)
          .catch(console.error)
          .finally(() => {
            setIsPending(false);
          })
      } else {
        setIsPending(false);
        mensagem.ExibeMensagem("não há matrículas cadastradas para este responsável", "3", true);
      }
    } else {
      setIsPending(false);
      mensagem.ExibeListMensagem(response.messages);
    }
  }

  return {
    isAuthenticated: !isExpired,
    logout,
    login,
    loadUserData,
    isPending,
    user: responsavel
  }
}

export default useAuth;
