import React from 'react';
import ReactTooltip from "react-tooltip";
import "./styles.css";

function ButtonCopyToClipboard(
  {
    content = "",
    label = "Copiar código",
    labelCopied = "copiado!",
    copyOnlyNumbers = false,
    ...props
  }
) {
  const [isCopied, setIsCopied] = React.useState(false);

  const handleCopy = () => {
    let value = content;
    setIsCopied(false);

    if (copyOnlyNumbers) {
      value = value.replace(/\D/g, "");
    }

    //check for browser compatibility
    if (!navigator.clipboard) {
      console.error('Clipboard API not available');
      return;
    }
    navigator.clipboard.writeText(value)
      .then(() => {
        setIsCopied(true);
      })
      .catch((err) => {
        console.error('Failed to copy: ', err);
      });
  }

  return (
    <span className={[
      "seb-smo-button-copy-to-clipboard-container",
      isCopied ? "copied" : ""
    ].join(" ")}>
      <button
        type="button"
        onClick={handleCopy}
        aria-label={label}
        data-tip={label}
        {...props}
      >
        <ReactTooltip
          place="top"
          type="dark"
          effect="float"
          className="tooltip"
        />

        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width={24}
          height={24}
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            d="M19,21H8V7H19M19,5H8A2,2 0 0,0 6,7V21A2,2 0 0,0 8,23H19A2,2 0 0,0 21,21V7A2,2 0 0,0 19,5M16,1H4A2,2 0 0,0 2,3V17H4V3H16V1Z"/>
        </svg>
      </button>

      <span className="seb-smo-button-copy-to-clipboard-copied text-success">
        {labelCopied}
      </span>
    </span>
  );
}

export default ButtonCopyToClipboard;
