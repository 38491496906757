import { map } from 'lodash'
import { PATH_ROUTE } from '../../../routes'
import initial from './initial'
import initialMobile from './initial-mobile'

export const tourList = [
  ...map(initial, (item) => ({ path: PATH_ROUTE.CADASTRO_CONCLUIDO, ...item })),
]

export const tourListMobile = [
  ...map(initialMobile, (item) => ({ path: PATH_ROUTE.CADASTRO_CONCLUIDO, ...item })),
]
