import {selector} from "recoil";
import moment from "moment-timezone";
import Cookies from "universal-cookie";

const isSessionExpiredSelector = selector({
  key: 'isSessionExpiredSelector', // unique ID (with respect to other atoms/selectors)
  get: () => {
    const cookies = new Cookies();
    const bearerToken = cookies.get('accessToken');

    if (!bearerToken) {
      return true;
    }

    const tokenData = bearerToken.split('.')[1];
    const decodedToken = JSON.parse(atob(tokenData));
    const exp = decodedToken.exp;
    const now = moment().unix();

    const expired =  exp < now;
    console.log('expired', expired)
    return expired
  },
});

export default isSessionExpiredSelector

