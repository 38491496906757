import { Alert, Box, MenuItem, Stack, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import * as utils from '../../commons/functions/utils.js';
import { responsavelState } from "../../recoil/atoms/responsavelState";
import { selectedDiaVencimentoPagamentoState } from "../../recoil/atoms/selectedDiaVencimentoPagamentoState";
import { selectedUnidadeState } from "../../recoil/atoms/selectedUnidadeState";
import * as _bancoService from '../../service/banco-service';
import dadosDebitoState, { defaultValues } from "../../recoil/atoms/dadosDebitoState.js";

const DebitoMetodoForm: React.FC = () => {
  const unidade = useRecoilValue(selectedUnidadeState);
  const [selectedDiaVencimentoPagamento, setSelectedDiaVencimentoPagamento] = useRecoilState(selectedDiaVencimentoPagamentoState);
  const [showAgencyDigit, setShowAgencyDigit] = useState(false);
  const responsavel = useRecoilValue(responsavelState);
  const [bancos, setBancos] = useState<Array<{ codigoBanco: string, nome: string }>>([]);
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useRecoilState(dadosDebitoState);
  const [qtdAgencyDigits, setQtdAgencyDigits] = useState(0);
  const [qtdAccountDigits, setQtdAccountDigits] = useState(0);

  console.log('values', values);

  useEffect(() => {
    if (loading) return;
    setLoading(true);
    _bancoService.listarBancos(unidade?.id)
      .then(setBancos)
      .finally(() => setLoading(false));

    setValues({
      ...defaultValues,
      cnpjCPFNum: responsavel?.cpf ?? "",
      name: responsavel?.nome ?? ""
    });
  }, [unidade?.id]);

  const handleChangeBank = (event: React.ChangeEvent<{ value: unknown }>) => {
    const banco = bancos.find(banco => banco.codigoBanco === event.target.value);
    if (!banco) return;
    setValues({
      ...defaultValues,
      bankId: banco.codigoBanco
    });

    setQtdAgencyDigits(utils.QntDigitoAgencia(event.target.value));
    setQtdAccountDigits(utils.QntDigitoConta(event.target.value));
    setShowAgencyDigit(utils.ExisteDigitoAgencia(event.target.value))
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    var regex = /\d+/g;
    var result = "";
    var item = { ...values }
    var returnRegex = event.target.value.toString().match(regex);

    if (returnRegex != null && returnRegex != undefined && !(event.target.name === "bankAccountDigit" && (event.target.value.toString() === "X" || event.target.value.toString() === "x"))) {
      result = returnRegex.join('');
    }
    if (item[event.target.name] != undefined && item[event.target.name] != null) {
      item[event.target.name] = result;
    }

    //Regra especial para contemplar o "X" como exceção para digito
    if (event.target.name === "bankAccountDigit" && (event.target.value.toString() === "X" || event.target.value.toString() === "x")) {
      item[event.target.name] = event.target.value.toString();
    }

    setValues({
      ...defaultValues,
      ...item,
      cnpjCPFNum: responsavel?.cpf ?? "",
      name: responsavel?.nome ?? ""
    });
  }

  return (
    <Box>
      <Stack
        direction="column"
        spacing={2}
      >
        <Typography
          fontWeight="bold"
        >
          Dia preferencial para o débito
        </Typography>

        <TextField
          fullWidth
          label="Datas de vencimento"
          select
          error={selectedDiaVencimentoPagamento === null}
          helperText={selectedDiaVencimentoPagamento === null ? "Selecione uma data de vencimento" : ""}
          onChange={event => setSelectedDiaVencimentoPagamento(event.target.value as string)}
          value={selectedDiaVencimentoPagamento ?? ""}
          required
        >
          {unidade?.diasVencimentoPagamento?.map((vencimento, i) => (
            <MenuItem
              key={vencimento ?? i}
              value={vencimento ?? i}
            >
              Todo dia {vencimento}
            </MenuItem>
          ))}
        </TextField>

        <Alert
          severity="warning"
        >
          <b>Importante:</b> Não se esqueça de acessar seu internet-banking e realizar o aceite bancário para que o débito automático ocorra.
        </Alert>


        <Typography
          fontWeight="bold"
        >
          Preencha os dados da conta
        </Typography>

        <Alert
          severity="warning"
        >
          Os dados do titular da conta no banco precisam ser os mesmos deste cadastro.
        </Alert>

        <Stack
          direction={{ xs: "column", sm: "row" }}
          spacing={2}
        >
          <TextField
            fullWidth
            label="Nome completo do titular da conta"
            value={responsavel?.nome ?? ""}
            required
            InputProps={{
              readOnly: true
            }}
          />

          <TextField
            fullWidth
            label="CPF / CNPJ do titular da conta"
            value={responsavel?.cpf ?? ""}
            required
            InputProps={{
              readOnly: true
            }}
          />
        </Stack>

        <Stack
          direction={{ xs: "column", sm: "row" }}
          spacing={2}
        >
          <TextField
            fullWidth
            label="Banco"
            select
            required
            name="BancoTitularConta"
            error={values.bankId === ""}
            helperText={values.bankId === "" ? "Selecione um banco" : ""}
            onChange={handleChangeBank}
            value={values.bankId}
          >
            {bancos.map((banco, i) => (
              <MenuItem
                key={banco?.codigoBanco ?? i}
                value={banco?.codigoBanco ?? i}
              >
                {banco?.nome}
              </MenuItem>
            ))}
          </TextField>

          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={2}
          >
            <TextField
              fullWidth
              label="Agência"
              required
              name="bankAgency"
              error={values.bankAgency === ""}
              helperText={values.bankAgency === "" ? "Informe a agência" : ""}
              onChange={handleChange}
              value={values.bankAgency}
              InputProps={{
                inputProps: {
                  maxLength: qtdAgencyDigits
                }
              }}
            />

            {showAgencyDigit && (
              <TextField
                fullWidth
                label="Dígito"
                required
                name="bankAgencyDigit"
                error={values.bankAgencyDigit === ""}
                helperText={values.bankAgencyDigit === "" ? "Informe o dígito da agência" : ""}
                onChange={handleChange}
                value={values.bankAgencyDigit}
                InputProps={{
                  inputProps: {
                    maxLength: 1
                  }
                }}
              />
            )}
          </Stack>
        </Stack>

        <Stack
          direction={{ xs: "column", sm: "row" }}
          spacing={2}
        >
          <TextField
            fullWidth
            label="Conta"
            required
            name="bankAccount"
            error={values.bankAccount === ""}
            helperText={values.bankAccount === "" ? "Informe a conta" : ""}
            onChange={handleChange}
            value={values.bankAccount}
            InputProps={{
              inputProps: {
                maxLength: qtdAccountDigits
              }
            }}
          />

          <TextField
            label="Dígito"
            required
            name="bankAccountDigit"
            autoComplete="off"
            error={values.bankAccountDigit === ""}
            helperText={values.bankAccountDigit === "" ? "Informe o dígito da conta" : ""}
            onChange={handleChange}
            value={values.bankAccountDigit}
            InputProps={{
              inputProps: {
                maxLength: 1
              }
            }}
          />
        </Stack>
      </Stack>
    </Box>
  )
}

export default DebitoMetodoForm;
