import {atom} from "recoil";
import {persistAtomEffect} from "../effects/persistAtom";

export const defaultValues = {
  bankAccount: "",
  bankAccountDigit: "",
  bankAgency: "",
  bankAgencyDigit: "",
  bankId: "",
  cnpjCPFNum: "",
  name: "",
};

const dadosDebitoState = atom({
  key: 'dadosDebitoState',
  default: defaultValues,
  effects: [
    persistAtomEffect
  ]
});

export default dadosDebitoState;
