import type {FC, ReactNode} from 'react';
import PropTypes from 'prop-types';

import {useSettings} from '../../hooks/use-settings';

import {useMobileSections, useSections} from './config';
import {HorizontalLayout} from './horizontal-layout';
import {VerticalLayout} from './vertical-layout';
import useMediaQuery from "@mui/material/useMediaQuery";
import {Theme} from "@mui/material/styles/createTheme";

interface LayoutProps {
  children?: ReactNode;
  onlyExit?: boolean;
  backgroundColor?: string;
}

export const Layout: FC<LayoutProps> = (props) => {
  const settings = useSettings();
  const sections = useSections();
  const mobileMode = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const mobileSections = useMobileSections();
  if (settings.layout === 'horizontal') {
    return (
      <HorizontalLayout
        /*@ts-ignore*/
        sections={mobileMode ? mobileSections : sections}
        navColor={settings.navColor}
        {...props}
      />
    );
  }

  return (
    <VerticalLayout
      /*@ts-ignore*/
      sections={mobileMode ? mobileSections : sections}
      navColor={settings.navColor}
      {...props}
    />
  );
}

Layout.propTypes = {
  children: PropTypes.node
};
