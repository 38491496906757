import {makeStyles} from "@mui/styles";

export default makeStyles((theme) => ({
  title: {
    marginTop: 25,
  },
  listItem: {
    backgroundColor: theme.palette.background.paper,
    marginTop: 15,
    marginBottom: 15,
    border: 1,
    borderRadius: theme.components.MuiCard.styleOverrides.root.borderRadius,
    borderRight: `25px solid ${theme.palette.primary.main}`,
  },
  divider: {
    marginTop: '8px !important',
    marginBottom: '8px !important',
    borderBottomWidth: '1 !important',
    borderColor: '#c6bbbb !important'
  },
  studentCard: {
    transition: 'background-color 0.3s ease-in-out',
    padding: 1,
    borderRadius: 2,
    fontWeight: 700,
    width: 'max-content',
    '&:hover': {
      cursor: 'pointer',
    }
  },
  maxContent: {
    width: 'max-content'
  }
}));
