import {makeStyles} from "@mui/styles";

export default makeStyles(theme => ({
  item: {
    paddingLeft: theme.spacing(1),
    width: '100%',
    display: 'inline-block',
  },
  root: {
    width: '100%',
    background: 'rgba(0, 0, 0, 0.1)',
    color: 'grey',
    height: 56,
    marginLeft: 8,
    marginBottom: 10,
    marginTop: 0,
    justifyContent: 'center',
    boxShadow: '0 3px 5px 2px rgba(0, 0, 0, 0.1)',
    borderColor: 'rgba(0, 0, 0, 0.23)',
    borderStyle: 'solid',
    borderWidth: 1,
    display: 'flex',
    borderRadius: 4,
  },
  paymentTotal : {
    display: 'contents',
    fontWeight: 'bolder',
  }
}))
