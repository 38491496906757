import { Stack, Typography, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { monthsEnumShort } from '../../utils/enums/date'

interface DateBannerProps {
    date: string;
    showMonth: boolean
}

export const DateBanner = (
    { 
        date, 
        showMonth = false
    } : DateBannerProps) => {
    const theme = useTheme();
    const [datePart] = date.split("T");
    
    const [year, month, day] = datePart?.split("-");
    const shortYear = year?.slice(-2);
    if(!year) return
    return (
        <Box 
            sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center'
            }}
        >
            <Stack
                direction={!showMonth ? 'row' : 'column'}
                justifyContent="center"
                alignItems="center"
                sx={{
                    backgroundColor: theme.palette.primary.light,
                    padding: theme.spacing(1),
                    borderRadius: '7px',
                    width: showMonth ? '55px' : '40px',
                    height: '45px'
                }}
            >
                {showMonth ? (
                    <>
                        <Typography variant="h6" sx={{fontWeight: 'normal'}}>{monthsEnumShort[parseInt(month)].toUpperCase()}</Typography>
                        <Typography variant="body1" sx={{fontWeight: 'bold'}}>{year}</Typography>
                    </>
                ) :(
                    <>
                        <Typography variant="body2" sx={{fontWeight: 'bold'}}>20</Typography>
                        <Typography variant="body2" sx={{fontWeight: 'bold'}}>{shortYear}</Typography>
                    </>
                )}
            </Stack>
        </Box>
    )
}