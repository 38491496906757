import fetch from "../utils/fetch";
import env from "../env";
import {setRecoil} from "recoil-nexus";
import { metodoPagamentoState } from "../recoil/atoms/metodoPagamentoState";
import { isTabelaResumoLoadingState } from "../recoil/atoms/isTabelaResumoLoadingState";
import IMetodoPagamentoDto from "../@types/IMetodoPagamentoDto";
import * as mensagem from '../components/shared/Message.js';
import { metodosDePagamentoState } from "../recoil/atoms/metodosDePagamentoState";
import IMetodoPagamentoViewModel from "../@types/IMetodoPagamentoViewModel";

export function GetbyId(id, tipo) {

    return fetch(env.apiUrl+"api/metodopagamento/get/" + id + "/" + tipo, {
        method: "GET",
        mode: "cors",
    })
        .then(res => res.json())
        .then(
            (result) => {
                return result;
            },
            (error) => {
                console.log(error.message);
                return false;
            }
        )
        .catch(error => {
            console.log(error.message);
            return false;
        });
}


export async function GetDadosMeiosPagamentos(idUnidade: string, idMatricula: string): Promise<IMetodoPagamentoViewModel> {
  return fetch(env.apiUrl + "api/MetodosDePagamento/Get/" + idUnidade + "/" + idMatricula, {
    method: "GET",
    mode: "cors",
  }).then(res => res.json())
    .then(data => {
      setRecoil(metodosDePagamentoState, data)
      return data;
    })
}


export async function GetByUnidade(query): Promise<IMetodoPagamentoDto> {
  return fetch(env.apiUrl + `api/MetodosDePagamento/GetByUnidade`, {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      'Accept': 'application/json'
    },
    body: JSON.stringify(query)
  }).then((res) => {
    if (!res.ok) {
      res.text().then((text) => {
        mensagem.ExibeMensagem(text, "3");
        return Promise.resolve({} as IMetodoPagamentoDto);
      });
    } else {
      return res.json();
    }
  }).then(data => {
    setRecoil(metodoPagamentoState, data.value)
    return data.value;
  })
    .catch(() => setRecoil(metodoPagamentoState, null))
    .finally(() => setRecoil(isTabelaResumoLoadingState, false));
}
