
const acceptedCards = [
  'visa',
  'mastercard',
  'hipercard',
  'amex',
  'elo'
];

Object.freeze(acceptedCards);

export default acceptedCards;