export const tokens = {
  common: {
    connectionError: 'common.connectionError',
    regularService: 'common.regularService',
    languageChanged: 'common.languageChanged',
    resetAll: 'common.resetAll',
    reset: 'common.reset',
    onWeek: 'common.onWeek',
    password: 'common.password',
    new: 'common.new',
    save: 'common.save',
    edit: 'common.edit',
    edited: 'common.edited',
    change: 'common.change',
    changed: 'common.changed',
    select: 'common.select',
    selected: 'common.selected',
    saved: 'common.saved',
    saving: 'common.saving',
    cancel: 'common.cancel',
    filters: 'common.filters',
    createdAt: 'common.createdAt',
    updatedAt: 'common.updatedAt',
    deletedAt: 'common.deletedAt',
    rowsPerPage: 'common.rowsPerPage',
    of: 'common.of',
    in: 'common.in',
    moreThan: 'common.moreThan',
    from: 'common.from',
    to: 'common.to',
    disable: 'common.disable',
    block: 'common.block',
    other: 'common.other',
    search: 'common.search',
    name: 'common.name',
    creationDate: 'common.creationDate',
    actions: 'common.actions',
    security: 'common.security',
    general: 'common.general',
    details: 'common.details',
    clickToSeePaymentDetails: 'common.clickToSeePaymentDetails',
    installment: 'common.installment',
    basicDetails: 'common.basicDetails',
    fullName: 'common.fullName',
    emailAdress: 'common.emailAdress',
    setUp: 'common.setUp',
    multiFactorAuthentication: 'common.multiFactorAuthentication',
    alternativeAuthentication: 'common.alternativeAuthentication',
    loginHistory: 'common.loginHistory',
    backToHome: 'common.backToHome',
    overdueInstallments: 'common.overdueInstallments',
    outstandingBills: 'common.outstandingBills',
    paidBills: 'common.paidBills',
    student: 'common.student',
    school: 'common.school',
    schoolMoment: 'common.schoolMoment',
    billet: 'common.billet',
    card: 'common.card',
    paidBillsDesc: 'common.paidBillsDesc',
    financialDesc: 'common.financialDesc',
    financialDeclarationDesc: 'common.financialDeclarationDesc',
    selectStudent: 'common.selectStudent',
    displayFinancialServices: 'common.displayFinancialServices',
    contractedServicesAndProducts: 'common.contractedServicesAndProducts',
    servicesContractedByStudent: 'common.servicesContractedByStudent',
    paymentMethod: 'common.paymentMethod',
    paymentForm: 'common.paymentForm',
    entry: 'common.entry',
    totalGross: 'common.totalGross',
    includingEntry: 'common.includingEntry',
    noServicesContracted: 'common.noServicesContracted',
    displayFinancialServicesPaymentMethod: 'common.displayFinancialServicesPaymentMethod',
    availablePaymentMethods: 'common.availablePaymentMethods',
    basicPayment: 'common.basicPayment',
    paymentSlip: 'common.paymentSlip',
    automaticDebit: 'common.automaticDebit',
    automaticDebitDiscount: 'common.automaticDebitDiscount',
    recurringCreditCard: 'common.recurringCreditCard',
    recurringCreditCardDiscount: 'common.recurringCreditCardDiscount',
    discountOf: 'common.discountOf',
    modalClickInformation: 'common.modalClickInformation',
    knowMore: 'common.knowMore',
    changeBankDetails: 'common.changeBankDetails',
    changeCreditCard: 'common.changeCreditCard',
    viewCurrentCard: 'common.viewCurrentCard',
    enrollment: 'common.enrollment',
    portal: 'common.portal',
    currentMethod: 'common.currentMethod',
    incomeTax: 'common.incomeTax',
    fullReceipt: 'common.fullReceipt',
    year: 'common.year',
    pay: 'common.pay',
    generateInvoice: 'common.generateInvoice',
    copyTypeableLine: 'common.copyTypeableLine',
    receipt: 'common.receipt',
    monthYear: 'common.monthYear',
    dueDate: 'common.dueDate',
    invoice: 'common.invoice',
    liquidation: 'common.liquidation',
    documentValue: 'common.documentValue',
    settledValue: 'common.settledValue',
    dueDateValue: 'common.dueDateValue',
    installmentAvailable: 'common.installmentAvailable',
    situation: 'common.situation',
    noValidEnrollments: 'common.noValidEnrollments',
  },
  page: {
    account: {
      loginHistorySub: 'page.account.loginHistorySub',
      loginType: 'page.account.loginType',
      ipAdress: 'page.account.ipAdress',
      client: 'page.account.client',
    },
    notAuthorized: {
      title: 'page.notAuthorized.title',
      subtitle: 'page.notAuthorized.subtitle'
    }
  },
  auth: {
    ad: {
      login: 'auth.ad.login',
      title: 'auth.ad.title',
      subtitle: 'auth.ad.subtitle',
      enter: 'auth.ad.enter'
    },
    facial: {
      enter: 'auth.facial.enter',
      feedback: {
        invalidPose: 'auth.facial.feedback.invalidPose',
        leftEyeClosed: 'auth.facial.feedback.leftEyeClosed',
        rightEyeClosed: 'auth.facial.feedback.rightEyeClosed',
        faceTooLow: 'auth.facial.feedback.faceTooLow',
        faceTooHigh: 'auth.facial.feedback.faceTooHigh',
        faceTooClose: 'auth.facial.feedback.faceTooClose',
        faceTooFar: 'auth.facial.feedback.faceTooFar',
        noFaceDetected: 'auth.facial.feedback.noFaceDetected',
      }
    },
    jwt: {
      login: 'auth.jwt.login',
      title: 'auth.jwt.title',
      subtitle: 'auth.jwt.subtitle',
    }
  },
  environment: {
    development: 'environment.development',
    homologation: 'environment.homologation',
    production: 'environment.production',
    staging: 'environment.staging',
    test: 'environment.test'
  },
  settings: {
    appSettings: 'settings.appSettings',
    orientation: 'settings.orientation',
    content: 'settings.content',
    layout: 'settings.layout',
    vertical: 'settings.vertical',
    horizontal: 'settings.horizontal',
    colorScheme: 'settings.colorScheme',
    primaryColor: 'settings.primaryColor',
    purple: 'settings.purple',
    blue: 'settings.blue',
    green: 'settings.green',
    indigo: 'settings.indigo',
    compact: 'settings.compact',
    wide: 'settings.wide',
    normal: 'settings.normal',
    high: 'settings.high',
    leftToRight: 'settings.leftToRight',
    rightToLeft: 'settings.rightToLeft',
    navColor: 'settings.navColor',
    contrast: 'settings.contrast',
    light: 'settings.light',
    dark: 'settings.dark',
    blendIn: 'settings.blendIn',
    discrete: 'settings.discrete',
    evident: 'settings.evident',
  },
  layout: {
    language: 'layout.language',
    notifications: 'layout.notifications',
    topNav: {
      account: {
        logout: 'layout.topNav.account.logout',
        settings: 'layout.topNav.account.settings',
        profile: 'layout.topNav.account.profile',
        billing: 'layout.topNav.account.billing'
      }
    }
  },
  nav: {
    enrollment: 'nav.enrollment',
    md: 'nav.md',
    services: 'nav.services',
    financial: 'nav.financial',
    financialHome: 'nav.financialHome',
    financialOpenQuotas: 'nav.financialOpenQuotas',
    financialPaidQuotas: 'nav.financialPaidQuotas',
    financialAgreement: 'nav.financialAgreement',
    financialHistory: 'nav.financialHistory',
    financialDeclarations: 'nav.financialDeclarations',
    financialPaymentMethod: 'nav.financialPaymentMethod',
    financialCards: 'nav.financialCards',
    agreements: 'nav.agreements',
    registrationData: 'nav.registrationData',
  }
};
