import {atom} from "recoil";
import {persistAtomEffect} from "../effects/persistAtom";

export const isCadastroConcluidoState = atom<boolean>({
  key: 'isCadastroConcluidoState',
  default: false,
  effects: [
    persistAtomEffect
  ]
});
