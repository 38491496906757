import { fetchUserMessage } from '../services/messaging'
import { isPopUpEnabledSelector } from '../../Login/selectors/user'
import { changeStatusPopUp } from '../../Login/actions'
import { userMessagePopUpSelector } from '../selectors/userMessage'

export const fetchClientMessages = (appId, enablePopUp = false ) => (dispatch, getState) => {
  try {
    return dispatch(fetchUserMessage(appId)).then(result => {
      if (enablePopUp) {
        const data = userMessagePopUpSelector(getState())
        const isEnabled = (data && !data.onlyOneExhibition) || isPopUpEnabledSelector(getState())
        dispatch(changeStatusPopUp(isEnabled))
      }
    })
  } catch (e) {
    console.error(e)
  }
}
