import {selector} from "recoil";
import {calcResumoListState} from "../../../atoms/calcResumoListState";
import servicoRegularSelector from "./servicoRegularSelector";
import {ServicoResumo} from "../../../../@types/IServicoResumoDto";

const servicoRegularDescontoMetodoSelector = selector({
  key: 'servicoRegularDescontoMetodoSelector',
  get: ({get}) => {
    let resumo = get(calcResumoListState);
    let servicoRegular = get(servicoRegularSelector);

    if (resumo) {
      const isSameService  = (r: ServicoResumo) => r.tipoParcela == 2 && r.tipoServico === "ServicoRegular" && r.id === servicoRegular?.id
      const linha = resumo.resumoCobrancas.find(s => s.servicos.find(isSameService))
      const servico = linha?.servicos.find(isSameService)
      return servico || null
    }

    return null
  },
});

export default servicoRegularDescontoMetodoSelector;
