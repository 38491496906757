import { Description, Info, Error, HourglassEmpty, Autorenew, Cached, Block, Timer } from '@mui/icons-material';
import moment from 'moment';

export const InstallmentStatusOriginal = {
  UNKNOWN: '',
  NONE: 'None',
  CANCEL: 'Cancel',
  SENT: 'Sent',
  CONFIRMED: 'Confirmed',
  REJECTED: 'Rejected',
  RECIEVED: 'Recieved',
  DELETE: 'Delete',
  SENTDELETE: 'SentDelete',
  DELETED: 'Deleted',
  EDUCBANK: 'EDUCBANK'
}

export const InstallmentStatus = {
  UNKNOWN: 'UNKNOWN',
  AVAILABLE: 'AVAILABLE',
  AUTOMATIC_DEBIT: 'AUTOMATIC_DEBIT',
  WAITING_PAYMENT_PROCESSING: 'WAITING_PAYMENT_PROCESSING',
  WAITING_BILLET_GENERATION: 'WAITING_BILLET_GENERATION',
  SENT_TO_DEBIT: 'SENT_TO_DEBIT',
  SENT_TO_REGISTRATION: 'SENT_TO_REGISTRATION',
  DEBIT_REJECTED: 'DEBIT_REJECTED',
  DEBIT_FAILURE: 'DEBIT_FAILURE',
  EXPIRED: 'EXPIRED',
  NEGOTIATION_EXPIRED: 'NEGOTIATION_EXPIRED',
  RECURRENCE: 'RECURRENCE'
};

export const InstallmentStatusLabel = {
  [InstallmentStatus.UNKNOWN]: '',
  [InstallmentStatus.AVAILABLE]: 'Em aberto',
  [InstallmentStatus.AUTOMATIC_DEBIT]: 'Débito automático',
  [InstallmentStatus.RECURRENCE]: 'Crédito Recorrente',
  [InstallmentStatus.WAITING_PAYMENT_PROCESSING]: 'Aguardando processamento',
  [InstallmentStatus.WAITING_BILLET_GENERATION]: 'Aguardando geração de boleto',
  [InstallmentStatus.SENT_TO_DEBIT]: 'Enviado para débito',
  [InstallmentStatus.SENT_TO_REGISTRATION]: 'Enviado para registro',
  [InstallmentStatus.DEBIT_REJECTED]: 'Débito rejeitado',
  [InstallmentStatus.DEBIT_FAILURE]: 'Falha no débito',
  [InstallmentStatus.EXPIRED]: 'Vencido',
  [InstallmentStatus.NEGOTIATION_EXPIRED]: 'Negociação vencida'
};
export const InstallmentStatusIcon = {
  [InstallmentStatus.UNKNOWN]: <Description/>,
  [InstallmentStatus.AVAILABLE]: <Info/>,
  [InstallmentStatus.AUTOMATIC_DEBIT]: <Autorenew/>,
  [InstallmentStatus.RECURRENCE]: <Autorenew/>,
  [InstallmentStatus.WAITING_PAYMENT_PROCESSING]: <HourglassEmpty/>,
  [InstallmentStatus.WAITING_BILLET_GENERATION]: <HourglassEmpty/>,
  [InstallmentStatus.SENT_TO_DEBIT]: <Cached/>,
  [InstallmentStatus.SENT_TO_REGISTRATION]: <Cached/>,
  [InstallmentStatus.DEBIT_REJECTED]: <Block/>,
  [InstallmentStatus.DEBIT_FAILURE]: <Error/>,
  [InstallmentStatus.EXPIRED]: <Timer/>,
  [InstallmentStatus.NEGOTIATION_EXPIRED]: <Timer/>,
};

export const InstallmentStatusType = {
  [InstallmentStatus.UNKNOWN]: 'info',
  [InstallmentStatus.AVAILABLE]: 'info',
  [InstallmentStatus.AUTOMATIC_DEBIT]: 'success',
  [InstallmentStatus.RECURRENCE]: 'success',
  [InstallmentStatus.WAITING_PAYMENT_PROCESSING]: 'info',
  [InstallmentStatus.WAITING_BILLET_GENERATION]: 'info',
  [InstallmentStatus.SENT_TO_DEBIT]: 'info',
  [InstallmentStatus.SENT_TO_REGISTRATION]: 'info',
  [InstallmentStatus.DEBIT_REJECTED]: 'error',
  [InstallmentStatus.DEBIT_FAILURE]: 'error',
  [InstallmentStatus.EXPIRED]: 'error',
  [InstallmentStatus.NEGOTIATION_EXPIRED]: 'error'
};

export const InstallmentStatusToLabel = (status) =>
  InstallmentStatusLabel[status] || InstallmentStatusLabel[InstallmentStatus.UNKNOWN];

export const isExirationedInstallment = (status) =>
  InstallmentStatusLabel[status] && InstallmentStatus[status] === InstallmentStatus[InstallmentStatus.EXPIRED];

export const isAvailableInstallment = (paymentavailability, paymentstatus, paymenttype, expirationdate) => {
  if (isBillet(paymenttype) == true && InstallmentStatusOriginal[paymentstatus] != "EDUCBANK") {
    return InstallmentStatus[paymentavailability] === InstallmentStatus.AVAILABLE ||
      InstallmentStatus[paymentavailability] === InstallmentStatus.EXPIRED ||
      InstallmentStatus[paymentavailability] === InstallmentStatus.SENT_TO_REGISTRATION;
  }
  else if ((isRecurrentPayment(paymenttype) == true || isAutomaticDebit(paymenttype) == true) && isExpired(expirationdate) == true) {
    return InstallmentStatusOriginal[paymentstatus] == InstallmentStatusOriginal.CANCEL ||
      InstallmentStatusOriginal[paymentstatus] == InstallmentStatusOriginal.UNKNOWN ||
      InstallmentStatusOriginal[paymentstatus] == InstallmentStatusOriginal.NONE ||
      InstallmentStatusOriginal[paymentstatus] == InstallmentStatusOriginal.REJECTED;
  }
  else {
    return false;
  }
};

export const isPrintInstallment = (paymentavailability, paymenttype) => {
  if (isBillet(paymenttype) == true) {
    return InstallmentStatus[paymentavailability] === InstallmentStatus.AVAILABLE || InstallmentStatus[paymentavailability] === InstallmentStatus.EXPIRED;
  }
  else {
    return false;
  }
}

export const isExpired = (expirationdate) => {
  let response = false;

  const expirationDate = moment(expirationdate);
  const dateNow = moment();

  if (dateNow.diff(expirationDate, 'days') > 0) {
    response = true;
  }

  return response;
};

export const isAutomaticDebit = (paymenttype) => {
  return (paymenttype == 'DebitoAutomatico');
};

export const isRecurrentPayment = (paymenttype) => {
  return (paymenttype == 'CreditoRecorrente');
};

export const isBillet = (paymenttype) => {
  return (paymenttype == 'BoletoBancario');
};