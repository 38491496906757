import {selector} from "recoil";
import {matriculasByResponsavelResponseState} from "../atoms/matriculasByResponsavelResponseState";
import {Matricula} from "../../@types/IMatriculaResponse";

const matriculasSelector = selector<Matricula[]>({
  key: 'matriculasSelector', // unique ID (with respect to other atoms/selectors)
  get: ({get}) => {
    const matriculas = get(matriculasByResponsavelResponseState);
    return matriculas?.value || []
  }
});

export default matriculasSelector

