import {atom} from "recoil";
import {persistAtomEffect} from "../effects/persistAtom";

export const sidebarHiddenMenusState = atom({
  key: 'SidebarHiddenMenusState',
  default: [],
  effects: [
    persistAtomEffect
  ]
});
