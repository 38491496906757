import { apiGet } from 'utils/API/actions/request'
import { HOME_SERVICES_REGISTRATION } from '../constants'

export const fetchRegistrationServices = (appid, accessId) => apiGet({
    entity: HOME_SERVICES_REGISTRATION,
    url: `/registration/${appid}/services`,
    config: {
      params: {
        accessId
      }
    }
  });