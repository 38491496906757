import {atom} from "recoil";
import {persistAtomEffect} from "../effects/persistAtom";
import IUnidadeResponse from "../../@types/IUnidadeResponse";

export const unidadeGetResponseState = atom<IUnidadeResponse | null>({
  key: 'unidadeGetResponseState',
  default: null,
  effects: [
    persistAtomEffect
  ]
});

export default unidadeGetResponseState;
