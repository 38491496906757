import {Dialog} from "@mui/material";
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import React, {useEffect, useState} from 'react';
import {useRecoilState, useRecoilValue} from "recoil";
import * as mensagem from '../shared/Message';
import {creditCardsState} from "../../recoil/atoms/creditCardsState";
import {loadedCreditCardsState} from "../../recoil/atoms/loadedCreditCardsState";
import {walletCardReloadState} from "../../recoil/atoms/walletCardReloadState";
import {walletShowChangeSubsState} from "../../recoil/atoms/walletShowChangeSubsState";
import * as _walletService from "../../service/wallet-service";
import WalletCardsList from "./WalletCardsList";
import WalletChangeSubs from "./WalletChangeSubs";
import WalletSaveCard from "./WalletSaveCard";
import WalletSelectionPreview from "./WalletSelectionPreview";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import "./styles.css";
import {selectedMatriculaState} from "../../recoil/atoms/selectedMatriculaState";
import {responsavelState} from "../../recoil/atoms/responsavelState";
import useMediaQuery from "@mui/material/useMediaQuery";
import {Theme} from "@mui/material/styles/createTheme";
import WalletLoading from "./WalletLoading";
import {selectedUnidadeState} from "../../recoil/atoms/selectedUnidadeState";

export const antifraudeOrg = process.env.REACT_APP_GETNET_ANTIFRAUDE_ORG ?? "1snn5n9w"

interface WalletModalProps {
  onSelectCard: (card: any) => void,
  selectedCard: any,
  show: boolean
}

const WalletModal: React.FC<WalletModalProps> = (props) => {
  const {
    onSelectCard = card => { },
    selectedCard = null,
    show = false
  } = props;
  const [loadingCards, setLoadingCards] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [creditCards, setCreditCards] = useRecoilState(creditCardsState);
  const [showAddCard, setShowAddCard] = useState(false);
  const [showChangeSubs, setShowChangeSubs] = useRecoilState(walletShowChangeSubsState);
  const selectedCreditCard = selectedCard ? creditCards.find(c => c.cardId === selectedCard) : null;
  const [loaded, setLoaded] = useRecoilState(loadedCreditCardsState);
  const [reload, setReload] = useRecoilState(walletCardReloadState);
  const selectedMatricula = useRecoilValue(selectedMatriculaState);
  const selectedUnidade = useRecoilValue(selectedUnidadeState);
  const responsavel = useRecoilValue(responsavelState);
  const smUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));

  useEffect(() => {
    if (creditCards.length === 0 && showAddCard) setShowAddCard(true);
  }, [creditCards, showAddCard])

  useEffect(() => {
    if (show && !open) {
      setOpen(true);
    }
  }, [show])

  useEffect(() => {
    if (open && false === loaded) {
      if (loadingCards) return;
      setLoadingCards(true)
      _walletService.cartaoListar(
        selectedUnidade?.id ?? selectedMatricula?.unidade_Id,
        responsavel?.id
      )
        .then(async resp => {
          if (resp?.ok) {
            return await resp.json();
          } else if (resp) {
            const json = await resp.json();
            if (json?.messages) {
              (json?.messages || []).map(m => mensagem.ExibeMensagem(m?.text, String(m?.typeMessage)))
            }
            setOpen(false);
          }
        })
        .then(({ value: { cards } }) => {
          if (creditCards && creditCards.length) {
            setCreditCards(cards.map(card => {
              const oldCard = creditCards.find(c => c.cardId === card.cardId);
              if (oldCard) {
                // restaura numberToken para  evitar uma nova verificação no cartão
                card.numberToken = oldCard.numberToken
              }
              return card
            }))
          } else {
            setCreditCards(cards ?? []);
          }
          setLoaded(true);

          if (!(cards || []).length) setShowAddCard(true);
        })
        .finally(() => {
          setLoadingCards(false)
          setReload(false)
        })
    }
  }, [open, loaded, creditCards])

  useEffect(() => {
    if (reload && !loadingCards) {
      setLoaded(false);
    }
  }, [reload, loadingCards]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setShowChangeSubs(false);
  };

  const handleCloseChangeSubs = () => {
    setShowChangeSubs(false);
    setReload(true);
  };

  const handleCancel = () => {
    setShowAddCard(false);
  }

  const handleAddCard = () => {
    setShowAddCard(true);
  }

  const handleCardSelection = (card) => {
    if (onSelectCard) {
      onSelectCard({
        card_id: card.cardId,
        bin: card.bin,
        cardholder_name: card.cardholderName,
        expiration_month: card.expirationMonth,
        expiration_year: card.expirationYear,
        number_token: card.numberToken,
        month_year: `${card.expirationMonth}/${card.expirationYear}`
      })
      handleClose();
    }
  }

  return (
    <div
      className="smo-wallet-modal-container panel-body"
      style={{ padding: 'unset' }}
    >
      <noscript>
        <iframe
          title="antifraude"
          style={{
            width: "100px",
            height: "100px",
            border: "0",
            position: "absolute",
            top: "-5000px"
          }}
          src={`https://h.online-metrix.net/fp/tags?org_id=${antifraudeOrg}&session_id=${responsavel?.id}`}>
        </iframe>
      </noscript>

      <Dialog
        open={open}
        onClose={handleClose}
        className="smo-wallet-modal"
        scroll="paper"
        title={showAddCard ? "Novo Cartão" : showChangeSubs ? "Alterar recorrência" : "Meus Cartões"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        maxWidth="lg"
        fullScreen={!smUp}
      >
        <DialogTitle
          id="scroll-dialog-title">
          {showAddCard ? "Novo Cartão" : showChangeSubs ? "Alterar recorrência" : "Meus Cartões"}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          {loadingCards ? (
            <div style={{width: "100%", height: "100%", minHeight: "400px" }}>
              <WalletLoading />
            </div>
          ) : (
            <React.Fragment>
              {showAddCard ? (
                <WalletSaveCard
                  onClose={handleClose}
                  onCancel={handleCancel}
                />
              ) : (
                <>
                  {showChangeSubs && (
                    <WalletChangeSubs
                      onCancel={handleCloseChangeSubs}
                    />
                  )}
                  <WalletCardsList
                    hidden={showChangeSubs}
                    selectedCreditCard={selectedCreditCard}
                    onClickSelectCard={handleCardSelection}
                    onClickAddCard={handleAddCard}
                    showRemove={undefined}
                  />
                </>
              )}
            </React.Fragment>
          )}
        </DialogContent>
      </Dialog>

      <WalletSelectionPreview
        card={selectedCreditCard}
        onClick={handleClickOpen}
        loadingCards={loadingCards}
      />
    </div>
  );
}

export default WalletModal;
