import React from "react";
import useAuth from "../hooks/use-auth";
import {Redirect, useLocation} from "react-router-dom";
import {PATH_ROUTE} from "../routes";

const AuthMiddleware: React.FC = (props: any) => {
  const {isAuthenticated, logout} = useAuth();
  const location = useLocation();

  if (isAuthenticated && location.pathname === PATH_ROUTE.HOME_SMO) {
    return <Redirect to={PATH_ROUTE.CADASTRO_CONCLUIDO}/>
  }

  if (!isAuthenticated && location.pathname !== PATH_ROUTE.HOME_SMO) {
    logout();
    return <Redirect to={PATH_ROUTE.HOME_SMO}/>
  }

  return props.children
}

export default AuthMiddleware;
