import { pipe, prop } from 'ramda'
import { isEmpty } from 'lodash'

import {
  getAPIDataSelector,
  hasErrorAPISelector,
  hasLoadedAPISelector,
  isLoadingAPISelector,
} from '../../../../utils/API/selectors'
import { createSelector } from 'reselect'

const baseFinanceSelector = prop('finance')

export const infoSelector = pipe(baseFinanceSelector, prop('info'))

export const infoDataSelector = getAPIDataSelector(infoSelector)

export const hasLoadedInfoSelector = hasLoadedAPISelector(infoSelector)

export const hasErrorInfoSelector = hasErrorAPISelector(infoSelector)

export const isLoadingInfoSelector = isLoadingAPISelector(infoSelector)

export const isMockInstallment = createSelector(
  infoDataSelector,
  hasErrorInfoSelector,
  (data, hasError) => {
    return hasError || isEmpty(data)
  }
)

export const isFetchInstallment = createSelector(
  isLoadingInfoSelector,
  hasErrorInfoSelector,
  infoDataSelector,
  (isLoading,  hasError, data) => {
    return isEmpty(data) && !isLoading && !hasError
  }
)
