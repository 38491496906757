import axios from '../../../utils/axios'
import { apiPost, apiGet } from '../../../utils/API/actions'
import {BILLET, INFO, OPENED} from '../Pay/constants'
import { VERSIONS } from '../../../utils/version'

export const closed = (appid, params) => axios.get(`${VERSIONS.V1}/installment/${appid}/closed`, { params })

export const open = (appid, params) => apiGet({
  entity: OPENED,
  url: `/installment/${appid}/open`,
  config: {
    params
  }
})

export const installmentsInfoRequest = ({ codigoMatriculaErp, codigoResponsavelErp, entidadeLegalUnidadeErp, ...params }) => apiGet({
  entity: INFO,
  url: `/installment/info?codigoMatriculaErp=${codigoMatriculaErp}&codigoResponsavelErp=${codigoResponsavelErp}&entidadeLegalUnidadeErp=${entidadeLegalUnidadeErp}`,
  config: {
    params
  }
})

export const boleto = (params, cpfCnpj) => axios.post(`${VERSIONS.V1}/installment/billet?cpfCnpj=${cpfCnpj}`, {...params, prmAcordo: true})

export const infoInstallment = (appId, journalTransRecId, cpfCnpj) => axios.get(`/installment/${appId}/open/${journalTransRecId}?cpfCnpj=${cpfCnpj}`)

export const billetRequest = (params, cpfCnpj) => apiPost({
  entity: BILLET,
  url: `/installment/billet?cpfCnpj=${cpfCnpj}`,
  data: {...params, prmAcordo: true},
  isChainable: true,
  analytics: {
    value: params.journaltransrecids.length
  }
})
