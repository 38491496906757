import {selector} from "recoil";
import IDevice from "../../@types/IDevice";

const deviceSelector = selector({
  key: 'deviceSelector',
  get: () => {
    const ip = localStorage.getItem('ip_address');
    return {
      ip_address: ip,
      device_id: ip
    } as IDevice;
  },
});

export default deviceSelector;
