import {selector} from "recoil";
import IProcessaPagamentoMatriculaRequest from "../../@types/IProcessaPagamentoMatriculaRequest";
import {meiosPagamentoToTipoPagamento} from "../../utils/tipoPagamento";
import {selectedDiaVencimentoPagamentoState} from "../atoms/selectedDiaVencimentoPagamentoState";
import {selectedEntradaCreditCardState} from "../atoms/selectedEntradaCreditCardState";
import {selectedMeioPagamentoEntradaState} from "../atoms/selectedMeioPagamentoEntradaState";
import {selectedMeioPagamentoParcelamentoState} from "../atoms/selectedMeioPagamentoParcelamentoState";
import {selectedRecorrenciaCreditCardState} from "../atoms/selectedRecorrenciaCreditCardState";
import contratoServicoRegularSelector from "./carrinho/servicoRegular/contratoServicoRegularSelector";
import parcelaEntradaRegularSelecionadaSelector
  from "./carrinho/servicoRegular/parcelaEntradaRegularSelecionadaSelector";
import parcelaRegularSelecionadaSelector from "./carrinho/servicoRegular/parcelaRegularSelecionadaSelector";
import servicoRegularSelector from "./carrinho/servicoRegular/servicoRegularSelector";
import parcelasObrigatoriosSelecionadasSelector
  from "./carrinho/servicosObrigatorios/parcelasObrigatoriosSelecionadasSelector";
import servicosObrigatoriosSelector from "./carrinho/servicosObrigatorios/servicosObrigatoriosSelector";
import parcelasUpSellingSelecionadasSelector from "./carrinho/servicosUpSelling/parcelasUpSellingSelecionadasSelector";
import servicosUpSellingSelecionadosSelector from "./carrinho/servicosUpSelling/servicosUpSellingSelecionadosSelector";
import deviceSelector from "./deviceSelector";
import dadosDebitoState from "../atoms/dadosDebitoState";
import contratosUpSellingSelector from "./carrinho/servicosUpSelling/contratosUpSellingSelector";

const checkoutRequestSelector = selector({
  key: 'checkoutRequestSelector',
  get: ({get}) => {
    let checkoutRequest: Array<IProcessaPagamentoMatriculaRequest> = [];
    const device = get(deviceSelector);
    const diasVencimentoPagamento = get(selectedDiaVencimentoPagamentoState);
    const selectedEntradaCreditCard = get(selectedEntradaCreditCardState);
    const selectedRecorrenciaCreditCard = get(selectedRecorrenciaCreditCardState);
    const dadosBancarios = get(dadosDebitoState);

    const meioPagamentoEntrada = get(selectedMeioPagamentoEntradaState);
    const entradaTipo = meiosPagamentoToTipoPagamento[meioPagamentoEntrada ?? 0] || 0

    const meioPagamentoParcelamento = get(selectedMeioPagamentoParcelamentoState);
    const parcelamentoTipo = meiosPagamentoToTipoPagamento[meioPagamentoParcelamento ?? 0] || 0

    // Regular
    const servicoPrincipal = get(servicoRegularSelector);
    const parcelaRegularSelecionada = get(parcelaRegularSelecionadaSelector);
    const parcelaEntradaRegularSelecionada = get(parcelaEntradaRegularSelecionadaSelector);
    const contratoServicoRegular = get(contratoServicoRegularSelector);
    const regularPossuiRecorrente = parcelaRegularSelecionada?.fullAvista === false || parcelaRegularSelecionada?.totalParcela !> 1;

    // Obrigatórios
    const servicosObrigatorios = get(servicosObrigatoriosSelector);
    const parcelasObrigatoriosSelecionadas = get(parcelasObrigatoriosSelecionadasSelector);
    const obrigatoriosPossuiRecorrente = Object.values(parcelasObrigatoriosSelecionadas).some(parcela => parcela?.totalParcela !> 1 || parcela?.fullAvista === false);

    // UpSelling
    const servicosUpSellingSelecionados = get(servicosUpSellingSelecionadosSelector);
    const parcelasUpSellingSelecionadas = get(parcelasUpSellingSelecionadasSelector);
    const upSellingPossuiRecorrente = Object.values(parcelasUpSellingSelecionadas).some(parcela => parcela?.totalParcela !> 1 || parcela?.fullAvista === false);
    const contratosUpSelling = get(contratosUpSellingSelector);


    if (servicoPrincipal && parcelaRegularSelecionada) {
      checkoutRequest.push({
        DadoCartao: regularPossuiRecorrente ? selectedRecorrenciaCreditCard || {} : selectedEntradaCreditCard || {},
        DadosBancarios: dadosBancarios || {},
        Tipo: regularPossuiRecorrente ? parcelamentoTipo : entradaTipo,
        device,
        Servicos: [
          {
            idservico: servicoPrincipal.id,
            qntParcela: parcelaRegularSelecionada?.totalParcela || 1,
            name: "Regular",
            diasVencimentoPagamento: diasVencimentoPagamento || "0",
            parcelamentoId: parcelaRegularSelecionada?.id || "00000000-0000-0000-0000-000000000000",
            tipoPagamento: parcelamentoTipo,
            dadosCartao: regularPossuiRecorrente ? selectedRecorrenciaCreditCard || {} : selectedEntradaCreditCard || {},
            dadosBancarios: dadosBancarios || {},
            parcelamentoEntradaId: parcelaEntradaRegularSelecionada?.id || "00000000-0000-0000-0000-000000000000",
            qntParcelaEntrada: parcelaEntradaRegularSelecionada?.totalParcela || 0,
            tipoPagamentoEntrada: entradaTipo,
            htmlContrato: contratoServicoRegular?.servicoUnidadeContratoHTML || ""
          }
        ]
      });
    }

    if (servicosObrigatorios.length > 0) {
      servicosObrigatorios.forEach(servico => {
        const parcela = parcelasObrigatoriosSelecionadas[servico.id];
        const entrada = parcela?.entradas![0] || null;
        if (parcela) {
          checkoutRequest.push({
            DadoCartao: obrigatoriosPossuiRecorrente ? selectedRecorrenciaCreditCard || {} : selectedEntradaCreditCard || {},
            DadosBancarios: dadosBancarios || {},
            Tipo: obrigatoriosPossuiRecorrente ? parcelamentoTipo : entradaTipo,
            device,
            Servicos: [
              {
                idservico: servico.id,
                qntParcela: parcela.totalParcela || 1,
                name: "Obrigatorio",
                diasVencimentoPagamento: diasVencimentoPagamento || "0",
                parcelamentoId: parcela.id,
                parcelamentoEntradaId: entrada?.id ?? "00000000-0000-0000-0000-000000000000",
                qntParcelaEntrada: entrada?.totalParcela || 0,
                tipoPagamentoEntrada: entradaTipo,
                tipoPagamento: obrigatoriosPossuiRecorrente ? parcelamentoTipo : entradaTipo,
                dadosCartao: obrigatoriosPossuiRecorrente ? selectedRecorrenciaCreditCard || {} : selectedEntradaCreditCard || {},
                dadosBancarios: dadosBancarios || {},
              }
            ]
          });
        }
      });
    }

    if (servicosUpSellingSelecionados.length > 0) {
      servicosUpSellingSelecionados.forEach(servico => {
        const parcela = parcelasUpSellingSelecionadas[servico.id];
        const contrato = contratosUpSelling[servico.id];
        if (parcela) {
          checkoutRequest.push({
            DadoCartao: upSellingPossuiRecorrente ? selectedRecorrenciaCreditCard || {} : selectedEntradaCreditCard || {},
            DadosBancarios: dadosBancarios || {},
            Tipo: parcelamentoTipo,
            device,
            Servicos: [
              {
                idservico: servico.id,
                qntParcela: parcela.totalParcela || 1,
                name: "Complementar",
                diasVencimentoPagamento: diasVencimentoPagamento || "0",
                parcelamentoId: parcela.id,
                tipoPagamento: parcelamentoTipo,
                dadosCartao: upSellingPossuiRecorrente ? selectedRecorrenciaCreditCard || {} : selectedEntradaCreditCard || {},
                dadosBancarios: dadosBancarios || {},
                htmlContrato: contrato?.servicoUnidadeContratoHTML || ""
              }
            ]
          });
        }
      });
    }

    if (parcelaEntradaRegularSelecionada) {
      checkoutRequest.push({
        DadoCartao: selectedEntradaCreditCard || {},
        DadosBancarios: dadosBancarios || {},
        Tipo: entradaTipo,
        device,
        Servicos: [
          {
            idservico: servicoPrincipal?.id,
            qntParcelaEntrada: parcelaEntradaRegularSelecionada.totalParcela || 1,
            name: "Entrada",
            diasVencimentoPagamento: diasVencimentoPagamento || "0",
            parcelamentoEntradaId: parcelaEntradaRegularSelecionada.id,
            tipoPagamentoEntrada: entradaTipo,
            dadosCartao: selectedEntradaCreditCard || {},
            dadosBancarios: dadosBancarios || {},
            htmlContrato: contratoServicoRegular?.servicoUnidadeContratoHTML || ""
          }
        ]
      });
    }

    return checkoutRequest;
  },
});

export default checkoutRequestSelector;
