import {selector} from "recoil";
import selectedCalcListServiceSelector from "../../selectedCalcListServiceSelector";

const servicoRegularSelector = selector({
  key: 'servicoRegularSelector',
  get: ({get}) => {
    let servicos = get(selectedCalcListServiceSelector);
    return servicos?.servicoPrincipal;
  },
});

export default servicoRegularSelector;
