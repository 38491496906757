import {selector} from "recoil";
import {selectedMatriculaState} from "../atoms/selectedMatriculaState";
import tipoMatricula from "../../utils/tipoMatricula";

const isPreReservaSemValorSelector = selector<boolean>({
  key: 'isPreReservaSemValorSelector', // unique ID (with respect to other atoms/selectors)
  get: ({get}) => {
    const selectedMatricula = get(selectedMatriculaState);
    return  selectedMatricula?.tipoMatricula_Value === tipoMatricula.reservaSemValor
  },
});

export default isPreReservaSemValorSelector

