import type {FC, ReactElement, ReactNode} from 'react';
import PropTypes from 'prop-types';
import Drawer from '@mui/material/Drawer';
import useMediaQuery from '@mui/material/useMediaQuery';
import type {Theme} from '@mui/material/styles/createTheme';
import {Scrollbar} from "./scrollbar";


interface ListSidebarProps {
  container?: HTMLDivElement | null;
  children?: ReactNode;
  onClose?: () => void;
  open?: boolean;
  width?: number;
  anchor?: "left" | "top" | "right" | "bottom" | undefined
}

export const ListSidebar: FC<ListSidebarProps> = (props) => {
  const {
    container,
    onClose,
    open,
    children,
    width = 340,
    anchor = "left",
    ...other
  } = props;
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));

  if (lgUp) {
    return (
      <Drawer
        anchor={anchor}
        open={open}
        PaperProps={{
          // elevation: 16,
          sx: {
            border: 'none',
            // borderRadius: 2.5,
            overflow: 'hidden',
            overflowY: 'auto',
            position: 'relative',
            background: 'transparent',
            width,
            zIndex: 0,
            height: '100%'
          }
        }}
        SlideProps={{ container }}
        variant="persistent"
        sx={{
          height: '100%'
        }}
        {...other}
      >
        <Scrollbar>
          {children}
        </Scrollbar>
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor={anchor}
      hideBackdrop
      ModalProps={{
        container,
        sx: {
          pointerEvents: 'none',
          position: 'absolute'
        }
      }}
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          maxWidth: '100%',
          width: width + 40,
          pointerEvents: 'auto',
          position: 'absolute'
        }
      }}
      SlideProps={{ container }}
      variant="temporary"
      {...other}
    >
      {children}
    </Drawer>
  );
};

ListSidebar.propTypes = {
  container: PropTypes.any,
  children: PropTypes.any,
  onClose: PropTypes.func,
  open: PropTypes.bool
};
