import React from 'react';
import {BRANDS_CONFIG} from "../../modules/Finance/constants";
import Icon from '@mdi/react';
import { mdiCreditCard } from '@mdi/js';

function PaymentIcon(
  {
    id,
    ...props
  }
) {
  const img = BRANDS_CONFIG()[id]?.img || "";
  
  if (!img) {
    return (
      <Icon 
        path={mdiCreditCard} 
        size={1}
        {...props}
      />
    );
  }
  
  return (
    <img
      src={img} 
      alt={id}
      {...props}
    />
  );
}

export default PaymentIcon;