import React from 'react';
import Negotiation from "../../modules/Negotiation/Finance";
import Container from "@mui/material/Container";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import {CardContent} from "@mui/material";
import Card from "@mui/material/Card";
import {useTheme} from "@mui/material/styles";
import {AcordoLayout} from "../../layouts/AcordoLayout";

function HomePortal(props) {
  const theme = useTheme();
  return (
    <AcordoLayout>
      <Container>
        <Card
          className="home-wallet-container-main-card"
          sx={{marginTop: theme.spacing(3)}}
        >
          <CardHeader
            title="Acordo"
            subheader="Aqui você pode realizar acordos financeiros com a escola."
          />
          <Divider/>

          <CardContent>
            <Negotiation
              {...props}
            />
          </CardContent>
        </Card>
      </Container>
    </AcordoLayout>
  );
}

export default HomePortal;
