import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { lowerCase } from 'lodash'
import classNames from 'classnames'
import moment from 'moment'
import { PAYMENT_TYPE, BRANDS_CONFIG, PAYMENT_TYPE_CONFIG } from '../../constants'
import { receiptSelector } from '../../selectors/receipt'
import SummaryTable from '../../../../modules/Finance/components/SummaryTable'
import { formatOwnName } from '../../../../utils/formatString'
import { currency } from '../../../../utils/utils'
import { FormattedMessage, injectIntl, FormattedPlural } from 'react-intl'

import useStyles from './style'
import { set } from '../../../../layouts/actions'
import Typography from "@mui/material/Typography";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {Grid} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

const infosReceipts = (classes) => [
  { title: 'receipt-name', id: 'lbl-pag-nome', render: (row) => formatOwnName(row.cardHolderName) },
  { title: 'receipt-last-card', id: 'lbl-pag-last-num', render: (row) => `**** **** **** ${row.last4}` },
  { title: 'receipt-total-value', id: 'lbl-pag-vlr', render: (row) => <label>{currency(parseInt(row.correctValue) / 100)}</label> },
  { title: 'receipt-payment-type', id: 'lbl-pag-tip-pag', html: true, render: (row) => <TipoPagamento classes={classes} {...row} /> },
  { title: 'receipt-authorization-number', id: 'lbl-pag-num-auth', render: (row) => row.authorizationCode },
  { title: 'receipt-nsu', id: 'lbl-pag-nsu', render: (row) => row.nsu },
  { title: 'receipt-transaction-number', id: 'lbl-pag-num-tran', render: (row) => row.billetTransId },
]

const columnInvoice = { title: 'Fatura', field: 'invoiceNumber', cellStyle: { fontSize: 12 } }

const columnTotal = { title: 'Total', render: (attributes) => currency(attributes.salesValue), cellStyle: { fontSize: 12 } }

const TipoPagamento = ({ flag, paymenttype, kind, installments, classes }) => {
  if (flag !== null) {
    const brand = BRANDS_CONFIG(false)[lowerCase(flag)]
    return (
      <Typography className={classes.textValue}>
        {`${PAYMENT_TYPE.CARD} ${kind} `} &nbsp;{installments > 0 && `${installments}x`} &nbsp;{brand && <img className={classes.brandIcon} src={brand.img} alt='brand' />}
      </Typography>
    )
  }
  return <Typography className={classes.textValue}>{`${PAYMENT_TYPE_CONFIG[paymenttype]} `}</Typography>
}

const columns = (isSmall) => {
  if (isSmall) {
    return [columnInvoice, columnTotal]
  }
  return [
    columnInvoice,
    { title: 'Vencimento', field: 'expirationDate', render: (attributes) => moment(attributes.expirationDate).format('DD/MM/YYYY'), cellStyle: { fontSize: 12 } },
    { title: 'Nome', render: (prop) => prop.studentName ? prop.studentName.split(' ')[0] : '---', cellStyle: { fontSize: 12 } },
    columnTotal
  ]
}

export default injectIntl(({ intl: { messages }, open, onClose }) => {

  const classes = useStyles()
  const theme = useTheme()
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'))

  const dispatch = useDispatch()
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'))
  const { data: receipt, error } = useSelector(receiptSelector)
  const infos = infosReceipts(classes)

  if (error) {
    onClose()
    dispatch(set(messages['message-error-unexpected']))
    return null
  }

  const { paymentDate, installmentInfos } = receipt

  const print = () => {
    window.print()
  }

  const handleClose = () => {
    onClose()
  }

  return (
    <div id='printThis'>
      <Dialog
        maxWidth={'sm'}
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby='payment-dialog-title'
        classes={{ paper: !fullScreen && classes.paperScrollPaper }}
      >
        <DialogContent classes={{ root: classes.receiptRoot }}>
          <Typography align='center' variant='h6' component='h2'>
            <FormattedMessage id='receipt-title' />
          </Typography>
          <Typography align='center' variant='subtitle2' component='h1' >
            {moment(paymentDate).format('DD/MM/YYYY HH:mm:ss')}
          </Typography>
          <DialogContentText classes={{ root: classes.noMarginPadding }} component='div' >
            <Grid container className={classes.containerDialog} spacing={1} >
              {infos.map(info =>
                <Grid item key={info.id} xs={6} >
                  <DialogContentRow {...info} row={receipt} />
                </Grid>
              )}
              {installmentInfos && <> <Grid item xs={12} className={classes.rowContent} >
                <Typography align='left'>
                  <FormattedPlural value={installmentInfos.length} one='Fatura' other='Faturas' />
                </Typography>
              </Grid>
              <Grid item xs={12} >
                <SummaryTable item={installmentInfos} columns={columns} hasDetail={false} />
              </Grid></>}
            </Grid>
            <Typography id='lbl-pag-comp-pag' variant='h6' align='center'>
              <FormattedMessage id='receipt-payment-number' />
            </Typography>
            <Typography align='center'>
              {receipt.salesReceiptNumber}
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions id='dialogActions' className={classNames(isSmall && classes.buttons)}>
          <Box displayPrint='none'>
            <Button id='btnPrint' onClick={print}>
              <FormattedMessage id='receipt-button-print' />
            </Button>
            <Button id='btnExit' onClick={handleClose}>
              <FormattedMessage id='receipt-button-exit' />
            </Button>
          </Box>
        </DialogActions>
      </Dialog >
    </div>
  )
})

const DialogContentRow = ({ title, id, align, html, render, row }) => {
  const classes = useStyles()
  const value = render(row)
  if (!value) {
    return null
  }
  return (
    <>
      <Grid item xs={12} className={classes.rowContent}>
        <Typography id={id} align={align}>
          <FormattedMessage id={title} />
        </Typography>
      </Grid>
      <Grid item xs={12} >
        {html ? render(row) :
          <Typography align='left' className={classes.textValue}>
            {render(row)}
          </Typography>
        }
      </Grid>
    </>
  )
}
