import { en } from '../locales/translations/en';
import { ptBR } from '../locales/translations/pt-br';
import {useSettings} from "./use-settings";

export type Language = 'en' | 'ptBR';

export function useTranslation() {
    const s = useSettings();
    const translations = {
        en,
        ptBR
    }
    return {
        t: (text: string) => translations[s.language][text] ?? text,
        i18n: {
            language: s.language,
            changeLanguage: async (language: Language) => s.handleChangeLanguage(language)
        }
    }
}
