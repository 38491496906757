import {atom} from "recoil";
import {persistAtomEffect} from "../effects/persistAtom";

export const selectedMatriculasState = atom<Array<string>>({
  key: 'selectedMatriculasState',
  default: [],
  effects: [
    persistAtomEffect,
  ]
});
