import React, {SyntheticEvent, useEffect, useState} from "react";
import {Layout as MomentoEscolarLayout} from "../../layouts/momento-escolar";
import StepCheckoutActionButtons from "../../components/StepCheckoutActionButtons/StepCheckoutActionButtons";
import {useRecoilState, useRecoilValue, useSetRecoilState} from "recoil";
import {selectedMatriculaState} from "../../recoil/atoms/selectedMatriculaState";
import { globalLoadingState } from "../../recoil/atoms/globalLoadingState";
import Loading from "./loading";
import * as _parametrosPagamentoService from "../../service/parametros-pagamento-service";
import * as _itemContratoService from "../../service/item-contrato-service";
import * as _metodoPagamentoService from "../../service/metodo-pagamento-service";
import * as _calcService from "../../service/calc-service";
import * as _checkoutService from "../../service/checkout-service";
import Typography from "@mui/material/Typography";
import TabelaResumo from "./TabelaResumo";
import Box from "@mui/material/Box";
import MetodoAccordion from "./MetodoAccordion";
import AcceptContract from "../../components/AcceptContract/AcceptContract";
import * as _blackListService from "../../service/blacklist-service";
import BlockedResourcesSystem from "../../utils/blockedResourcesSystem";
import {IMeioPagamento} from "../../@types/IServicoResumoQuery";
import {selectedMeioPagamentoEntradaState} from "../../recoil/atoms/selectedMeioPagamentoEntradaState";
import {selectedMeioPagamentoParcelamentoState} from "../../recoil/atoms/selectedMeioPagamentoParcelamentoState";
import {acceptedContractsState} from "../../recoil/atoms/acceptedContractsState";
import canFinishCheckoutSelector from "../../recoil/selectors/canFinishCheckoutSelector";
import {PATH_ROUTE} from "../../routes";
import {useHistory} from "react-router-dom";
import possuiRecorrenteSelector from "../../recoil/selectors/possuiRecorrenteSelector";
import possuiEntradaSelector from "../../recoil/selectors/possuiEntradaSelector";
import {fromServicosDisponiveisState} from "../../recoil/atoms/fromServicosDisponiveisState";
import {CardContent} from "@mui/material";
import {toast} from "react-toastify";
import isPreReservaSemValorSelector from "../../recoil/selectors/isPreReservaSemValorSelector";
import isPreReservaSelector from "../../recoil/selectors/isPreReservaSelector";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import servicoRegularSelector from "../../recoil/selectors/carrinho/servicoRegular/servicoRegularSelector";
import Divider from "@mui/material/Divider";
import {currency} from "../../utils/utils";
import servicosObrigatoriosSelector
  from "../../recoil/selectors/carrinho/servicosObrigatorios/servicosObrigatoriosSelector";
import {logPurchase} from "../../utils/GTM";

const MomentoEscolarServicos: React.FC = () => {
  const selectedMatricula = useRecoilValue(selectedMatriculaState);
  const [isLoaded, setIsLoaded] = useState(false);
  const setSelectedMeioPagamentoEntrada = useSetRecoilState(selectedMeioPagamentoEntradaState);
  const setSelectedMeioPagamentoParcelamento = useSetRecoilState(selectedMeioPagamentoParcelamentoState);
  const [acceptedContracts, setAcceptedContracts] = useRecoilState(acceptedContractsState);
  const canFinishCheckout = useRecoilValue(canFinishCheckoutSelector);
  const history = useHistory();
  const possuiRecorrente = useRecoilValue(possuiRecorrenteSelector);
  const possuiEntrada = useRecoilValue(possuiEntradaSelector);
  const fromServicosDisponiveis = useRecoilValue(fromServicosDisponiveisState);
  const [globalLoading, setGlobalLoading] = useRecoilState(globalLoadingState);
  const isReserva = useRecoilValue(isPreReservaSelector);
  const isReservaSemValor = useRecoilValue(isPreReservaSemValorSelector);
  const servicoPrincipal = useRecoilValue(servicoRegularSelector);
  const servicosObrigatorios = useRecoilValue(servicosObrigatoriosSelector);
  const [showSuccessAnimation, setShowSuccessAnimation] = useState(false);

  const {showLoading} = globalLoading;
  useEffect(() => {
    const idMatricula = selectedMatricula?.id
    if (idMatricula && selectedMatricula?.servico_Id && selectedMatricula.id && selectedMatricula?.unidade_Id) {
      setSelectedMeioPagamentoEntrada(null);
      setSelectedMeioPagamentoParcelamento(null);

      //Monta método pagametno
      const listServico = new Array();

      listServico.push(servicoPrincipal?.id);

      if (servicosObrigatorios != null && servicosObrigatorios.length > 0) {
        servicosObrigatorios.map(servico => {

          listServico.push(servico?.id);
        });
      }

      Promise.all([
        _parametrosPagamentoService.ObterPorIdUnidade(selectedMatricula?.unidade_Id),
        _metodoPagamentoService.GetByUnidade({ matriculaId: idMatricula, unidadeId: selectedMatricula?.unidade_Id, servicos: listServico }),
        _blackListService.GetBySistemaId(BlockedResourcesSystem.SMO),
        _calcService.resumoListar(),
        _itemContratoService.getNomesDosContratos(),
      ]).then(() => setIsLoaded(true));
    }
  }, []);

  const handleSelectMeioPagamentoEntrada = (meioPagamento: IMeioPagamento) => {
    setSelectedMeioPagamentoEntrada(meioPagamento);
    _calcService.resumoListar();
  }

  const handleSelectMeioPagamentoParcelamento = (meioPagamento: IMeioPagamento) => {
    setSelectedMeioPagamentoParcelamento(meioPagamento);
    _calcService.resumoListar();
  }

  const handleAcceptContract = (event: SyntheticEvent<Element, Event>) => {
    event.preventDefault();
    setAcceptedContracts(true);
  }

  const handleSubmit = () => {
    if (showLoading) return;
    setGlobalLoading({showLoading: true, textToDisplay: "Aguarde, estamos finalizando a sua contratação"});
    setShowSuccessAnimation(false);

    _itemContratoService.AceiteContrato(selectedMatricula?.id)
      .then(() => {
        return _checkoutService.concluiMatricula()
          .then(() => {
            setShowSuccessAnimation(true);
            logPurchase()
            setTimeout(() => history.push(PATH_ROUTE.CHECKOUT_CONCLUIDO), 2000);
          })
          .catch((error) => {
            if (String(error).includes("<html>")) {
              const Component = () => <div dangerouslySetInnerHTML={{__html: error}}/>
              toast.error(<Component/>)
            }
          })
          .finally(() => setGlobalLoading({showLoading: false, textToDisplay: "Carregando"}))
      });
  }

  return (
    <MomentoEscolarLayout
      breads={fromServicosDisponiveis ? [
        {to: PATH_ROUTE.CADASTRO_CONCLUIDO, text: "Home"},
        {to: PATH_ROUTE.SERVICOS_DISPONIVEIS, text: "Serviços Extras"},
        {text: "Momento Escolar Pagamento"}
      ] : isReserva && isReservaSemValor ? [
        {to: PATH_ROUTE.CADASTRO_CONCLUIDO, text: "Home"},
        {to: `${PATH_ROUTE.MOMENTO_ESCOLAR}?idmatricula=${selectedMatricula?.id}`, text: "Momento Escolar"},
        {text: "Reserva"}
      ] : [
        {to: PATH_ROUTE.CADASTRO_CONCLUIDO, text: "Home"},
        {to: `${PATH_ROUTE.MOMENTO_ESCOLAR}?idmatricula=${selectedMatricula?.id}`, text: "Momento Escolar"},
        {to: PATH_ROUTE.MOMENTO_ESCOLAR_SERVICOS, text: "Momento Escolar Serviços"},
        {text: "Momento Escolar Pagamento"}
      ]}
      loading={!isLoaded}
      skeleton={<Loading/>}
      actions={
        <StepCheckoutActionButtons
          previousStepPath={fromServicosDisponiveis ? PATH_ROUTE.SERVICOS_DISPONIVEIS : isReserva && isReservaSemValor ? `${PATH_ROUTE.MOMENTO_ESCOLAR}?idmatricula=${selectedMatricula?.id}` : PATH_ROUTE.MOMENTO_ESCOLAR_SERVICOS}
          nextStepPath={PATH_ROUTE.CHECKOUT_CONCLUIDO}
          onClickNextStep={handleSubmit}
          nextStepDisabled={!canFinishCheckout}
          loading={showLoading}
          showCart
          showSuccessAnimation={showSuccessAnimation}
        />
      }
      fullScreenPaper
    >

      {isReservaSemValor && (
        <React.Fragment>
          <Typography
            fontWeight="bold"
            variant="h5"
          >
            Regular:
          </Typography>

          <Box
            sx={{
              pt: 2
            }}
          >
            <Card>
              <CardHeader
                title={servicoPrincipal?.nome}
              />
              <Divider/>
              <CardContent>
                <Typography>
                  Valor total do contrato: <b>{currency(servicoPrincipal?.valorContrato)}</b>
                </Typography>
              </CardContent>
            </Card>
          </Box>

          {servicosObrigatorios.length > 0 && (
            <>
              <Typography
                fontWeight="bold"
                variant="h5"
                sx={{
                  pt: 4
                }}
              >
                Materiais ou Taxas:
              </Typography>

              <Box
                sx={{
                  pt: 2
                }}
              >
                {servicosObrigatorios.map(servico => (
                  <Card key={servico.id}>
                    <CardHeader
                      title={servico.tipo === "MaterialDidatico" ? "Material didático" : servico.nome}
                    />
                    <Divider/>
                    <CardContent>
                      <Typography>
                        Valor total do contrato: <b>{currency(servico?.valorContrato)}</b>
                      </Typography>
                    </CardContent>
                  </Card>
                ))}
              </Box>
            </>
          )}
        </React.Fragment>
      )}

      {(!isReservaSemValor && possuiEntrada) && (
        <Card
          elevation={1}
          sx={{
            boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px'
          }}
        >
          <CardHeader
            title="Valores da primeira parcela (incluindo entradas)"
            subheader="Escolha como deseja realizar o pagamento"
          />
          <CardContent>
            <TabelaResumo
              entrada
            />

            <MetodoAccordion
              entrada
              onSelectMeioPagamento={handleSelectMeioPagamentoEntrada}
            />
          </CardContent>
        </Card>
      )}

      {(!isReservaSemValor && possuiRecorrente) && (
        <Card
          elevation={1}
          sx={{
            mt: 2,
            boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px'
          }}
        >
          <CardHeader
            title="Parcelamento de todos os serviços (regular, material didático e extras)"
            subheader="Escolha como deseja realizar o pagamento"
          />
          <CardContent>
            <TabelaResumo/>

            <MetodoAccordion
              onSelectMeioPagamento={handleSelectMeioPagamentoParcelamento}
            />
          </CardContent>
        </Card>
      )}

      <AcceptContract
        onContractConfirm={handleAcceptContract}
        acceptContract={acceptedContracts}
      />
    </MomentoEscolarLayout>
  )
}

export default MomentoEscolarServicos;
