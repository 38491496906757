import { tokens } from '../tokens';

export const ptBR = {
  [tokens.common.languageChanged]: 'Idioma alterado',
  [tokens.common.reset]: 'Resetar',
  [tokens.common.resetAll]: 'Resetar Todos',
  [tokens.common.password]: 'Senha',
  [tokens.common.onWeek]: 'Na semana',
  [tokens.common.fullName]: 'Nome Completo',
  [tokens.common.emailAdress]: 'Endereço de E-mail',
  [tokens.common.multiFactorAuthentication]: 'Autenticação de múltiplos fatores',
  [tokens.common.alternativeAuthentication]: 'Outros métodos de autenticação',
  [tokens.common.new]: 'Novo',
  [tokens.common.filters]: 'Filtros',
  [tokens.common.createdAt]: 'Criado Em',
  [tokens.common.updatedAt]: 'Alterado Em',
  [tokens.common.deletedAt]: 'Removido At',
  [tokens.common.rowsPerPage]: 'Linhas por página',
  [tokens.common.of]: 'de',
  [tokens.common.in]: 'Em',
  [tokens.common.installment]: 'Parcelamento',
  [tokens.common.moreThan]: 'mais de',
  [tokens.common.from]: 'De',
  [tokens.common.to]: 'Até',
  [tokens.common.setUp]: 'Configurar',
  [tokens.common.loginHistory]: 'Histórico de login',
  [tokens.common.search]: 'Buscar',
  [tokens.common.name]: 'Nome',
  [tokens.common.entry]: 'Entrada',
  [tokens.common.creationDate]: 'Data de Criação',
  [tokens.common.actions]: 'Ações',
  [tokens.common.save]: 'Salvar',
  [tokens.common.saved]: 'Salvo',
  [tokens.common.edit]: 'Editar',
  [tokens.common.disable]: 'Desabilitar',
  [tokens.common.block]: 'Bloquear',
  [tokens.common.edited]: 'Editado',
  [tokens.common.change]: 'Alterar',
  [tokens.common.changed]: 'Alterado',
  [tokens.common.select]: 'Selecionar',
  [tokens.common.selected]: 'Selecionado',
  [tokens.common.saving]: 'Salvando',
  [tokens.common.security]: 'Segurança',
  [tokens.common.portal]: 'Portal',
  [tokens.common.general]: 'Geral',
  [tokens.common.pay]: 'Pagar',
  [tokens.common.generateInvoice]: 'Gerar Boleto',
  [tokens.common.copyTypeableLine]: 'Copiar Linha Digitável',
  [tokens.common.receipt]: 'Comprovante',
  [tokens.common.basicDetails]: 'Detalhes Básicos',
  [tokens.common.details]: 'Detalhes',
  [tokens.common.clickToSeePaymentDetails]: 'Clique para ver os detalhes do pagamento',
  [tokens.common.cancel]: 'Cancelar',
  [tokens.common.backToHome]: 'Voltar para Home',
  [tokens.common.overdueInstallments]: 'Parcelas com vencimento próximo ou vencidas',
  [tokens.common.outstandingBills]: 'Parcelas Abertas',
  [tokens.common.paidBills]: 'Parcelas Pagas',
  [tokens.common.paidBillsDesc]: 'Aqui estão todas as parcelas que já foram liquidadas.',
  [tokens.common.financialDesc]: 'Aqui estão todos os dados referente ao financeiro!',
  [tokens.common.financialDeclarationDesc]: 'Aqui estão todas as Declarações de imposto de Renda.',
  [tokens.common.student]: 'Estudante',
  [tokens.common.school]: 'Escola',
  [tokens.common.schoolMoment]: 'Momento Escolar',
  [tokens.common.selectStudent]: 'Selecione o Estudante',
  [tokens.common.displayFinancialServices]: 'Para exibir as informações financeiras dos serviços e produtos contratados.',
  [tokens.common.contractedServicesAndProducts]: 'Serviços e Produtos Contratados',
  [tokens.common.servicesContractedByStudent]: 'Aqui estão todos os serviços e produtos contratados para o estudante',
  [tokens.common.paymentMethod]: "Método de Pagamento",
  [tokens.common.paymentForm]: "Forma de Pagamento",
  [tokens.common.regularService]: "Serviço Regular",
  [tokens.common.totalGross]: "Total Bruto",
  [tokens.common.includingEntry]: "Incluindo Entrada",
  [tokens.common.noServicesContracted]: "Nenhum serviço contratado",
  [tokens.common.displayFinancialServicesPaymentMethod]: 'Aqui estão todos os serviços e produtos contratados, clique aqui para fazer a mudança de método  de pagamento.',
  [tokens.common.liquidation]: 'Liquidação',
  [tokens.common.documentValue]: 'Valor do Documento',
  [tokens.common.settledValue]: 'Valor Liquidado',
  [tokens.common.availablePaymentMethods]: 'Método de Pagamento Disponíveis',
  [tokens.common.basicPayment]: 'Pagamento Básico',
  [tokens.common.paymentSlip]: 'Boleto Bancário',
  [tokens.common.billet]: 'Boleto',
  [tokens.common.card]: 'Cartão',
  [tokens.common.automaticDebit]: 'Débito Automático',
  [tokens.common.automaticDebitDiscount]: 'Desconto de 5%',
  [tokens.common.discountOf]: 'Desconto de',
  [tokens.common.recurringCreditCard]: 'Cartão de Crédito Recorrente',
  [tokens.common.recurringCreditCardDiscount]: 'Desconto de 5%',
  [tokens.common.modalClickInformation]: 'Clique para abrir o modal com mais informações',
  [tokens.common.knowMore]: 'Saiba mais',
  [tokens.common.changeBankDetails]: 'Alterar Dados Bancários',
  [tokens.common.changeCreditCard]: 'Alterar Cartão',
  [tokens.common.viewCurrentCard]: 'Visualizar Cartão Vigente',
  [tokens.common.enrollment]: 'Matrícula',
  [tokens.common.currentMethod]: 'Método Atual',
  [tokens.common.incomeTax]: 'Imposto de Renda',
  [tokens.common.fullReceipt]: 'Recebimento Total',
  [tokens.common.year]: 'Ano',
  [tokens.common.noValidEnrollments]: 'Não existem matrículas ativas no momento ou a sua matrícula está cancelada.',
  [tokens.common.monthYear]: 'Mês/Ano',
  [tokens.common.dueDate]: 'Vencimento',
  [tokens.common.invoice]: 'Fatura',
  [tokens.common.dueDateValue]: 'Valor no Vencimento',
  [tokens.common.installmentAvailable]: 'Parcelamento Disponível',
  [tokens.common.situation]: 'Situação',
  [tokens.common.other]: 'Outros',
  [tokens.page.account.loginHistorySub]: 'Entradas recentes',
  [tokens.page.account.loginType]: 'Tipo de Login',
  [tokens.page.account.ipAdress]: 'Endereço',
  [tokens.page.account.client]: 'Dispositivo',
  [tokens.page.notAuthorized.title]: '401: Autorização necessária',
  [tokens.page.notAuthorized.subtitle]: 'Você tentou algum caminho obscuro ou veio aqui por engano. Seja o que for, tente usar a navegação.',
  [tokens.layout.language]: 'Idioma',
  [tokens.layout.notifications]: 'Notificações',
  [tokens.layout.topNav.account.logout]: 'Sair',
  [tokens.layout.topNav.account.settings]: 'Configurações',
  [tokens.layout.topNav.account.profile]: 'Perfil',
  [tokens.layout.topNav.account.billing]: 'Pagamento',
  [tokens.settings.appSettings]: 'Configurações do App',
  [tokens.settings.orientation]: 'Orientação',
  [tokens.settings.content]: 'Conteúdo',
  [tokens.settings.layout]: 'Leiaute',
  [tokens.settings.vertical]: 'Vertical',
  [tokens.settings.horizontal]: 'Horizontal',
  [tokens.settings.dark]: 'Escuro',
  [tokens.settings.light]: 'Claro',
  [tokens.settings.colorScheme]: 'Esquema de Cores',
  [tokens.settings.primaryColor]: 'Cor Primária',
  [tokens.settings.purple]: 'Roxo',
  [tokens.settings.blue]: 'Azul',
  [tokens.settings.green]: 'Verde',
  [tokens.settings.indigo]: 'Índigo',
  [tokens.settings.compact]: 'Compacto',
  [tokens.settings.wide]: 'Largo',
  [tokens.settings.normal]: 'Normal',
  [tokens.settings.high]: 'Alto',
  [tokens.settings.leftToRight]: 'Esquerda para Direita',
  [tokens.settings.rightToLeft]: 'Direita para Esquerda',
  [tokens.settings.blendIn]: 'Misturar',
  [tokens.settings.discrete]: 'Discreto',
  [tokens.settings.evident]: 'Evidente',
  [tokens.settings.navColor]: 'Cor da Navegação',
  [tokens.settings.contrast]: 'Contraste',
  [tokens.auth.ad.login]: 'Entrar',
  [tokens.auth.jwt.title]: 'Faça seu login',
  [tokens.auth.jwt.login]: 'Entrar',
  [tokens.auth.jwt.subtitle]: 'Preencha os campos abaixo para entrar no sistema',
  [tokens.auth.ad.title]: 'Entrar',
  [tokens.auth.ad.subtitle]: 'Escolha um método de login abaixo para entrar no sistema',
  [tokens.auth.ad.enter]: 'Entrar com Microsoft',
  [tokens.auth.facial.enter]: 'Entrar com reconhecimento facial',
  [tokens.environment.development]: 'Desenvolvimento',
  [tokens.environment.homologation]: 'Homologação',
  [tokens.environment.production]: 'Produção',
  [tokens.environment.staging]: 'Staging',
  [tokens.environment.test]: 'Teste',
  [tokens.auth.facial.feedback.faceTooLow]: 'Rosto muito baixo',
  [tokens.auth.facial.feedback.invalidPose]: 'Pose inválida',
  [tokens.auth.facial.feedback.leftEyeClosed]: 'Olho esquerdo fechado',
  [tokens.auth.facial.feedback.rightEyeClosed]: 'Olho direito fechado',
  [tokens.auth.facial.feedback.faceTooClose]: 'Rosto muito perto',
  [tokens.auth.facial.feedback.faceTooFar]: 'Rosto muito longe',
  [tokens.auth.facial.feedback.faceTooHigh]: 'Rosto muito alto',
  [tokens.auth.facial.feedback.noFaceDetected]: 'Nenhum rosto detectado',
  [tokens.nav.enrollment]: 'Matrículas',
  [tokens.nav.md]: 'Matrículas',
  [tokens.nav.services]: 'Serviços extras',
  [tokens.nav.financial]: 'Financeiro',
  [tokens.nav.financialHome]: 'Início',
  [tokens.nav.financialOpenQuotas]: 'Parc. Abertas',
  [tokens.nav.financialPaidQuotas]: 'Parc. Pagas',
  [tokens.nav.financialAgreement]: 'Acordo',
  [tokens.nav.financialHistory]: 'Histórico',
  [tokens.nav.financialDeclarations]: 'Declarações',
  [tokens.nav.financialPaymentMethod]: 'Mudança Método Pag.',
  [tokens.nav.financialCards]: 'Meus Cartões',
  [tokens.nav.agreements]: 'Acordo',
};
