import React, {useEffect, useState} from "react";
import {useRecoilState, useRecoilValue, useSetRecoilState} from "recoil";
import acceptedCards from "../../utils/acceptedCards";
import Card from "react-credit-cards";
import {Card as CardBootStrap, Container, ListGroup} from 'react-bootstrap';
import WalletSelectionPreview from "./WalletSelectionPreview";
import {walletCardChangeSubsState} from "../../recoil/atoms/walletCardChangeSubsState";
import {walletCardSubsState} from "../../recoil/atoms/walletCardSubsState";
import WalletCardsList from "./WalletCardsList";
import {creditCardsState} from "../../recoil/atoms/creditCardsState";
import * as mensagem from '../../components/shared/Message.js';
import * as _walletService from "../../service/wallet-service";
import * as _matriculaService from '../../service/matricula-service.js';
import WalletContractList from "./WalletContractList";
import {walletContractsCheckedState} from "../../recoil/atoms/walletContractsCheckedState";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import {LoadingButton} from "@mui/lab";
import WalletLoading from "./WalletLoading";
import WalletChangeSubsSteps from "./WalletChangeSubsSteps";
import {responsavelState} from "../../recoil/atoms/responsavelState";
import {selectedMatriculaState} from "../../recoil/atoms/selectedMatriculaState";
import {selectedUnidadeState} from "../../recoil/atoms/selectedUnidadeState";

function WalletChangeSubs(
  {
    onCancel = () => {
    }
  }
) {
  const {
    cardId,
    lastFourDigits = '',
    expirationYear = '',
    expirationMonth = '',
    cardholderName = '',
    brand = '',
  } = useRecoilValue(walletCardChangeSubsState);
  const originalCard = useRecoilValue(walletCardChangeSubsState);
  const [step, setStep] = React.useState(1);
  const previewLabel = "Selecione o novo cartão para esta recorrência";
  const cardNumber = `**** **** **** ${lastFourDigits}`;
  const [isSaving, setIsSaving] = React.useState(false);
  const subs = useRecoilValue(walletCardSubsState);
  const setSubs = useSetRecoilState(walletCardSubsState);
  const [showCardSelection, setShowCardSelection] = React.useState(false);
  const [selectedCreditCard, setSelectedCreditCard] = React.useState(undefined);
  const [creditCards, setCreditCards] = useRecoilState(creditCardsState);
  const [selectedSub, setSelectedSub] = React.useState(undefined);
  const [matriculas, setMatriculas] = useState([]);
  const [loading, setLoading] = React.useState(false);
  const [checked, setChecked] = useRecoilState(walletContractsCheckedState);
  const responsavel = useRecoilValue(responsavelState);
  const selectedMatricula = useRecoilValue(selectedMatriculaState);
  const unidade = useRecoilValue(selectedUnidadeState);

  useEffect(() => {
    if (loading) return;

    setLoading(true);

    _matriculaService.GetbyIdResponsavel(responsavel?.id ?? localStorage.getItem("@SMO20/idResponsavel"))
      .then(resp => {
        if (resp && resp?.value) {
          setMatriculas(resp.value);
        }
      }).finally(() => setLoading(false))
  }, [])

  const onSubmit = async (event) => {
    event.preventDefault();

    if (step === 1) {
      const changedAllSubs = subs.find(s => s.subscription.payment_type.credit.card.card_id === cardId);

      if (changedAllSubs !== undefined) {
        mensagem.ExibeMensagem(`Altere o cartão de todas as recorrências para continuar.`, '2')
        return false;
      }

      setChecked([]);
      setStep(2)
      return;
    }

    if (isSaving) return;

    setIsSaving(true);

    let sellerId = selectedMatricula?.unidade_Id ?? unidade?.id;

    _walletService.cartaoAlterarAssinaturas(sellerId, cardId, subs)
      .then(resp => {
        if (resp.ok) {
          mensagem.ExibeMensagem(`Alteração realizada com sucesso!`, '0')
          onCancel();
        } else {
          mensagem.ExibeMensagem(`Não foi possível realizar a alteração.`, '2')
        }
      }).finally(() => setIsSaving(false))

    return false;
  }

  const handleBack = () => {
    if (showCardSelection) {
      setShowCardSelection(false);
    } else if (onCancel) {
      onCancel()
    }
  }

  const handleSelectCard = (cardId, sub) => () => {
    const card = creditCards.find(c => c.cardId === cardId);
    setShowCardSelection(true);
    setSelectedCreditCard(card);
    setSelectedSub(sub);
  }

  const handleCardSelection = (selectedCard) => {
    const indexOfSub = subs.indexOf(selectedSub);
    const newSubs = [...subs];

    newSubs[indexOfSub] = {
      ...selectedSub,
      subscription: {
        ...selectedSub.subscription,
        payment_type: {
          ...selectedSub.subscription.payment_type,
          credit: {
            ...selectedSub.subscription.payment_type.credit,
            card: {
              cardholder_name: selectedCard.cardholderName,
              expiration_month: selectedCard.expirationMonth,
              last_four_digits: selectedCard.lastFourDigits,
              expiration_year: selectedCard.expirationYear,
              brand: selectedCard.brand,
              card_id: selectedCard.cardId
            }
          }
        }
      }
    };

    setSubs(newSubs);
    setShowCardSelection(false);
    setSelectedCreditCard(undefined);
    setSelectedSub(undefined);
  }

  const renderStep = (step) => {
    switch (step) {
      case 1:
        return changeCardStep();
      case 2:
        return acceptStep();
      default:
        return null;
    }
  }

  const changeCardStep = () => {
    return (
      <>
        <WalletChangeSubsSteps activeStep={step-1}/>

        <Box
          sx={{
            mb: 3,
            mt: 3
          }}
        >
          <Card
            number={cardNumber}
            name={cardholderName}
            expiry={`${expirationMonth}/${expirationYear}`}
            acceptedCard={acceptedCards}
            preview={true}
            placeholders={{name: 'Nome impresso no cartão'}}
            locale={{valid: 'BR'}}
            issuer={brand}
          />
        </Box>

        {showCardSelection ? (
          <CardBootStrap>
            <CardBootStrap.Body>
              <WalletCardsList
                hiddenAddCard
                selectedCreditCard={selectedCreditCard}
                showRemove={false}
                onClickSelectCard={handleCardSelection}
              />
            </CardBootStrap.Body>
          </CardBootStrap>
        ) : (
          <ListGroup>
            {(subs || []).map(sub => {
              const {
                order_id,
                plan: {
                  name = "",
                },
                subscription: {
                  payment_type: {
                    credit: {
                      card: {
                        cardholder_name,
                        expiration_month,
                        last_four_digits,
                        expiration_year,
                        brand,
                        card_id,
                        bin
                      }
                    }
                  }
                }
              } = sub;
              let selected = undefined;

              if (card_id !== cardId || !(bin === originalCard?.bin && expiration_month === originalCard?.expirationMonth && expiration_year === originalCard?.expiration_year)) {
                selected = {
                  cardId: card_id,
                  lastFourDigits: card_id === cardId ? lastFourDigits : last_four_digits,
                  expirationYear: expiration_year,
                  expirationMonth: expiration_month,
                  cardholderName: cardholder_name,
                  brand: String(brand).toLowerCase(),
                  numberToken: null,
                  isExpired: false,
                  canRemove: false
                }
              }
              let nome = " - ";

              if (matriculas && matriculas.length) {
                let matricula = (matriculas || []).find(m => m?.codigoMatricula === order_id);

                if (matricula) {
                  nome = matricula?.nomeLandingPage || matricula?.servico_Name;
                }
              }

              return (
                <ListGroup.Item>
                  Serviço: {nome && <>{nome} <br/></>}
                  Plano: {name ?? ""} <br/>
                  <WalletSelectionPreview
                    card={selected}
                    label={previewLabel}
                    onClick={handleSelectCard(card_id, sub)}
                  />
                </ListGroup.Item>
              )
            })}
          </ListGroup>
        )}
      </>
    )
  }

  const acceptStep = () => {
    return (
      <>
        <WalletChangeSubsSteps activeStep={step-1}/>

        {matriculas !== null && (
          <WalletContractList
            matriculas={matriculas}
          />
        )}
      </>
    )
  }

  if (loading) return <WalletLoading/>

  return (
    <span>
      <Container className="mt-3">
        {renderStep(step)}
      </Container>

      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{
          mt: 5,
          width: '100%',
        }}
      >
        <Button
          color="secondary"
          onClick={handleBack}
          type="button"
          variant="outlined"
          disabled={isSaving}
          startIcon={(
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                rotate: '180deg'
              }}
            >
              <svg className="seb seb-arrow-right" width="24" height="24" viewBox="0 0 24 24" fill="currentColor"
                   xmlns="http://www.w3.org/2000/svg">
                <path d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z"/>
              </svg>
            </Box>
          )}
        >
          Voltar
        </Button>


        <LoadingButton
          type="button"
          disabled={isSaving}
          onClick={onSubmit}
          variant="contained"
          endIcon={(
            <svg className="seb seb-arrow-right" width="24" height="24" viewBox="0 0 24 24" fill="currentColor"
                 xmlns="http://www.w3.org/2000/svg">
              <path d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z"/>
            </svg>
          )}
          loading={isSaving}
        >
          {step === 2 ? "Finalizar" : "Próxima etapa"}
        </LoadingButton>
      </Stack>
    </span>
  )
}

export default WalletChangeSubs;
