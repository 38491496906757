import React from 'react'
import ListItemText from '@mui/material/ListItemText'
import Grid from '@mui/material/Grid'
import { currency } from '../../../../utils/utils'
import moment from 'moment'
import useStyles from './style'
import Table from '../../../../components/Table'
import { Paper, Toolbar, Typography, ListItem } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import useMediaQuery from "@mui/material/useMediaQuery";

const columnVencimento = {
  title: 'Vencimento',
  field: 'expirationdate',
  render: (attributes) => moment(attributes.expirationdate).format('DD/MM/YYYY'),
  cellStyle: { fontSize: 12 }
}

const columnTotal = {
  title: 'Total',
  render: (attributes) => currency(attributes.correctvalue),
  cellStyle: { fontSize: 12 }
}

const columns = [
  {
    title: 'Título',
    render: (prop) => prop.internalnumber ? prop.internalnumber : prop.invoice,
    cellStyle: { fontSize: 12 }
  },
  {
    title: 'Valor',
    field: 'documentvalue',
    render: (attributes) => currency(attributes.documentvalue),
    cellStyle: { fontSize: 12 }
  },
  {
    title: 'Desconto',
    field: 'scholarshipdiscount',
    render: (attributes) =>
      attributes.correctvalue < attributes.documentvalue ?
        currency(attributes.correctvalue - attributes.documentvalue) :
        currency(0),
    cellStyle: { fontSize: 12 }
  }]

const mountColumn = (isSmall) =>
  isSmall ?
    [columnVencimento, columnTotal] :
    [columnVencimento, ...columns, columnTotal]

export default ({ item, hasTitle, hasDetail = true, columns = mountColumn }) => {
  const classes = useStyles()
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('xs'))

  return (
    <Table
      components={{
        Container: (props) => <Paper {...props} elevation={1} className={classes.paper} />,
        Toolbar: props => hasTitle ?
          <Toolbar className={classes.toolbar} >
            <Typography variant='subtitle2'>{props.title}</Typography>
          </Toolbar> : null
      }}
      options={{
        paging: false,
        sorting: false,
        headerStyle: {
          fontSize: 12,
          paddingRight: 5
        }
      }}
      title={item[0].studentname}
      columns={columns(isSmall)}
      data={item}
      detailPanel={isSmall && hasDetail ? [
        {
          tooltip: 'Mais detalhes',
          render: rowData => {
            const lenghtColumn = rowData.internalnumber ? 4 : 6
            return (
              <Grid container className={!rowData.isSmall && classes.detailPanel} justify='space-between'>
                {details.map((detail, index) => index === 0 && !rowData.internalnumber ?
                  null :
                  <ColumnDetail {...detail} lenghtColumn={lenghtColumn} rowData={rowData} />
                )}
              </Grid>
            )
          },
        }] : []}
    />
  )
}

const details = [
  { idMessage: 'summary-table-title', render: row => row.internalnumber },
  { idMessage: 'summary-table-discount', render: row =>
    row.correctvalue < row.documentvalue ?
      currency(row.correctvalue - row.documentvalue) :
      currency(0) },
  { idMessage: 'summary-table-value', render: row => currency(row.documentvalue) }
]

const ColumnDetail = ({ idMessage, render, lenghtColumn, rowData }) => {
  const classes = useStyles()

  return (
    <Grid item sm={lenghtColumn} md={lenghtColumn}>
      <ListItem>
        <ListItemText
          primary={
            <Typography className={classes.itemDetailTitle} variant='subtitle2'>
              <FormattedMessage id={idMessage} />
            </Typography>
          }
          secondary={<Typography className={classes.itemDetailContent} variant='caption text'>{render(rowData)}</Typography>}
        />
      </ListItem>
    </Grid>
  )
}
