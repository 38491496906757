import env from "../env";
import fetch from "../utils/fetch";

export function cartaoSalvar(card) {
    return fetch(`${env.apiUrl}api/wallet/card`, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(card),
    });
}

export function cartaoVerificar(cardId, card) {
  return fetch(`${env.apiUrl}api/wallet/card/${cardId}/verify`, {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(card),
  });
}

export function cartaoApagar(unidadeId, cardId) {
  return fetch(`${env.apiUrl}api/wallet/card/${cardId}?sellerId=${unidadeId}`, {
    method: "DELETE",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
    },
  })
}

export function cartaoAssinaturas(unidadeId, cardId) {
  return fetch(`${env.apiUrl}api/wallet/card/${cardId}/subscriptions?sellerId=${unidadeId}`, {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
    },
  })
}

export function cartaoAlterarAssinaturas(unidadeId, cardId, subs) {
  return fetch(`${env.apiUrl}api/wallet/card/${cardId}/subscriptions?sellerId=${unidadeId}`, {
    method: "PUT",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(subs),
  })
}

export function cartaoListar(unidadeId, responsavelId) {
  return fetch(`${env.apiUrl}api/wallet/cards?sellerId=${unidadeId}&customerId=${responsavelId}`, {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
    },
  }).catch((error) => {
      console.error(error.message);
    });
}
