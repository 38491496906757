import { forwardRef } from 'react';
import {Link} from "react-router-dom";
import Box from "@mui/material/Box";

/**
 * This is a wrapper over `next/link` component.
 * We use this to help us maintain consistency between Vite and Next.js
 */
export const RouterLink = forwardRef(function RouterLink({to, href, ...props}: any, ref: any) {
  return (
    <Box
      sx={{
        'a': {
          textDecoration: 'none',
          color: 'initial',
        }
      }}
    >
      <Link
        ref={ref}
        to={href ?? to}
        {...props}
      />
    </Box>
  );
});
