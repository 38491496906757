import React from 'react';
import {PortalFinanceiroLayout} from "../../layouts/PortalFinanceiroLayout";
import Tax from "../../modules/Tax";
import Container from "@mui/material/Container";
import { useMediaQuery } from '@mui/material';

function HomePortal(props) {
  const isMedium = useMediaQuery(theme => theme.breakpoints.down('md'))
  return (
    <PortalFinanceiroLayout>
      <Container
        sx={isMedium ? { pl: 0, pr: 0 } : {}}
      >
        <Tax
          {...props}
        />
      </Container>
    </PortalFinanceiroLayout>
  );
}

export default HomePortal;
