import {applyMiddleware, createStore} from 'redux'
import thunkMiddleware from 'redux-thunk'
import {persistStore} from 'redux-persist'
import {composeWithDevTools} from 'redux-devtools-extension'
import {onError, REFRESH, setCredentials} from './utils/axios'
import {logout, refreshToken} from 'modules/Login/actions'
import {userSelector} from 'modules/Login/selectors/user'
import persistedReducer from './middlewares/persistedReducer'
import apiRequestMiddleware from './middlewares/ApiRequest'
import sendGtm from './middlewares/GTM'
import {apiMockRequest} from './middlewares/Mocks'
import {EVENTS_ACTIONS, onEvent} from './utils/eventEmitter'
import {forEach} from 'lodash'

const middlewares = [
  thunkMiddleware,
  apiMockRequest,
  apiRequestMiddleware,
  sendGtm
]

// if (process.env.NODE_ENV === 'development') {
//   middlewares.push(logger)
// }

const configureStore = () => createStore(
  persistedReducer,
  composeWithDevTools(applyMiddleware(...middlewares))
)

const store = configureStore()
const persistor = persistStore(store)

onError(401, response => {
  store.dispatch(logout())
})

onError(REFRESH, ({ token, refreshtoken }) => {
  store.dispatch(refreshToken({ token, refreshtoken }))
})

forEach(EVENTS_ACTIONS, ({ callback, code }) => {
  onEvent(code, (props) => callback(store, props))
})

let lastToken = undefined
let lastRefreshToken = undefined
let lastTourEnabled = undefined

// verificar uma melhor forma de fazer isso
// com um middleware talvez...
function handleChange() {
  const currentValue = userSelector(store.getState())
  if (lastToken !== currentValue.token) {
    lastToken = currentValue.token
    lastRefreshToken = currentValue.refreshtoken
    setCredentials(lastToken, lastRefreshToken)
  }

  // const tourEnabled = isTourEnabledSelector(store.getState())
  // if (lastTourEnabled !== tourEnabled) {
  //   lastTourEnabled = tourEnabled
  //   if (tourEnabled) {
  //     enableTourMock(store)
  //   } else {
  //     disableTourMock()
  //   }
  // }
}

store.subscribe(handleChange)

export {
  store,
  persistor,
}
