import {useRecoilState} from "recoil";
import {isMockState} from "../recoil/atoms/isMockState";

export default function useMock() {
  const [isMocked, setIsMocked] = useRecoilState(isMockState)

  const enableMock = () => {
    setIsMocked(true);
  }

  const disableMock = () => {
    setIsMocked(false)
  }

  return {
    isMocked,
    enableMock,
    disableMock
  };
}
