import React, {ReactNode, useMemo} from 'react';
import {useTranslation} from '../../hooks/use-translation';
import {tokens} from '../../locales/tokens';
import {paths} from '../../paths';
import {CheckCircle, CreditCard, FileCopy, FolderZip, Info, Update} from "@mui/icons-material";
import {useRecoilValue} from "recoil";
import podeAbrirServicosDisponiveisSelector from "../../recoil/selectors/podeAbrirServicosDisponiveisSelector";
import podeAbrirFinanceiroSelector from "../../recoil/selectors/podeAbrirFinanceiroSelector";
import {mdiHandshake, mdiHistory} from "@mdi/js";
import Icon from "@mdi/react";
import {loadingPortalFinanceiroState} from "../../recoil/atoms/loadingPortalFinanceiroState";
import {isMockState} from "../../recoil/atoms/isMockState";
import matriculasSelector from "../../recoil/selectors/matriculasSelector";
import unidadeGetResponseState from "../../recoil/atoms/unidadeGetResponseState";
import {responsavelState} from "../../recoil/atoms/responsavelState";

export interface Item {
  disabled?: boolean;
  external?: boolean;
  icon?: ReactNode;
  badge?: ({children}: { children: React.ReactElement }) => React.ReactElement;
  items?: Item[];
  label?: ReactNode;
  path?: string;
  title: string;
  visible?: boolean;
  loading?: boolean;
  tour?: string;
}

export interface Section {
  items: Item[];
  subheader?: string;
}

export const useSections = () => {
  const {t} = useTranslation();
  const unidade = useRecoilValue(unidadeGetResponseState);
  const responsavel = useRecoilValue(responsavelState);
  const podeAbrirServicosDisponiveis = useRecoilValue(podeAbrirServicosDisponiveisSelector);
  const podeAbrirFinanceiro = useRecoilValue(podeAbrirFinanceiroSelector);
  const loadingPortalFinanceiro = useRecoilValue(loadingPortalFinanceiroState);
  const matriculas = useRecoilValue(matriculasSelector);
  const isMocked = useRecoilValue(isMockState);

  return useMemo(
    () => {
      return [
        {
          items: [
            {
              title: t(tokens.nav.services),
              path: paths.dashboard.services,
              visible: isMocked || podeAbrirServicosDisponiveis,
              tour: 'tur-icon-menu-servicos'
            },
            {
              title: t(tokens.nav.md),
              path: paths.dashboard.index,
              tour: 'tur-icon-menu-matricula',
            },
            {
              title: t(tokens.nav.financial),
              path: paths.dashboard.financial.home,
              visible: isMocked || podeAbrirFinanceiro,
              loading: loadingPortalFinanceiro && !isMocked,
              tour: 'tur-icon-menu-portal'
            },
            {
              title: t(tokens.nav.agreements),
              path: paths.dashboard.financial.agreement,
              visible: isMocked || podeAbrirFinanceiro,
              loading: loadingPortalFinanceiro && !isMocked,
              tour: 'tur-icon-menu-acordo'
            },
          ],
        },
      ];
    },
    [t, responsavel, unidade, podeAbrirServicosDisponiveis, podeAbrirFinanceiro, matriculas, loadingPortalFinanceiro]
  );
};

export const useMobileSections = () => {
  const {t} = useTranslation();
  const unidade = useRecoilValue(unidadeGetResponseState);
  const responsavel = useRecoilValue(responsavelState);
  const podeAbrirServicosDisponiveis = useRecoilValue(podeAbrirServicosDisponiveisSelector);
  const podeAbrirFinanceiro = useRecoilValue(podeAbrirFinanceiroSelector);
  const loadingPortalFinanceiro = useRecoilValue(loadingPortalFinanceiroState);
  const matriculas = useRecoilValue(matriculasSelector);
  const isMocked = useRecoilValue(isMockState);

  return useMemo(
    () => {
      return [
        {
          items: [
            {
              title: t(tokens.nav.services),
              path: paths.dashboard.services,
              visible: isMocked || podeAbrirServicosDisponiveis,
              tour: 'tur-icon-menu-servicos'
            },
            {
              title: t(tokens.nav.md),
              path: paths.dashboard.index,
              tour: 'tur-icon-menu-matricula',
            },
            {
              title: t(tokens.nav.financial),
              path: paths.dashboard.financial.home,
              visible: isMocked || podeAbrirFinanceiro,
              loading: loadingPortalFinanceiro && !isMocked,
              tour: 'tur-icon-menu-portal',
              items: [
                {
                  title: t(tokens.nav.financialHome),
                  path: paths.dashboard.financial.home,
                },
                {
                  title: t(tokens.nav.financialOpenQuotas),
                  path: paths.dashboard.financial.openQuotas
                },
                {
                  title: t(tokens.nav.financialPaidQuotas),
                  path: paths.dashboard.financial.paidQuotas
                },
                {
                  title: t(tokens.nav.financialDeclarations),
                  path: paths.dashboard.financial.declarations,
                },
                {
                  title: t(tokens.nav.financialPaymentMethod),
                  path: paths.dashboard.financial.paymentChange,
                },
                {
                  title: t(tokens.nav.financialCards),
                  path: paths.dashboard.financial.myCards,
                }
              ]
            },
            {
              title: t(tokens.nav.agreements),
              path: paths.dashboard.financial.agreement,
              visible: isMocked || podeAbrirFinanceiro,
              loading: loadingPortalFinanceiro && !isMocked,
              tour: 'tur-icon-menu-acordo',
              items: [
                {
                  title: t(tokens.nav.agreements),
                  path: paths.dashboard.financial.agreement
                },
                {
                  title: t(tokens.nav.financialHistory),
                  path: paths.dashboard.financial.history
                },
              ]
            },
          ]
        },
      ];
    },
    [t, responsavel, unidade, podeAbrirServicosDisponiveis, podeAbrirFinanceiro, matriculas, loadingPortalFinanceiro]
  );
};


export const useFinancialSection = () => {
  const {t} = useTranslation();

  return useMemo(
    () => {
      return [
        {
          title: t(tokens.nav.financial),
          path: paths.dashboard.financial.home,
          icon: <FileCopy/>,
          items: [
            {
              title: t(tokens.nav.financialOpenQuotas),
              path: paths.dashboard.financial.openQuotas,
              icon: <Info/>
            },
            {
              title: t(tokens.nav.financialPaidQuotas),
              path: paths.dashboard.financial.paidQuotas,
              icon: <CheckCircle/>
            }
          ]
        },
        {
          title: t(tokens.nav.financialDeclarations),
          path: paths.dashboard.financial.declarations,
          icon: <FolderZip/>,
        },
        {
          title: t(tokens.nav.financialPaymentMethod),
          path: paths.dashboard.financial.paymentChange,
          icon: <Update/>,
        },
        {
          title: t(tokens.nav.financialCards),
          path: paths.dashboard.financial.myCards,
          icon: <CreditCard/>,
        }
      ];
    },
    [t]
  );
};

export const useAcordoSection = () => {
  const {t} = useTranslation();

  return useMemo(
    () => {
      return [
        {
          title: t(tokens.nav.agreements),
          path: paths.dashboard.financial.agreement,
          icon: <Icon path={mdiHandshake} size={1} />,
        },
        {
          title: t(tokens.nav.financialHistory),
          path: paths.dashboard.financial.history,
          icon: <Icon path={mdiHistory} size={1} />
        },
      ];
    },
    [t]
  );
}
