import React from "react";
import {Box, ButtonGroup, CardContent, CardMedia, Grid} from "@mui/material";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import useMediaQuery from "@mui/material/useMediaQuery";
import {Theme} from "@mui/material/styles/createTheme";
import {mdiBarcode, mdiFileSign, mdiSchool} from '@mdi/js';
import Icon from '@mdi/react';
import {razaoStatus, razaoStatusNome} from "../../utils/razaoStatus";
import AlertCard from "../../components/AlertCard/AlertCard";
import * as menssagem from "../../components/shared/Message";
import tipoMatricula from "../../utils/tipoMatricula";
import {useRecoilValue, useSetRecoilState} from "recoil";
import {selectedMatriculaState} from "../../recoil/atoms/selectedMatriculaState";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import {LoadingButton} from "@mui/lab";
import SvgIcon from "@mui/material/SvgIcon";
import {avatarsState} from "../../recoil/atoms/avatarsState";
import SchoolIcon from '@mui/icons-material/School';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import PersonIcon from "@mui/icons-material/Person";
import Zoom from "@mui/material/Zoom";
import Avatar from "@mui/material/Avatar";
import servicoTipoitem from "../../utils/servicoTipoitem";

const mensagemBloqueioColaborador = "Matrícula bloqueada para analise de bolsa de colaboradores do Grupo SEB."

interface Props {
  matricula: any
  onClickUpSelling: () => {}
  onClickGerarContrato: () => {}
  isLoadingUpselling: { id: string; value: boolean }
  isLoadingTurma: boolean
  onClickAvancar: () => {}
  onClickGerarBoleto: (matricula) => {}
  index?: number
}

const HomeCard: React.FC<Props> = (props) => {
  const {
    matricula,
    onClickUpSelling = matricula => {
    },
    onClickGerarContrato = () => {
    },
    isLoadingUpselling,
    isLoadingTurma,
    onClickAvancar = matricula => {
    },
    onClickGerarBoleto = matricula => {
    },
    index,
  } = props;
  const smUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));
  const mensagemBloqueioUnidade = matricula?.mensagemBloqueioUnidade && matricula?.mensagemBloqueioUnidade !== "" ? matricula?.mensagemBloqueioUnidade : "Bloqueio de contratação pela unidade."
  const setSelectedMatricula = useSetRecoilState(selectedMatriculaState)
  const preReservaAguardandoAprovacao = matricula.razaoStatus_Value === razaoStatus.zero_13_aguardandoAprovacao && matricula.tipoMatricula_Value === tipoMatricula.reservaSemValor;
  const avatars = useRecoilValue(avatarsState);
  const avatar = avatars[matricula?.estudante_Id] ?? null
  const getNomeRazaoStatus = (razaoStatusValue: number) => {
    const labels = {
      [razaoStatus.ponto_1_preReserva]: "Reservar Vaga*",
      [razaoStatus.ponto_2_reservada]: "Vaga Reservada*",
    };

    return labels[razaoStatusValue] || "Pré Matricular*";
  }
  const getColorRazaostatus = (razaoStatusValue: number): any => {
    const colors = {
      [razaoStatus.zero_7_cancelada]: "error",
      [razaoStatus.zero_12_cancelada]: "error",
      [razaoStatus.zero_11_recusa]: "error",
      [razaoStatus.inativo]: "secondary",
      [razaoStatus.zero_9_emProcessoDeRevisao]: "warning",
      [razaoStatus.zero_6_matriculado]: "default",
      [razaoStatus.zero_4_processamentoPagamento]: "warning",
      [razaoStatus.zero_13_aguardandoAprovacao]: "warning",
      [razaoStatus.ponto_2_reservada]: "success",
    };

    // @ts-ignore
    return colors[parseInt(razaoStatusValue)] ?? "default";
  }

  const handleClickUpSelling = (matricula) => event => {
    setSelectedMatricula(matricula);
    onClickUpSelling(matricula);
  }

  const handleClickAvancar = (matricula) => event => {
    setSelectedMatricula(matricula);
    onClickAvancar(matricula);
  }

  return (
    <Zoom
      in={true}
      style={{transitionDelay: `${(index || 1) * 200}ms`}}
    >
      <Card
        sx={{
          maxWidth: smUp ? 330 : "100%",
          width: "100%",
          flex: 1,
          minWidth: 260,
          alignSelf: "stretch",
          display: "grid",
          boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px'
        }}
      >
        <CardMedia
          title={matricula?.estudante_Name}
          sx={{
            height: 140,
            position: 'relative',
          }}
        >
          <Box
            sx={{
              p: 2,
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Avatar
              src={avatar}
              variant="circular"
              sx={{
                height: 120,
                width: 'auto',
                aspectRatio: '1/1',
                border: '7px solid #e5e5e5'
              }}
            >
              <SvgIcon
                component={PersonIcon}
                sx={{
                  fontSize: 100,
                }}
              />
            </Avatar>
          </Box>
        </CardMedia>
        <CardContent
          sx={{
            // p: 1,
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column"
          }}
        >
          <>
            {(matricula.bloqueioColaborador == true) &&
              menssagem.ExibeMensagem(mensagemBloqueioColaborador, "3", true, "", "", "", true)
            }

            {(matricula.bloqueioPedagogico == true) &&
              menssagem.ExibeMensagem("Detectamos alguma inconsistência em nosso sistema que não permite que a sua rematrícula seja realizada de forma on-line. Por favor, solicitamos que entre em contato com a secretaria da sua unidade para obter mais orientações e concluir a rematrícula.", "3", true, "", "", "", true)
            }

            {(matricula.adimplente == false) &&
              menssagem.ExibeMensagem("Detectamos alguma inconsistência em nosso sistema que não permite que a sua rematrícula seja realizada de forma on-line. Por favor, solicitamos que entre em contato com a tesouraria da sua unidade para obter mais orientações e concluir a rematrícula.", "3", true, "", "", "", true)
            }
          </>
          <Typography
            gutterBottom
            component="div"
            fontSize="medium"
            fontWeight="bold"
            color="primary"
            align="center"
          >
            {matricula?.estudante_Name}
          </Typography>

          <Typography
            gutterBottom
            component="div"
            fontSize="small"
            fontWeight="bold"
            align="center"
          >
            {matricula?.nomeLandingPage}
          </Typography>

          <Chip
            label={servicoTipoitem[parseInt(matricula?.servicoTipoItem)]}
            variant="outlined"
            color="primary"
            size="small"
            sx={{
              width: 'fit-content',
              margin: '0 auto'
            }}
          />

          {(matricula.bloqueioColaborador == true) &&
            <AlertCard iconPosition="top" sx={{mt: 1}}>
              {mensagemBloqueioColaborador}
            </AlertCard>
          }

          {(matricula.bloqueioPedagogico == true) &&
            <AlertCard iconPosition="top" sx={{mt: 1}}>
              Detectamos alguma inconsistência em nosso sistema que não permite que a sua rematrícula seja
              realizada de forma on-line. Por favor, solicitamos que entre em contato com a secretaria da sua
              unidade para obter mais orientações e concluir a rematrícula.
            </AlertCard>
          }

          {(matricula.adimplente == false) &&
            <AlertCard iconPosition="top" sx={{mt: 1}}>
              Detectamos alguma inconsistência em nosso sistema que não permite que a sua rematrícula seja
              realizada de forma on-line. Por favor, solicitamos que entre em contato com a tesouraria da sua
              unidade para obter mais orientações e concluir a rematrícula.
            </AlertCard>
          }

          {(matricula?.bloqueioContratacaoUnidade) &&
            <AlertCard iconPosition="top" sx={{mt: 1}}>
              {mensagemBloqueioUnidade}
            </AlertCard>
          }

          <Grid
            container
            spacing={2}
            sx={{
              pt: 2,
              mt: 'auto'
            }}
          >
            <Grid item xs={12}>
              {(matricula.adimplente == true && matricula.bloqueioColaborador == false && matricula.bloqueioPedagogico == false) && (!matricula?.bloqueioContratacaoUnidade) &&
                <Stack
                  justifyContent="center"
                  direction="row"
                  spacing={1}
                  sx={{
                    pb: 1
                  }}
                >
                  <Chip
                    label={matricula?.anoLetivo_Name}
                    variant="outlined"
                    color="default"
                    icon={<CalendarMonthIcon/>}
                    sx={{
                      width: 'fit-content',

                    }}
                  />
                  {(matricula.razaoStatus_Value != razaoStatus.zero_1_iniciada &&
                      matricula.razaoStatus_Value != razaoStatus.ponto_1_preReserva &&
                      matricula.razaoStatus_Value != razaoStatus.zero_8_movimentacaoIniciada) &&
                    <Chip
                      variant="outlined"
                      color={getColorRazaostatus(matricula.razaoStatus_Value)}
                      icon={<SchoolIcon/>}
                      label={razaoStatusNome[matricula.razaoStatus_Value] || matricula.razaoStatus_Nome}
                    />
                  }
                </Stack>
              }
            </Grid>

            <>
              <Grid item xs={12}>
                <ButtonGroup
                  variant="contained"
                  fullWidth
                >
                  {matricula.razaoStatus_Value === razaoStatus.ponto_2_reservada ? (
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      sx={{
                        height: 40,
                        display: !(matricula.urlBoletoReserva !== "" && matricula.urlBoletoReserva != null) ? 'none' : undefined
                      }}
                      onClick={() => onClickGerarBoleto(matricula)}
                      title={!matricula.urlBoletoReserva ? "Boleto não disponível" : ""}
                      disabled={!(matricula.urlBoletoReserva !== "" && matricula.urlBoletoReserva != null)}
                      startIcon={(
                        (matricula.urlBoletoReserva !== "" && matricula.urlBoletoReserva != null) &&
                        <Icon path={mdiBarcode} size={1}/>
                      )}
                    >
                      {!(matricula.urlBoletoReserva !== "" && matricula.urlBoletoReserva != null) ? "" : "Boleto..."}
                    </Button>
                  ) : (
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      sx={{
                        height: 40,
                        display: !(matricula.entradaPaga === false && (matricula.urlBoleto !== "" && matricula.urlBoleto != null && true || matricula.boletosDaMatricula && matricula.boletosDaMatricula.length > 0) && (matricula.razaoStatus_Value === razaoStatus.zero_5_preMatriculado || matricula.razaoStatus_Value === razaoStatus.zero_6_matriculado || matricula.razaoStatus_Value === razaoStatus.ponto_2_reservada)) ? 'none' : undefined
                      }}
                      onClick={() => onClickGerarBoleto(matricula)}
                      disabled={!(matricula.entradaPaga === false && (matricula.urlBoleto !== "" && matricula.urlBoleto != null && true || matricula.boletosDaMatricula && matricula.boletosDaMatricula.length > 0) && (matricula.razaoStatus_Value === razaoStatus.zero_5_preMatriculado || matricula.razaoStatus_Value === razaoStatus.zero_6_matriculado || matricula.razaoStatus_Value === razaoStatus.ponto_2_reservada))}
                      startIcon={(
                        (matricula.entradaPaga === false && (matricula.urlBoleto !== "" && matricula.urlBoleto != null && true || matricula.boletosDaMatricula && matricula.boletosDaMatricula.length > 0) && (matricula.razaoStatus_Value === razaoStatus.zero_5_preMatriculado || matricula.razaoStatus_Value === razaoStatus.zero_6_matriculado || matricula.razaoStatus_Value === razaoStatus.ponto_2_reservada)) &&
                        <Icon path={mdiBarcode} size={1}/>
                      )}
                    >
                      {!(matricula.entradaPaga === false && (matricula.urlBoleto !== "" && matricula.urlBoleto != null && true || matricula.boletosDaMatricula && matricula.boletosDaMatricula.length > 0) && (matricula.razaoStatus_Value === razaoStatus.zero_5_preMatriculado || matricula.razaoStatus_Value === razaoStatus.zero_6_matriculado || matricula.razaoStatus_Value === razaoStatus.ponto_2_reservada)) ? "" : "Boleto..."}
                    </Button>
                  )}

                  <Button
                    fullWidth
                    variant="contained"
                    value={matricula.urlContrato || matricula.urlTermoReserva}
                    color="primary"
                    disabled={!((matricula.urlContrato != "" && matricula.urlContrato != null) || (matricula.urlTermoReserva != "" && matricula.urlTermoReserva != null))}
                    onClick={onClickGerarContrato}
                    sx={{
                      height: 40,
                      opacity: !((matricula.urlContrato != "" && matricula.urlContrato != null) || (matricula.urlTermoReserva != "" && matricula.urlTermoReserva != null)) ? 0 : undefined
                    }}
                    startIcon={(
                      ((matricula.urlContrato != "" && matricula.urlContrato != null) || (matricula.urlTermoReserva != "" && matricula.urlTermoReserva != null)) &&
                      <Icon path={mdiFileSign} size={1}/>
                    )}
                  >
                    {!((matricula.urlContrato != "" && matricula.urlContrato != null) || (matricula.urlTermoReserva != "" && matricula.urlTermoReserva != null)) ? "" : "Contrato"}
                  </Button>
                </ButtonGroup>
              </Grid>

              <Grid
                item xs={12}
              >
                {(matricula.adimplente === true && matricula.bloqueioColaborador === false && matricula.bloqueioPedagogico === false) && (!matricula?.bloqueioContratacaoUnidade) && (!preReservaAguardandoAprovacao) &&
                  <Stack
                    justifyContent="center"
                    direction="row"
                    spacing={1}
                    sx={{
                      pb: 1
                    }}
                  >
                    {(matricula.razaoStatus_Value === razaoStatus.ponto_1_preReserva || matricula.razaoStatus_Value === razaoStatus.zero_1_iniciada || matricula.razaoStatus_Value === razaoStatus.zero_8_movimentacaoIniciada) &&
                    matricula.tipoMatricula.valor !== tipoMatricula.upselling ?
                      <LoadingButton
                        disabled={matricula.disponivelParaMatricular === false && matricula.razaoStatus_Value !== razaoStatus.ponto_1_preReserva}
                        fullWidth
                        variant="contained"
                        type="button"
                        color="primary"
                        sx={{
                          height: 40,
                          opacity: (matricula.disponivelParaMatricular === false && matricula.razaoStatus_Value !== razaoStatus.ponto_1_preReserva) ? 0 : undefined
                        }}
                        onClick={handleClickAvancar(matricula)}
                        loading={isLoadingTurma}
                        startIcon={(
                          !(matricula.disponivelParaMatricular === false && matricula.razaoStatus_Value !== razaoStatus.ponto_1_preReserva) &&
                          <Icon path={mdiSchool} size={1}/>
                        )}
                      >
                        {(matricula.disponivelParaMatricular == false && matricula.razaoStatus_Value != razaoStatus.ponto_1_preReserva) ? "" : getNomeRazaoStatus(matricula.razaoStatus_Value)}
                      </LoadingButton>
                      : (
                        <LoadingButton
                          fullWidth
                          variant="contained"
                          type="button"
                          color="primary"
                          sx={{
                            height: 40,
                            opacity: !(((matricula.razaoStatus_Value !== razaoStatus.zero_6_matriculado && matricula.tipoMatricula.valor !== tipoMatricula.upselling  && matricula.razaoStatus_Value !== razaoStatus.ponto_2_reservada && matricula.adimplente === true) && (!matricula?.bloqueioContratacaoUnidade) && (matricula.adimplente === true && matricula.bloqueioColaborador === false && matricula.bloqueioPedagogico === false))) ? 0 : undefined
                          }}
                          startIcon={(
                            (!(isLoadingUpselling.id === matricula.id && isLoadingUpselling.value) && ((matricula.razaoStatus_Value === razaoStatus.zero_6_matriculado && matricula.tipoMatricula.valor !== tipoMatricula.upselling && matricula.razaoStatus_Value !== razaoStatus.ponto_2_reservada && matricula.adimplente === true) && (!matricula?.bloqueioContratacaoUnidade) && (matricula.adimplente === true && matricula.bloqueioColaborador === false && matricula.bloqueioPedagogico === false))) &&
                            <SvgIcon>
                              <ShoppingCartIcon/>
                            </SvgIcon>
                          )}
                          onClick={handleClickUpSelling(matricula)}
                          loading={isLoadingUpselling.id === matricula.id && isLoadingUpselling.value}
                          disabled={!(!(isLoadingUpselling.id === matricula.id && isLoadingUpselling.value) && ((matricula.razaoStatus_Value === razaoStatus.zero_6_matriculado && matricula.tipoMatricula.valor !== tipoMatricula.upselling && matricula.adimplente === true) && (!matricula?.bloqueioContratacaoUnidade) && (matricula.adimplente === true && matricula.bloqueioColaborador === false && matricula.bloqueioPedagogico === false)))}
                        >
                          {!(!(isLoadingUpselling.id === matricula.id && isLoadingUpselling.value) && ((matricula.razaoStatus_Value === razaoStatus.zero_6_matriculado && matricula.tipoMatricula.valor !== tipoMatricula.upselling && matricula.adimplente === true) && (!matricula?.bloqueioContratacaoUnidade) && (matricula.adimplente === true && matricula.bloqueioColaborador === false && matricula.bloqueioPedagogico === false))) ? "" : "Contratar curso extra"}
                        </LoadingButton>
                      )
                    }
                  </Stack>
                }
              </Grid>
            </>
          </Grid>
        </CardContent>
      </Card>
    </Zoom>
  )
}

export default HomeCard
