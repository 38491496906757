import { tokens } from '../tokens';

export const en = {
  [tokens.common.languageChanged]: 'Language changed',
  [tokens.common.reset]: 'Reset',
  [tokens.common.resetAll]: 'Reset All',
  [tokens.common.password]: 'Password',
  [tokens.common.new]: 'New',
  [tokens.common.filters]: 'Filters',
  [tokens.common.createdAt]: 'Created At',
  [tokens.common.updatedAt]: 'Updated At',
  [tokens.common.deletedAt]: 'Deleted At',
  [tokens.common.rowsPerPage]: 'Rows per page',
  [tokens.common.onWeek]: 'On week',
  [tokens.common.fullName]: 'Full Name',
  [tokens.common.emailAdress]: 'Email Adress',
  [tokens.common.multiFactorAuthentication]: 'Multi Factor Authentication',
  [tokens.common.alternativeAuthentication]: 'Other Authentication methods',
  [tokens.common.of]: 'of',
  [tokens.common.setUp]: 'Set Up',
  [tokens.common.loginHistory]: 'Login history',
  [tokens.common.moreThan]: 'more than',
  [tokens.common.from]: 'From',
  [tokens.common.in]: 'In',
  [tokens.common.to]: 'To',
  [tokens.common.installment]: 'Installment',
  [tokens.common.entry]: 'Entry',
  [tokens.common.search]: 'Search',
  [tokens.common.name]: 'Name',
  [tokens.common.creationDate]: 'Creation date',
  [tokens.common.actions]: 'Actions',
  [tokens.common.save]: 'Save',
  [tokens.common.saved]: 'Saved',
  [tokens.common.edit]: 'Edit',
  [tokens.common.disable]: 'Disable',
  [tokens.common.block]: 'Block',
  [tokens.common.edited]: 'Edited',
  [tokens.common.change]: 'Change',
  [tokens.common.changed]: 'Changed',
  [tokens.common.select]: 'Select',
  [tokens.common.selected]: 'Selected',
  [tokens.common.saving]: 'Saving',
  [tokens.common.security]: 'Security',
  [tokens.common.portal]: 'Portal',
  [tokens.common.general]: 'General',
  [tokens.common.basicDetails]: 'Basic Details',
  [tokens.common.details]: 'Details',
  [tokens.common.clickToSeePaymentDetails]: 'Click to see payment details',
  [tokens.common.cancel]: 'Cancel',
  [tokens.common.backToHome]: 'Back to Home',
  [tokens.common.overdueInstallments]: 'Upcoming or Overdue Installments',
  [tokens.common.outstandingBills]: 'Outstanding Bills',
  [tokens.common.paidBills]: 'Paid Bills',
  [tokens.common.paidBillsDesc]: 'Here are all the installments that have already been paid.',
  [tokens.common.financialDesc]: 'Here are all the financial data!',
  [tokens.common.financialDeclarationDesc]: 'Here are all the Income Tax Declarations.',
  [tokens.common.student]: 'Student',
  [tokens.common.school]: 'School',
  [tokens.common.schoolMoment]: 'School Moment',
  [tokens.common.selectStudent]: 'Select a student',
  [tokens.common.displayFinancialServices]: 'To display financial information about contracted services and products.',
  [tokens.common.contractedServicesAndProducts]: 'Contracted Services and Products',
  [tokens.common.servicesContractedByStudent]: 'Here are all the services and products contracted for the student',
  [tokens.common.paymentMethod]: 'Payment Method',
  [tokens.common.paymentForm]: "Form of Payment",
  [tokens.common.regularService]: "Regular Service",
  [tokens.common.totalGross]: "Totla Gross",
  [tokens.common.includingEntry]: "Including Entry",
  [tokens.common.noServicesContracted]: "No services contracted",
  [tokens.common.displayFinancialServicesPaymentMethod]: 'Here are all the services and products contracted, click here to change the payment method',
  [tokens.common.liquidation]: 'Liquidation',
  [tokens.common.documentValue]: 'Document Value',
  [tokens.common.settledValue]: 'Settled Value',
  [tokens.common.availablePaymentMethods]: 'Available Payment Methods',
  [tokens.common.basicPayment]: 'Basic Payment',
  [tokens.common.paymentSlip]: 'Payment Slip.',
  [tokens.common.automaticDebit]: 'Automatic Debit',
  [tokens.common.automaticDebitDiscount]: '5% Discount',
  [tokens.common.discountOf]: 'Desconto de',
  [tokens.common.recurringCreditCard]: 'Recurring Credit Card',
  [tokens.common.recurringCreditCardDiscount]: '5% Discount',
  [tokens.common.modalClickInformation]: 'Click to open the modal with more information',
  [tokens.common.knowMore]: 'Know more',
  [tokens.common.changeBankDetails]: 'Change Bank Details',
  [tokens.common.changeCreditCard]: 'Change Credit Card',
  [tokens.common.viewCurrentCard]: 'View Current Card',
  [tokens.common.enrollment]: 'Enrollment',
  [tokens.common.currentMethod]: 'Current Method',
  [tokens.common.incomeTax]: 'Income Tax',
  [tokens.common.fullReceipt]: 'Full Receipt',
  [tokens.common.year]: 'Year',
  [tokens.common.other]: 'Others',
  [tokens.common.noValidEnrollments]: 'There are no active enrollments at the moment or your enrollment is canceled.',
  [tokens.common.monthYear]: 'Month/Year',
  [tokens.common.dueDate]: 'Due Date',
  [tokens.common.invoice]: 'Invoice',
  [tokens.common.billet]: 'Billet',
  [tokens.common.card]: 'Card',
  [tokens.common.pay]: 'Pay',
  [tokens.common.generateInvoice]: 'Generate Invoice',
  [tokens.common.copyTypeableLine]: 'Copy Typeable Line',
  [tokens.common.receipt]: 'Receipt',
  [tokens.common.dueDateValue]: 'Due date value',
  [tokens.common.installmentAvailable]: 'Installment Available',
  [tokens.common.situation]: 'Situation',
  [tokens.page.account.loginHistorySub]: 'Your recent login activity',
  [tokens.page.account.loginType]: 'Login Type',
  [tokens.page.account.ipAdress]: 'Adress',
  [tokens.page.account.client]: 'Client',
  [tokens.page.notAuthorized.title]: '401: Authorization required',
  [tokens.page.notAuthorized.subtitle]: 'You either tried some shady route or you came here by mistake. Whichever it is, try using the navigation.',
  [tokens.layout.language]: 'Language',
  [tokens.layout.notifications]: 'Notifications',
  [tokens.layout.topNav.account.logout]: 'Logout',
  [tokens.layout.topNav.account.settings]: 'Settings',
  [tokens.layout.topNav.account.profile]: 'Profile',
  [tokens.layout.topNav.account.billing]: 'Billing',
  [tokens.settings.appSettings]: 'App Settings',
  [tokens.settings.orientation]: 'Orientation',
  [tokens.settings.content]: 'Content',
  [tokens.settings.layout]: 'Layout',
  [tokens.settings.vertical]: 'Vertical',
  [tokens.settings.horizontal]: 'Horizontal',
  [tokens.settings.dark]: 'Dark',
  [tokens.settings.light]: 'Light',
  [tokens.settings.colorScheme]: 'Color Scheme',
  [tokens.settings.primaryColor]: 'Primary Color',
  [tokens.settings.purple]: 'Purple',
  [tokens.settings.blue]: 'Blue',
  [tokens.settings.green]: 'Green',
  [tokens.settings.indigo]: 'Indigo',
  [tokens.settings.compact]: 'Compact',
  [tokens.settings.wide]: 'Wide',
  [tokens.settings.normal]: 'Normal',
  [tokens.settings.high]: 'High',
  [tokens.settings.leftToRight]: 'Left-to-right',
  [tokens.settings.rightToLeft]: 'Right-to-left',
  [tokens.settings.blendIn]: 'Blend-in',
  [tokens.settings.discrete]: 'Discrete',
  [tokens.settings.evident]: 'Evident',
  [tokens.settings.navColor]: 'Nav Color',
  [tokens.settings.contrast]: 'Contrast',
  [tokens.auth.ad.login]: 'Log in',
  [tokens.auth.jwt.login]: 'Log in',
  [tokens.auth.jwt.title]: 'Log in',
  [tokens.auth.jwt.subtitle]: 'Fill in the fields below to enter the system',
  [tokens.auth.ad.title]: 'Log in',
  [tokens.auth.ad.subtitle]: 'Choose one login method below to enter the system',
  [tokens.auth.ad.enter]: 'Enter with Microsoft',
  [tokens.auth.facial.enter]: 'Enter with facial recognition',
  [tokens.environment.development]: 'Development',
  [tokens.environment.homologation]: 'Homologation',
  [tokens.environment.production]: 'Production',
  [tokens.environment.staging]: 'Staging',
  [tokens.environment.test]: 'Test',
  [tokens.nav.financialHome]: 'Home',
  [tokens.nav.financialOpenQuotas]: 'Open Quotas',
  [tokens.nav.financialPaidQuotas]: 'Paid Quotas',
  [tokens.nav.financialAgreement]: 'Agreement',
  [tokens.nav.financialHistory]: 'History',
  [tokens.nav.financialDeclarations]: 'Declarations',
  [tokens.nav.financialPaymentMethod]: 'Payment Method',
  [tokens.nav.financialCards]: 'My Cards',
  [tokens.nav.agreements]: 'Agreement',
};
