import { createContext } from 'react';

import type { Settings } from '../../types/settings';
import {Language} from "../../hooks/use-translation";
import getAmbiente from "../../utils/getAmbiente";

export const defaultSettings: Settings = {
  colorPreset: 'blue',
  contrast: 'normal',
  direction: 'ltr',
  layout: 'horizontal',
  navColor: 'evident',
  paletteMode: 'light',
  responsiveFontSizes: true,
  language: 'ptBR',
  stretch: false
};

export interface State extends Settings {
  openDrawer: boolean;
  isInitialized: boolean;
}

export const initialState: State = {
  ...defaultSettings,
  isInitialized: false,
  openDrawer: false
};

export interface SettingsContextType extends State {
  handleDrawerClose: () => void;
  handleDrawerOpen: () => void;
  handleReset: () => void;
  handleUpdate: (settings: Settings) => void;
  handleChangeLanguage: (language: Language) => void;
  isCustom: boolean;
}

export const SettingsContext = createContext<SettingsContextType>({
  ...initialState,
  handleDrawerClose: () => {},
  handleDrawerOpen: () => {},
  handleReset: () => {},
  handleUpdate: () => {},
  handleChangeLanguage: () => {},
  isCustom: false
});
