import React from 'react';
import {
    Backdrop,
    CircularProgress,
    Stack,
    Typography
} from '@mui/material';
import { globalLoadingState } from '../../recoil/atoms/globalLoadingState';
import { styled, keyframes } from '@mui/system';
import { useRecoilValue } from 'recoil';

const dotsAnimation = keyframes`
  0% { content: '.'; }
  33% { content: '..'; }
  66% { content: '...'; }
  100% { content: ''; }
`;

const AnimatedDots = styled('span')`
  display: inline-block;
  min-width: 1em;
  &::after {
    content: '';
    animation: ${dotsAnimation} 2.5s steps(4, end) infinite;
  }
`;

export const GlobalLoading = () => {
    const showLoadingState = useRecoilValue(globalLoadingState);

    const { showLoading, textToDisplay } = showLoadingState;

    return (
        <Backdrop
            open={showLoading}
            sx={{color: '#fff', zIndex: (theme) => theme.zIndex.appBar + 1}}
        >
            <Stack
                alignItems="center"
                spacing={2}
                sx={{
                    padding: 2,
                    textAlign: 'center'
                }}
            >
                <Typography variant="h5" fontWeight="bold">
                    {textToDisplay}<AnimatedDots/>
                </Typography>
                <CircularProgress color="primary"/>
            </Stack>
        </Backdrop>
    );
};
