import {selector} from "recoil";
import {parcelasSelecionadasState} from "../../../atoms/parcelasSelecionadasState";
import {ParcelaMensal} from "../../../../@types/IServicoDto";
import selectedCalcListServiceSelector from "../../selectedCalcListServiceSelector";

const parcelasUpSellingSelecionadasSelector = selector<{[servicoId: string]: ParcelaMensal | null | undefined}>({
  key: 'parcelasUpSellingSelecionadasSelector', // unique ID (with respect to other atoms/selectors)
  get: ({get}) => {
    const servicos = get(selectedCalcListServiceSelector);
    let parcelasUpSellingIds = (servicos?.servicosUpSelling || [])
      .map(servico => servico?.mensais?.map(parcela => parcela?.id))
      .flat(1)

    parcelasUpSellingIds = [
      ...parcelasUpSellingIds,
      ...servicos?.servicoPrincipal?.servicosRelacionados?.map(servico => servico?.mensais?.map(parcela => parcela?.id)).flat(1) ?? [],
    ]

    const parcelasSelecionadas = get(parcelasSelecionadasState);
    const keys = Object.keys(parcelasSelecionadas?.servicosUpSelling);
    const parcelas: {[servicoId: string]: ParcelaMensal | null | undefined} = {};

    keys.forEach(key => {
      const isUpSelling = parcelasUpSellingIds
        ?.includes(parcelasSelecionadas?.servicosUpSelling[key]?.id ?? "") || parcelasSelecionadas?.servicosUpSelling[key] === null
      if (isUpSelling) {
        parcelas[key] = parcelasSelecionadas?.servicosUpSelling[key] as ParcelaMensal;
      }
    })

    return parcelas;
  },
});

export default parcelasUpSellingSelecionadasSelector

