import {useEffect} from 'react';
import {useRecoilValue} from "recoil";
import {startBotState} from "../recoil/atoms/startBotState";
import {isMobile} from 'react-device-detect';
import {getRecoil} from "recoil-nexus";
import unidadeGetResponseState from "../recoil/atoms/unidadeGetResponseState";
import {responsavelState} from "../recoil/atoms/responsavelState";

const initBot = () => {
    const iframe = document.getElementById('gbt-frame');

    if (!iframe) {
        const unidade = getRecoil(unidadeGetResponseState);
        const responsavel = getRecoil(responsavelState);
        const script = document.createElement('script')
        const token = process.env.REACT_APP_GLOBAL_BOT_TOKEN;

        if (token) {
          script.type = 'text/javascript'
          script.src = `https://globalbot.ai/init/1/${token}?identifiers=name-${responsavel?.nome}|unidade-${unidade?.nome}|cpf-${responsavel?.cpf}`
          document.body.appendChild(script)
        }
    }
}

export const Ajuda = ({forceShow = false}) => {
    const startBot = useRecoilValue(startBotState);

    useEffect(() => {
        if (startBot && (!isMobile || forceShow)) {
            initBot();
            const moveChat = () => {
                setTimeout(() => {
                    const targetNode = document.getElementById('gbt-frame');

                    const config = { attributes: true };

                    const observer = new MutationObserver((mutationList) => {
                        for (let mutation of mutationList) {
                            if (mutation.type === 'attributes') {
                                const iframe = document.getElementById('gbt-frame');
                                const screenWidth = window.screen.width;
                                if (screenWidth <= 480) {
                                    if (iframe.style.height !== '80px' && iframe.classList.contains('mobile')) {
                                        iframe.classList.remove('mobile');
                                    } else if (iframe.style.height === '80px' && !iframe.classList.contains('mobile')) {
                                        iframe.classList.add('mobile');
                                    }
                                }
                            }
                        }
                    });

                    if (targetNode) {
                        observer.observe(targetNode, config);
                    } else {
                        moveChat();
                    }
                }, 5000);
            }
        }
    }, [startBot, isMobile, forceShow]);

    return null;
}

