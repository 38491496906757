import { pipe, prop } from 'ramda'

import {
  getAPIDataSelector,
  hasErrorAPISelector,
  hasLoadedAPISelector,
  isLoadingAPISelector,
} from '../../../../utils/API/selectors'

const baseFinanceSelector = prop('finance')

export const paymentSelector = pipe(baseFinanceSelector, prop('payment'))

export const paymentDataSelector = getAPIDataSelector(paymentSelector)

export const hasLoadedPaymentSelector = hasLoadedAPISelector(paymentSelector)

export const hasErrorPaymentSelector = hasErrorAPISelector(paymentSelector)

export const isLoadingPaymentSelector = isLoadingAPISelector(paymentSelector)

export const paymentRedeDebitSelector = pipe(paymentDataSelector, prop('debit'))

export const paymentRedeOrderNumberSelector = pipe(paymentRedeDebitSelector, prop('orderNumber'))
