import fetch from "../utils/fetch";
import env from "../env";
import {getRecoil, setRecoil} from "recoil-nexus";
import {selectedMatriculaState} from "../recoil/atoms/selectedMatriculaState";
import servicosObrigatoriosSelector
  from "../recoil/selectors/carrinho/servicosObrigatorios/servicosObrigatoriosSelector";
import servicoRegularSelector from "../recoil/selectors/carrinho/servicoRegular/servicoRegularSelector";
import servicosUpSellingSelector from "../recoil/selectors/carrinho/servicosUpSelling/servicosUpSellingSelector";
import {razaoStatus} from "../utils/razaoStatus";
import {nomesContratosState} from "../recoil/atoms/nomesContratosState";
import { da } from "date-fns/locale";
import IModeloContratoResponse from "../@types/IModeloContratoResponse";
import IListaServicoUnidadeIdResponse from "../@types/IListaServicoUnidadeIdResponse";
import * as mensagem from '../components/shared/Message.js';
import servicosUpSellingSelecionadosSelector
  from "../recoil/selectors/carrinho/servicosUpSelling/servicosUpSellingSelecionadosSelector";

export function GetModeloContratoPorServico(idMatricula, idServicoUnidade) {
    return fetch(env.apiUrl+"api/ItemContrato/GetModeloContratoPorServico/" + idMatricula + '/' + idServicoUnidade + '/' + localStorage.getItem('@SMO20/idResponsavel'), {
        method: "GET",
        mode: "cors",
    })
        .then(res => res.json())
        .then(
            (result) => {
                if (result != null && result.servicoUnidadeContratoHTML != null) {
                    return result.servicoUnidadeContratoHTML;
                } else {
                    return "";
                }
            },
            (error) => {

                console.log(error);
                return "";
            }
        )
        .catch(error => {
            console.log(error.message);
        });
}

export function AceiteContrato(matricula) {
    return fetch(env.apiUrl+"api/ItemContrato/PostAceiteContrato/" + matricula, {
        method: "POST",
        mode: "cors",
        headers: {
            "Content-Type": "application/json",
            'Accept': 'application/json'
        },
    }).then((response) => {
        if (response != null && response.ok) {
            return true;

        } else {
            return false;
        }
    }).catch(error => {
        console.log(error.message);
    });
}

export async function getNomesDosContratos(): Promise<Array<IListaServicoUnidadeIdResponse>> {
  const selectedMatricula = getRecoil(selectedMatriculaState);
  const servicoRegular = getRecoil(servicoRegularSelector);
  const servicosUpSelling = getRecoil(servicosUpSellingSelecionadosSelector);
  const Ids: Array<string> = [];
  if (servicoRegular && selectedMatricula?.razaoStatus_Value !== razaoStatus.zero_6_matriculado) {
    Ids.push(servicoRegular?.id);
  }

  servicosUpSelling.forEach(servico => {
    if (servicoRegular && servicoRegular?.servicosRelacionados?.length && servicoRegular?.servicosRelacionados?.findIndex(x => x.id === servico.id) > -1) {
      return;
    }
    Ids.push(servico.id);
  });
  return fetch(env.apiUrl+"api/ItemContrato/GetNomesDosContratos/" + selectedMatricula?.id, {
    method: "Post",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      'Accept': 'application/json'
    },
    body: JSON.stringify({ Ids })
  })
    .then((res) => {
        if (!res.ok) {
            res.text().then((text) => {
              mensagem.ExibeMensagem(text, "3");
              return Promise.resolve([] as IListaServicoUnidadeIdResponse[]);
            });
        } else {
            return res.json();
        }
    })
    .then((result) => {
      if (!result || result.length === 0){
        mensagem.ExibeMensagem("Contrato ou Termo de aceite não cadastrado.", 3);
        return Promise.resolve([] as IListaServicoUnidadeIdResponse[]);
      } else
        return result as Promise<Array<IListaServicoUnidadeIdResponse>>
    })
    .then(data => {
      setRecoil(nomesContratosState, data)
      return data
    })
}

export function getModeloContratoPorServico({matriculaId, servicoId, responsavelId}: { matriculaId: string, servicoId: string, responsavelId: string }): Promise<IModeloContratoResponse> {
  return fetch(env.apiUrl+"api/ItemContrato/GetModeloContratoPorServico/" + matriculaId + '/' + servicoId + '/' + responsavelId, {
    method: "GET",
    mode: "cors",
  }).then(res => res.json())
}
