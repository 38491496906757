import {makeStyles} from "@mui/styles";

export default makeStyles(theme => ({
  paper: {
    marginBottom: theme.spacing(1)
  },
  toolbar: {
    minHeight: theme.spacing(6),
  },
  detailPanel: {
    display: 'none'
  },
  itemDetailTitle: {
    color: theme.palette.primary.main,
    fontSize: '0.8rem'
  },
  itemDetailContent: {
    fontSize: '0.75rem'
  },
}))
