import React, {useCallback, useEffect, useState} from 'react';
import {Ajuda} from '../../components/Ajuda';
import {useHistory} from 'react-router-dom';
import StepCheckoutActionButtons from "../../components/StepCheckoutActionButtons/StepCheckoutActionButtons";
import env from "../../env";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import fetch from "../../utils/fetch";
import {Layout as DashboardLayout} from "../../layouts/dashboard";
import {CardContent} from "@mui/material";
import FirstAccessSteps from "../../components/FirstAccessSteps/FirstAccessSteps";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Loading from "./Loading";
import {useFormik} from "formik";
import {toast} from "react-toastify";
import * as Yup from "yup";
import {PATH_ROUTE} from "../../routes";
import Divider from "@mui/material/Divider";
import Avatar from "@mui/material/Avatar";
import GerarSiglaNome from "../../commons/functions/gerar-sigla-nome";
import * as _contactService from "../../service/contact-service";
import {useRecoilState, useRecoilValue} from "recoil";
import {avatarsState} from "../../recoil/atoms/avatarsState";
import Paper from "@mui/material/Paper";
import {neutral} from "../../theme/colors";
import Typography from "@mui/material/Typography";
import UploadDoc from "./UploadDoc";
import {useTheme} from "@mui/material/styles";
import * as anexo from "../../commons/functions/anexo";
import tipoMatricula from "../../utils/tipoMatricula";
import {Scrollbar} from "../../components/scrollbar";
import {slideDirectionState} from "../../recoil/atoms/slideDirectionState";
import Slide from "@mui/material/Slide";

const tipoAnexos = {
  CPF_CNH: 1,
  COMPROVANTE_RESIDENCIA: 2,
  EMANCIPADO: 3,
  RG_CERTIDAO: 4,
}

interface Aluno {
  id: string;
  nome: string;
  rg: string|null;
  cpf: string|null;
  passaporte: string|null;
  urlImagemPerfil?: string;
  idMatricula: string;
}


const abreviarSobreNomes = (nome?: string | null): string => {
  const nomes = (nome ?? "").split(" ");
  return nomes.map((n, i) => i === 0 || i === nomes.length - 1 ? n : `${n[0]}.`).join(" ");
}

function stringAvatar(name: string) {
  return {
    sx: {
      background: 'linear-gradient(135deg, #4180f6, #1cfaef)',
      width: '100%',
      height: '100%',
      maxHeight: 120,
      maxWidth: 120,
      aspectRatio: 1,
      margin: '0 auto',
      fontSize: '2rem',
      border: '8px solid #fff',
      color: '#fff',
    },
    variant: 'circle',
    children: GerarSiglaNome(name || ""),
  };
}

const InformeSeusDados: React.FC = () => {
  const slideDirection = useRecoilValue(slideDirectionState);
  const theme = useTheme();
  const history = useHistory();
  const [avatars, setAvatars] = useRecoilState(avatarsState);
  const [isLoaded, setIsLoaded] = useState(false);
  const [saving, setSaving] = useState(false);
  const [alunos, setAlunos] = useState<Aluno[]>([]);
  const [initialValues, setInitialValues] = useState({
    arquivosResponsavel: {},
    arquivosEstudantes: {},
  });
  const validationSchema = useCallback(() => {
    const arquivosEstudantes = {};
    alunos.forEach((aluno) => {
      arquivosEstudantes[`aluno_${aluno?.id}`] = Yup.object().shape({
        [tipoAnexos.RG_CERTIDAO]: Yup.object().shape({
          id: Yup.string().required("campo obrigatório"),
          extensao: Yup.string().required("campo obrigatório"),
          tipoAnexo: Yup.number().required("campo obrigatório"),
          arquivo: Yup.string().required("campo obrigatório"),
          url: Yup.string().nullable(),
          idMatricula: Yup.string().required("campo obrigatório"),
          old: Yup.boolean().nullable(),
        }).required("campo obrigatório"),
      }).required("campo obrigatório");
    });

    return Yup.object().shape({
      arquivosResponsavel: Yup.object().shape({
        [tipoAnexos.CPF_CNH]: Yup.object().shape({
          id: Yup.string().required("campo obrigatório"),
          extensao: Yup.string().required("campo obrigatório"),
          tipoAnexo: Yup.number().required("campo obrigatório"),
          arquivo: Yup.string().required("campo obrigatório"),
          url: Yup.string().nullable(),
          old: Yup.boolean().nullable(),
        }).required("campo obrigatório"),
        [tipoAnexos.COMPROVANTE_RESIDENCIA]: Yup.object().shape({
          id: Yup.string().required("campo obrigatório"),
          extensao: Yup.string().required("campo obrigatório"),
          tipoAnexo: Yup.number().required("campo obrigatório"),
          arquivo: Yup.string().required("campo obrigatório"),
          url: Yup.string().nullable(),
          old: Yup.boolean().nullable(),
        }).required("campo obrigatório")
      }).required("campo obrigatório"),
      arquivosEstudantes: Yup.object().shape(arquivosEstudantes).required("campo obrigatório"),
    })
  }, [alunos])();
  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values): Promise<void> => {
      if (saving) return;
      setSaving(true);

      if (!formik.dirty) {
        history.push(PATH_ROUTE.CONCLUIDO);
        return;
      }

      try {
        const keys = Object.keys(values.arquivosResponsavel);
        const promises = keys.map(async key => {
          const hasChanged = values.arquivosResponsavel[key].old === false;
          if (!hasChanged) return;
          return fetch(env.apiUrl + "api/Documento", {
            method: "POST",
            mode: "cors",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(values.arquivosResponsavel[key]),
          }).then((res) => res.json())
        });

        const keysEstudantes = Object.keys(values.arquivosEstudantes);
        const promisesEstudantes = keysEstudantes.map(async key => {
          const hasChanged = values.arquivosEstudantes[key][tipoAnexos.RG_CERTIDAO].old === false;
          if (!hasChanged) return;
          return fetch(env.apiUrl + "api/Documento", {
            method: "POST",
            mode: "cors",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(values.arquivosEstudantes[key][tipoAnexos.RG_CERTIDAO]),
          }).then((res) => res.json())
        });

        const result = await Promise.all(promises.concat(promisesEstudantes));

        await Promise.all(result.map((res) => {
          if (!res) return;
          return fetch(env.apiUrl + "api/documento/SalvarUrlDocumentos", {
            method: "POST",
            mode: "cors",
            headers: {
              "Content-Type": "application/json"
            },
            body: JSON.stringify(res)
          })
        }));

        history.push(PATH_ROUTE.CONCLUIDO);
      } catch (e) {
        console.error(e);
        toast.error('Erro ao atualizar dados!', {autoClose: 5000});
      } finally {
        setSaving(false);
      }
    }
  });

  useEffect(() => {
    const idResponsavel = localStorage.getItem("@SMO20/idResponsavel") ?? "";

    const getMatriculasByIdResponsavelTipo = async (idResponsavel: string, tipoMatricula: number) =>
      new Promise(async (resolve, reject) => {
        await fetch(env.apiUrl + "api/Matricula/ListAllByResponsavelTipo/" + idResponsavel + "/" + tipoMatricula)
          .then(res => res.json()).then(resolve).catch(reject);
      })

    const populate = (matriculas: any[]) => (result: Aluno[]) => {
      const a: Aluno[] = [];

      const promises = (result || []).map(async aluno => {
        if (matriculas.some(x => x.estudante_Id === aluno.id)) {
          aluno.idMatricula = matriculas.find(x => x.estudante_Id == aluno.id).id;
        } else {
          return;
        }

        if (aluno?.urlImagemPerfil) {
          let clone = {...avatars};
          clone[aluno?.id] = await _contactService.getSas(aluno?.urlImagemPerfil)
          setAvatars(clone)
        }

        a.push(aluno);

        const index = a.findIndex(x => x.id === aluno.id);

        return getDocumento(aluno.id, tipoAnexos.RG_CERTIDAO, index);
      })

      Promise.all(promises).then(async () => {
        await getDocumento(idResponsavel, tipoAnexos.COMPROVANTE_RESIDENCIA, 0);
        await getDocumento(idResponsavel, tipoAnexos.CPF_CNH, 0);
        setAlunos(a);
      }).finally(() => setIsLoaded(true))
    }

    Promise.all([
      getMatriculasByIdResponsavelTipo(idResponsavel, tipoMatricula.nova) || [],
      getMatriculasByIdResponsavelTipo(idResponsavel, tipoMatricula.reservaComValor) || []
    ]).then(matriculas => matriculas.reduce((a: any, b: any) => a.concat(b), []) as any[])
      .then((matriculas) => {
        fetch(env.apiUrl + "api/estudante/GetbyIdResponsavel/" + idResponsavel)
          .then((res) => res.json())
          .then(populate(matriculas))
      })
  }, []);

  const handleUpload = (field: string, id: string, tipo: number, idMatricula?: string) => (file: File) => {
    anexo.ConvertBlobUrlToBase64(URL.createObjectURL(file)).then((base64) => {
      formik.setFieldValue(`${field}`, {
        id: id,
        extensao: file.type.split("/")[1],
        tipoAnexo: tipo,
        arquivo: base64,
        url: "",
        idMatricula: idMatricula,
        old: false
      });
    });
  }

  const handleUploadEstudante = (field: string, id: string, tipo: number, idMatricula?: string) => (file: File) => {
    anexo.ConvertBlobUrlToBase64(URL.createObjectURL(file)).then((base64) => {
      formik.setFieldValue(`${field}`, {
        ...formik.values.arquivosEstudantes,
        [`aluno_${id}`]: {
          ...formik.values.arquivosEstudantes[`aluno_${id}`],
          [tipo]: {
            id: id,
            extensao: file.type.split("/")[1],
            tipoAnexo: tipo,
            arquivo: base64,
            url: "",
            idMatricula: idMatricula,
            old: false
          }
        }
      });
    });
  }

  const getDocumento = (idContato: string, tipoDocumento: number, index: number) => {
    return new Promise(async (resolve, reject) => {
      await fetch(env.apiUrl + "api/Documento/GetUrlDocumento/" + idContato + "/" + tipoDocumento, {
        method: "GET",
        mode: "cors",
      })
        .then(res => res.json())
        .then(async (result) => {
          if (result != null && result.url != null) {
            let type = "application/jpg";
            if (result.url.includes(".pdf")) {
              type = "application/pdf";
            }
            if (tipoDocumento == tipoAnexos.CPF_CNH || tipoDocumento == tipoAnexos.COMPROVANTE_RESIDENCIA) {
              let {arquivosResponsavel} = initialValues;
              arquivosResponsavel[tipoDocumento] = {
                id: idContato,
                tipoAnexo: tipoDocumento,
                extensao: type.split("/")[1],
                arquivo: result.url,
                old: true,
                url: "",
              }

              setInitialValues({
                ...initialValues,
                arquivosResponsavel
              })

            } else if (tipoDocumento == tipoAnexos.RG_CERTIDAO) {
              let {arquivosEstudantes} = initialValues;

              // @ts-ignore
              arquivosEstudantes[`aluno_${idContato}`] = {
                ...arquivosEstudantes[`aluno_${idContato}`],
                [tipoDocumento]: {
                  id: idContato,
                  tipoAnexo: tipoDocumento,
                  extensao: type.split("/")[1],
                  arquivo: result.url,
                  old: true,
                  url: "",
                  idMatricula: result.idMatricula
                }
              }

              setInitialValues({
                ...initialValues,
                arquivosEstudantes
              })
            }
          }
        })
        .then(resolve)
        .catch(reject);
    });
  }

  const hasError = (obj: string, index: number | string, type?: number) => {
    try {
      const error = type ? formik.errors[obj]![index]![type]?.arquivo : formik.errors[obj]![index]?.arquivo;
      return !!(error)
    } catch (e) {
      return undefined
    }
  }

  const helpText = (obj: string, index: number | string, type?: number) => {
    try {
      return type ? formik.errors[obj]![index]![type]?.arquivo : formik.errors[obj]![index]?.arquivo;
    } catch (e) {
      return undefined
    }
  }

  return (
    <DashboardLayout backgroundColor={theme.palette.background.paper}>
      <Scrollbar>
        <FirstAccessSteps activeStep={2}/>

        <Slide in direction={slideDirection}>
          <Card
            elevation={0}
          >
            <Container
              maxWidth="lg"
              sx={{
                pb: 8,
              }}
            >
              <CardHeader
                title="Anexe os documentos solicitados:"
                sx={{
                  position: 'relative',
                  "&:after": {
                    content: "''",
                    position: 'absolute',
                    zIndex: 0,
                    boxShadow: '0 0 40px rgb(0 0 0 / 34%)',
                    bottom: '0px',
                    width: '45%',
                    height: '100%',
                    borderRadius: '100%',
                    left: '10%',
                    right: '10%',
                    top: '-100%'
                  }
                }}
              />

              {!isLoaded && <Loading/>}

              {isLoaded && (
                <CardContent>
                  <form
                    onSubmit={formik.handleSubmit}
                  >
                    <Box
                      sx={{
                        mb: 4
                      }}
                    >
                      <Typography
                        variant="subtitle1"
                        fontWeight="bold"
                        sx={{
                          mb: 2
                        }}
                      >
                        Documento do responsável
                      </Typography>

                      <Stack
                        direction={{md: 'row', sm: 'column'}}
                        spacing={0}
                        sx={{
                          gap: 3
                        }}
                      >
                        <Paper
                          sx={{
                            backgroundColor: neutral[100],
                            p: 2,
                            width: '100%',
                            height: '100%',
                            maxHeight: 250,
                            maxWidth: 250,
                            [theme.breakpoints.down('sm')]: {
                              maxWidth: '100%'
                            },
                            aspectRatio: 1,
                          }}
                          elevation={0}
                        >
                          <Stack
                            direction="column"
                            spacing={2}
                            alignItems="center"
                            justifyContent="center"
                          >
                            {/* @ts-ignore */}
                            <Avatar
                              {...stringAvatar(localStorage.getItem("@SMO20/nomeResponsavel") ?? "")}
                            />

                            <Typography
                              fontWeight="bold"
                            >
                              Responsável
                            </Typography>

                            <Typography>
                              {abreviarSobreNomes(localStorage.getItem("@SMO20/nomeResponsavel"))}
                            </Typography>
                          </Stack>
                        </Paper>

                        <UploadDoc
                          title='CPF (frente) ou CNH (documento aberto, frente e verso)'
                          name={`arquivosResponsavel[${tipoAnexos.CPF_CNH}].id`}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          error={hasError('arquivosResponsavel', tipoAnexos.CPF_CNH)}
                          helperText={helpText('arquivosResponsavel', tipoAnexos.CPF_CNH)}
                          onFileChange={handleUpload(
                            `arquivosResponsavel[${tipoAnexos.CPF_CNH}]`,
                            localStorage.getItem("@SMO20/idResponsavel") ?? "",
                            tipoAnexos.CPF_CNH
                          )}
                          defaultFileName={
                            formik.values.arquivosResponsavel[tipoAnexos.CPF_CNH]?.old
                              ? formik.values.arquivosResponsavel[tipoAnexos.CPF_CNH]?.arquivo
                              : undefined
                          }
                        />

                        <UploadDoc
                          title='Comprovante de residência'
                          name={`arquivosResponsavel[${tipoAnexos.COMPROVANTE_RESIDENCIA}].id`}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          error={hasError('arquivosResponsavel', tipoAnexos.COMPROVANTE_RESIDENCIA)}
                          helperText={helpText('arquivosResponsavel', tipoAnexos.COMPROVANTE_RESIDENCIA)}
                          onFileChange={handleUpload(
                            `arquivosResponsavel[${tipoAnexos.COMPROVANTE_RESIDENCIA}]`,
                            localStorage.getItem("@SMO20/idResponsavel") ?? "",
                            tipoAnexos.COMPROVANTE_RESIDENCIA
                          )}
                          defaultFileName={
                            formik.values.arquivosResponsavel[tipoAnexos.COMPROVANTE_RESIDENCIA]?.old
                              ? formik.values.arquivosResponsavel[tipoAnexos.COMPROVANTE_RESIDENCIA]?.arquivo
                              : undefined
                          }
                        />
                      </Stack>
                    </Box>

                    {(alunos.length > 0) && (
                      <Typography
                        variant="subtitle1"
                        fontWeight="bold"
                        sx={{
                          mb: 2
                        }}
                      >
                        Documento do(s) estudante(s)
                      </Typography>
                    )}

                    {alunos.map((aluno, index) => {
                      const label = aluno?.passaporte ? "Passaporte" : aluno?.rg ? "RG" : "CPF";
                      return (
                        <React.Fragment key={index}>
                          <Stack
                            direction={{md: 'row', sm: 'column'}}
                            spacing={0}
                            sx={{
                              gap: 3
                            }}
                          >
                            <Paper
                              sx={{
                                backgroundColor: neutral[100],
                                p: 2,
                                width: '100%',
                                height: '100%',
                                maxHeight: 250,
                                maxWidth: 250,
                                [theme.breakpoints.down('sm')]: {
                                  maxWidth: '100%'
                                },
                                aspectRatio: 1,
                              }}
                              elevation={0}
                            >
                              <Stack
                                direction="column"
                                spacing={2}
                                alignItems="center"
                                justifyContent="center"
                                sx={{
                                  textAlign: 'center'
                                }}
                              >
                                {/* @ts-ignore */}
                                <Avatar
                                  {...stringAvatar(aluno?.nome)}
                                  src={avatars[aluno?.id] ?? undefined}
                                />

                                <Typography
                                  fontWeight="bold"
                                >
                                  Estudante
                                </Typography>

                                <Typography>
                                  {abreviarSobreNomes(aluno?.nome)}
                                </Typography>
                              </Stack>
                            </Paper>

                            <UploadDoc
                              title={`${label} (aberto, exibindo frente e verso ou certidão de nascimento frente)`}
                              name={`arquivosEstudantes[aluno_${aluno?.id}][${tipoAnexos.RG_CERTIDAO}].id`}
                              onBlur={formik.handleBlur}
                              onChange={formik.handleChange}
                              error={hasError('arquivosEstudantes', `aluno_${aluno?.id}`, tipoAnexos.RG_CERTIDAO)}
                              helperText={helpText('arquivosEstudantes', `aluno_${aluno?.id}`, tipoAnexos.RG_CERTIDAO)}
                              onFileChange={handleUploadEstudante(
                                `arquivosEstudantes`,
                                aluno?.id,
                                tipoAnexos.RG_CERTIDAO,
                                aluno?.idMatricula
                              )}
                              defaultFileName={
                                // @ts-ignore
                                formik.values.arquivosEstudantes[`aluno_${aluno?.id}`]?.[tipoAnexos.RG_CERTIDAO]?.old
                                  // @ts-ignore
                                  ? formik.values.arquivosEstudantes[`aluno_${aluno?.id}`]?.[tipoAnexos.RG_CERTIDAO]?.arquivo
                                  : undefined
                              }
                            />
                          </Stack>


                          {(index < alunos.length - 1) && (
                            <Divider
                              sx={{
                                mt: 6,
                                mb: 4
                              }}
                            >{abreviarSobreNomes(alunos[index + 1]?.nome)}</Divider>
                          )}
                        </React.Fragment>
                      )
                    })}

                    <Box
                      sx={{
                        mt: 6
                      }}
                    >
                      <StepCheckoutActionButtons
                        previousStepPath={PATH_ROUTE.DADOS_DO_ESTUDANTE}
                        nextStepPath={PATH_ROUTE.ANEXAR_DOCUMENTOS}
                        nextStepText="Avançar"
                        onClickNextStep={() => {
                        }}
                        nextStepDisabled={!formik.isValid || saving}
                        nextStepType="submit"
                        loading={saving}
                      />
                    </Box>
                  </form>
                </CardContent>
              )}
            </Container>
          </Card>
        </Slide>
        <Ajuda/>
      </Scrollbar>
    </DashboardLayout>
  )
}

export default InformeSeusDados;
