import {selector} from "recoil";
import {blackListState} from "../atoms/blackListState";
import {blockedResourcesEntitiesValues} from "../../utils/blockedResourcesEntities";
import {selectedMatriculaState} from "../atoms/selectedMatriculaState";

const metodosBloqueadosSelector = selector({
  key: 'metodosBloqueadosSelector',
  get: ({get}) => {
    const blackList = get(blackListState);
    const matricula = get(selectedMatriculaState);
    let locks = {
      entradaBoletoLock: false,
      entradaCartaoDeCreditoLock: false,
      recorrenteBoletoLock: false,
      recorrenteDALock: false,
      recorrenteCartaoDeCreditoLock: false,
    };

    const verifylock = (
      {
        lockName,
        action,
      }
    ) => {
      let lockFound = blackList.find(x =>
        x.type === blockedResourcesEntitiesValues.Unidade
        && x.unidadeId === matricula.unidade_Id
        && x.action === action
      );

      if (lockFound) {
        locks = {
          ...locks,
          [lockName]: true,
        }
      }
    }

    if (blackList) {
      //region Locks de Entrada
      verifylock({
        lockName: "entradaBoletoLock",
        action: "MetodosDePagamento/Unidade/Tipo/Entrada/Boleto",
      })

      verifylock({
        lockName: "entradaCartaoDeCreditoLock",
        action: "MetodosDePagamento/Unidade/Tipo/Entrada/CartaoDeCredito",
      })
      //endregion Locks de Entrada

      //region Locks de Recorrente
      verifylock({
        lockName: "recorrenteBoletoLock",
        action: "MetodosDePagamento/Unidade/Tipo/Recorrente/Boleto",
      })

      verifylock({
        lockName: "recorrenteDALock",
        action: "MetodosDePagamento/Unidade/Tipo/Recorrente/DA",
      })

      verifylock({
        lockName: "recorrenteCartaoDeCreditoLock",
        action: "MetodosDePagamento/Unidade/Tipo/Recorrente/CartaoDeCredito",
      })
      //endregion Locks de Recorrente
    }

    return locks
  },
});

export default metodosBloqueadosSelector;
