import React, {useMemo} from "react";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import SvgIcon from "@mui/material/SvgIcon";
import Stack from "@mui/material/Stack";
import includesSearch from "../../utils/includesSearch";
import Card from "@mui/material/Card";
import {CardContent, CardMedia, Checkbox} from "@mui/material";
import Button from "@mui/material/Button";
import Zoom from "@mui/material/Zoom";
import Alert from "@mui/material/Alert";
import {currency} from "../../utils/utils";
import {useRecoilValue, useSetRecoilState} from "recoil";
import {IServico, IServicoUpSelling} from "../../@types/IServicoDto";
import {DateTime} from "luxon";
import carrinho from "../../utils/carrinho";
import parcelasUpSellingSelecionadasSelector
  from "../../recoil/selectors/carrinho/servicosUpSelling/parcelasUpSellingSelecionadasSelector";
import servicosUpSellingSelector from "../../recoil/selectors/carrinho/servicosUpSelling/servicosUpSellingSelector";
import servicosUpSellingMultipleSelector
  from "../../recoil/selectors/carrinho/servicosUpSelling/servicosUpSellingMultipleSelector";
import {selectedMatriculaState} from "../../recoil/atoms/selectedMatriculaState";
import {selectedMatriculasState} from "../../recoil/atoms/selectedMatriculasState";
import {Search} from "@mui/icons-material";
import {useTheme} from "@mui/material/styles";
import matriculasSelector from "../../recoil/selectors/matriculasSelector";

interface ServicosComplementaresProps {
  disableTitle?: boolean;
  multiple?: boolean;
}

const ServicosComplementares: React.FC<ServicosComplementaresProps> = (props) => {
  const [search, setSearch] = React.useState("");
  const theme = useTheme();
  const matriculas = useRecoilValue(matriculasSelector);
  const selectedMatricula = useRecoilValue(selectedMatriculaState);
  const selectedMatriculas = useRecoilValue(selectedMatriculasState);
  const servicosUpSelling = useRecoilValue(servicosUpSellingSelector);
  const servicosUpSellingMultiple = useRecoilValue(servicosUpSellingMultipleSelector);
  const parcelasSelecionadas = useRecoilValue(parcelasUpSellingSelecionadasSelector);
  const servicos = props.multiple && !('id' in selectedMatricula) ? servicosUpSellingMultiple : servicosUpSelling;
  const setSelectedMatricula = useSetRecoilState(selectedMatriculaState);
  const setSelectedMatriculas = useSetRecoilState(selectedMatriculasState);
  const filtered = useMemo(() => servicos.filter(s => {
    if (search.length > 0) {
      return includesSearch(s?.nome, search);
    }
    return true
  }), [servicos, search]);

  const handleSelectService = (servico: IServicoUpSelling) => (e: any) => {
    if (parcelasSelecionadas[servico.id] === null || parcelasSelecionadas[servico.id] !== undefined) {
      carrinho.removeParcela(servico);
      if (props.multiple) {
        const matricula = (matriculas || []).find(m => m.id === servico?.matriculaId);
        if (matricula && matricula?.id && selectedMatricula?.id === matricula?.id) {
          setSelectedMatricula({});
        }
      }
    } else {
      carrinho.addParcela(servico, null);
      if (props.multiple) {
        const matricula = (matriculas || []).find(m => m.id === servico?.matriculaId);
        if (matricula && matricula?.id && selectedMatricula?.id !== matricula?.id) {
          setSelectedMatricula(matricula);
          setSelectedMatriculas([matricula?.id])
        }
      }
    }
  }

  const handleSelectParcela = (servico: IServico) => (e: any) => {
    const parcela = servico.mensais?.find(p => p.id === e.target.value);
    carrinho.addParcela(
      servico,
      parcela
    )
  }

  const formatDateStringToDisplay = (date: string) => {
    return DateTime.fromISO(date).setLocale("pt-br").toFormat("MMMM 'de' yyyy");
  }

  const renderAlert = (text: string) => (
    <Typography
      variant="h6"
      textAlign="center"
      align="center"
      sx={{
        width: '100%',
        position: 'relative',
        minHeight: 300,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: theme.palette.action.hover,
      }}
      color="textSecondary"
    >
      <span
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          zIndex: 1,
        }}
      >
        {text}
      </span>
      <img
        src="/img/empty.svg"
        alt="Nenhum dado encontrado"
        width="auto"
        height="100%"
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          opacity: 0.2,
          zIndex: 0,
        }}
      />
    </Typography>
  )

  return (
    <Box sx={{pt: '15px'}}>
      {!!props.disableTitle && (
        <>
          <Typography variant="h4" component="h3">
            Serviços extras
          </Typography>
          <p>Selecione todas os serviços que deseja contratar</p>
        </>
      )}

      <Paper
        variant="outlined"
        sx={{
          backgroundColor: theme => theme.palette.background.default,
          pt: 8,
          pb: 1,
          mb: 1,
          position: 'relative',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            p: 1
          }}
        >
          <TextField
            fullWidth
            size="small"
            label="Buscar"
            onChange={(e) => setSearch(e.target.value)}
            value={search}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SvgIcon>
                    <Search/>
                  </SvgIcon>
                </InputAdornment>
              )
            }}
          />
        </Box>

        <Box
          sx={{
            display: 'block',
            bgcolor: 'background.paper',
            m: 2,
          }}
        >
          <Stack
            direction="row"
            flexWrap="wrap"
            justifyContent="flex-start"
            spacing={0}
            sx={{
              maxWidth: 1200,
              ml: 'auto',
              mr: 'auto',
            }}
          >
            {servicos.length === 0 && (props.multiple ? selectedMatriculas.length !== 0 : selectedMatricula)
              ? renderAlert("No momento não há serviços disponíveis.")
              : (props.multiple ? selectedMatriculas.length === 0 : !selectedMatricula)
                ? renderAlert("Selecione uma matrícula para visualizar os serviços disponíveis.")
                : filtered.length === 0 ? renderAlert("No momento não há serviços disponíveis para esta matrícula.")
                  : filtered.map((servico, i) => {
                    const labelId = `checkbox-list-secondary-label-${servico?.id}`;
                    const parcela = parcelasSelecionadas[servico?.id];
                    return (
                      <Stack
                        key={servico?.id + (props.multiple ? servico.matriculaId : selectedMatricula?.id)}
                        direction="column"
                        alignItems="center"
                        spacing={1}
                        sx={{
                          maxWidth: 280,
                          width: '100%',
                          m: 1
                        }}
                      >
                        <Zoom
                          in
                          timeout={i * 100 > 1000 ? 1000 : i * 100}
                          easing="ease-in-out"
                        >
                          <Card
                            key={servico?.id}
                            elevation={0}
                            sx={{
                              width: '100%',
                              height: '100%',
                            }}
                          >
                            <CardMedia
                              image={servico?.imagem ?? ""}
                              sx={{
                                height: 140,
                                backgroundSize: 'cover',
                                backgroundRepeat: 'no-repeat',
                                background: servico?.imagem ? undefined : 'linear-gradient(135deg, #3083dc, #276eb9)'
                              }}
                              title="green iguana"
                            >
                              {!servico?.imagem && (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  fill="white"
                                  width="24px"
                                  height="24px"
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    opacity: 0.1
                                  }}
                                >
                                  {/*@ts-ignore*/}
                                  {(servico?.nome).toLowerCase().includes("esporte")
                                    ? <path
                                      d="M16.93 17.12L16.13 15.76L17.59 11.39L19 10.92L20 11.67C20 11.7 20 11.75 20 11.81C20 11.88 20.03 11.94 20.03 12C20.03 13.97 19.37 15.71 18.06 17.21L16.93 17.12M9.75 15L8.38 10.97L12 8.43L15.62 10.97L14.25 15H9.75M12 20.03C11.12 20.03 10.29 19.89 9.5 19.61L8.81 18.1L9.47 17H14.58L15.19 18.1L14.5 19.61C13.71 19.89 12.88 20.03 12 20.03M5.94 17.21C5.41 16.59 4.95 15.76 4.56 14.75C4.17 13.73 3.97 12.81 3.97 12C3.97 11.94 4 11.88 4 11.81C4 11.75 4 11.7 4 11.67L5 10.92L6.41 11.39L7.87 15.76L7.07 17.12L5.94 17.21M11 5.29V6.69L7 9.46L5.66 9.04L5.24 7.68C5.68 7 6.33 6.32 7.19 5.66S8.87 4.57 9.65 4.35L11 5.29M14.35 4.35C15.13 4.57 15.95 5 16.81 5.66C17.67 6.32 18.32 7 18.76 7.68L18.34 9.04L17 9.47L13 6.7V5.29L14.35 4.35M4.93 4.93C3 6.89 2 9.25 2 12S3 17.11 4.93 19.07 9.25 22 12 22 17.11 21 19.07 19.07 22 14.75 22 12 21 6.89 19.07 4.93 14.75 2 12 2 6.89 3 4.93 4.93Z"/>
                                    /*@ts-ignore*/
                                    : (servico?.nome).toLowerCase().includes("ballet") || (servico?.nome).toLowerCase().includes("dança")
                                      ? <path
                                        d="M17 17H15V23H13V17H10.88L9.34 18.93L11.71 21.29L10.29 22.71L7.93 20.34C7.58 20 7.38 19.53 7.35 19.04C7.32 18.55 7.47 18.06 7.78 17.68L8.32 17H7L9 13V10C8.38 10.47 7.88 11.07 7.53 11.76C7.18 12.46 7 13.22 7 14H5C5 12.14 5.74 10.36 7.05 9.05C8.36 7.74 10.14 7 12 7C13.33 7 14.6 6.47 15.54 5.54C16.47 4.6 17 3.33 17 2H19C19 3.32 18.62 4.62 17.91 5.73C17.2 6.85 16.2 7.74 15 8.31V13L17 17M14 4C14 4.4 13.88 4.78 13.66 5.11C13.44 5.44 13.13 5.7 12.77 5.85C12.4 6 12 6.04 11.61 5.96C11.22 5.88 10.87 5.69 10.59 5.41C10.31 5.13 10.12 4.78 10.04 4.39C9.96 4 10 3.6 10.15 3.24C10.3 2.87 10.56 2.56 10.89 2.34C11.22 2.12 11.6 2 12 2C12.53 2 13.04 2.21 13.41 2.59C13.79 2.96 14 3.47 14 4Z"/>
                                      /*@ts-ignore*/
                                      : (servico?.nome).toLowerCase().includes("vôlei") || (servico?.nome).toLowerCase().includes("futsal")
                                        ? <path
                                          d="M19.04 4.85C17.34 3.2 15.33 2.25 13 2V5.62L22 10.8C21.72 8.5 20.73 6.5 19.04 4.85M12 22C15.44 22 18.16 20.62 20.17 17.86L17.06 16L8.07 21.2C9.32 21.73 10.64 22 12 22M13 11.41L21.15 16.07C21.59 15.13 21.88 14.14 22 13.11L13 7.93V11.41M3.88 17.81C4.54 18.72 5.26 19.46 6.05 20L15.04 14.9L12 13.15L3.88 17.81M11.04 2C10 2.09 9 2.36 8 2.8V13.15L11.04 11.41V2M2 12C2 13.39 2.3 14.77 2.89 16.12L6 14.28V4C3.33 6 2 8.65 2 12Z"/>
                                        /*@ts-ignore*/
                                        : (servico?.nome).toLowerCase().includes("jazz")
                                          ? <path
                                            d="M21,3V15.5A3.5,3.5 0 0,1 17.5,19A3.5,3.5 0 0,1 14,15.5A3.5,3.5 0 0,1 17.5,12C18.04,12 18.55,12.12 19,12.34V6.47L9,8.6V17.5A3.5,3.5 0 0,1 5.5,21A3.5,3.5 0 0,1 2,17.5A3.5,3.5 0 0,1 5.5,14C6.04,14 6.55,14.12 7,14.34V6L21,3Z"/>
                                          /*@ts-ignore*/
                                          : (servico?.nome).toLowerCase().includes("judô") || (servico?.nome).toLowerCase().includes("karatê")
                                            ? <path
                                              d="M19.8 2L11.6 8.7L10.39 7.66L14 5.58L9.41 1L8 2.41L10.74 5.15L5 8.46L3.81 12.75L6.27 17L8 16L5.97 12.5L6.32 11.18L9.5 13L10 22H12L12.5 12L21 3.4L19.8 2M5 3C6.11 3 7 3.9 7 5S6.11 7 5 7 3 6.11 3 5 3.9 3 5 3Z"/>
                                            /*@ts-ignore*/
                                            : (servico?.nome).toLowerCase().includes("esporte")
                                              ? <path
                                                d="M4 6C4 4.9 4.9 4 6 4S8 4.9 8 6 7.1 8 6 8 4 7.1 4 6M1 9H7L14 4L15.31 5.5L11.14 8.5H14L21.8 4L23 5.4L14.5 12L14 22H12L11.5 12L8 11H1V9Z"/>
                                              : <path
                                                d="M12 3L1 9L5 11.18V17.18L12 21L19 17.18V11.18L21 10.09V17H23V9L12 3M18.82 9L12 12.72L5.18 9L12 5.28L18.82 9M17 16L12 18.72L7 16V12.27L12 15L17 12.27V16Z"/>}
                                </svg>
                              )}
                            </CardMedia>
                            <CardContent
                              sx={{
                                p: 1
                              }}
                            >
                              <Typography
                                variant="caption"
                                sx={{
                                  minHeight: 40,
                                  display: "block"
                                }}
                              >
                                {servico?.nome}
                              </Typography>

                              <Button
                                fullWidth
                                variant="contained"
                                color={parcelasSelecionadas[servico?.id] !== undefined ? "primary" : "secondary"}
                                size="small"
                                onClick={handleSelectService(servico)}
                                sx={{
                                  textTransform: "uppercase",
                                  mt: 2,
                                  fontSize: 18,
                                  backgroundColor: parcelasSelecionadas[servico?.id] !== undefined ? theme.palette.primary.main : "#c8c8c9",
                                  "&:hover Mui-checked": {
                                    color: theme.palette.primary.main
                                  }
                                }}
                              >
                                <Stack
                                  direction="row"
                                  spacing={1}
                                  alignItems="center"
                                  justifyContent="center"
                                >
                                  <Checkbox
                                    edge="end"
                                    onChange={handleSelectService(servico)}
                                    onClick={handleSelectService(servico)}
                                    checked={parcelasSelecionadas[servico?.id] !== undefined}
                                    inputProps={{'aria-labelledby': labelId}}
                                    size="large"
                                    sx={{
                                      pointerEvents: "none",
                                      color: "primary",
                                      '&.Mui-checked': {
                                        color: 'white',
                                      },
                                    }}
                                  />
                                  <span>Selecionar</span>
                                </Stack>
                              </Button>

                              <Zoom
                                in={parcelasSelecionadas[servico?.id] !== undefined}
                                unmountOnExit
                                mountOnEnter
                              >
                                <Stack
                                  direction="row"
                                  sx={{
                                    width: '100%',
                                    pt: 1
                                  }}
                                >
                                  <TextField
                                    fullWidth
                                    size="small"
                                    label="Parcelamento"
                                    error={!parcela}
                                    FormHelperTextProps={{
                                      component: "div",
                                    }}
                                    helperText={parcela && parcela.descricaoParcelas.length > 0 && (
                                      <Alert
                                        icon={false}
                                        severity="info"
                                      >
                                        <SvgIcon
                                          sx={{
                                            width: 24,
                                            height: 24,
                                            pr: 1
                                          }}
                                        >
                                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                               xmlns="http://www.w3.org/2000/svg">
                                            <path
                                              d="M18 5H6C4.89543 5 4 5.89543 4 7V19C4 20.1046 4.89543 21 6 21H18C19.1046 21 20 20.1046 20 19V7C20 5.89543 19.1046 5 18 5Z"
                                              stroke={theme.palette.info.main} strokeWidth="2" strokeLinecap="round"
                                              fill="transparent"
                                              strokeLinejoin="round"/>
                                            <path d="M16 3V7" stroke={theme.palette.info.main} strokeWidth="2"
                                                  strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M8 3V7" stroke={theme.palette.info.main} strokeWidth="2"
                                                  strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M4 11H20" stroke={theme.palette.info.main} strokeWidth="2"
                                                  strokeLinecap="round" strokeLinejoin="round"/>
                                          </svg>
                                        </SvgIcon>
                                        Pagamento
                                        em {formatDateStringToDisplay(parcela.descricaoParcelas[0].vencimento)}
                                      </Alert>
                                    )}
                                    value={parcela?.id ?? ""}
                                    onChange={handleSelectParcela(servico)}
                                    select
                                    required
                                    SelectProps={{
                                      native: true,
                                    }}
                                  >
                                    <option value="" disabled>Selecione</option>
                                    {servico.mensais?.map((opcao) => (
                                      <option key={opcao.id} value={opcao.id}>
                                        {opcao.fullAvista
                                          ? <>Pagamento à vista {currency(opcao.valor)}</>
                                          : <>{opcao.totalParcela}x {currency(opcao.valor)}</>
                                        }
                                      </option>
                                    ))}
                                  </TextField>
                                </Stack>
                              </Zoom>
                            </CardContent>
                          </Card>
                        </Zoom>
                      </Stack>
                    )
                  })
            }
          </Stack>
        </Box>
      </Paper>
    </Box>
  );
}

export default ServicosComplementares;
