import React from 'react'
import { useSelector } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'
import { PATH_ROUTE } from '../routes'
import { DASHBOARD, hasAny } from 'utils/permissions'
import { permissionsSelector } from 'modules/Login/selectors/user'

const PrivateRoute = ({ component: Component, ...rest }) => {
  const permissions = useSelector(permissionsSelector)
  const isIn = hasAny(permissions, [DASHBOARD])

  return (
    <Route
      {...rest}
      render={props =>
        isIn ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={PATH_ROUTE.LOGIN}
          />
        )
      }
    />
  )
}

export default PrivateRoute
