import {makeStyles} from "@mui/styles";


export default makeStyles(theme => ({
  billetFormContainer: {
    display: 'flex',
  },
  billetFormItem: {
    marginBottom: theme.spacing(2),
    paddingLeft: theme.spacing(1),
    marginTop: theme.spacing(1),
    minHeight: 70
  },
  root: {
    background: 'rgba(0, 0, 0, 0.1)',
    color: 'grey',
    height: 56,
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: '0 3px 5px 2px rgba(0, 0, 0, 0.1)',
    borderColor: 'rgba(0, 0, 0, 0.23)',
    borderStyle: 'solid',
    borderWidth: 1,
    display: 'flex',
    borderRadius: 4,
    padding: 15
  },
  disabled: {
    color: '#757575',
  },
  formValueAmount: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: '2rem',
  },
  formAmount:{
    textAlign: 'center',
    lineHeight: 1.6,
    fontSize: '1rem',
  }
}))
