import {getRecoil} from "recoil-nexus";
import {portalAuthState} from "../recoil/atoms/portalAuthState";

export function PortalAcceptanceTermChangePaymentMode(acceptanceTermJson, appId = "e24b77aa-da82e5427477") {
  const auth = getRecoil(portalAuthState);
  return fetch(`${process.env.REACT_APP_API}v1/acceptanceterm/${appId}/acceptance-term-change-payment-mode`, {
    method: "POST",
    mode: "cors",
    headers: {
      Authorization: `Bearer ${auth?.token}`,
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      acceptanceTerm: JSON.stringify(acceptanceTermJson)
    })
  })
}
