import {selector} from "recoil";
import {selectedMatriculaState} from "../../../atoms/selectedMatriculaState";
import {razaoStatus} from "../../../../utils/razaoStatus";
import selectedCalcListServiceSelector from "../../selectedCalcListServiceSelector";

const podeContratarServicoComplementarSelector = selector({
  key: 'podeContratarServicoComplementarSelector', // unique ID (with respect to other atoms/selectors)
  get: ({get}) => {
    const selectedMatricula = get(selectedMatriculaState);
    const service = get(selectedCalcListServiceSelector);
    return selectedMatricula &&
      selectedMatricula.razaoStatus_Value !== razaoStatus.ponto_1_preReserva &&
      service &&
      service.servicosUpSelling &&
      service.servicosUpSelling.length > 0;
  },
});

export default podeContratarServicoComplementarSelector

