import CloseIcon from '@mui/icons-material/Close';
import {
  CardContent,
  Checkbox,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  IconButton,
  List,
  Skeleton,
  Stack,
  useTheme
} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CircularProgress from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import React, {SyntheticEvent, useState} from 'react';
import renderHTML from "react-render-html";
import {useRecoilValue} from "recoil";
import IGuid from "../../@types/IGuid";
import {loadingContractState} from '../../recoil/atoms/loadingContractState';
import {nomesContratosState} from '../../recoil/atoms/nomesContratosState';
import contratoServicoRegularSelector
  from '../../recoil/selectors/carrinho/servicoRegular/contratoServicoRegularSelector';
import servicoRegularSelector from '../../recoil/selectors/carrinho/servicoRegular/servicoRegularSelector';
import FileTextIcon from "../Icons/FileTextIcon";
import ShadowRoot from '../ShadowRoot/ShadowRoot';
import contratosUpSellingSelector from "../../recoil/selectors/carrinho/servicosUpSelling/contratosUpSellingSelector";

interface IProps {
  isLoading?: boolean
  onContractConfirm: (event: SyntheticEvent<Element, Event>) => void
  acceptContract: boolean
  disabled?: boolean
}

function AcceptContract(props: IProps) {
  const {
    isLoading = false,
    onContractConfirm,
    acceptContract,
    disabled = false,
  } = props;
  const contractsNames = useRecoilValue(nomesContratosState);
  const contratoServicoRegular = useRecoilValue(contratoServicoRegularSelector);
  const contratosUpSelling = useRecoilValue(contratosUpSellingSelector);
  const isLoadingContract = useRecoilValue(loadingContractState);
  const servicoRegular = useRecoilValue(servicoRegularSelector);
  const [htmlContract, setHtmlContract] = useState<string | null>(null);
  const [showContract, setShowContract] = useState(false);
  const theme = useTheme();

  const descriptionElementRef = React.useRef<HTMLElement>(null);
  React.useEffect(() => {
    if (showContract) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [showContract]);

  const handleCloseModalContrato = () => {
    setShowContract(false);
  }

  const handleOpenModalContrato = (idServicoUnidade: IGuid) => async (event: React.MouseEvent<HTMLDivElement, MouseEvent>,) => {
    event.preventDefault();

    if (servicoRegular?.id === idServicoUnidade) {
      setHtmlContract(contratoServicoRegular.servicoUnidadeContratoHTML);
    } else {
      const contrato = contratosUpSelling[idServicoUnidade]
      if (contrato) {
        setHtmlContract(contrato.servicoUnidadeContratoHTML);
      }
    }

    setShowContract(true);
  }

  if (isLoadingContract)
    return (
      <Stack
        direction="column"
        spacing={2}
        sx={{
          mt: 2
        }}
      >
        <Skeleton
          variant="rounded"
          height={200}
        />
      </Stack>
    )

  return (
    <>
      <Card
        sx={{
          mt: 2,
          boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px'
        }}
      >
        <CardHeader
          title="Concorda com o nosso contrato?"
        />

        <CardContent>
          <FormControlLabel
            disabled={disabled}
            checked={acceptContract}
            onChange={onContractConfirm}
            required
            sx={{
              color: acceptContract ? undefined : theme.palette.error.main
            }}
            control={
              isLoading || isLoadingContract ?
                <CircularProgress size={16} sx={{ m: 2 }} /> :
                <Checkbox
                  color={acceptContract ? "primary" : "error"}
                  required
                  size="large"
                />
            }
            label={"Declaro que li e concordo com" + (contractsNames.length > 1 ? " os contratos" : " o contrato")}
          />

          <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
            {contractsNames.map((item, index) => (
              <ListItemButton
                key={index}
                disabled={disabled || isLoadingContract}
                onClick={handleOpenModalContrato(item.idServicoUnidade)}
              >
                <ListItemAvatar>
                  <Avatar
                    variant="rounded"
                  >
                    <FileTextIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={item.descricao}
                />
              </ListItemButton>
            ))}
          </List>
        </CardContent>
      </Card>

      <Dialog
        open={showContract}
        onClose={handleCloseModalContrato}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        fullWidth
        maxWidth="md"
      >
        <DialogTitle id="scroll-dialog-title">Contrato</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseModalContrato}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            <ShadowRoot>
              {renderHTML(htmlContract ?? "")}
            </ShadowRoot>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default AcceptContract;
