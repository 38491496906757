import {selector} from "recoil";
import {parcelasSelecionadasState} from "../../../atoms/parcelasSelecionadasState";
import {ParcelaMensal} from "../../../../@types/IServicoDto";
import servicosObrigatoriosSelector from "./servicosObrigatoriosSelector";

const parcelasObrigatoriosSelecionadasSelector = selector<{[servicoId: string]: ParcelaMensal | null}>({
  key: 'parcelasObrigatoriosSelecionadasSelector', // unique ID (with respect to other atoms/selectors)
  get: ({get}) => {
    const servicos = get(servicosObrigatoriosSelector);
    const parcelasIds = servicos
      .map(servico => servico?.mensais?.map(parcela => parcela?.id))
      .flat(1)
    const parcelasSelecionadas = get(parcelasSelecionadasState);
    const keys = Object.keys(parcelasSelecionadas?.servicosObrigatorio);
    const parcelas: {[servicoId: string]: ParcelaMensal | null} = {};

    keys.forEach(key => {
      const isIn = parcelasIds
        ?.includes(parcelasSelecionadas?.servicosObrigatorio[key]?.id ?? "") || parcelasSelecionadas?.servicosObrigatorio[key] === null
      if (isIn) {
        parcelas[key] = parcelasSelecionadas?.servicosObrigatorio[key] as ParcelaMensal;
      }
    })

    return parcelas;
  },
});

export default parcelasObrigatoriosSelecionadasSelector

