import {selector} from "recoil";
import selectedCalcListServiceSelector from "../../selectedCalcListServiceSelector";
import {IServicoUpSelling} from "../../../../@types/IServicoDto";

const servicosUpSellingSelector = selector({
  key: 'servicosUpSellingSelector',
  get: ({get}) => {
    let servicos = get(selectedCalcListServiceSelector);
    return (servicos?.servicosUpSelling || []).map(servico => ({
      ...servico,
      matriculaId: servicos?.matriculaId,
    })) as Array<IServicoUpSelling>;
  },
});

export default servicosUpSellingSelector;
