import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import {useRecoilValue} from "recoil";
import servicosObrigatoriosSelector
  from "../../recoil/selectors/carrinho/servicosObrigatorios/servicosObrigatoriosSelector";
import {currency} from "../../utils/utils";
import {IServico, ParcelaMensal} from "../../@types/IServicoDto";
import carrinho from "../../utils/carrinho";
import parcelasObrigatoriosSelecionadasSelector
  from "../../recoil/selectors/carrinho/servicosObrigatorios/parcelasObrigatoriosSelecionadasSelector";
import {DateTime} from "luxon";
import Alert from "@mui/material/Alert";
import SvgIcon from "@mui/material/SvgIcon";
import {useTheme} from "@mui/material/styles";
import Chip from "@mui/material/Chip";

const ServicosObrigatorios: React.FC = () => {
  const servicosObrigatorios = useRecoilValue(servicosObrigatoriosSelector);
  const parcelasSelecionadas = useRecoilValue(parcelasObrigatoriosSelecionadasSelector);
  const theme = useTheme();

  const handleSelectParcela = (servico: IServico) => (e: any) => {
    const parcela = servico?.mensais?.find(p => p.id === e.target.value);

    if (parcela?.entradas?.length === 1) {
      carrinho.addParcelaEntrada(
        servico,
        parcela,
        parcela.entradas[0]
      )
    } else {
      const parcelaSelecionada = parcelasSelecionadas[servico?.id as string] as ParcelaMensal;

      carrinho.removeParcelaEntrada(
        servico,
        parcelaSelecionada
      )
    }

    carrinho.addParcela(
      servico,
      parcela
    )
  }

  const formatDateStringToDisplay = (date: string) => {
    return DateTime.fromISO(date).setLocale("pt-br").toFormat("MMMM 'de' yyyy");
  }

  return (
    <>
      {
        servicosObrigatorios?.map((servicoObrigatorio, idx) => {
          const parcelaSelecionada = parcelasSelecionadas[servicoObrigatorio.id] as ParcelaMensal;
          return (
            <Box key={idx} sx={{pt: '15px'}}>
              <Typography variant="h4" component="h3">
                {servicoObrigatorio.tipo === "MaterialDidatico" ? "Material didático" : servicoObrigatorio.nome}
              </Typography>

              <p>Escolha como deseja realizar o pagamento</p>

              <div className='row'>
                <div className='col-sm-12 pb-3'>
                  <TextField
                    FormHelperTextProps={{
                      component: "div",
                    }}
                    helperText={parcelaSelecionada
                      ? (
                        <Alert
                          icon={<SvgIcon
                            sx={{
                              width: 24,
                              height: 24,
                            }}
                          >
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M18 5H6C4.89543 5 4 5.89543 4 7V19C4 20.1046 4.89543 21 6 21H18C19.1046 21 20 20.1046 20 19V7C20 5.89543 19.1046 5 18 5Z"
                                stroke={theme.palette.info.main} strokeWidth="2" strokeLinecap="round"
                                fill="transparent"
                                strokeLinejoin="round"/>
                              <path d="M16 3V7" stroke={theme.palette.info.main} strokeWidth="2"
                                    strokeLinecap="round" strokeLinejoin="round"/>
                              <path d="M8 3V7" stroke={theme.palette.info.main} strokeWidth="2"
                                    strokeLinecap="round" strokeLinejoin="round"/>
                              <path d="M4 11H20" stroke={theme.palette.info.main} strokeWidth="2"
                                    strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                          </SvgIcon>}
                          severity="info"
                        >
                          Pagamento em {formatDateStringToDisplay(parcelaSelecionada.descricaoParcelas[0].vencimento)}
                        </Alert>
                      )
                      : "Selecione uma opção de pagamento"
                    }
                    error={!parcelaSelecionada}
                    fullWidth
                    size="small"
                    label={servicoObrigatorio.tipo === "MaterialDidatico" ? "Material didático" : servicoObrigatorio.nome}
                    value={parcelaSelecionada?.id ?? ""}
                    onChange={handleSelectParcela(servicoObrigatorio)}
                    select
                    sx={{
                      "MuiFormHelperText-root": {
                        color: "red",
                      },
                    }}
                    SelectProps={{
                      native: true,
                    }}
                  >
                    <option value="" disabled>Selecione</option>
                    {servicoObrigatorio.mensais?.map((opcao) => {
                      return (
                        <option key={opcao.id} value={opcao.id}>
                          {opcao.fullAvista && opcao.totalParcela === 1
                            ? <>Pagamento à vista {currency(opcao.valorTotal)}</>
                            : <>{opcao?.valorEntrada > 0 ? <>Entrada
                              de {currency(opcao?.valorEntrada)} mais</> : ""} {opcao.totalParcela}x {currency(opcao.valor)}</>
                          }

                          <Chip size="small" label=" (sem juros)" color="primary" variant="outlined"/>
                        </option>
                      )
                    })}
                  </TextField>
                </div>
              </div>
            </Box>
          )
        })
      }
      <Divider/>
    </>
  )
}

export default ServicosObrigatorios;
