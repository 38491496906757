import {selector} from "recoil";
import {acceptedContractsState} from "../atoms/acceptedContractsState";
import {selectedMeioPagamentoEntradaState} from "../atoms/selectedMeioPagamentoEntradaState";
import {selectedMeioPagamentoParcelamentoState} from "../atoms/selectedMeioPagamentoParcelamentoState";
import possuiRecorrenteSelector from "./possuiRecorrenteSelector";
import {meiosPagamento} from "../../utils/tipoPagamento";
import {selectedEntradaCreditCardState} from "../atoms/selectedEntradaCreditCardState";
import {selectedRecorrenciaCreditCardState} from "../atoms/selectedRecorrenciaCreditCardState";
import isPreReservaSemValorSelector from "./isPreReservaSemValorSelector";
import isPreReservaSelector from "./isPreReservaSelector";
import possuiEntradaSelector from "./possuiEntradaSelector";
import dadosDebitoState from "../atoms/dadosDebitoState";
import * as utils from "../../commons/functions/utils";

const canFinishCheckoutSelector = selector({
  key: 'canFinishCheckoutSelector',
  get: ({get}) => {
    const acceptedContracts = get(acceptedContractsState);
    let selectedMetodoEntrada = get(selectedMeioPagamentoEntradaState);
    let selectedMetodoParcelamento = get(selectedMeioPagamentoParcelamentoState);
    const possuiRecorrente = get(possuiRecorrenteSelector);
    const possuiEntrada = get(possuiEntradaSelector);
    const selectedCreditCardEntrada = get(selectedEntradaCreditCardState);
    const selectedCreditCardRecorrente = get(selectedRecorrenciaCreditCardState);
    const dadosDebito = get(dadosDebitoState);
    const isReservaSemValor = get(isPreReservaSemValorSelector);

    if (isReservaSemValor) {
      return acceptedContracts
    }

    if (selectedMetodoEntrada === meiosPagamento.Cartao_Credito && !selectedCreditCardEntrada) {
      return false;
    }

    if (selectedMetodoEntrada === meiosPagamento.Cartao_Credito_Recorrente && !selectedCreditCardEntrada) {
      return false;
    }

    if (selectedMetodoParcelamento === meiosPagamento.Cartao_Debito && (dadosDebito.bankAccount == '' || dadosDebito.bankAgency == '' || dadosDebito.bankId == '' || dadosDebito.bankAccountDigit == '' || dadosDebito.cnpjCPFNum == '' || dadosDebito.name == '')) {
      return false;
    } else if (selectedMetodoParcelamento === meiosPagamento.Cartao_Debito && utils.ExisteDigitoAgencia(dadosDebito?.bankId) && dadosDebito?.bankAgencyDigit == '') {
      return false;
    }

    if (selectedMetodoParcelamento === meiosPagamento.Cartao_Credito_Recorrente && !selectedCreditCardRecorrente) {
      return false;
    }

    return acceptedContracts && (selectedMetodoEntrada || !possuiEntrada) && ((possuiRecorrente && selectedMetodoParcelamento) || !possuiRecorrente);
  },
});

export default canFinishCheckoutSelector;
