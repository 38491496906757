import React from 'react';
import "./styles.css";
import {LinearProgress} from "@mui/material";
import Box from "@mui/material/Box";

interface PageLoadingProps {
  children?: React.ReactNode;
}

function PageLoading(
  {
    children,
    ...rest
  } : PageLoadingProps
) {
  return (
    <div
      className="page-loading-animation-container"
      {...rest}
    >
      <Box sx={{ width: '50%', p: 2 }}>
        <LinearProgress />
      </Box>
      {children}
    </div>
  );
}

export default PageLoading;

