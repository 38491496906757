import {datadogRum} from "@datadog/browser-rum";
import moment from "moment-timezone";

export function inicializaUsuarioDD() {
  try {
    datadogRum.setUser({
      id: localStorage.getItem('@SMO20/idResponsavel'),
      name: localStorage.getItem('@SMO20/nomeResponsavel'),
      email: localStorage.getItem('@SMO20/email'),
      fuso: moment().format('YYYY-MM-DDTHH:mm:ss')
    })
  } catch (e) {
    console.error("Não foi possível inicializar o usuário DD")
  }
}
