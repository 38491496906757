import React, {useEffect, useState} from 'react'
import {isEmpty, isNil, omit} from 'lodash'
import {accessIdSelectedSelector, appIdSelector, isTourEnabledSelector} from 'modules/Login/selectors/user'
import {Description} from '@mui/icons-material'
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  IconButton,
  OutlinedInput,
  Skeleton,
  Stack,
  SvgIcon,
  Tooltip,
  Typography,
  useMediaQuery
} from '@mui/material'
import {makeStyles, useTheme} from '@mui/styles'
import {useDispatch, useSelector} from 'react-redux'
import {generateIncomeTax} from './actions'
import {isLoadingDeclarationSelector} from './selectors/declaration'
import {ListTable} from '../../components/customTable/list-table'
import {ListContainer} from '../../components/customTable/list-container'
import {DateBanner} from '../../components/dateBanner'
import {GridSearchIcon} from '@mui/x-data-grid'
import {getStudents} from './services/incometax'
import {useTranslation} from '../../hooks/use-translation'
import {tokens} from '../../locales/tokens'
import {isLoadingStudentsSelector, studentsDataSelector} from './selectors/students'
import {FormattedMessage, injectIntl} from 'react-intl'
import {useRecoilValue} from 'recoil'
import {avatarsState} from '../../recoil/atoms/avatarsState'
import matriculasSelector from "../../recoil/selectors/matriculasSelector";

const useStyles = makeStyles(() => ({
  notFoundStudents: {
    textAlign: 'center'
  }
}))

export default injectIntl(({ intl: { messages } }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const theme = useTheme()
  const isLoadingDeclaration = useSelector(isLoadingDeclarationSelector)
  const isLoadingStudents = useSelector(isLoadingStudentsSelector)
  const [isLoading, setIsLoading] = useState(true)
  const avatars = useRecoilValue(avatarsState)
  const matriculas = useRecoilValue(matriculasSelector)
  const [search, setSearch] = useState('')
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'))
  const isMedium = useMediaQuery(theme => theme.breakpoints.down('md'))
  const appId = useSelector(appIdSelector)
  const data = useSelector(studentsDataSelector)
  const clientCode = useSelector(accessIdSelectedSelector)
  const isTourOpen = useSelector(isTourEnabledSelector)
  const { t } = useTranslation()

  const onGenerate = (item, isTotal, id) => {
    const data = {
      ...omit(item, ['studentName', 'tableData']),
      reportType: isTotal ? 1 : 0
    }

    dispatch(generateIncomeTax(appId, isTotal, data, id))
  }

  const showYearColumn = () => [
    {
      field: 'year',
      headerName: t(tokens.common.year) ?? '',
      headerAlign: 'center',
      width: 120,
      renderCell: (params) => (
        <DateBanner date={params.row.year} />
      )
    },
  ]

  const columns = () => {
    return [
      ...isSmall ? [] : showYearColumn(),
      {
        field: 'studentName',
        headerName: t(tokens.common.name) ?? '',
        headerAlign: 'center',
        flex: 1,
        width: 300,
        renderCell: (params) => {
          return (
            <Stack direction='row' alignItems='center' spacing={1}>
              {!isSmall && <Avatar
                src={
                  avatars[
                    matriculas.find(
                      m => m.dadosAluno.codigoErp === params.row.studentCode
                    )?.dadosAluno?.id
                  ]
                }
                sx={{ width: 32, height: 32 }}
              />
              }
              <Typography>{params.row.studentName}</Typography>
            </Stack>
          )
        }
      },
      {
        field: 'clientCode',
        headerName: t(tokens.common.enrollment) ?? '',
        headerAlign: 'center',
        flex: 1,
        width: 130
      },
      {
        field: 'incomeTaxButton',
        headerName: t(tokens.common.incomeTax) ?? '',
        headerAlign: 'center',
        flex: 1,
        width: 160,
        renderCell: (params) => (
          <Stack sx={{ width: '100%', alignItems: 'center' }}>
            <IconButton
              id='icon-button'
              onClick={() => onGenerate(params.row, false, 'icon-button')}
              sx={{
                '&:hover': {
                  color: theme.palette.primary.main
                }
              }}
            >
              <Description />
            </IconButton>
          </Stack>
        )
      },
      {
        field: 'fullReceiptButton',
        headerName: t(tokens.common.fullReceipt) ?? '',
        headerAlign: 'center',
        flex: 1,
        width: 160,
        renderCell: (params) => (
          <Stack sx={{ width: '100%', alignItems: 'center' }}>
            <IconButton
              id='icon-button-total'
              onClick={() => onGenerate(params.row, true, 'icon-button-total')}
              sx={{
                '&:hover': {
                  color: theme.palette.primary.main
                }
              }}
            >
              <Description />
            </IconButton>
          </Stack>
        )
      }
    ]
  }

  useEffect(() => {
    if (isTourOpen && !isEmpty(data)) {
      setIsLoading(false)
      return
    }

    dispatch(getStudents(appId, isNil(clientCode) ? '' : clientCode)).then(() => {
      setIsLoading(false)
    })
  }, [dispatch, appId, isTourOpen])

  if (isNil(data) || data.length === 0) {
    return (
      <Box
        sx={{
          marginTop: theme.spacing(3)
        }}
      >
        <Typography
          gutterBottom
          component='p'
          color='textPrimary'
          className={classes.notFoundStudents}
        >
          <FormattedMessage id='income-tax-students-not-found' />
        </Typography>
      </Box>
    )
  }

  const rowsFiltered =
    data &&
    data.length > 0 ?
    data
      .map((item, index) => {
        return {
          id: index,
          ...item
        }
      })
      .filter(item => {
        return (
          item.studentName.toLowerCase().includes(search.toLowerCase()) ||
          item.clientCode.toLowerCase().includes(search.toLowerCase())
        )
      })
    : []

  return (
    <Box
      sx={{
        marginTop: theme.spacing(3)
      }}
    >
      <Card>
        <CardContent
          sx={ isMedium ? { padding: 0 } : {} }
        >
          <Grid
            container
            sx={{
              paddingTop: 4,
              paddingLeft: 3,
              paddingRight: 3,
              paddingBottom: 2
            }}
            spacing={2}
          >
            <Grid item xs={12} sm={8}>
              <Typography component='h5' variant='h5'>
                {t(tokens.nav.financialDeclarations)}
              </Typography>
              <Tooltip title={t(tokens.common.financialDeclarationDesc)}>
                <Typography
                  component={'h6'}
                  variant={'h6'}
                  color={'textSecondary'}
                >
                  {t(tokens.common.financialDeclarationDesc)}
                </Typography>
              </Tooltip>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Stack alignItems='center' direction='row' spacing={1}>
                <OutlinedInput
                  fullWidth
                  placeholder={t(tokens.common.search) ?? ''}
                  defaultValue={search}
                  onChange={e => setSearch(e.target.value)}
                  autoComplete='off'
                  startAdornment={
                    <SvgIcon>
                      <GridSearchIcon />
                    </SvgIcon>
                  }
                />
              </Stack>
            </Grid>
          </Grid>
          <Divider
            variant='middle'
            sx={{
              borderBottomWidth: 2,
              borderColor: '#c6bbbb !important'
            }}
          />
          <ListContainer
            sx={{
              paddingTop: '0',
              paddingBottom: '0',
              marginTop: theme.spacing(2)
            }}
          >
            <ListTable
              columns={columns()}
              loading={isLoadingStudents || isLoadingDeclaration}
              selectable={false}
              isPaginated={false}
              autoRowHeight
              disableColumnMenu
              items={rowsFiltered ?? []}
            />
          </ListContainer>
        </CardContent>
      </Card>
    </Box>
  )
})
