import {apiReducer} from "../../../../utils/API";
import {sortBy} from "lodash";
import {PAYMENTTYPES_REGISTRATION} from "../constants";

const mapSuccessData = (action, states) => {
  const registrations = action.data ? sortBy(action.data, ["studentname"]) : [];
  return registrations;
};

export default apiReducer({
  entity: PAYMENTTYPES_REGISTRATION,
  defaultData: [],
  mapSuccessData,
});
