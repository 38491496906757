import { pipe, prop } from 'ramda'

import {
  getAPIDataSelector,
  hasErrorAPISelector,
  hasLoadedAPISelector,
  isLoadingAPISelector,
  getAPIErrorSelector
} from '../../../utils/API/selectors'

const baseFinanceSelector = prop('finance')

export const receiptSelector = pipe(baseFinanceSelector, prop('receipt'))

export const receiptDataSelector = getAPIDataSelector(receiptSelector)

export const receiptErrorSelector = getAPIErrorSelector(receiptSelector)

export const hasLoadedReceiptSelector = hasLoadedAPISelector(receiptSelector)

export const hasErrorReceiptSelector = hasErrorAPISelector(receiptSelector)

export const isLoadingReceiptSelector = isLoadingAPISelector(receiptSelector)
