import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'
import { accessIdSelector } from '../../../Login/selectors/user'
import Checkout from './components/Checkout'
import { CREATE_FINANCIAL, PAYMENT_CREDIT_NEGOTIATION } from '../constants'
import { apiResetAction } from '../../../../utils/API/actions'
import { isLoadingPostFinancialSelector } from '../selectors/postFinancial'
import { fetchExpirated, resetTourModalPayment } from '../actions'
import { hide } from '../../../../layouts/actions'
import { isEmpty, isNil } from 'ramda'
import { stepTourPaymentSelector } from '../selectors/payment'
import useMediaQuery from "@mui/material/useMediaQuery";
import {makeStyles} from "@mui/styles";
import Dialog from "@mui/material/Dialog";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const useStyles = makeStyles(() => ({
  paperScrollPaper: {
    maxHeight: '98%',
  },
  buttonClose: {
    position: 'absolute',
    right: 0
  }
}))

export const stepsBillet = [
  {
    label: 'Pagamento',
    id: 'lbl-mdl-neg-pag'
  },
  {
    label: 'Aceite',
    isConfirmar: true,
    isAccept: true,
    isVoltar: true,
    labelButton: 'Finalizar acordo',
    id: 'lbl-mdl-neg-cfm'
  },
  {
    label: 'Impressão',
    isFechar: true,
    isSmallModal: true,
    id: 'lbl-mdl-pag-cfm'
  }]

export const stepsCard = [
  {
    label: 'Proposta',
    isConfirmar: true,
    labelButton: 'Avançar',
    id: 'lbl-mdl-neg-cfm'
  },
  {
    label: 'Pagamento',
    id: 'lbl-mdl-neg-pag',
    labelButton: 'Finalizar acordo',
    // isAccept: true,
    // isVoltar: false,
    // isConfirmar: true
  },
  // {
  //   label: 'Aceite',
  //   labelButton: 'Finalizar acordo',
  //   isAccept: true,
  //   isVoltar: false,
  //   isConfirmar: true,
  //   id: 'lbl-mdl-neg-cfm'
  // },
  {
    label: 'Confirmação',
    isFechar: true,
    isSmallModal: true,
    id: 'lbl-mdl-pag-cfm'
  }
]

export default ({ open, onClose, isBillet, data }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [activeStep, setActiveStep] = useState(0)
  const fullScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'))
  const isLoadingFinance = useSelector(isLoadingPostFinancialSelector)
  const accessId = useSelector(accessIdSelector)
  const activeStepTour = useSelector(stepTourPaymentSelector)

  useEffect(() => {
    if (!isNil(activeStepTour)) {
      setActiveStep(activeStepTour)
    }
  }, [activeStepTour])

  /**
   *
   * @param {object} event
   * @param {"backdropClick"|"escapeKeyDown"} reason
   */
  const handleClose = (event, reason) => {
    if (reason === 'backdropClick' || reason === 'escapeKeyDown' && isLoadingFinance) {
      return
    }
    onClose()
    dispatch(hide())
    setActiveStep(0)
    dispatch(apiResetAction(PAYMENT_CREDIT_NEGOTIATION))
    dispatch(apiResetAction(CREATE_FINANCIAL))
    dispatch(resetTourModalPayment())
    dispatch(fetchExpirated({ accessId }))
  }

  const getItems = () => {
    const itemsSelected = data && data.filter(item => item.tableData && item.tableData.checked)
    return isBillet ? data : (itemsSelected && itemsSelected.length > 0 && itemsSelected) || data || []
  }

  if (isEmpty(getItems())) {
    return null
  }

  return (
    <Dialog
      maxWidth="lg"
      fullScreen={fullScreen}
      open={open && !isEmpty(data)}
      onClose={handleClose}
      aria-labelledby='payment-dialog-title'
      disableEscapeKeyDown
      classes={{ paper: !fullScreen && classes.paperScrollPaper }}
    >
      <Box display='flex' justifyContent='flex-end'>
        <IconButton className={classNames(!fullScreen && classes.buttonClose)} disabled={isLoadingFinance} aria-label='close-payment' id='ico-mdl-pag-fch' onClick={handleClose} >
          <CloseIcon />
        </IconButton>
      </Box>
      <Checkout
        isBillet={isBillet}
        handleClose={handleClose}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        items={getItems()}
        steps={isBillet ? stepsBillet : stepsCard}
      />
    </Dialog>
  )
}
