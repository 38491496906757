import React from "react";
import Stack from "@mui/material/Stack";
import {Skeleton} from "@mui/material";
import Divider from "@mui/material/Divider";

const Loading: React.FC = () => {
  return (
    <>
      <Stack
        spacing={4}
      >
        <Stack
          direction="column"
          spacing={2}
        >
          <Skeleton
            variant="rounded"
            height={32}
            width={300}
          />
          <Skeleton
            variant="rounded"
            height={22}
            width={250}
          />
          <Skeleton
            variant="rounded"
            height={55}
          />
        </Stack>

        <Divider/>

        <Stack
          direction="column"
          spacing={2}
        >
          <Skeleton
            variant="rounded"
            height={200}
          />
        </Stack>
      </Stack>
    </>
  );
}

export default Loading;
