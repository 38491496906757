import {
  WAITING_REDE_PAYMENT,
  RESET_PAYMENT,
  PAYMENT_DEBIT,
  PAYMENT_CREDIT,
  CONFIRM_PAYMENT,
} from '../constants'
import { nthArg } from 'ramda'
import { reduceReducers } from '../../../../utils/API/reducer/reducer-utils'
import { apiReducer } from '../../../../utils/API'

const defaultData = {
  message: '',
  waitingRede: false,
  debit: {
    concluded: false
  },
  receipt: {}
}

const paymentDebitSuccessData = (action, states) => ({
  ...states.data,
  debit: {
    ...states.data.debit,
    ...action.data
  }
})

const paymentCreditSuccessData = (action, states) => ({
  ...states.data,
  receipt: action.data,
  message: action.data.message
})

const paymentError = (action, states) => ({
  ...states,
  error: true,
  data: {
    ...states.data,
    message: (action.error && action.error.message) || 'Erro! Tente novamente mais tarde.'
  }
})

const payment = reduceReducers(
  apiReducer({
    defaultData,
    entity: PAYMENT_DEBIT,
    mapLoadingState: nthArg(1),
    mapSuccessData: paymentDebitSuccessData,
    mapError: paymentError,
    resetActions: [RESET_PAYMENT],
  }),
  apiReducer({
    defaultData,
    entity: PAYMENT_CREDIT,
    mapLoadingState: nthArg(1),
    mapSuccessData: paymentCreditSuccessData,
    mapError: paymentError,
    resetActions: [RESET_PAYMENT],
  }),
  apiReducer({
    defaultData,
    entity: CONFIRM_PAYMENT,
    mapLoadingState: nthArg(1),
    mapSuccessData: paymentCreditSuccessData,
    mapError: paymentError,
    resetActions: [RESET_PAYMENT],
  }),
  (states, action) => {
    switch (action.type) {
    case WAITING_REDE_PAYMENT:
      return {
        ...states,
        data: {
          ...states.data,
          waitingRede: action.payload,
          debit: {
            ...states.data.debit,
            concluded: !action.payload
          }
        }
      }
    default:
      return states
    }
  }
)

export default payment
