import { styled } from '@mui/material/styles';

export const ListContainer = styled(
  'div',
  { shouldForwardProp: (prop) => prop !== 'open'}
)<{ open?: boolean; width?: number; anchor?: "left" | "right"}>(
  ({ theme, open, anchor = "left", width = 380 }) => ({
    flexGrow: 1,
    overflow: 'auto',
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
    height: '100%',
    zIndex: 1,
    [theme.breakpoints.up('lg')]: {
      marginLeft: anchor === 'left' ? -(width + 40) : undefined,
      marginRight: anchor === 'right' ? -(width + 40) : undefined
    },
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    ...(open && {
      [theme.breakpoints.up('lg')]: {
        marginLeft: anchor === 'left' ? 0 : undefined,
        marginRight: anchor === 'right' ? 0 : undefined,
      },
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen
      })
    })
  })
);
