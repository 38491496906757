import fetch from "../utils/fetch";
import env from "../env";
import IParametrosPagamentoResponse from "../@types/IParametrosPagamentoResponse";
import {setRecoil} from "recoil-nexus";
import {parametrosPagamentoState} from "../recoil/atoms/parametrosPagamentoState";

export async function ObterPorIdUnidade(idUnidade: string): Promise<IParametrosPagamentoResponse> {
  return fetch(env.apiUrl+"api/ParametrosPagamento/ObterPorIdUnidade/" + idUnidade, {
    method: "GET",
    mode: "cors",
  })
    .then(res => res.json())
    .then((result) => {
        setRecoil(parametrosPagamentoState, result)
        return result;
    })
}
