import React from 'react';
import './styles.css';
import TextField from "@mui/material/TextField";

function SearchInput(props) {
  const {
    onSearch = () => {},
  } = props;
  const [search, setSearch] = React.useState('');

  const handleSearch = (event) => {
    const { value } = event.target;
    setSearch(value);
    onSearch(value);
  }

  const handleClear = () => {
    setSearch('');
    onSearch('');
  }

  return (
    <TextField
      type="text"
      label="Pesquisar"
      value={search}
      size="small"
      fullWidth
      onChange={handleSearch}
      sx={{
        maxWidth: 400,
      }}
      InputProps={{
        endAdornment: (
          <svg
            role="button"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
            onClick={search.length > 0 ? handleClear : () => handleSearch({target: {value: search}})}
          >
            {search.length > 0 ? (
              <path
                d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z"/>
            ) : (
              <path
                d="M9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.44,13.73L14.71,14H15.5L20.5,19L19,20.5L14,15.5V14.71L13.73,14.44C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3M9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5Z"/>
            )}
          </svg>
        ),
      }}
    />
  );
}

export default SearchInput;
