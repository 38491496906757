import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import {CardContent, Grid} from "@mui/material";
import Box from "@mui/material/Box";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import SvgIcon from "@mui/material/SvgIcon";
import Typography from "@mui/material/Typography";
import {Theme} from "@mui/material/styles/createTheme";
import useMediaQuery from "@mui/material/useMediaQuery";
import XIcon from "@untitled-ui/icons-react/build/esm/X";
import React, {FC, ReactNode, useEffect, useRef, useState} from "react";
import {useHistory} from "react-router-dom";
import Carrinho from "../../components/Carrinho/Carrinho";
import {BreadcrumbsSeparator} from "../../components/breadcrumbs-separator";
import {ListContainer} from "../../components/list-container";
import {ListSidebar} from "../../components/list-sidebar";
import {Scrollbar} from "../../components/scrollbar";
import {Layout as DashboardLayout} from "../dashboard";
import Avatar from "@mui/material/Avatar";
import GerarSiglaNome from "../../commons/functions/gerar-sigla-nome";
import Slide from "@mui/material/Slide";
import {slideDirectionState} from "../../recoil/atoms/slideDirectionState";
import {useRecoilValue} from "recoil";
import Paper from "@mui/material/Paper";
import {useTheme} from "@mui/material/styles";

interface LayoutProps {
  title?: any;
  subtitle?: any;
  children?: ReactNode;
  actions?: ReactNode;
  skeleton?: ReactNode;
  loading?: boolean;
  breads?: Array<{
    to?: any;
    text: string;
  }>;
  disableCart?: boolean;
  fullScreenPaper?: boolean;
}

export const Layout: FC<LayoutProps> = (
  {
    title,
    subtitle,
    children,
    actions,
    breads = [],
    skeleton,
    loading,
    disableCart,
    fullScreenPaper
  }
) => {
  const rootRef = useRef(null);
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const smUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));
  const [openSidebar, setOpenSidebar] = useState(lgUp);
  const history = useHistory();
  const slideDirection = useRecoilValue(slideDirectionState);
  const theme = useTheme();

  useEffect(() => {
    if (lgUp && !openSidebar) {
      setOpenSidebar(true)
    }
  }, [lgUp])

  return (
    <DashboardLayout
      backgroundColor={fullScreenPaper ? theme.palette.background.paper : undefined}
    >
      <Box
        component={fullScreenPaper ? Paper : "main"}
        elevation={fullScreenPaper ? 0 : undefined}
        sx={{
          display: 'flex',
          flex: '1 1 auto',
          overflow: 'hidden',
          position: 'relative',
          pl: 3,
          pr: 3,
        }}
      >
        <Box
          ref={rootRef}
          sx={{
            bottom: 0,
            display: 'flex',
            left: 0,
            position: 'absolute',
            zIndex: 0,
            right: 0,
            top: 0,
            pt: 0,
          }}
        >
          <ListContainer
            open={openSidebar}
            anchor="right"
            width={440}
          >
            <Scrollbar>
              <Grid
                container
                wrap="wrap"
                spacing={2}
                sx={{
                  p: 0,
                  pb: 1,
                  pt: 1,
                  mb: smUp ? undefined : 8,
                }}
              >
                {breads && (
                  <Grid item xs={12}>
                    <Box
                      sx={{
                        pl: 2,
                        pr: 2
                      }}
                    >
                      <Breadcrumbs separator={<BreadcrumbsSeparator/>}>
                        {breads?.map((({to, text}) => {
                          if (to) {
                            return (
                              <Button
                                size="small"
                                key={text}
                                variant="text"
                                onClick={() => {
                                  history.push(to)
                                }}
                              >
                                {text}
                              </Button>
                            )
                          }
                          return (
                            <Button
                              size="small"
                              key={text}
                              variant="text"
                              disabled
                            >
                              {text}
                            </Button>
                          )
                        }))}
                      </Breadcrumbs>
                    </Box>
                  </Grid>
                )}

                <Grid
                  item
                  xs={12}
                >
                  <Slide in direction={slideDirection}>
                    <Card
                      raised={!fullScreenPaper}
                      elevation={fullScreenPaper ? 0 : undefined}
                      sx={{
                        backgroundColor: fullScreenPaper ? 'transparent' : undefined,
                        height: '100%',
                        pb: 10
                      }}
                    >
                      {title && (
                        <>
                          <CardHeader
                            title={title}
                            subheader={subtitle}
                            action={(!lgUp && !disableCart) && (
                              <Button
                                variant="text"
                                color="primary"
                                onClick={() => setOpenSidebar(true)}
                              >
                                <SvgIcon>
                                  <ShoppingCartIcon/>
                                </SvgIcon>
                              </Button>
                            )}
                            avatar={(
                              <Avatar
                                sx={{
                                  width: 0,
                                  height: 44,
                                  opacity: 0,
                                  background: 'linear-gradient(135deg, #4180f6, #1cfaef)'
                                }}
                              >
                                {GerarSiglaNome(title || "")}
                              </Avatar>
                            )}
                          />
                          <Divider/>
                        </>
                      )}

                      {loading && (
                        <CardContent>
                          {skeleton}
                        </CardContent>
                      )}

                      {!loading && (
                        <CardContent>
                          {children}
                        </CardContent>
                      )}
                    </Card>
                  </Slide>

                  {actions}
                </Grid>
              </Grid>
            </Scrollbar>
          </ListContainer>

          {!disableCart && (
            <Box
              sx={{
                pt: 6,
                pl: lgUp ? 1 : 0,
              }}
            >
              <ListSidebar
                container={rootRef?.current}
                open={openSidebar}
                width={440}
                anchor="right"
              >
                <Box>
                  <Stack
                    alignItems="center"
                    justifyContent="space-between"
                    direction="row"
                    sx={{
                      pt: 1,
                    }}
                  >
                    <Typography></Typography>
                    {!lgUp && (
                      <IconButton
                        onClick={() => setOpenSidebar(false)}
                      >
                        <SvgIcon>
                          <XIcon/>
                        </SvgIcon>
                      </IconButton>
                    )}
                  </Stack>
                  <Stack
                    spacing={0}
                  >
                    <Carrinho/>
                  </Stack>
                </Box>
              </ListSidebar>
            </Box>
          )}
        </Box>
      </Box>
    </DashboardLayout>
  )
}
