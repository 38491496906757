import React from 'react'
import {useDispatch} from 'react-redux'
import {ListItemIcon, ListItemText, SvgIcon} from '@mui/material'
import {activeTour} from '../actions'
import useStyles from './style'
import {PATH_ROUTE} from '../../routes'
import {useHistory} from 'react-router-dom'
import {Help} from "@mui/icons-material";
import ContactInfoCard from "../../components/ContactInfoCard/ContactInfoCard";
import Version from "../../components/Version/Version";
import ListItemButton from "@mui/material/ListItemButton";
import List from "@mui/material/List";

export default function FooterMenu({color = "primary"}: {color?:  "primary" | "disabled" | "action" | "inherit" | "secondary" | "error" | "info" | "success" | "warning" | undefined}) {
  const classes = useStyles()
  const history = useHistory();

  const dispatch = useDispatch()

  const goToTour = () => {
    dispatch(activeTour())
    history.push(PATH_ROUTE.CADASTRO_CONCLUIDO)
  }

  return (
    <footer className={classes.footer}>
      <List>
        <ListItemButton onClick={goToTour} className={classes.footerTour} id={'main-menu-'}>
          <ListItemIcon
            sx={{
              color: "white"
            }}
          >
            <SvgIcon color={color}>
              <Help />
            </SvgIcon>
          </ListItemIcon>
          <ListItemText className={classes.footerTextTour} primary='Ver tutorial'/>
        </ListItemButton>
      </List>
      <ContactInfoCard
        color={color}
      />
      <Version/>
    </footer>
  )
}
