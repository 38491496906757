export default function cnpjMask(value: string) {
  if (!value) return;
  return value
    .replace(/\D/g, '') // substitui qualquer character que nao seja numero por nada
    .replace(/(\d{2})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 2 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
    .replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
    .replace(/(\d{3})(\d)/, '$1/$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona uma barra antes do segundo grupo de numero
    .replace(/(\d{4})(\d)/, '$1-$2') // captura 2 grupos de número o primeiro de 4 e o segundo de 1, após capturar o primeiro grupo ele adiciona um traço antes do segundo grupo de número
    .replace(/(-\d{2})\d+?$/, '$1'); // captura 2 números seguidos de um traço e não deixa ser digitado mais nada
}
