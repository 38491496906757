import MockAdapter from 'axios-mock-adapter'
import axios from '../../../utils/axios'
import registrations from '../../../utils/mock/tour/registration'
import { contact } from './contact'
import messaging from '../../../utils/mock/tour/messaging'
import declaration from '../../../utils/mock/tour/declaration'
import installments from './installments'
import { OPENED } from '../../../modules/Finance/Pay/constants'
import { HOME_REGISTRATION, USER_MESSAGE } from '../../../modules/Home/constants'
import { STUDENTS_DECLARATIONS } from '../../../modules/Tax/constants'
import { forEach, find } from 'lodash'
import { getEntityFromAction } from '../../API/action-types'
import { isNil } from 'ramda'
import { HISTORY_NEGOTIATION } from '../../../modules/Negotiation/History/constants'
import { EXPIRATED_FINANCIALS, GET_COMPANY_INFORMATIONS, CREATE_FINANCIAL, POST_FINANCE } from '../../../modules/Negotiation/Finance/constants'
import negotiation from './negotiation/negotiation'
import create from './negotiation/createFinancial'
import company from './negotiation/company'
import postFinancial from './negotiation/postFinancial'
import history from './negotiation/history'

export let mock = null

export const METHOD_ENTITY = {
  [USER_MESSAGE]: {
    method: messaging,
    type: 'get',
  },
  [STUDENTS_DECLARATIONS]: {
    method: declaration,
    type: 'get',
  },
  [OPENED]: {
    method: installments,
    type: 'get',
  },
  [HOME_REGISTRATION]: {
    method: registrations,
    type: 'get',
  },
  [EXPIRATED_FINANCIALS]: {
    method: negotiation,
    type: 'get',
  },
  [CREATE_FINANCIAL]: {
    method: create,
    type: 'post',
  },
  [GET_COMPANY_INFORMATIONS]: {
    method: company,
    type: 'get',
  },
  [POST_FINANCE]: {
    method: postFinancial,
    type: 'post',
  },
  [HISTORY_NEGOTIATION]: {
    method: history,
    type: 'post',
  },
}

export const refreshMock = (type, store) => {
  const entity = METHOD_ENTITY[getEntityFromAction(type)]

  if (!isNil(entity) && !find(mock.handlers[entity.type], ([ item, ]) => item.test(entity.urlRegex))) {
    entity.method.addMock(mock, store)
    entity.method.requestApi(store, mock, true)
  }
}

export const enableTourMock = (store) => {

  mock = new MockAdapter(axios, { onNoMatch: 'passthrough' })

  forEach(METHOD_ENTITY, ({ method }) =>  method.initializeMock(mock, store))

  mock
    .onGet(/contactinfo/).reply(200, contact)
}

export const disableTourMock = () => {
  if (mock) {
    mock.restore()
  }
}
