import {atom} from "recoil";
import {persistAtomEffect} from "../effects/persistAtom";
import IUnidadeResponse from "../../@types/IUnidadeResponse";

export const selectedUnidadeState = atom<IUnidadeResponse | null>({
  key: 'selectedUnidadeState',
  default: null,
  effects: [
    persistAtomEffect,
  ]
});
