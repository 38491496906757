import React, { useState } from 'react';
import { Button, Card, CardContent, Collapse, Container, Divider, Skeleton, Stack, Typography } from "@mui/material";
import useStyles from '../../style'
import { useTheme } from "@mui/material/styles";
import { PAYMENT_METHOD_ENUM } from '../../../../utils/enums/payment';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { tokens } from '../../../../locales/tokens';
import { useTranslation } from '../../../../hooks/use-translation';
import { paths } from '../../../../paths';
import { RouterLink } from "../../../../components/router-link";
import { Theme } from "@mui/material/styles/createTheme";
import useMediaQuery from "@mui/material/useMediaQuery";

interface Service {
  descriptionitemid: string;
  itemid: string;
  configuracaoid: string;
  matriculaid: string;
  condicaodepagamento: string;
  precounitario: number;
  quantidade: number;
}

interface GroupedServices {
  itemid: string;
  matriculaid: string;
  servicos: Service[];
}

interface ServiceListProps {
  data: GroupedServices;
}

const getPaymentMethod = (servicos) => {
  for (const serv of servicos) {
    const paymentKeyArray = Object.keys(PAYMENT_METHOD_ENUM);
    const paymentValueArray = Object.values(PAYMENT_METHOD_ENUM);

    const isThereKey = paymentKeyArray.find((value) => serv.condicaodepagamento.toUpperCase().includes(value));

    if (isThereKey) {
      return paymentValueArray[paymentKeyArray.indexOf(isThereKey)];
    }
  }
  return 'Método de Pagamento não Identificado!';
};

const formatCurrency = (value) => {
  return value?.toFixed(2)?.replace(/\B(?=(\d{3})+(?!\d))/g, ".")?.replace(".", ",");
};

function ServiceList({ data }: ServiceListProps) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const theme = useTheme();

  const { servicos } = data;
  const entryService = servicos.find((serv) => serv.configuracaoid.toUpperCase() === "1ª PARCELA");
  const installmentService = servicos.find((serv) => serv.configuracaoid.toUpperCase() === "MENSALIDADE");
  const paymentMethod = getPaymentMethod(servicos)
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  if (!installmentService || !entryService) {
    let uniqueService: Service | undefined = undefined;

    if(!installmentService && !entryService){
      uniqueService = servicos[0];
      if(servicos.length > 1){
        const totalGross = servicos.reduce((acc, curr) => acc + curr.precounitario, 0);
        uniqueService.precounitario = totalGross;
      }
    }
    else 
      uniqueService = installmentService || entryService || servicos[0] || {};

    if (!uniqueService)
      return

    return (
      <Stack>
        <Container
          sx={{
            padding: isMobile ? 0 : 'unset',
          }}
        >
          <Stack
            justifyContent="space-around"
            flexDirection="row"
            spacing={2}
            flexWrap="nowrap"
            alignItems="flex-start"
            direction={{ xs: 'column', sm: 'row' }}
          >
            <Card
              elevation={5}
              sx={{
                whiteSpace: 'initial',
                textOverflow: 'clip',
                width: '100%',
              }}
            >
              <CardContent>
                <Stack
                  spacing={2}
                  sx={{
                    marginBottom: 2,
                  }}
                >
                  <Button
                    onClick={() => setOpen(!open)}
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      width: '100%',
                      textAlign: 'left',
                      color: 'unset',
                      padding: 0,
                      '&:hover': {
                        color: 'unset',
                        outline: 'none',
                        outlineOffset: 'none'
                      },
                      '&:focus': {
                        outline: 'none',
                        outlineOffset: 'none'
                      }
                    }}
                  >
                    <Stack
                      spacing={1}
                      sx={{
                        overflow: 'hidden'
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          fontWeight: 700,
                          color: "#3083DC",
                          whiteSpace: 'initial'
                        }}
                      >
                        {uniqueService?.descriptionitemid}
                      </Typography>
                      <Typography
                        variant="body2"
                      >
                        {/*@ts-ignore*/}
                        <strong>{t(tokens.common.totalGross)}</strong> ({t(tokens.common.includingEntry)}) - R$ {formatCurrency(uniqueService?.precounitario)}
                      </Typography>
                    </Stack>
                    {open ? <ExpandLess /> : <ExpandMore />}
                  </Button>
                </Stack>
                <Collapse
                  in={open}
                  timeout="auto"
                  unmountOnExit
                >
                  <Divider
                    className={classes.divider}
                  />
                  <Stack
                    spacing={2}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: 700
                      }}
                    >
                      {t(tokens.common.paymentForm)}
                    </Typography>
                    {!uniqueService ? (
                      <Skeleton variant="text" style={{ width: '100%' }} />
                    ) : (
                      uniqueService?.quantidade > 1 ? (
                      <Typography
                        variant="body2"
                        sx={{
                          whiteSpace: 'initial'
                        }}
                      >
                        <strong>{t(tokens.common.totalGross)}</strong> {t(tokens.common.of)} R$ {formatCurrency(uniqueService?.precounitario)} {t(tokens.common.in)} {uniqueService?.quantidade}x R$ {formatCurrency(uniqueService?.precounitario / uniqueService?.quantidade)}<br />
                      </Typography>
                      ) : (
                        <Typography
                          variant="body2"
                          sx={{
                            whiteSpace: 'initial'
                          }}
                        >
                          <strong>{t(tokens.common.totalGross)}</strong> {t(tokens.common.of)} R$ {formatCurrency(uniqueService?.precounitario)}<br />
                        </Typography>
                      )
                    )}
                  </Stack>
                  <Divider
                    className={classes.divider}
                  />
                  <Stack
                    spacing={2}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: 700
                      }}
                    >
                      {t(tokens.common.paymentMethod)}
                    </Typography>
                    <Typography
                      variant="body2"
                    >
                      {paymentMethod}
                    </Typography>
                  </Stack>
                </Collapse>
              </CardContent>
            </Card>
            {isMobile ?
              (
                <Stack
                  direction="column"
                  justifyContent="center"
                  spacing={2}
                  sx={{
                    ml: 1,
                    width: '100%'
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    component={RouterLink}
                    to={paths.dashboard.financial.openQuotas}
                    fullWidth
                    sx={{
                      whiteSpace: 'nowrap',
                      textOverflow: 'clip',
                      width: '100%',
                      overflow: 'hidden',
                      color: 'white !important',
                      '&:hover': {
                        color: theme.palette.primary.main + ' !important',
                      },
                    }}
                  >
                    {t(tokens.common.outstandingBills)}
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    component={RouterLink}
                    to={paths.dashboard.financial.paidQuotas}
                    fullWidth
                    sx={{
                      whiteSpace: 'nowrap',
                      textOverflow: 'clip',
                      width: '100%',
                      overflow: 'hidden',
                      color: 'white !important',
                      '&:hover': {
                        color: theme.palette.primary.main + ' !important',
                      },
                    }}
                  >
                    {t(tokens.common.paidBills)}
                  </Button>
                </Stack>
              ) : (
                <Stack
                  direction="column"
                  justifyContent="center"
                  spacing={2}
                  sx={{
                    ml: 1
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    component={RouterLink}
                    to={paths.dashboard.financial.openQuotas}
                    sx={{
                      whiteSpace: 'nowrap',
                      textOverflow: 'clip',
                      width: '100%',
                      overflow: 'hidden',
                      color: 'white !important',
                      '&:hover': {
                        color: theme.palette.primary.main + ' !important',
                      },
                    }}
                  >
                    {t(tokens.common.outstandingBills)}
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    component={RouterLink}
                    to={paths.dashboard.financial.paidQuotas}
                    sx={{
                      whiteSpace: 'nowrap',
                      textOverflow: 'clip',
                      width: '100%',
                      overflow: 'hidden',
                      color: 'white !important',
                      '&:hover': {
                        color: theme.palette.primary.main + ' !important',
                      },
                    }}
                  >
                    {t(tokens.common.paidBills)}
                  </Button>
                </Stack>
              )
            }

          </Stack>
        </Container>
      </Stack>
    )
  }
  return (
    <Stack>
      <Container
        sx={{
          padding: isMobile ? 0 : 'unset',
        }}
      >
        <Stack
          justifyContent="space-around"
          flexDirection="row"
          spacing={2}
          flexWrap="nowrap"
          alignItems="flex-start"
          direction={{ xs: 'column', sm: 'row' }}
        >
          <Card
            elevation={5}
            sx={{
              flexGrow: 1,
              whiteSpace: 'initial',
              textOverflow: 'clip',
              width: '100%'
            }}
          >
            <CardContent>
              <Stack
                spacing={2}
                sx={{
                  marginBottom: 2
                }}
              >
                <Button
                  onClick={() => setOpen(!open)}
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                    textAlign: 'left',
                    color: 'unset',
                    padding: 0,
                    '&:hover': {
                      color: 'unset',
                      outline: 'none',
                      outlineOffset: 'none'
                    },
                    '&:focus': {
                      outline: 'none',
                      outlineOffset: 'none'
                    }
                  }}
                >
                  <Stack
                    spacing={1}
                    sx={{
                      overflow: 'hidden'
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: 700,
                        color: "#3083DC",
                        whiteSpace: 'initial'
                      }}
                    >
                      {entryService?.descriptionitemid}
                    </Typography>
                    <Typography
                      variant="body2"
                    >
                      {/*@ts-ignore*/}
                      <strong>{t(tokens.common.totalGross)}</strong> ({t(tokens.common.includingEntry)}) - R$ {formatCurrency(entryService?.precounitario + installmentService?.precounitario)}
                    </Typography>
                  </Stack>
                  {open ? <ExpandLess /> : <ExpandMore />}
                </Button>
              </Stack>
              <Collapse
                in={open}
                timeout="auto"
                unmountOnExit
              >
                <Divider
                  className={classes.divider}
                />
                <Stack
                  spacing={2}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: 700
                    }}
                  >
                    {t(tokens.common.paymentForm)}
                  </Typography>
                  {!entryService ? (
                    <Skeleton variant="text" style={{ width: '100%' }} />
                  ) : (
                    entryService?.quantidade > 1 ? (
                      <Typography
                        variant="body2"
                        sx={{
                          whiteSpace: 'initial'
                        }}
                      >
                        <strong>{t(tokens.common.entry)}</strong> {t(tokens.common.of)} R$ {formatCurrency(entryService?.precounitario)} {t(tokens.common.in)} {entryService?.quantidade}x R$ {formatCurrency(entryService?.precounitario / entryService?.quantidade)}<br />
                      </Typography>
                    ) : (
                      <Typography
                        variant="body2"
                        sx={{
                          whiteSpace: 'initial'
                        }}
                      >
                        <strong>{t(tokens.common.entry)}</strong> {t(tokens.common.of)} R$ {formatCurrency(entryService?.precounitario)}<br />
                      </Typography>
                    )
                  )}
                  {!installmentService ? (
                    <Skeleton variant="text" style={{ width: '100%' }} />
                  ) : (
                    installmentService?.quantidade > 1 ? (
                      <Typography
                        variant="body2"
                        sx={{
                          whiteSpace: 'initial',
                          overflow: 'hidden'
                        }}
                      >
                        <strong>{t(tokens.common.installment)}</strong> {t(tokens.common.of)} R$ {formatCurrency(installmentService?.precounitario)} {t(tokens.common.in)} {installmentService?.quantidade}x R$ {formatCurrency(installmentService?.precounitario / installmentService?.quantidade)}
                      </Typography>
                    ) : (
                      <Typography
                        variant="body2"
                        sx={{
                          whiteSpace: 'initial',
                          overflow: 'hidden'
                        }}
                      >
                        <strong>{t(tokens.common.installment)}</strong> {t(tokens.common.of)} R$ {formatCurrency(installmentService?.precounitario)}
                      </Typography>
                    )
                  )}
                </Stack>
                <Divider
                  className={classes.divider}
                />
                <Stack
                  spacing={2}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: 700
                    }}
                  >
                    {t(tokens.common.paymentMethod)}
                  </Typography>
                  <Typography
                    variant="body2"
                  >
                    {paymentMethod}
                  </Typography>
                </Stack>
              </Collapse>
            </CardContent>
          </Card>
          {isMobile ?
            (
              <Stack
                direction="column"
                justifyContent="center"
                spacing={2}
                sx={{
                  ml: 1,
                  width: '100%'
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  component={RouterLink}
                  to={paths.dashboard.financial.openQuotas}
                  fullWidth
                  sx={{
                    whiteSpace: 'nowrap',
                    textOverflow: 'clip',
                    width: '100%',
                    overflow: 'hidden',
                    color: 'white !important',
                    '&:hover': {
                      color: theme.palette.primary.main + ' !important',
                    },
                  }}
                >
                  {t(tokens.common.outstandingBills)}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  component={RouterLink}
                  to={paths.dashboard.financial.paidQuotas}
                  fullWidth
                  sx={{
                    whiteSpace: 'nowrap',
                    textOverflow: 'clip',
                    width: '100%',
                    overflow: 'hidden',
                    color: 'white !important',
                    '&:hover': {
                      color: theme.palette.primary.main + ' !important',
                    },
                  }}
                >
                  {t(tokens.common.paidBills)}
                </Button>
              </Stack>
            ) : (
              <Stack
                direction="column"
                justifyContent="center"
                spacing={2}
                sx={{
                  ml: 1
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  component={RouterLink}
                  to={paths.dashboard.financial.openQuotas}
                  sx={{
                    whiteSpace: 'nowrap',
                    textOverflow: 'clip',
                    width: '100%',
                    overflow: 'hidden',
                    color: 'white !important',
                    '&:hover': {
                      color: theme.palette.primary.main + ' !important',
                    },
                  }}
                >
                  {t(tokens.common.outstandingBills)}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  component={RouterLink}
                  to={paths.dashboard.financial.paidQuotas}
                  sx={{
                    whiteSpace: 'nowrap',
                    textOverflow: 'clip',
                    width: '100%',
                    overflow: 'hidden',
                    color: 'white !important',
                    '&:hover': {
                      color: theme.palette.primary.main + ' !important',
                    },
                  }}
                >
                  {t(tokens.common.paidBills)}
                </Button>
              </Stack>
            )
          }

        </Stack>
      </Container>
    </Stack>
  );
}

export default ServiceList
