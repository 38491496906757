import {IMeioPagamento} from "../@types/IServicoResumoQuery";

const tipoPagamento = {
  Boleto_Bancario: 0,
  Cartao_Credito: 1,
  Cartao_Debito: 2,
  Cartao_Credito_Recorrente: 3,
};

const meioDePagamentoString = {
  Boleto_Bancario: "Boleto_bancario",
  Cartao_Credito: "Cartao_de_credito",
  Debito_automatico: "Debito_automatico_unico",
  Cartao_Credito_Recorrente: "Cartao_de_credito_recorrente",
};

const meiosPagamento: {[key: string | "Boleto_Bancario" | "Cartao_Credito" | "Cartao_Debito" | "Cartao_Credito_Recorrente"]: IMeioPagamento} = {
  Boleto_Bancario: 284360002,
  Cartao_Credito: 284360000,
  Cartao_Debito: 284360001,
  Cartao_Credito_Recorrente: 284360003,
};

const meiosPagamentoToTipoPagamento: {[key: number]: number} = {
  [meiosPagamento.Boleto_Bancario]: tipoPagamento.Boleto_Bancario,
  [meiosPagamento.Cartao_Credito]: tipoPagamento.Cartao_Credito,
  [meiosPagamento.Cartao_Debito]: tipoPagamento.Cartao_Debito,
  [meiosPagamento.Cartao_Credito_Recorrente]: tipoPagamento.Cartao_Credito_Recorrente,
};

const meioPagamentoNome = {
  [meiosPagamento.Boleto_Bancario]: "Boleto Bancário",
  [meiosPagamento.Cartao_Credito]: "Cartão de Crédito",
  [meiosPagamento.Cartao_Debito]: "Débito Automático",
  [meiosPagamento.Cartao_Credito_Recorrente]: "Cartão de Crédito Recorrente",
};

Object.freeze(tipoPagamento);

export function includesTipoPagamento(tipo: any) {
  return Object.values(tipoPagamento).includes(tipo);
}

export default Object.freeze(tipoPagamento);

export { meiosPagamento, meiosPagamentoToTipoPagamento, meioPagamentoNome, meioDePagamentoString };
