import React, {useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'

import {formatOwnName} from '../../../../../utils/formatString.js'
import {fetchSelectedExpirated, setRegistrationExpiredSelected} from '../../actions'
import {
  hasLoadedRegistrationExpiredSelector,
  registrationExpiredDataSelector,
  regristrationExpiratedSelected
} from '../../selectors/registrations'
import {accessIdSelector, appIdSelector} from '../../../../Login/selectors/user'
import GenderAvatar from '../../../../../components/gender'
import {FormattedMessage} from 'react-intl'
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import {Grid} from "@mui/material";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import {useTheme} from "@mui/material/styles";
import {useRecoilValue, useSetRecoilState} from "recoil";
import * as _matricula from "../../../../../service/matricula-service";
import {selectedMatriculaState} from "../../../../../recoil/atoms/selectedMatriculaState";
import Stack from "@mui/material/Stack";
import matriculasSelector from "../../../../../recoil/selectors/matriculasSelector";

export default () => {
  const setSelectedMatricula = useSetRecoilState(selectedMatriculaState)
  const [active, setActive] = useState(0)
  const dispatch = useDispatch()
  const data = useSelector(registrationExpiredDataSelector)
  const registrationSelected = useSelector(regristrationExpiratedSelected)
  const isLoadedRegistrationSelected = useSelector(hasLoadedRegistrationExpiredSelector)
  const appid = useSelector(appIdSelector)
  const accessId = useSelector(accessIdSelector)
  const theme = useTheme()
  const matriculasByResponsavelResponse = useRecoilValue(matriculasSelector)

  React.useEffect(() => {
    if (data) {
      const {
        registrationId,
        studentName
      } = data[active]

      if (!matriculasByResponsavelResponse) {
        _matricula.GetbyMatriculasByResponsavel(localStorage.getItem('@SMO20/idResponsavel'), false)
          .then(resp => {
            const {
              value = []
            } = resp;
            let mat = (value || []).find(a => a?.codigoMatriculaErp === registrationId)

            if (!mat) {
              mat = (value || []).find(a => String(a?.estudante_Name).toLowerCase() === String(studentName).toLowerCase())
            }

            if (mat) {
              setSelectedMatricula(mat)
            }
          });
      } else {
        const {
          value = []
        } = matriculasByResponsavelResponse;

        let mat = (value || []).find(a => a?.codigoMatriculaErp === registrationId)

        if (!mat) {
          mat = (value || []).find(a => String(a?.estudante_Name).toLowerCase() === String(studentName).toLowerCase())
        }

        if (mat) {
          setSelectedMatricula(mat)
          console.log('matricula', mat)
        }
      }
    }
  }, [active])

  React.useEffect(() => {
  }, [registrationSelected, isLoadedRegistrationSelected, dispatch, appid, accessId])

  if (!isLoadedRegistrationSelected) {
    return (
      <CircularProgress
        sx={{
          margin: '0 auto',
          display: 'block'
        }}
      />
    )
  }

  if (data.length === 0) {
    return (
      <Typography
        gutterBottom
        component='p'
        color={'textPrimary'}
      >
        <FormattedMessage id='finance-negotiation-comp-student-not-found'/>
      </Typography>
    )
  }

  const selectRegistration = (student, key) => {
    setActive(key)
    dispatch(fetchSelectedExpirated(student.studentName, student.unityId))
    dispatch(setRegistrationExpiredSelected(student))
    setSelectedMatricula(student)
  }

  return (
    <Grid container spacing={2}>
      {data.map((student, i) => {
        const {studentName, gender, unityName} = student
        return (
          <Grid item xs={12} sm={6} md={6} lg={3} key={i} onClick={() => {
            selectRegistration(student, i)
          }}>
            <Button
              type="button"
              variant={active === i ? "contained" : "outlined"}
            >
              <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
              >
                <GenderAvatar
                  gender={gender}
                  sx={{
                    margin: '10px auto',
                    width: 80,
                    height: 80,
                    backgroundColor: '#404041'
                  }}
                />
                <Box
                  sx={{
                    padding: '0 20px',
                    textAlign: 'center',
                    [theme.breakpoints.down('sm')]: {
                      padding: 0,
                    },
                  }}
                >
                  <Typography gutterBottom>
                    {formatOwnName(studentName)}
                  </Typography>
                  <Typography variant='caption' gutterBottom>
                    {unityName}
                  </Typography>
                </Box>
              </Stack>
            </Button>
          </Grid>)
      })}
    </Grid>)
}

