import React, {useEffect, useState} from "react";
import * as _calcService from "../../service/calc-service";
import {Layout as MomentoEscolarLayout} from "../../layouts/momento-escolar";
import StepCheckoutActionButtons from "../../components/StepCheckoutActionButtons/StepCheckoutActionButtons";
import {useRecoilState, useRecoilValue, useSetRecoilState} from "recoil";
import ServicosComplementares from "../../components/ServicosComplementares/ServicosComplementares";
import Loading from "./loading";
import {parcelasSelecionadasState} from "../../recoil/atoms/parcelasSelecionadasState";
import Typography from "@mui/material/Typography";
import parcelasUpSellingSelecionadasSelector
  from "../../recoil/selectors/carrinho/servicosUpSelling/parcelasUpSellingSelecionadasSelector";
import servicosUpSellingSelector from "../../recoil/selectors/carrinho/servicosUpSelling/servicosUpSellingSelector";
import servicosUpSellingSelecionadosSelector
  from "../../recoil/selectors/carrinho/servicosUpSelling/servicosUpSellingSelecionadosSelector";
import Button from "@mui/material/Button";
import {Checkbox} from "@mui/material";
import Stack from "@mui/material/Stack";
import Avatar from "@mui/material/Avatar";
import {avatarsState} from "../../recoil/atoms/avatarsState";
import PersonIcon from "@mui/icons-material/Person";
import SvgIcon from "@mui/material/SvgIcon";
import {useHistory} from "react-router-dom";
import {PATH_ROUTE} from "../../routes";
import podeAbrirServicosDisponiveisSelector from "../../recoil/selectors/podeAbrirServicosDisponiveisSelector";
import matriculasContratadasSelector from "../../recoil/selectors/matriculasContratadasSelector";
import {fromServicosDisponiveisState} from "../../recoil/atoms/fromServicosDisponiveisState";
import {selectedMatriculasState} from "../../recoil/atoms/selectedMatriculasState";
import {selectedMatriculaState} from "../../recoil/atoms/selectedMatriculaState";

const ServicosDisponiveis: React.FC = () => {
  const history = useHistory();
  const matriculasContratadas = useRecoilValue(matriculasContratadasSelector);
  const podeAbrirServicosDisponiveis = useRecoilValue(podeAbrirServicosDisponiveisSelector);
  const [isLoaded, setIsLoaded] = useState(false);
  const servicosUpSelling = useRecoilValue(servicosUpSellingSelector);
  const servicosUpSellingSelecionados = useRecoilValue(servicosUpSellingSelecionadosSelector);
  const parcelasUpSellingSelecionadas = useRecoilValue(parcelasUpSellingSelecionadasSelector);
  const setParcelasSelecionadas = useSetRecoilState(parcelasSelecionadasState);
  const avatars = useRecoilValue(avatarsState);
  const setFromServicosDisponiveis = useSetRecoilState(fromServicosDisponiveisState);
  const canContinue = isLoaded && (servicosUpSelling?.length > 0 && servicosUpSellingSelecionados.length > 0 && servicosUpSellingSelecionados.length === Object.keys(parcelasUpSellingSelecionadas).map(key => parcelasUpSellingSelecionadas[key]).filter(value => value !== null).length);
  const [selectedMatriculas, setSelectedMatriculas] = useRecoilState(selectedMatriculasState);
  const setSelectedMatricula = useSetRecoilState(selectedMatriculaState);
  const setSeletedMatriculas = useSetRecoilState(selectedMatriculasState);
  const seletedMatricula = useRecoilValue(selectedMatriculaState);

  useEffect(() => {
    setParcelasSelecionadas({
      servicosObrigatorio: {},
      servicoPrincipal: {},
      servicosUpSelling: {},
    });
    setSeletedMatriculas([]);
    setSelectedMatricula({});

    if (!podeAbrirServicosDisponiveis) {
      setFromServicosDisponiveis(true);
      history.push(PATH_ROUTE.CADASTRO_CONCLUIDO)
      return;
    }

    Promise.all(matriculasContratadas.map(m => {
      if (m?.servico_Id && m?.id)
        return _calcService.listar({
          servicoId: m.servico_Id,
          matriculaId: m.id,
          saldoPago: 0
        })
    })).then(() => {
      setIsLoaded(true)
      setSeletedMatriculas([matriculasContratadas[0]?.id ?? ""])
    })
  }, []);

  return (
    <MomentoEscolarLayout
      loading={!isLoaded}
      skeleton={<Loading/>}
      breads={[
        {to: PATH_ROUTE.CADASTRO_CONCLUIDO, text: "Home"},
        {text: "Serviços Extras"}
      ]}
      actions={
        <StepCheckoutActionButtons
          previousStepPath={null}
          nextStepPath={PATH_ROUTE.MOMENTO_ESCOLAR_PAGAMENTO}
          onClickNextStep={() => {
            setFromServicosDisponiveis(true);
            history.push(PATH_ROUTE.MOMENTO_ESCOLAR_PAGAMENTO)
          }}
          nextStepDisabled={!canContinue}
          showCart
        />
      }
      disableCart
    >
      <Typography>
        Selecione o estudante para ver os serviços extras em sua grade.
      </Typography>

      <Stack
        direction={{xs: 'column', sm: 'row'}}
        spacing={2}
        sx={{
          mt: 2
        }}
      >
        {(matriculasContratadas || []).map((matricula) => {
          const avatar = avatars[matricula?.estudante_Id ?? ""] ?? null
          return (
            <Button
              key={matricula.id}
              type="button"
              variant="text"
              disabled={'id' in seletedMatricula && seletedMatricula.id !== matricula.id}
              sx={{
                backgroundColor: selectedMatriculas.includes(matricula?.id ?? "") ? 'rgba(0, 0, 0, 0.04)' : 'transparent',
                opacity: 'id' in seletedMatricula && seletedMatricula.id !== matricula.id ? 0.5 : 1,
              }}
              onClick={() => {
                if (selectedMatriculas.includes(matricula?.id ?? "")) {
                  const result = selectedMatriculas.filter(id => id !== matricula?.id)
                  setSelectedMatriculas(result)
                  if (result.length === 0) {
                    setSelectedMatricula({})
                  }
                } else {
                  setSelectedMatriculas([...selectedMatriculas, matricula?.id ?? ""])
                  setParcelasSelecionadas({
                    servicosObrigatorio: {},
                    servicoPrincipal: {},
                    servicosUpSelling: {},
                  });
                }
              }}
              startIcon={(
                <Checkbox
                  checked={selectedMatriculas.includes(matricula?.id ?? "")}
                  sx={{
                    pointerEvents: 'none'
                  }}
                />
              )}
            >
              <Stack
                direction="row"
                spacing={2}
                alignItems="center"
              >
                <Avatar
                  src={avatar}
                  variant="circular"
                  sx={{
                    height: 64,
                    width: 'auto',
                    aspectRatio: '1/1',
                    border: '7px solid #e5e5e5'
                  }}
                >
                  <SvgIcon
                    component={PersonIcon}
                    sx={{
                      fontSize: 44,
                    }}
                  />
                </Avatar>

                <Typography>
                  {matricula.estudante_Name}
                </Typography>
              </Stack>
            </Button>
          )
        })}
      </Stack>

      <ServicosComplementares
        multiple
      />
    </MomentoEscolarLayout>
  )
}

export default ServicosDisponiveis;
