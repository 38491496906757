import React from "react";
import CountUp from "react-countup";

const Currency: React.FC<{value: number}> = (props) => {
    return (
      <CountUp
        start={0}
        delay={1}
        end={props.value}
        preserveValue
        enableScrollSpy
        scrollSpyDelay={1}
        separator="."
        decimals={2}
        decimal=","
        prefix="R$ "
      />
    );
}

export default Currency;
