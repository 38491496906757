import React, {useEffect, useState} from 'react';
import "../../components/WalletModal/styles.css";
import {useRecoilState, useRecoilValue} from "recoil";
import {creditCardsState} from "../../recoil/atoms/creditCardsState";
import {walletShowChangeSubsState} from "../../recoil/atoms/walletShowChangeSubsState";
import {loadedCreditCardsState} from "../../recoil/atoms/loadedCreditCardsState";
import {walletCardReloadState} from "../../recoil/atoms/walletCardReloadState";
import * as _walletService from "../../service/wallet-service";
import * as mensagem from "../../components/shared/Message";
import WalletSaveCard from "../../components/WalletModal/WalletSaveCard";
import WalletChangeSubs from "../../components/WalletModal/WalletChangeSubs";
import WalletCardsList from "../../components/WalletModal/WalletCardsList";
import {PortalFinanceiroLayout} from "../../layouts/PortalFinanceiroLayout";
import Card from "@mui/material/Card";
import {CardContent, Container} from "@mui/material";
import {responsavelState} from "../../recoil/atoms/responsavelState";
import {useTheme} from "@mui/material/styles";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import WalletLoading from "../../components/WalletModal/WalletLoading";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Alert from "@mui/material/Alert";
import matriculasSelector from "../../recoil/selectors/matriculasSelector";

function MeusCartoes() {
  const [loadingCards, setLoadingCards] = useState(false);
  const [creditCards, setCreditCards] = useRecoilState(creditCardsState);
  const [showAddCard, setShowAddCard] = useState(false);
  const [showChangeSubs, setShowChangeSubs] = useRecoilState(walletShowChangeSubsState);
  const [loaded, setLoaded] = useRecoilState(loadedCreditCardsState);
  const [reload, setReload] = useRecoilState(walletCardReloadState);
  const [unidades, setUnidades] = useState([]);
  const matriculas = useRecoilValue(matriculasSelector);
  const [unidade, setUnidade] = useState(null);
  const responsavel = useRecoilValue(responsavelState);
  const theme = useTheme();

  useEffect(() => {
    let unities = [];

    (matriculas || []).map(u => {
      unities.push({
        id: u?.unidade_Id,
        name: u?.unidade_Name
      })
    })

    //remove duplicados
    unities = unities.filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i)

    if (unities.length === 1 || unities.length > 1) {
      setUnidade(unities[0])
    }
    setUnidades(unities)
  }, []);

  useEffect(() => {
    if (unidade !== null && false === loaded) {
      if (loadingCards) return;
      setLoadingCards(true)
      _walletService.cartaoListar(
        unidade.id,
        responsavel?.id
      )
        .then(async resp => {
          if (resp.ok) {
            return await resp.json();
          } else {
            const json = await resp.json();
            if (json?.messages) {
              (json?.messages || []).map(m => mensagem.ExibeMensagem(m?.text, String(m?.typeMessage)))
            }
          }
        })
        .then(({value: {cards}}) => {
          if (creditCards && creditCards.length) {
            setCreditCards(cards.map(card => {
              const oldCard = creditCards.find(c => c.cardId === card.cardId);
              if (oldCard) {
                // restaura numberToken para  evitar uma nova verificação no cartão
                card.numberToken = oldCard.numberToken
              }
              return card
            }))
          } else {
            setCreditCards(cards ?? []);
          }
          setLoaded(true);
          if (!(cards || []).length) setShowAddCard(true);
        })
        .finally(() => setLoadingCards(false))
    }
  }, [unidade, loaded, creditCards])

  useEffect(() => {
    if (reload) {
      setLoaded(false);
      setReload(false)
    }
  }, [reload]);

  const handleClose = () => {
    setShowAddCard(false);
    setShowChangeSubs(false);
  };

  const handleCloseChangeSubs = () => {
    setShowChangeSubs(false);
    setReload(true);
  };

  const handleCancel = () => {
    setShowAddCard(false);
  }

  const handleAddCard = () => {
    setShowAddCard(true);
  }

  return (
    <PortalFinanceiroLayout>
      <Container>
        <Card
          className="home-wallet-container-main-card"
          sx={{marginTop: theme.spacing(3)}}
        >
          <CardHeader
            title="Meus Cartões"
            subheader="Aqui você pode visualizar, adicionar ou remover cartões de crédito"
          />
          <Divider/>

          <CardContent>
            {loadingCards ? (
              <div style={{position: "relative", width: "100%", height: "100%", minHeight: "400px"}}>
                <WalletLoading/>
              </div>
            ) : (
              <React.Fragment>
                {showAddCard ? (
                  <WalletSaveCard
                    onClose={handleClose}
                    onCancel={handleCancel}
                  />
                ) : (
                  <>
                    {showChangeSubs && (
                      <WalletChangeSubs
                        onCancel={handleCloseChangeSubs}
                      />
                    )}

                    {(unidades.length > 1 && !showChangeSubs) && (
                      <Stack
                        sx={{
                          mb: 3
                        }}
                        spacing={2}
                      >
                        <Alert
                          severity="warning"
                          sx={{
                            width: '100%'
                          }}
                        >
                          <strong>Atenção:</strong> Você possui mais de uma unidade vinculada a sua conta, selecione a unidade para visualizar os cartões
                        </Alert>

                        <TextField
                          fullWidth
                          label="Selecione a unidade"
                          select
                          size="small"
                          onChange={event => {
                            const unidade = unidades.find(u => u.id === event.target.value)
                            setUnidade(unidade)
                            setLoaded(false);
                          }}
                          value={unidade?.id}
                        >
                          {unidades?.map((unidade, i) => (
                            <MenuItem
                              key={unidade?.id}
                              value={unidade?.id}
                            >
                              {unidade?.name}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Stack>
                    )}

                    <WalletCardsList
                      hidden={showChangeSubs}
                      selectedCreditCard={null}
                      onClickAddCard={handleAddCard}
                      onClickSelectCard={null}
                    />
                  </>
                )}
              </React.Fragment>
            )}
          </CardContent>
        </Card>
      </Container>
    </PortalFinanceiroLayout>
  );
}

export default MeusCartoes;
