import React from "react";

import Close from "@mui/icons-material/Close";
import {paymentSelector} from "../../../Pay/selectors/payments";
import {useSelector} from "react-redux";
import AutomaticDebitDialogCheckout from './components/AutomaticDebitDialogCheckout';
import useMediaQuery from "@mui/material/useMediaQuery";
import Dialog from "@mui/material/Dialog";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";

export default ({ open, onClose, setIsLoading, onSuccess, onError, selectedMatricula, setAcceptanceTermObj, data }) => {
  const fullScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { loading, waitingRede } = useSelector(paymentSelector);

  function handleClose() {
    onClose();
  }

  return (
    <Dialog
      maxWidth={"sm"}
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      aria-labelledby="automatic-debit-dialog-title"
    >
      <Box display="flex" justifyContent="flex-end">
        <IconButton
          disabled={loading || waitingRede}
          aria-label="close-payment"
          id="ico-mdl-pag-fch"
          onClick={handleClose}
        >
          <Close />
        </IconButton>
      </Box>
      <AutomaticDebitDialogCheckout
        setIsLoading={setIsLoading}
        onSuccess={onSuccess}
        onError={onError}
        selectedMatricula={selectedMatricula}
        setAcceptanceTermObj={setAcceptanceTermObj}
        data={data}
      />
    </Dialog>
  );
};
