import {atom} from "recoil";
import {persistAtomEffect} from "../effects/persistAtom";
import IServicoDto from "../../@types/IServicoDto";

export const calcListServicesState = atom<Array<IServicoDto>>({
  key: 'calcListServicesState',
  default: [],
  effects: [
    persistAtomEffect,
  ]
});
