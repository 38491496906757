import React from "react";
import Close from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import {Grid, Theme} from "@mui/material";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import useMediaQuery from "@mui/material/useMediaQuery";
import {useTheme} from "@mui/material/styles";

export default ({ open, onClose }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  function handleClose() {
    onClose();
  }

  return (
    <Dialog
      maxWidth={"sm"}
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      aria-labelledby="automatic-debit-dialog-title"
      sx={{ width: !fullScreen ? "98%" : undefined }}
    >
      <Box display="flex" justifyContent="flex-end">
        <IconButton
          sx={{ position: !fullScreen ? "absolute" : undefined, right: !fullScreen ? 0 : undefined }}
          aria-label="close-payment"
          id="ico-mdl-pag-fch"
          onClick={handleClose}
        >
          <Close />
        </IconButton>
      </Box>
      <Grid
        container
        justifyContent="space-between"
        direction="row"
        alignItems="center"
        sx={{
          padding: theme.spacing(2),
          paddingBottom: theme.spacing(1),
          boxShadow: "none",
          display: "inline"
        }}
      >
        <Grid item xs={12} marginBottom={2}>
          <Typography component="h1" variant="h4" align="center">
            Pagamento Básico
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography
            sx={{ fontWeight: 'bold' }}
          >
            Boleto
          </Typography>

          <Typography>
            Ao escolher esta forma de pagamento, você deverá acessar mensalmente
            nosso portal financeiro e fazer o download do seu boleto.
          </Typography>

          <br />

          <Typography>
            Os boletos podem ser pagos em redes bancárias, lotéricas ou via
            aplicativos de banco e pagamentos.
          </Typography>

          <br />

          <Typography>
            <b>⚠</b> Como <b>não é um pagamento automático</b>, demanda atenção
            com a data de pagamento.
          </Typography>

          <br />

          <Typography>
            Lembrando que o prazo de compensação do boleto é de 3 dias úteis.
          </Typography>

          <Typography>
            Após a conclusão da operação haverá período de carência para nova
            alteração.
          </Typography>

          <br />
          <br />

          <Typography
            sx={{ fontWeight: 'bold' }}
          >
            Cartão de crédito
          </Typography>

          <Typography>
            Nesta opção, o pagamento <b>não é automático.</b> Mensalmente, na
            data de vencimento, você acessa nosso portal financeiro, insere os
            dados do seu cartão e realiza o pagamento.
          </Typography>

          <br />

          <Typography>
            <b>⚠</b> Como o pagamento não é automático, essa opção demanda maior
            atenção com a data de pagamento.
          </Typography>

          <br />

          <Typography>
            <b>✈</b> Assim como na opção crédito recorrente, você pode acumular
            pontos em programas de milhagens que participar.
          </Typography>

          <br />

          <Typography>
            Após a conclusão da operação haverá período de carência para nova
            alteração.
          </Typography>
        </Grid>

        <Grid item xs={12} sx={{display: 'flex', justifyContent: 'flex-end'}}>
          <Button
            onClick={handleClose}
            sx={{ marginTop: theme.spacing(3), marginLeft: theme.spacing(1)}}
            variant="outlined"
            color="secondary"
          >
            Fechar
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  );
};
