import React from 'react'
import {makeStyles} from "@mui/styles";
import Typography from "@mui/material/Typography";

const useStyle = makeStyles(theme => ({
  title: {
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    textAlign: 'center'
  },
  content: {
    textAlign: 'center'
  }
}))

export default ({ title, content }) => {
  const classes = useStyle()
  return (
    <div>
      <Typography gutterBottom className={classes.title} variant='h5'>{title}</Typography>
      <Typography variant='body1' className={classes.content} >{content}</Typography>
    </div>
  )
}
