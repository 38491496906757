import type {FC, ReactNode} from 'react';
import PropTypes from 'prop-types';
import useMediaQuery from '@mui/material/useMediaQuery';
import {styled} from '@mui/material/styles';
import type {Theme} from '@mui/material/styles/createTheme';

import type {NavColor} from '../../../types/settings';

import type {Section} from '../config';
import {MobileNav} from '../mobile-nav';
import {TopNav} from './top-nav';
import {useMobileNav} from './use-mobile-nav';
import { GlobalLoading } from '../../../components/GlobalLoading';

const HorizontalLayoutRoot = styled(
  'div',
  { shouldForwardProp: (prop) => prop !== 'backgroundColor'}
)<{ backgroundColor?: string}>(
  (({ theme, backgroundColor }) => ({
    display: 'flex',
    flex: '1 1 auto',
    maxWidth: '100%',
    backgroundColor
  }))
);

const HorizontalLayoutContainer = styled('div')(
  (({ theme }) => ({
    display: 'flex',
    flex: '1 1 auto',
    flexDirection: 'column',
    width: '100%',
    maxWidth: theme.breakpoints.values.xl,
    margin: '0 auto',
    maxHeight: 'calc(100vh - 80px)'
  }))
);

interface HorizontalLayoutProps {
  children?: ReactNode;
  navColor?: NavColor;
  sections?: Section[];
  onlyExit?: boolean;
  backgroundColor?: string;
}

export const HorizontalLayout: FC<HorizontalLayoutProps> = (props) => {
  const {
    children,
    navColor,
    sections,
    onlyExit,
    backgroundColor
  } = props;
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const mobileNav = useMobileNav();

  return (
    <>
      <GlobalLoading/>
      <TopNav
        color={navColor}
        onMobileNav={mobileNav.handleOpen}
        sections={sections}
        onlyExit={onlyExit}
      />
      {!lgUp && (
        <MobileNav
          color={navColor}
          onClose={mobileNav.handleClose}
          open={mobileNav.open}
          sections={sections}
        />
      )}
      <HorizontalLayoutRoot backgroundColor={backgroundColor}>
        <HorizontalLayoutContainer>
          {children}
        </HorizontalLayoutContainer>
      </HorizontalLayoutRoot>
    </>
  );
};

HorizontalLayout.propTypes = {
  children: PropTypes.node,
  navColor: PropTypes.oneOf<NavColor>(['blend-in', 'discrete', 'evident']),
  sections: PropTypes.array
};
