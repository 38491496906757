import {atom} from "recoil";
import {persistAtomEffect} from "../effects/persistAtom";

export const homeState = atom({
  key: 'homeState',
  default: {
    username: '',
    password: '',
    manterDados: true,
  },
  effects: [
    persistAtomEffect
  ]
});
