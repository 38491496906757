import { atom } from "recoil";
import IListaServicoUnidadeIdResponse from "../../@types/IListaServicoUnidadeIdResponse";
import * as _itemContratoService from "../../service/item-contrato-service";
import { selectedMatriculaState } from "./selectedMatriculaState";
import { getRecoil, setRecoil } from "recoil-nexus";
import { responsavelState } from "./responsavelState";
import IModeloContratoResponse from "../../@types/IModeloContratoResponse";
import { contratosState } from "./contratosState";
import { loadingContractState } from "./loadingContractState";

export const nomesContratosState = atom<Array<IListaServicoUnidadeIdResponse>>({
  key: 'nomesContratosState',
  default: [],
  effects: [
    ({ onSet }) => {
      onSet(newValue => {
        const selectedMatricula = getRecoil(selectedMatriculaState);
        const responsavel = getRecoil(responsavelState);

        let contracts = {} as { [servicoId: string]: IModeloContratoResponse };

        if (newValue && newValue.length > 0) {
          setRecoil(loadingContractState, true);

          Promise.all(
            newValue.map(async (item) => {
              const html = await _itemContratoService.getModeloContratoPorServico({
                matriculaId: selectedMatricula?.id || "",
                servicoId: item.idServicoUnidade,
                responsavelId: responsavel?.id || ""
              })
  
              contracts[item?.idServicoUnidade] = html;
            })
          ).then(() => {
            setRecoil(contratosState, contracts);
          }).finally(() => {
            setRecoil(loadingContractState, false);
          })
        } else {
          setRecoil(contratosState, {});
          setRecoil(loadingContractState, false);
        }
      });
    }
  ]
});
