import {selector} from "recoil";
import {errorLoginPortalState} from "../atoms/errorLoginPortalState";
import {portalAuthState} from "../atoms/portalAuthState";
import {loadingPortalFinanceiroState} from "../atoms/loadingPortalFinanceiroState";
import matriculasSelector from "./matriculasSelector";

const podeAbrirFinanceiroSelector = selector({
  key: 'podeAbrirFinanceiroSelector', // unique ID (with respect to other atoms/selectors)
  get: ({get}) => {
    const matriculas = get(matriculasSelector);
    const errorLoginPortal = get(errorLoginPortalState);
    const auth = get(portalAuthState);
    const loadingPortal = get(loadingPortalFinanceiroState);
    return (matriculas || []).length > 0 && !errorLoginPortal && auth !== null || loadingPortal;
  },
});

export default podeAbrirFinanceiroSelector

