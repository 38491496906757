import env from "../env";
import fetch from "../utils/fetch";
import IServicoDto from "../@types/IServicoDto";
import {getRecoil, setRecoil} from "recoil-nexus";
import {calcListServicesState} from "../recoil/atoms/calcListServicesState";
import IServicoResumoDto from "../@types/IServicoResumoDto";
import {calcResumoListState} from "../recoil/atoms/calcResumoListState";
import IServicoResumoQuery from "../@types/IServicoResumoQuery";
import parcelaRegularSelecionadaSelector
  from "../recoil/selectors/carrinho/servicoRegular/parcelaRegularSelecionadaSelector";
import parcelaEntradaRegularSelecionadaSelector
  from "../recoil/selectors/carrinho/servicoRegular/parcelaEntradaRegularSelecionadaSelector";
import servicosObrigatoriosSelector
  from "../recoil/selectors/carrinho/servicosObrigatorios/servicosObrigatoriosSelector";
import parcelasObrigatoriosSelecionadasSelector
  from "../recoil/selectors/carrinho/servicosObrigatorios/parcelasObrigatoriosSelecionadasSelector";
import {selectedMeioPagamentoEntradaState} from "../recoil/atoms/selectedMeioPagamentoEntradaState";
import {selectedMeioPagamentoParcelamentoState} from "../recoil/atoms/selectedMeioPagamentoParcelamentoState";
import {selectedMatriculaState} from "../recoil/atoms/selectedMatriculaState";
import {isTabelaResumoLoadingState} from "../recoil/atoms/isTabelaResumoLoadingState";
import parcelasUpSellingSelecionadasSelector
  from "../recoil/selectors/carrinho/servicosUpSelling/parcelasUpSellingSelecionadasSelector";
import {meiosPagamento} from "../utils/tipoPagamento";
import * as mensagem from '../components/shared/Message.js';
import isPreReservaSemValorSelector from "../recoil/selectors/isPreReservaSemValorSelector";

export async function listar(body: {matriculaId: string, servicoId: string, saldoPago: number}): Promise<IServicoDto> {
  return fetch(env.apiUrl + `api/calc/listar`, {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      'Accept': 'application/json'
    },
    body: JSON.stringify({
      ...body,
      saldoPago: 0
    })
  }).then((res) => {
      if (!res.ok) {
        res.text().then((text) => {
          mensagem.ExibeMensagem(text, "3");
          return Promise.resolve({} as IServicoDto);
        });
      } else {
          return res.json();
      }
    }).then(data => {
      setRecoil(calcListServicesState, current => [
        ...current.filter(s => s.matriculaId !== body.matriculaId),
        {
          ...data.value,
          matriculaId: body.matriculaId
        }
      ])
      return data.value;
    })
}

export async function resumoListar(query?: IServicoResumoQuery): Promise<IServicoResumoDto> {
  let body = query;
  if (!body) {
    const isPreReservaSemValor = getRecoil(isPreReservaSemValorSelector);
    const selectedMatricula = getRecoil(selectedMatriculaState);
    const selectedMeioPagamentoEntrada = getRecoil(selectedMeioPagamentoEntradaState);
    const selectedMeioPagamentoParcelamento = getRecoil(selectedMeioPagamentoParcelamentoState);
    const parcelaRegularSelecionada = getRecoil(parcelaRegularSelecionadaSelector);
    const parcelaEntradaRegularSelecionada = getRecoil(parcelaEntradaRegularSelecionadaSelector);
    const servicosObrigatorios = getRecoil(servicosObrigatoriosSelector);
    const parcelasObrigatoriosSelecionadas = getRecoil(parcelasObrigatoriosSelecionadasSelector);
    const parcelasUpSellingSelecionadas = getRecoil(parcelasUpSellingSelecionadasSelector);

    if (isPreReservaSemValor) {
      //@ts-ignore
      return;
    }

    const demaisServicos = servicosObrigatorios.map(servico => {
      const parcela = parcelasObrigatoriosSelecionadas[servico.id];
      return {
        id: servico.id,
        parcelaId: parcela?.id || "00000000-0000-0000-0000-000000000000",
        meioPagamento: selectedMeioPagamentoParcelamento ?? meiosPagamento.Boleto_Bancario,
        opcaoParcelas: parcela?.totalParcela || 1,
        saldoPago: 0,
        diaVencimentoSelecionado: 0,
        entrada: parcela?.entradas && parcela?.entradas?.length > 0 ? {
          parcelaId: parcela?.entradas[0].id || "00000000-0000-0000-0000-000000000000",
          meioPagamento: selectedMeioPagamentoEntrada ?? meiosPagamento.Boleto_Bancario,
          opcaoParcelas: parcela?.entradas[0].totalParcela || 1,
          saldoPago: 0,
          diaVencimentoSelecionado: 0
        } : null
      }
    })

    if (parcelasUpSellingSelecionadas) {
      for (const key in parcelasUpSellingSelecionadas) {
        const parcela = parcelasUpSellingSelecionadas[key];
        if (parcela) {
          demaisServicos.push({
            id: key,
            parcelaId: parcela.id,
            meioPagamento: selectedMeioPagamentoParcelamento ?? meiosPagamento.Boleto_Bancario,
            opcaoParcelas: parcela.totalParcela || 1,
            saldoPago: 0,
            diaVencimentoSelecionado: 0,
            entrada: parcela.entradas && parcela.entradas.length > 0 ? {
              parcelaId: parcela.entradas[0].id || "00000000-0000-0000-0000-000000000000",
              meioPagamento: selectedMeioPagamentoEntrada ?? meiosPagamento.Boleto_Bancario,
              opcaoParcelas: parcela.entradas[0].totalParcela || 1,
              saldoPago: 0,
              diaVencimentoSelecionado: 0
            } : null
          })
        }
      }
    }

    body = {
      matriculaId: selectedMatricula.id || "00000000-0000-0000-0000-000000000000",
      regular: {
        id: selectedMatricula?.servico_Id || "00000000-0000-0000-0000-000000000000",
        parcelaId: parcelaRegularSelecionada?.id || "00000000-0000-0000-0000-000000000000",
        meioPagamento: selectedMeioPagamentoParcelamento ?? meiosPagamento.Boleto_Bancario,
        opcaoParcelas: parcelaRegularSelecionada?.totalParcela || 1,
        saldoPago: 0,
        diaVencimentoSelecionado: 0,
        entrada: parcelaEntradaRegularSelecionada ? {
          parcelaId: parcelaEntradaRegularSelecionada?.id || "00000000-0000-0000-0000-000000000000",
          meioPagamento: selectedMeioPagamentoEntrada ?? meiosPagamento.Boleto_Bancario,
          opcaoParcelas: parcelaEntradaRegularSelecionada?.totalParcela || 1,
          saldoPago: 0,
          diaVencimentoSelecionado: 0
        } : null
      },
      demaisServicos
    }
  }

  setRecoil(isTabelaResumoLoadingState, true);

  return fetch(env.apiUrl + `api/calc/resumolistar`, {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      'Accept': 'application/json'
    },
    body: JSON.stringify(body)
  }).then((res) => {
      if (!res.ok) {
        res.text().then((text) => {
          mensagem.ExibeMensagem(text, "3");
          return Promise.resolve({} as IServicoDto);
        });
      } else {
          return res.json();
      }
    }).then(data => {
      setRecoil(calcResumoListState, data.value)
      return data.value;
    })
    .catch(() => setRecoil(calcResumoListState, null))
    .finally(() => setRecoil(isTabelaResumoLoadingState, false));
}
