import fetch from "../utils/fetch";
import env from "../env";

export function GetServicosDisponiveis(idMatricula, ignorarObrigatoriosDoUpSelling) {

    return fetch(env.apiUrl + "api/Servico/GetServicosDisponiveis/" + idMatricula +"/"+ (ignorarObrigatoriosDoUpSelling == true ? "true":"false"), {
        method: "GET",
        mode: "cors",
    })
        .then(res => res.json())
        .then(
            (result) => {
                return result;
            },
            (error) => {
                console.log(error.message);
                return false;
            }
        )
        .catch(error => {
            console.log(error.message);
            return false;
        });
}

export function GetServicoUpSelling(idMatricula, idServico) {

    return fetch(env.apiUrl+"api/Servico/GetServicosUpSelling/" + idMatricula + "/" + idServico, {
        method: "GET",
        mode: "cors",
    })
        .then(res => res.json())
        .then(
            (result) => {
                return result;
            },
            (error) => {
                console.log(error.message);
                return false;
            }
        )
        .catch(error => {
            console.log(error.message);
            return false;
        });
}

        export function GetListServicosUpSelling(idMatricula, idServico) {

            return fetch(env.apiUrl + "api/Servico/GetListServicosUpSelling/" + idMatricula + "/" + idServico, {
                method: "GET",
                mode: "cors",
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        return result;
                    },
                    (error) => {
                        console.log(error.message);
                        return false;
                    }
                )
                .catch(error => {
                    console.log(error.message);
                    return false;
                });
        }


