import { pipe, prop } from 'ramda'

import {
  getAPIDataSelector,
  hasErrorAPISelector,
  hasLoadedAPISelector,
  isLoadingAPISelector,
} from '../../../utils/API/selectors'

const baseLoginSelector = prop('login')

export const authenticationSelector = pipe(baseLoginSelector, prop('authentication'))

export const authenticationDataSelector = getAPIDataSelector(authenticationSelector)

export const hasLoadedAuthenticationSelector = hasLoadedAPISelector(authenticationSelector)

export const hasErrorAuthenticationSelector = hasErrorAPISelector(authenticationSelector)

export const isLoadingAuthenticationSelector = isLoadingAPISelector(authenticationSelector)

export const tryAgainButtonSelector = pipe(authenticationSelector, prop('tryAgain'))
