import React from 'react';
import {Ajuda} from '../../components/Ajuda';
import {useHistory} from 'react-router-dom';
import Card from "@mui/material/Card";
import {Layout as DashboardLayout} from "../../layouts/dashboard";
import FirstAccessSteps from "../../components/FirstAccessSteps/FirstAccessSteps";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import {PATH_ROUTE} from "../../routes";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {useTheme} from "@mui/material/styles";
import ConfettiExplosion, {ConfettiProps} from "react-confetti-explosion";
import {Scrollbar} from "../../components/scrollbar";
import {slideDirectionState} from "../../recoil/atoms/slideDirectionState";
import {useRecoilValue, useResetRecoilState, useSetRecoilState} from "recoil";
import Slide from "@mui/material/Slide";
import {matriculasByResponsavelResponseState} from "../../recoil/atoms/matriculasByResponsavelResponseState";
import useAuth from "../../hooks/use-auth";
import {isCadastroConcluidoState} from "../../recoil/atoms/isCadastroConcluidoState";

const Concluido: React.FC = () => {
  const slideDirection = useRecoilValue(slideDirectionState);
  const history = useHistory();
  const theme = useTheme();
  const {loadUserData} = useAuth();
  const setIsCadastroConcluido = useSetRecoilState(isCadastroConcluidoState);
  const smallProps: ConfettiProps = {
    force: 0.8,
    duration: 3000,
    particleCount: 250,
    width: 1600,
    zIndex: theme.zIndex.modal,
    colors: [theme.palette.primary.main, theme.palette.secondary.main]
  };
  const resetMatriculas = useResetRecoilState(matriculasByResponsavelResponseState);

  const handleClick = () => {
    resetMatriculas();
    setIsCadastroConcluido(true);
    loadUserData();
    history.push(PATH_ROUTE.CADASTRO_CONCLUIDO);
  }

  return (
    <DashboardLayout backgroundColor={theme.palette.background.paper}>
      <Scrollbar>
        <FirstAccessSteps activeStep={3}/>

        <Slide in direction={slideDirection}>
          <Card
            elevation={0}
            sx={{
              height: '100%',
            }}
          >
            <Container
              maxWidth="lg"
              sx={{
                pb: 8,
                height: '100%',
              }}
            >
              <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={4}
                sx={{
                  height: '100%',
                  gap: 4
                }}
              >
                <Stack
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  spacing={2}
                >
                  <ConfettiExplosion {...smallProps} />

                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width={128}
                    height={128}
                    fill={theme.palette.primary.main}
                  >
                    <path
                      d="M12 2C6.5 2 2 6.5 2 12S6.5 22 12 22 22 17.5 22 12 17.5 2 12 2M12 20C7.59 20 4 16.41 4 12S7.59 4 12 4 20 7.59 20 12 16.41 20 12 20M16.59 7.58L10 14.17L7.41 11.59L6 13L10 17L18 9L16.59 7.58Z"/>
                  </svg>

                  <Typography
                    variant="h5"
                    component="h1"
                    fontWeight={400}
                  >
                    Seu cadastro foi concluído
                  </Typography>
                </Stack>

                <Button
                  variant="contained"
                  onClick={handleClick}
                  endIcon={(
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={24} height={24}
                         fill="currentColor">
                      <path d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z"/>
                    </svg>
                  )}
                >
                  Ir para a Home
                </Button>
              </Stack>
            </Container>
          </Card>
        </Slide>
        <Ajuda/>
      </Scrollbar>
    </DashboardLayout>
  )
}

export default Concluido;
