import React, {useEffect, useState} from 'react';
import getIdade from '../../utils/getIdade'
import {Ajuda} from '../../components/Ajuda';
import {useHistory} from 'react-router-dom';
import * as formValidacao from '../../commons/functions/form-validacao.js';
import StepCheckoutActionButtons from "../../components/StepCheckoutActionButtons/StepCheckoutActionButtons";
import onInputOnlyNumber from "../../utils/onlyNumberOnInput";
import env from "../../env";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import fetch from "../../utils/fetch";
import {Layout as DashboardLayout} from "../../layouts/dashboard";
import {CardContent} from "@mui/material";
import FirstAccessSteps from "../../components/FirstAccessSteps/FirstAccessSteps";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Loading from "./Loading";
import {useFormik} from "formik";
import {toast} from "react-toastify";
import * as Yup from "yup";
import CircularProgress from "@mui/material/CircularProgress";
import {PATH_ROUTE} from "../../routes";
import {useRecoilState, useRecoilValue} from "recoil";
import {responsavelState} from "../../recoil/atoms/responsavelState";
import {Scrollbar} from "../../components/scrollbar";
import Slide from "@mui/material/Slide";
import {slideDirectionState} from "../../recoil/atoms/slideDirectionState";
import {useTheme} from "@mui/material/styles";

const validationSchema = Yup.object().shape({
  nome: Yup.string().nullable().typeError("Campo obrigatório"),
  grauParentesco: Yup.number().required("Campo obrigatório").typeError("Campo obrigatório"),
  celular: Yup.string().required("Campo obrigatório").typeError("Campo obrigatório"),
  telefoneFixo: Yup.string().nullable().typeError("Campo obrigatório"),
  email: Yup.string().email("Insira um email válido").required("Campo obrigatório").typeError("Campo obrigatório"),
  endereco: Yup.string().required("Campo obrigatório").typeError("Campo obrigatório"),
  numero: Yup.string().required("Campo obrigatório").typeError("Campo obrigatório"),
  bairro: Yup.string().required("Campo obrigatório").typeError("Campo obrigatório"),
  estado: Yup.string().required("Campo obrigatório").typeError("Campo obrigatório"),
  cidade: Yup.string().required("Campo obrigatório").typeError("Campo obrigatório"),
  cep: Yup.string().required("Campo obrigatório").typeError("Campo obrigatório"),
  cpf: Yup.string().nullable().typeError("Campo obrigatório"),
  complemento: Yup.string().nullable().typeError("Campo obrigatório"),
  dataNascimento: Yup.string().test(`test-minimum-date`, function (value) {
    const { path, createError } = this;
    return (
      new Date(value ?? "") >=  new Date('1753-01-01') ||
      createError({ path, message: "Data mínima permitida 01/01/1753" })
    );
  }).required("Campo obrigatório").typeError("Campo obrigatório"),
});

const InformeSeusDados: React.FC = () => {
  const theme = useTheme();
  const slideDirection = useRecoilValue(slideDirectionState);
  const history = useHistory();
  const [isLoaded, setIsLoaded] = useState(false);
  const [saving, setSaving] = useState(false);
  const [loadingCep, setLoadingCep] = useState(false);
  const [responsavel, setResponsavel] = useRecoilState(responsavelState);
  const [options, setOptions] = useState<{
    valor: string
    label: string
  }[]>([]);
  const [initialValues, setInitialValues] = useState({
    nome: '',
    cpf: '',
    grauParentesco: '',
    celular: '',
    telefoneFixo: '',
    email: '',
    dataNascimento: '',
    cep: '',
    estado: '',
    endereco: '',
    numero: '',
    bairro: '',
    cidade: '',
    complemento: '',
  });
  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values, formikHelpers): Promise<void> => {
      if (saving) return;
      setSaving(true);

      try {
        const resp = await fetch(`${env.apiUrl}api/responsavel/${responsavel?.id}/true`, {
          method: "PATCH",
          mode: "cors",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            ...values,
            cadastropendente: false,
            numero: String(values.numero),
          })
        })

        if (resp.ok) {
          const json = await resp.json();
          setResponsavel(json);
          history.push(PATH_ROUTE.DADOS_DO_ESTUDANTE);
        } else {
          toast.error('Erro ao atualizar dados!', {autoClose: 5000});
        }

      } catch (e) {
        console.error(e);
        toast.error('Erro ao atualizar dados!', {autoClose: 5000});
      } finally {
        setSaving(false);
      }
    }
  });
  useEffect(() => {
    const idResponsavel = localStorage.getItem("@SMO20/idResponsavel");

    fetch(env.apiUrl + "api/conjuntoopcao/get/contact/seb_grau_parentesco", {
      method: "GET",
      mode: "cors",
    }).then(res => res.json()).then(setOptions)

    fetch(env.apiUrl + "api/responsavel/get/" + idResponsavel, {
      method: "GET",
      mode: "cors",
    }).then(res => res.json())
      .then((result) => {
        if (!formValidacao.isValidData(result.dataNascimento)) {
          result.dataNascimento = null;
        }
        setInitialValues(result);
      }).finally(() => {
      setIsLoaded(true);
    })
  }, []);

  console.log("formik.values.dataNascimento", formik.values.dataNascimento)
  console.log("formik.isValid", formik.isValid)
  console.log("formik.errors", formik.errors)

  const handleCep = (e: React.ChangeEvent<HTMLInputElement>) => {
    formik.handleChange(e);

    if (e.target.value.length < 8) return;

    setLoadingCep(true);

    fetch(`${env.apiUrl}api/cep/get/${e.target.value.replace("-", "")}`, {
      method: "GET",
      mode: "cors",
    }).then(res => res.json())
      .then((result) => {
        formik.setValues({
          ...formik.values,
          estado: result.uf,
          cep: result.cep,
          cidade: result.localidade,
          bairro: result.bairro,
          endereco: result.logradouro,
        })
      }).finally(() => {
      setLoadingCep(false);
    })
  }

  return (
    <DashboardLayout backgroundColor={theme.palette.background.paper}>
      <Scrollbar>
        <FirstAccessSteps/>

        <Slide in direction={slideDirection}>
          <Card
            elevation={0}
          >
            <Container
              sx={{
                pb: 8,
              }}
            >
              <CardHeader
                title="Informe os dados do responsável contratual:"
                sx={{
                  position: 'relative',
                  "&:after": {
                    content: "''",
                    position: 'absolute',
                    zIndex: 0,
                    boxShadow: '0 0 40px rgb(0 0 0 / 34%)',
                    bottom: '0px',
                    width: '45%',
                    height: '100%',
                    borderRadius: '100%',
                    left: '10%',
                    right: '10%',
                    top: '-100%'
                  }
                }}
              />

              {!isLoaded && <Loading/>}

              {isLoaded && (
                <CardContent>
                  <form
                    onSubmit={formik.handleSubmit}
                  >
                    <Stack
                      direction={{md: 'row', sm: 'column'}}
                      sx={{
                        mb: 2,
                        gap: 2
                      }}
                      spacing={0}
                    >
                      <TextField
                        fullWidth
                        label="Nome completo*"
                        name="nome"
                        value={formik.values.nome}
                        disabled
                      />
                    </Stack>

                    <Stack
                      direction={{md: 'row', sm: 'column'}}
                      sx={{
                        mb: 2,
                        gap: 2
                      }}
                      spacing={0}
                    >
                      <TextField
                        fullWidth
                        label="CPF*"
                        name="cpf"
                        value={formik.values.cpf?.replace(/^(\d{3})(\d{3})(\d{3})(\d{2}).*/, '$1.$2.$3-$4')}
                        disabled
                      />

                      <TextField
                        fullWidth
                        label="Grau de parentesco com o(s) estudante(s)*"
                        name="grauParentesco"
                        select
                        error={!!(formik.touched.grauParentesco && formik.errors.grauParentesco)}
                        helperText={formik.touched.grauParentesco && formik.errors.grauParentesco}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.grauParentesco}
                        SelectProps={{
                          native: true,
                        }}
                      >
                        <option value="" disabled>Selecione</option>
                        {options?.map((option, i) => (
                          <option
                            key={option?.valor ?? i}
                            value={option?.valor ?? i}
                          >
                            {option?.label}
                          </option>
                        ))}
                      </TextField>
                    </Stack>

                    <Stack
                      direction={{md: 'row', sm: 'column'}}
                      sx={{
                        mb: 2,
                        gap: 2
                      }}
                      spacing={0}
                    >
                      <TextField
                        fullWidth
                        label="Celular*"
                        name="celular"
                        error={!!(formik.touched.celular && formik.errors.celular)}
                        helperText={formik.touched.celular && formik.errors.celular}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.celular?.replace(/^(\d{2})(\d{5})(\d{4}).*/, '($1) $2-$3')}
                      />

                      <TextField
                        fullWidth
                        label="Telefone fixo"
                        name="telefoneFixo"
                        placeholder="(Opcional)"
                        error={!!(formik.touched.telefoneFixo && formik.errors.telefoneFixo)}
                        helperText={formik.touched.telefoneFixo && formik.errors.telefoneFixo}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.telefoneFixo?.replace(/^(\d{2})(\d{5})(\d{4}).*/, '($1) $2-$3')}
                      />
                    </Stack>

                    <Stack
                      direction={{md: 'row', sm: 'column'}}
                      sx={{
                        mb: 2,
                        gap: 2
                      }}
                      spacing={0}
                    >
                      <TextField
                        fullWidth
                        label="E-mail*"
                        name="email"
                        type="email"
                        error={!!(formik.touched.email && formik.errors.email)}
                        helperText={formik.touched.email && formik.errors.email}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.email}
                      />

                      <TextField
                        fullWidth
                        label="Data de Nascimento*"
                        name="dataNascimento"
                        type="date"
                        error={!!(formik.touched.dataNascimento && formik.errors.dataNascimento) || getIdade(formik.values.dataNascimento) < 18 && formik.values.dataNascimento?.length > 0}
                        helperText={(getIdade(formik.values.dataNascimento) < 18 && formik.values.dataNascimento?.length > 0) ? 'Responsável Financeiro menor de 18 anos. Solicitamos que entre em contato com a secretaria da sua unidades para mais informações.' : formik.touched.dataNascimento && formik.errors.dataNascimento  ?  formik.errors.dataNascimento : null}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.dataNascimento ? new Date(formik.values.dataNascimento).toISOString().slice(0, 10) : ""}
                      />
                    </Stack>

                    <Stack
                      direction={{md: 'row', sm: 'column'}}
                      sx={{
                        mb: 2,
                        gap: 2
                      }}
                      spacing={0}
                    >
                      <TextField
                        fullWidth
                        label="CEP*"
                        name="cep"
                        error={!!(formik.touched.cep && formik.errors.cep)}
                        helperText={formik.touched.cep && formik.errors.cep}
                        onBlur={formik.handleBlur}
                        onChange={handleCep}
                        value={formik.values.cep?.replace(/^(\d{5})(\d{3}).*/, '$1-$2')}
                        InputProps={{
                          endAdornment: loadingCep ? <CircularProgress size={16}/> : null
                        }}
                      />

                      <TextField
                        fullWidth
                        label="Estado*"
                        name="estado"
                        select
                        error={!!(formik.touched.estado && formik.errors.estado)}
                        helperText={formik.touched.estado && formik.errors.estado}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.estado}
                        disabled
                      >
                        <MenuItem value="AC">Acre</MenuItem>
                        <MenuItem value="AL">Alagoas</MenuItem>
                        <MenuItem value="AP">Amapá</MenuItem>
                        <MenuItem value="AM">Amazonas</MenuItem>
                        <MenuItem value="BA">Bahia</MenuItem>
                        <MenuItem value="CE">Ceará</MenuItem>
                        <MenuItem value="DF">Distrito Federal</MenuItem>
                        <MenuItem value="ES">Espírito Santo</MenuItem>
                        <MenuItem value="GO">Goiás</MenuItem>
                        <MenuItem value="MA">Maranhão</MenuItem>
                        <MenuItem value="MT">Mato Grosso</MenuItem>
                        <MenuItem value="MS">Mato Grosso do Sul</MenuItem>
                        <MenuItem value="MG">Minas Gerais</MenuItem>
                        <MenuItem value="PA">Pará</MenuItem>
                        <MenuItem value="PB">Paraíba</MenuItem>
                        <MenuItem value="PR">Paraná</MenuItem>
                        <MenuItem value="PE">Pernambuco</MenuItem>
                        <MenuItem value="PI">Piauí</MenuItem>
                        <MenuItem value="RJ">Rio de Janeiro</MenuItem>
                        <MenuItem value="RN">Rio Grande do Norte</MenuItem>
                        <MenuItem value="RS">Rio Grande do Sul</MenuItem>
                        <MenuItem value="RO">Rondônia</MenuItem>
                        <MenuItem value="RR">Roraima</MenuItem>
                        <MenuItem value="SC">Santa Catarina</MenuItem>
                        <MenuItem value="SP">São Paulo</MenuItem>
                        <MenuItem value="SE">Sergipe</MenuItem>
                        <MenuItem value="TO">Tocantins</MenuItem>
                      </TextField>
                    </Stack>

                    <Stack
                      direction={{md: 'row', sm: 'column'}}
                      sx={{
                        mb: 2,
                        gap: 2
                      }}
                      spacing={0}
                    >
                      <TextField
                        fullWidth
                        label="Endereço*"
                        name="endereco"
                        error={!!(formik.touched.endereco && formik.errors.endereco)}
                        helperText={formik.touched.endereco && formik.errors.endereco}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.endereco}
                        disabled={loadingCep}
                      />
                    </Stack>

                    <Stack
                      direction={{md: 'row', sm: 'column'}}
                      sx={{
                        mb: 2,
                        gap: 2
                      }}
                      spacing={0}
                    >
                      <TextField
                        fullWidth
                        label="Número*"
                        name="numero"
                        type="number"
                        error={!!(formik.touched.numero && formik.errors.numero)}
                        helperText={formik.touched.numero && formik.errors.numero}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        onInput={onInputOnlyNumber}
                        value={formik.values.numero}
                        disabled={loadingCep}
                      />

                      <TextField
                        fullWidth
                        label="Complemento"
                        name="complemento"
                        placeholder="(Opcional)"
                        error={!!(formik.touched.complemento && formik.errors.complemento)}
                        helperText={formik.touched.complemento && formik.errors.complemento}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.complemento}
                        disabled={loadingCep}
                      />
                    </Stack>

                    <Stack
                      direction={{md: 'row', sm: 'column'}}
                      sx={{
                        mb: 2,
                        gap: 2
                      }}
                      spacing={0}
                    >
                      <TextField
                        fullWidth
                        label="Bairro*"
                        name="bairro"
                        error={!!(formik.touched.bairro && formik.errors.bairro)}
                        helperText={formik.touched.bairro && formik.errors.endereco}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.bairro}
                        disabled={loadingCep}
                      />

                      <TextField
                        fullWidth
                        label="Cidade*"
                        name="cidade"
                        error={!!(formik.touched.cidade && formik.errors.cidade)}
                        helperText={formik.touched.cidade && formik.errors.endereco}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.cidade}
                        disabled
                      />
                    </Stack>

                    <Box
                      sx={{
                        mt: 6
                      }}
                    >
                      <StepCheckoutActionButtons
                        previousStepPath={PATH_ROUTE.INICIO}
                        nextStepPath={PATH_ROUTE.DADOS_DO_ESTUDANTE}
                        nextStepText="Avançar"
                        onClickNextStep={() => {

                        }}
                        nextStepDisabled={saving}
                        nextStepType="submit"
                        loading={saving}
                      />
                    </Box>
                  </form>
                </CardContent>
              )}
            </Container>

          </Card>
        </Slide>
        <Ajuda/>
      </Scrollbar>
    </DashboardLayout>
  )
}

export default InformeSeusDados;
