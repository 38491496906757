import React, {useEffect} from 'react';
import './styles.css';
import Typography from "@mui/material/Typography";

function AccordionContracts(
  {
    id ,
    parentId = "accordionContracts",
    title = "",
    children,
    opened = false
  }
) {
  const [expanded, setExpanded] = React.useState(opened);

  useEffect(() => {
    if (opened && !expanded) setExpanded(true)
  }, [opened])

  return (
    <div className="card">
      <div>
        <Typography
          sx={{
            mb: 1,
            mt: 2
          }}
        >
          {title}
        </Typography>
      </div>

      <div>
        <div className="card-body">
          {children}
        </div>
      </div>
    </div>
  );
}

export default AccordionContracts;
