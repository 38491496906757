import React from 'react'
import { replace, omit } from 'lodash'
import { MTableCell } from 'material-table'

export default (props) => {
  const { value, ...others } = props

  if (props.tableref.current && props.tableref.current.dataManager && props.tableref.current.dataManager.searchText) {

    //TO-DO: Melhorar esse tratamento quando a pesquisa conter "."
    const searchRegex = props.tableref.current.dataManager.searchText.replace('.', '[.]')

    const regex = new RegExp(searchRegex, 'i')

    if (props.tableref.current.dataManager.searchText !== '' &&
      typeof value === 'string' && value.match(regex)) {
      const formatedValue = replace(value, regex,
        `<code style="font-size: 13px;
              padding: 4px 0px;
              border-radius: 9px;
              background-color: rgb(0, 128, 0, 0.2);
              font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;" >
              ${value.match(regex)} </code>`)
      others.columnDef = omit(others.columnDef, 'render')
      return <MTableCell {...omit(others, 'render')} > <div dangerouslySetInnerHTML={{ __html: formatedValue }} /> </MTableCell>
    }
  }
  return <MTableCell {...props} />
}
