import React, {useEffect, useState} from "react";
import {error, neutral} from "../../theme/colors";
import Typography from "@mui/material/Typography";
import {useTheme} from "@mui/material/styles";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import {CardActionArea} from "@mui/material";
import Dropzone, {FileRejection} from "react-dropzone";


const sliceFileNameWithExtension = (fileName: string): string => {
  if (fileName.length > 20) {
    return fileName.slice(0, 20) + '...' + fileName.slice(-4);
  }
  return fileName;
}

const UploadDoc: React.FC<{
  title?: string;
  onFileChange?: (file: File) => void
  name?: string
  error?: boolean
  helperText?: string
  onBlur?: (e: React.FocusEvent<any, Element>) => void
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  defaultFileName?: string
}> = (props) => {
  const [files, setFiles] = useState<File[]>([]);
  const [rejectedFiles, setRejectedFiles] = useState<FileRejection[]>([]);
  const theme = useTheme();

  return (
    <Card
      sx={{
        backgroundColor: rejectedFiles.length || props?.error ? error.alpha4 : neutral[50],
        width: '100%',
        height: '100%',
        maxHeight: 250,
        maxWidth: 250,
        [theme.breakpoints.down('sm')]: {
          maxWidth: '100%'
        },
        aspectRatio: 1,
        textAlign: 'center',
        color: neutral[500],
        border: files.length > 0 || props.defaultFileName || rejectedFiles.length || props?.error ? 3 : 0,
        borderColor: rejectedFiles.length > 0 || props?.error ? theme.palette.error.main : theme.palette.primary.main
      }}
      elevation={0}
    >
      <Dropzone
        onDrop={(accepted, rejected) => {
          setFiles(accepted);
          setRejectedFiles(rejected);
          if (accepted.length > 0 && props.onFileChange) {
            props.onFileChange(accepted[0]);
          }
        }}
        accept='image/jpeg, image/png, application/pdf'
        minSize={0}
        maxSize={10485760}
        maxFiles={1}
      >
        {({getRootProps, getInputProps}) => {
          const {onChange, ...inputProps} = getInputProps();
          return (
            <CardActionArea
              {...getRootProps({className: 'dropzone'})}
              sx={{
                width: '100%',
                height: '100%',
                pt: 2,
                pb: 2,
                pl: 4,
                pr: 4,
                '&:focus': {
                  border: 'none',
                  outline: 'none'
                }
              }}
            >
              <input
                {...inputProps}
                name={props.name}
                onBlur={props.onBlur}
                onChange={(e) => {
                  if (props.onChange) {
                    props.onChange(e);
                  }
                  if (onChange) {
                    onChange(e);
                  }
                }}
              />
              {(files.length > 0 || rejectedFiles.length > 0 || props?.error || props.defaultFileName) ? (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    flexDirection: 'column',
                    gap: 2,
                    height: '100%'
                  }}
                >
                  <Stack
                    spacing={3}
                    justifyContent='center'
                    alignItems='center'
                    sx={{
                      mt: 3
                    }}
                  >
                    {(rejectedFiles.length > 0 || props?.error) ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width={64}
                        height={64}
                        fill={theme.palette.error.main}
                      >
                        <path
                          d="M8.27,3L3,8.27V15.73L8.27,21H15.73C17.5,19.24 21,15.73 21,15.73V8.27L15.73,3M9.1,5H14.9L19,9.1V14.9L14.9,19H9.1L5,14.9V9.1M9.12,7.71L7.71,9.12L10.59,12L7.71,14.88L9.12,16.29L12,13.41L14.88,16.29L16.29,14.88L13.41,12L16.29,9.12L14.88,7.71L12,10.59"/>
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width={64}
                        height={64}
                        fill={theme.palette.primary.main}
                      >
                        <path
                          d="M12 2C6.5 2 2 6.5 2 12S6.5 22 12 22 22 17.5 22 12 17.5 2 12 2M12 20C7.59 20 4 16.41 4 12S7.59 4 12 4 20 7.59 20 12 16.41 20 12 20M16.59 7.58L10 14.17L7.41 11.59L6 13L10 17L18 9L16.59 7.58Z"/>
                      </svg>
                    )}

                    <Typography
                      variant="caption"
                      color={(rejectedFiles.length > 0 || props?.error) ? 'error' : 'inherit'}
                    >
                      {rejectedFiles.length > 0 &&
                        <>
                          Máximo 1 arquivo de até 10MB<br/>
                          Fomatos aceitos: .jpg, .png, .pdf
                        </>
                      }
                      {(props.defaultFileName && !files.length && !rejectedFiles.length) &&
                        <>
                          {sliceFileNameWithExtension(props.defaultFileName)}
                        </>
                      }
                      {props?.error && props.helperText}
                      {rejectedFiles.length == 0 && files.map((file, i) =>
                        <React.Fragment key={i}>
                          {sliceFileNameWithExtension(file.name)}<br/>
                        </React.Fragment>
                      )}
                    </Typography>
                  </Stack>

                  <Typography
                    variant="caption"
                    color="primary"
                    sx={{
                      textDecoration: 'underline',
                      mb: 3
                    }}
                  >
                    Alterar arquivo
                  </Typography>
                </Box>
              ) : (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    flexDirection: 'column',
                    gap: 2,
                    height: '100%'
                  }}
                >
                  <Typography
                    aria-hidden={!props?.title}
                    sx={{
                      opacity: props?.title ? 1 : 0
                    }}
                  >
                    {props?.title}
                  </Typography>

                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      flexDirection: 'column',
                      gap: 1
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        gap: 1
                      }}
                    >
                      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M9.33327 23.9996C7.66964 23.9996 6.07415 23.3675 4.89779 22.2423C3.72142 21.117 3.06055 19.5909 3.06055 17.9996C3.06055 16.4083 3.72142 14.8822 4.89779 13.757C6.07415 12.6318 7.66964 11.9996 9.33327 11.9996C9.72619 10.2492 10.8756 8.71092 12.5287 7.72323C13.3473 7.23417 14.2648 6.89501 15.229 6.72509C16.1931 6.55518 17.1851 6.55785 18.1481 6.73294C19.1111 6.90804 20.0264 7.25213 20.8417 7.74558C21.6569 8.23903 22.3562 8.87217 22.8996 9.60885C23.443 10.3455 23.8199 11.1713 24.0087 12.0391C24.1975 12.9068 24.1945 13.7996 23.9999 14.6663H25.3333C26.5709 14.6663 27.7579 15.1579 28.6331 16.0331C29.5083 16.9083 29.9999 18.0953 29.9999 19.3329C29.9999 20.5706 29.5083 21.7576 28.6331 22.6328C27.7579 23.5079 26.5709 23.9996 25.3333 23.9996H23.9999"
                          stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M12 20L16 16L20 20" stroke="#424E66" strokeWidth="2" strokeLinecap="round"
                              strokeLinejoin="round"/>
                        <path d="M16 16V28" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                              strokeLinejoin="round"/>
                      </svg>

                      <Typography
                        variant="caption"
                      >
                        Selecione um arquivo ou solte-o aqui...
                      </Typography>
                    </Box>

                    <Typography
                      variant="caption"
                      color="primary"
                      sx={{
                        textDecoration: 'underline',
                        mb: 3
                      }}
                    >
                      Selecionar arquivo
                    </Typography>
                  </Box>
                </Box>
              )}
            </CardActionArea>
          )
        }}
      </Dropzone>
    </Card>
  )
}

export default UploadDoc;
