import env from "../env";
import fetch from "../utils/fetch";
import {matriculasByResponsavelResponseState} from "../recoil/atoms/matriculasByResponsavelResponseState";

export function GetbyIdResponsavel(idResponsavel) {
  return fetch(env.apiUrl + "api/matricula/ListAllByResponsavel/" + idResponsavel, {
    method: "GET",
    mode: "cors",
  })
    .then((res) => {
        return res.json();
      }
    )
    .then(
      (result) => {
        return result;
      },
      (error) => {

        console.log(error);
      }
    )
    .catch((error) => {

      console.log(error.message);
    });
}

export function GetbyMatriculasByResponsavel(idResponsavel, validaFicha) {
  return fetch("/api/matricula/ListAllByIdResponsavel/" + idResponsavel + "/" + validaFicha, {
    method: "GET",
    mode: "cors",
    atom: matriculasByResponsavelResponseState
  })
    .then((res) => {
      return res.json();
    })
    .catch((error) => {
      console.log(error.message);
    });
}

export function ListByIdEstudante(
  idEstudante,
  ano,
  tipoItensServico,
  tiposMatricula
) {
  const request = {
    IdEstudante: idEstudante,
    Ano: ano,
    TiposItemServico: tipoItensServico,
    TiposMatricula: tiposMatricula,
  };

  return fetch(env.apiUrl + "api/matricula/ListByIdEstudante/", {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(request),
  })
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log(error);
      }
    )
    .catch((error) => {
      console.log(error.message);
    });
}

export function ListAllByResponsavelTipo(idResponsavel, tipoMatricula) {
  return fetch(
    env.apiUrl + "api/Matricula/ListAllByResponsavelTipo/" +
    idResponsavel +
    "/" +
    tipoMatricula,
    {
      method: "GET",
      mode: "cors",
    }
  )
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log(error);
      }
    )
    .catch((error) => {
      console.log(error.message);
    });
}

export function GetById(idMatricula) {
  return fetch(env.apiUrl + "api/matricula/" + idMatricula, {
    method: "GET",
    mode: "cors",
  })
    .then((res) => {
        return res.json();
      }
    )
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log(error);
      }
    )
    .catch((error) => {
      console.log(error.message);
    });
}


export function GetFinanceiroByMatricula(idMatricula) {
  return fetch("/api/ManutencaoFinanceira/Transacoes/" + idMatricula, {
    method: "GET",
    mode: "cors",
  })
    .then((res) => {
        return res.json();
      }
    )
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log(error);
      }
    )
    .catch((error) => {
      console.log(error.message);
    });
}

export function VerificaVagaDosServicos(idMatricula, idServicoUnidade, tipodeServico) {
  var url = env.apiUrl + `api/matricula/VerificaVagaDosServicos/${idMatricula}/${idServicoUnidade}/${tipodeServico}`;
  return fetch(url, {
    method: "GET",
    mode: "cors",
  })
    .then((res) => {
      return res.json();
    })
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log(error);
      }
    )
    .catch((error) => {
      console.log(error.message);
    });
}

