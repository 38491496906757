
export function DataFormatada(dateString) {

    if (dateString == null || dateString == undefined) {
        return null;
    }

    if (dateString.substring(0, 10) == '0001-01-01') {
        return null;
    }
    var data = dateString.substring(0, 10) + " 00:00:00";

    var result = new Date(data).toLocaleDateString('pt-BR');

    return result;
}

export function Idade(dateString) {

    if (dateString == null || dateString == undefined) {
        return null;
    }

    var dataNascimento = dateString.substring(0, 10) + " 00:00:00";

    let today = new Date();

    let birthDate = new Date(dataNascimento);

    let age = today.getFullYear() - birthDate.getFullYear();
    let m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }

    return age;
}

export function MenorIdade(dateString) {

    var idade = Idade(dateString);

    return (idade < 18)
}

export function CepFormatado(cep) {

    var regex = /\d+/g;
    var result = "";
    var cepNum = ""

    if (cep != null && cep != undefined) {

        var returnRegex = cep.toString().match(regex);

        if (returnRegex != null && returnRegex != undefined) {
            cepNum = returnRegex.join('');
        }
    }

    if (cepNum.length > 5) {
        result = cepNum.substring(0, 5) + "-" + cepNum.substring(5, cepNum.length);
    } else {
        result = cepNum;
    }

    return result;
}

export function SomenteNumero(str) {

    var regex = /\d+/g;
    var strNum = ""

    if (str != null && str != undefined) {

        var returnRegex = str.toString().match(regex);

        if (returnRegex != null && returnRegex != undefined) {
            strNum = returnRegex.join('');
        }
    }

    return strNum;
}

export function CpfCnpjFormatado(str) {
    
    var regex = /\d+/g;
    var result = "";

    var returnRegex = str.toString().match(regex);

    if (returnRegex != null && returnRegex != undefined) {
        var strNum = returnRegex.join('');

        if (strNum.length >= 12) {

            result = strNum                           //Remove tudo o que não é dígito
            result = result.replace(/^(\d{2})(\d)/, "$1.$2")             //Coloca ponto entre o segundo e o terceiro dígitos
            result = result.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3") //Coloca ponto entre o quinto e o sexto dígitos
            result = result.replace(/\.(\d{3})(\d)/, ".$1/$2")           //Coloca uma barra entre o oitavo e o nono dígitos
            result = result.replace(/(\d{4})(\d)/, "$1-$2")              //Coloca um hífen depois do bloco de quatro dígitos
            
        } else {
            result = strNum                    //Remove tudo o que não é dígito
            result = result.replace(/(\d{3})(\d)/, "$1.$2")       //Coloca um ponto entre o terceiro e o quarto dígitos
            result = result.replace(/(\d{3})(\d)/, "$1.$2")       //Coloca um ponto entre o terceiro e o quarto dígitos
            //de novo (para o segundo bloco de números)
            result = result.replace(/(\d{3})(\d{1,2})$/, "$1-$2") //Coloca um hífen entre o terceiro e o quarto dígitos
            
        }
    }

    return result
    
}

export function RetornaMascaraCartao(str) {
    var result = "9999 9999 9999 9999";

    if (str != null && str != undefined && str.length > 1) {
        if (str.substring(0, 2) == "34" || str.substring(0, 2) == "37") {
            result = "9999 9999 9999 999"
        }
    }

    return result
}

export function RetornaParImpar(num) {
    
    var total = num / 2;
    var result = total % 2 == 0 ? 'Par' : 'Impar';

    return result
}

export function ValidaBanco(numBanco) {
    var result = false;

    switch (numBanco) {
        case "237":
            result = true
            break;
        case "001":
            result = true
            break;
        case "033":
            result = true
            break;
        case "104":
            result = true
            break;
        case "341":
            result = true
            break;
    }

    return result
}

export function ExisteDigitoAgencia(numBanco) {
    var result = false;

    switch (numBanco) {
        case "237":
            result = true
            break;
        case "001":
            result = false
            break;
        case "033":
            result = false
            break;
        case "104":
            result = false
            break;
        case "341":
            result = false
            break;    
    }

    return result
}

export function QntDigitoAgencia(numBanco) {
    var result = 0;

    switch (numBanco) {
        case "237":
            result = 4
            break;
        case "001":
            result = 4
            break;
        case "033":
            result = 4
            break;
        case "104":
            result = 4
            break;
        case "341":
            result = 4
            break;
    }

    return result
}


export function QntDigitoConta(numBanco) {
    var result = 0;

    switch (numBanco) {
        case "237":
            result = 7
            break;
        case "001":
            result = 14
            break;
        case "033":
            result = 13
            break;
        case "104":
            result = 8
            break;
        case "341":
            result = 8
            break;
    }

    return result
}

export function DownloadArquivoPDF(base64, fileName) {

    if (base64 != undefined && base64 != null) {
        var link = document.createElement('a');
        link.href = 'data:application/pdf;base64,' + base64;
        link.download = fileName + '_' + Date.now() + '.pdf';
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(link.href);
        document.body.removeChild(link);
    }
}

export function ValidaBoleto(boleto) {
    var result = true;

    if (boleto == null || boleto == undefined) {
        result = false;
    }

    if (boleto.boleto == null || boleto.boleto == undefined) {
        result = false;
    }

    return result;
}

export function RetornaMsgBoleto(boleto) {
    var result = "";

    if (boleto != null && boleto != undefined) {

        if (boleto.msgBoletoRegistrado != null && boleto.msgBoletoRegistrado != undefined && boleto.msgBoletoRegistrado != "") {
            result = boleto.msgBoletoRegistrado;
        }        
    }

    return result;
}

export function createGuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

export function MesPorExtenso(index) {
    var mesNome = new Array("janeiro", "fevereiro", "março", "abril", "Maio", "junho", "agosto", "outubro", "novembro", "dezembro")

    return mesNome[index]
}

export function FormatarMoeda(valor) {
    
    var result = valor

    valor = valor + '';
    valor = parseInt(valor.replace(/[\D]+/g, ''));
    valor = valor + '';
    valor = valor.replace(/([0-9]{2})$/g, ",$1");

    if (valor.length > 6) {
        valor = valor.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");
    }

    result = valor;
    if (valor == 'NaN') result = '';

    return result;
}

export function FormatarMoedaToLocale(valor, sifra = false) {
    return valor.toLocaleString('pt-br', { 
        minimumFractionDigits: 2, 
        maximumFractionDigits: 2,
        style: sifra ? 'currency' : undefined,
        currency: sifra ? 'BRL' : undefined,
    });
}

export function FormatarPorcentagem(fraction) {
    return fraction.toLocaleString('pt-br', { style: "percent", minimumFractionDigits: 2, maximumFractionDigits: 2 })
}

export function Truncar(valor) {
    valor = valor.toString(); 
    valor = valor.slice(0, (valor.indexOf(".")) + 3);
    return Number(valor); 
}
