import { apiRequestActionType, apiSuccessActionType, apiErrorActionType } from '../../../utils/API/action-types'

export const HOME_REGISTRATION = 'HOME_REGISTRATION'
export const HOME_REGISTRATION_REQUEST = apiRequestActionType(HOME_REGISTRATION)
export const HOME_REGISTRATION_SUCCESS = apiSuccessActionType(HOME_REGISTRATION)
export const HOME_REGISTRATION_ERROR = apiErrorActionType(HOME_REGISTRATION)

export const USER_MESSAGE = 'USER_MESSAGE'
export const REQUEST_USER_MESSAGE = apiRequestActionType(USER_MESSAGE)
export const SUCCESS_USER_MESSAGE = apiSuccessActionType(USER_MESSAGE)
export const ERROR_USER_MESSAGE = apiErrorActionType(USER_MESSAGE)

export const HOME_SERVICES_REGISTRATION = 'HOME_SERVICES_REGISTRATION'
export const HOME_SERVICES_REQUEST = apiRequestActionType(HOME_SERVICES_REGISTRATION)
export const HOME_SERVICES_SUCCESS = apiSuccessActionType(HOME_SERVICES_REGISTRATION)
export const HOME_SERVICES_ERROR = apiErrorActionType(HOME_SERVICES_REGISTRATION)