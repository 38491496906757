import { selector } from "recoil";
import parcelaRegularSelecionadaSelector from "./carrinho/servicoRegular/parcelaRegularSelecionadaSelector";
import parcelasObrigatoriosSelecionadasSelector from "./carrinho/servicosObrigatorios/parcelasObrigatoriosSelecionadasSelector";
import parcelasUpSellingSelecionadasSelector from "./carrinho/servicosUpSelling/parcelasUpSellingSelecionadasSelector";
import possuiRecorrenteSelector from "./possuiRecorrenteSelector";

const possuiEntradaSelector = selector({
  key: 'possuiEntradaSelector',
  get: ({get}) => {
    const parcelaRegularSelecionada = get(parcelaRegularSelecionadaSelector);
    const parcelasObrigatoriosSelecionadas = get(parcelasObrigatoriosSelecionadasSelector);
    const parcelasUpSellingSelecionadas = get(parcelasUpSellingSelecionadasSelector);
    const possuiRecorrente = get(possuiRecorrenteSelector);

    const obrigatorioPossuiEntrada = Object.values(parcelasObrigatoriosSelecionadas).some(parcela => parcela?.entradas && parcela?.entradas?.length > 0);
    const upSellingPossuiEntrada = Object.values(parcelasUpSellingSelecionadas).some(parcela => parcela?.entradas && parcela?.entradas?.length > 0);

    return (parcelaRegularSelecionada?.entradas && parcelaRegularSelecionada?.entradas?.length > 0) || obrigatorioPossuiEntrada || upSellingPossuiEntrada || !possuiRecorrente;
  },
});

export default possuiEntradaSelector

