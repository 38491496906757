import {makeStyles} from "@mui/styles";

export default makeStyles((theme) => ({
  paymentKnowMore: {
    marginTop: 5,
    display: "inline-block",
    width: "auto",
    cursor: "pointer",
    textDecoration: "underline",
    fontWeight: "bold",
    color: theme.palette.primary.main,
    '&:hover': {
      fontWeight: '500'
    }
  },
}));
