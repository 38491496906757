import React from 'react'

import Paper from "@mui/material/Paper";
import {Grid} from "@mui/material";
import Typography from "@mui/material/Typography";
import {useTheme} from "@mui/material/styles";
import {ErrorOutline, WarningOutlined, CheckCircleOutline} from "@mui/icons-material";

export const BannerType = {
  Error: '1',
  Warning: '2',
  Success: '3'
}

export const BannerTypeData = {
  [BannerType.Error]: {
    name: 'Erro',
    color: '#ef5350',
    icon: ErrorOutline
  },
  [BannerType.Success]: {
    name: 'Sucesso',
    color: '#66bb6A',
    icon: CheckCircleOutline
  },
  [BannerType.Warning]: {
    name: 'Atenção',
    color: '#ffbb55',
    icon: WarningOutlined
  }
}

export default (props) => {
  const theme = useTheme()
  const {messagingType: type, urlClick, openInNewTab, description} = props
  const {icon: Icon, color} = BannerTypeData[type] || BannerTypeData[1]

  const onClick = ev => !urlClick && ev.preventDefault()

  return (
    <Paper
      data-tut='tour-notification'
      onClick={onClick}
      component='a'
      href={urlClick}
      sx={{
        maxHeight: 230,
        padding: '15px 0',
        textDecoration: 'none',
        cursor: urlClick ? 'pointer' : 'auto',
        display: 'block',
        backgroundColor: color,
      }}
      target={openInNewTab ? '_blank' : '_self'}
    >
      <Grid container>
        <Grid
          item
          xs={12}
          sm={2}
          sx={{
            textAlign: 'center',
            justifyContent: 'center',
            fontSize: 48,
            color: 'white',
            display: 'flex',
            flexDirection: 'row',
            alignContent: 'center',
            alignItems: 'center',
          }}
        >
          {Icon && <Icon fontSize={'large'}/>}
        </Grid>
        <Grid
          item
          xs={12}
          sm={10}
          sx={{
            padding: '10px 20px',
            color: 'white',
            display: 'flex',
            flexDirection: 'row',
            alignContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography
            component='div'
            sx={{
              [theme.breakpoints.down('xs')]: {
                flexGrow: 1,
                textAlign: 'center'
              },
            }}
          >
            {description}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  )
}
