import React from 'react';
import EmailIcon from "../Icons/EmailIcon";
import PhoneIcon from "../Icons/PhoneIcon";
import WhatsAppIcon from "../Icons/WhatsAppIcon";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import {useRecoilValue} from "recoil";
import contactInfoSelector from "../../recoil/selectors/contactInfoSelector";

const icons = {
  email: EmailIcon,
  telefone: PhoneIcon,
  whatsapp: WhatsAppIcon
}

function ContactInfoCard({color = "inherit"}) {
  const contactInfo = useRecoilValue(contactInfoSelector);
  const [selectedContactInfo, setSelectedContactInfo] = React.useState(null);

  React.useEffect(() => {
    if (selectedContactInfo === null && contactInfo) {
      setSelectedContactInfo(contactInfo.telefone);
    }
  }, [selectedContactInfo, contactInfo]);

  if (contactInfo === null) return null

  return (
    <Stack
      direction="column"
      sx={{
        textAlign: "center",
        transition: "all 0.3s",
        mb: 2
      }}
      data-tut="tur-menu-footer"
    >
      {contactInfo ? (
        <Box
          sx={{
            pt: 1
          }}
        >
          {Object.keys(contactInfo).map((key) => {
            const item = contactInfo[key];
            const size = selectedContactInfo && selectedContactInfo.href === item.href ? 35 : 30;
            const Icon = icons[key];

            if (!Icon) return null;

            return (
              <Link
                key={key}
                href={item.href}
                color={color}
                onMouseEnter={e => {
                  if (selectedContactInfo === item) return;
                  e.preventDefault();
                  setSelectedContactInfo(item)
                }}
              >
                <Icon
                  width={size}
                  height={size}
                />
              </Link>
            )
          })}
          <br/>
        </Box>
      ): (
        <CircularProgress/>
      )}
      {selectedContactInfo && (
        <Link
          href={selectedContactInfo.href}
          className="pt-1"
          color={color}
        >
          {selectedContactInfo.label}
        </Link>
      )}
    </Stack>
  );
}

export default ContactInfoCard;
