import fetch from "../utils/fetch";
import env from "../env";
import {getRecoil} from "recoil-nexus";
import {selectedMatriculaState} from "../recoil/atoms/selectedMatriculaState";
import {responsavelState} from "../recoil/atoms/responsavelState";
import checkoutRequestSelector from "../recoil/selectors/checkoutRequestSelector";
import {toast} from "react-toastify";
import isPreReservaSemValorSelector from "../recoil/selectors/isPreReservaSemValorSelector";

export function ConcluiMatricula(listPagamentoRequest, matriculaId, unidadeId, responsavelId) {

    return fetch(env.apiUrl+"api/CheckOut/ConcluiMatricula/" + matriculaId + "/" + unidadeId + "/" + responsavelId, {
        method: "POST",
        mode: "cors",
        headers: {
            "Content-Type": "application/json",
            'Accept': 'application/json'
        },
        body: JSON.stringify(listPagamentoRequest)
    }).then(res => res.json())
        .then((result) => {
            return result;
        },
            (error) => {
                return error;
            }
        ).catch(error => {
            return error;
            console.log(error.message);
        });
}

export async function concluiMatricula() {
  const matricula = getRecoil(selectedMatriculaState);
  const responsavel = getRecoil(responsavelState);
  const isPreReservaSemValor = getRecoil(isPreReservaSemValorSelector);

  if (isPreReservaSemValor) {
    return fetch(env.apiUrl+"api/CheckOut/FinalizarReservaSemValor", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        'Accept': 'application/json'
      },
      body: JSON.stringify({
        HtmlContrato: ".",
        MatriculaId: matricula?.id,
      })
    }).then(async res => {
      const json = await res.json()
      if (res.ok && !json.messages.length) {
        return json
      } else {
        if (!json.messages[0]?.text.includes("<html>")) {
          toast.error(json.messages[0]?.text)
        }
        throw new Error(json.messages[0]?.text)
      }
    })
  }

  const body = getRecoil(checkoutRequestSelector);

  return fetch(env.apiUrl+"api/CheckOut/ConcluiMatricula/" + matricula?.id + "/" + matricula?.unidade_Id + "/" + responsavel?.id, {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      'Accept': 'application/json'
    },
    body: JSON.stringify(body)
  }).then(async res => {
    const json = await res.json()
    if (res.ok) {
      return json
    } else {
      if (!json.messages[0]?.text.includes("<html>")) {
        toast.error(json.messages[0]?.text)
      }
      throw new Error(json.messages[0]?.text)
    }
  })
}

export function FinalizarReservaSemValor(request) {

    return fetch(env.apiUrl+"api/CheckOut/FinalizarReservaSemValor", {
        method: "POST",
        mode: "cors",
        headers: {
            "Content-Type": "application/json",
            'Accept': 'application/json'
        },
        body: JSON.stringify(request)
    }).then(res => res.json())
        .then((result) => {
            return result;
        },
            (error) => {
                return error;
            }
        ).catch(error => {
            return error;
            console.log(error.message);
        });
}

export function VerificaCadastro(idMatricula, idResponsavel) {
  var url = env.apiUrl+`api/checkout/VerificaCadastro/matricula/${idMatricula}/responsavel/${idResponsavel}`;
  return fetch(url, {
    method: "GET",
    mode: "cors",
  }).then(res => res.text())
}


