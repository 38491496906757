import React from 'react';
import {PortalFinanceiroLayout} from "../../layouts/PortalFinanceiroLayout";
import PaymentTypes from "../../modules/Finance/PaymentTypes";

function HomePortal(props) {
  return (
    <PortalFinanceiroLayout>
      <PaymentTypes
        {...props}
      />
    </PortalFinanceiroLayout>
  );
}

export default HomePortal;
