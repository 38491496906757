import {selector} from "recoil";
import {IServicoUpSelling} from "../../../../@types/IServicoDto";
import {calcListServicesState} from "../../../atoms/calcListServicesState";
import {selectedMatriculasState} from "../../../atoms/selectedMatriculasState";

const servicosUpSellingMultipleSelector = selector({
  key: 'ServicosUpSellingMultipleSelector',
  get: ({get}) => {
    const servicos = get(calcListServicesState);
    const selectedMatriculas = get(selectedMatriculasState);

    return selectedMatriculas.map(id => {
      const servico = servicos.find(s => s.matriculaId === id);
      return (servico?.servicosUpSelling || []).map(servicoUpSelling => ({
        ...servicoUpSelling,
        matriculaId: id,
      })) as Array<IServicoUpSelling>;
    }).flat();
  },
});

export default servicosUpSellingMultipleSelector;
