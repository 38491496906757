import { pipe, prop, isNil } from "ramda";
import { find, isEqual } from "lodash";
import {
  getAPIDataSelector,
  hasErrorAPISelector,
  hasLoadedAPISelector,
  isLoadingAPISelector,
} from "../../../utils/API/selectors";
import { createSelector } from "reselect";
import { tourActiveSelector } from "../../../layouts/selectors/tour";
import {
  idMessageNotificationSelector,
  idMessagePopUpSelector,
} from "../../Home/selectors/userMessage";
import Cookies from "universal-cookie/cjs";

const baseLoginSelector = prop("login");

export const userSelector = pipe(baseLoginSelector, prop("user"));

export const userDataSelector = getAPIDataSelector(userSelector);

export const hasLoadedUserSelector = hasLoadedAPISelector(userSelector);

export const hasErrorUserSelector = hasErrorAPISelector(userSelector);

export const isLoadingUserSelector = isLoadingAPISelector(userSelector);

export const userAppConfigsSelector = pipe(userSelector, prop("appconfigs"));

export const userConfigsSelector = pipe(userSelector, prop("config"));

export const userSelectedSelector = pipe(userSelector, prop("selected"));

export const adminFlagSelector = pipe(userSelector, prop("adminFlag"));

export const tokenUserSelector = pipe(userSelector, prop("token"));

export const popUpByUserSelectedSelector = pipe(
  userConfigsSelector,
  prop("popup")
);

export const NotificationByUserSelectedSelector = pipe(
  userConfigsSelector,
  prop("notification")
);

export const popUpEnabledSelector = pipe(
  popUpByUserSelectedSelector,
  prop("enable")
);

export const notificationClientsDisabledSelector = pipe(
  popUpByUserSelectedSelector,
  prop("clientsDisable")
);

export const appIdSelector = pipe(userSelectedSelector, prop("appid"));

export const appNameSelector = pipe(userSelectedSelector, prop("appname"));

export const accessSelector = pipe(userSelectedSelector, prop("access"));

export const userAppUsername = pipe(userSelectedSelector, prop("appusername"));

export const userAcessIdSelector = pipe(userSelectedSelector, prop("accessid"));

export const userUnitiesSelector = pipe(userSelectedSelector, prop("unity_id"));

export const userCpfCnpjSelector = pipe(userSelectedSelector, prop("cpfcnpj"));

export const userEmailSelector = pipe(userSelectedSelector, prop("email"));

export const isTourEnabledSelector = createSelector(
  tourActiveSelector,
  (tourActive) => {
    return tourActive;
  }
);

export const isPopUpEnabledSelector = createSelector(
  notificationClientsDisabledSelector,
  idMessagePopUpSelector,
  (notificationByUser, idMessageNotification) => {
    if (isNil(notificationByUser)) {
      return true;
    }
    return (
      notificationByUser &&
      !find(notificationByUser, (id) => isEqual(id, idMessageNotification))
    );
  }
);

export const isNotificationEnabledSelector = createSelector(
  NotificationByUserSelectedSelector,
  idMessageNotificationSelector,
  (notificationByUser, idMessageNotification) => {
    if (isNil(notificationByUser)) {
      return true;
    }
    return (
      idMessageNotification &&
      !find(notificationByUser, (id) => isEqual(id, idMessageNotification))
    );
  }
);

export const permissionsSelector = createSelector(
  userSelectedSelector,
  (selectedUser) =>
    selectedUser && selectedUser.permissions ? selectedUser.permissions : []
);

export const accessIdSelector = createSelector(accessSelector, (access) =>
  access ? access.accessid : undefined
);

export const cpfCnpjSelector = createSelector(accessSelector, (access) =>
  access ? access.cpfcnpj : undefined
);

export const accessIdSelectedSelector = createSelector(
  accessSelector,
  userAcessIdSelector,
  (access, accessid) => (access ? access.accessid : accessid)
);
