import {atom} from "recoil";
import {persistAtomEffect} from "../effects/persistAtom";

export const startBotState = atom({
  key: 'startBotState',
  default: false,
  effects: [
    persistAtomEffect
  ]
});
