import fetch from "../utils/fetch";
import env from "../env";
import IUnidadeResponse from "../@types/IUnidadeResponse";

export async function getUnidade(idUnidade: string): Promise<IUnidadeResponse> {
    return fetch(env.apiUrl+"api/unidade/get/" + idUnidade, {
        method: "GET",
        mode: "cors",
    }).then(res => res.json())
}
