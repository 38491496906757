import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FormattedHTMLMessage, FormattedMessage, injectIntl } from "react-intl";
import logo from "../../../../../assets/img/logo-grupo-seb.svg";
import useStyle from "./style";
import { companyInformationDataSelector } from "../../../../Negotiation/Finance/selectors/companyInformations";
import { getCompanyInformations } from "../../../../Negotiation/Finance/services/company";
import { userSelectedSelector, appIdSelector } from "../../../../Login/selectors/user";
import moment from "moment";
import {Checkbox, DialogContentText, DialogTitle, FormControlLabel, Grid} from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";

export default injectIntl(
  ({
    setDisabled,
    selectedMatricula,
    newPaymentType,
    setAcceptanceTermObj,
  }) => {

    const dispatch = useDispatch();
    const appId = useSelector(appIdSelector);

    const classes = useStyle();
    const [accept, setAccept] = useState(false);
    const [acceptCompay, setAcceptCompany] = useState(true);
    const companyInformations = useSelector(companyInformationDataSelector);
    if (acceptCompay)
    {
      dispatch(getCompanyInformations(appId, selectedMatricula?.unitycode));
      setAcceptCompany(false);
    }

    const userSelected = useSelector(userSelectedSelector);

    const selectedService =
      selectedMatricula.services.length > 0
        ? selectedMatricula.services[selectedMatricula.services.length - 1]
            .productname
        : selectedMatricula.unity.name;

    const acceptanceTermObj = {
      RazaoSocialUnidade: companyInformations.companyName,
      CNPJUnidade: companyInformations.cnpj,
      EnderecoUnidade: companyInformations.address,
      SiteUnidade: companyInformations.urlZenDesk,
      NomeAluno: selectedMatricula.studentname,
      Matricula: selectedMatricula.registrationid,
      TipoPagamento: newPaymentType,
      DescricaoCurso: selectedService,
      ResponsavelPessoaFisica: userSelected.appusername,
      SegmentoAluno: selectedMatricula.segment.description,
      AnoLetivo: selectedMatricula.schoolyear,
      CidadeEscola: companyInformations.city,
      DataMatricula: moment(new Date()).format("DD/MM/YYYY"),
      NomeResponsavelContrato: userSelected.appusername,
      AssinaturaResponsaveisContratual: userSelected.appusername,
    };

    const acceptTerm = () => {
      if (accept) {
        setAccept(false);
        setDisabled(false);
      } else {
        setAccept(true);
        setDisabled(true);

        setAcceptanceTermObj(acceptanceTermObj);
      }
    };

    return (
      <>
        <Paper
          className={classes.termAgreementRoot}
          id="term-agreement-payment-type"
          elevation={2}
        >
          <DialogTitle
            id="alert-dialog-title"
            classes={{
              root: classes.termAgreementDialogTitle,
            }}
          >
            <Grid container>
              <Grid item xs={12} md={3} align="center">
                <img
                  alt="logo"
                  src={logo}
                  id="btn-menu-logo-foot"
                  className={classes.termAgreementLogo}
                />
              </Grid>
              <Grid item xs={12} md={9} className={classes.termAgreementTitle}>
                <FormattedMessage id="term-agreement-payment-type-title" />
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <FormattedHTMLMessage
                id={"term-agreement-payment-type"}
                values={acceptanceTermObj}
              />
              <Typography
                variant="caption"
                className={classes.termAgreementFinal}
              ></Typography>
            </DialogContentText>
          </DialogContent>
        </Paper>
        <FormControlLabel
          control={
            <Checkbox
              checked={accept}
              onChange={acceptTerm}
              value="accept"
              color="primary"
              inputProps={{
                "aria-label": "secondary checkbox",
              }}
            />
          }
          label={<FormattedMessage id="term-agreement-check" />}
        />
      </>
    );
  }
);
