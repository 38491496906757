import TagManager from 'react-gtm-module'
import {findLast} from 'lodash'
import {getTitle} from '../../routes'
import {gtmConfig} from "../../config";


export const GTM_EVENT_PAGE_VIEW = 'PAGE_VIEW'
export const GTM_EVENT_PURCHASE = 'purchase'

export const initializeGtm = () => {
  if (process.env.NODE_ENV === 'production') {
    TagManager.initialize({
      gtmId: gtmConfig.containerId,
      dataLayer: window.dataLayer || [],
      events: {
        purchase: GTM_EVENT_PURCHASE,
        pageView: GTM_EVENT_PAGE_VIEW
      }
    })
  }
}

export const logPageView = () => {
  if (process.env.NODE_ENV === 'production') {
    const lastPageView = findLast(window.dataLayer, dataLayer => dataLayer.event === GTM_EVENT_PAGE_VIEW)
    if (lastPageView) {
      if (lastPageView.pagePath === window.location.pathname) {
        return
      }
    }
    const selecao = localStorage.getItem("selecaoServicos");
    let decoded = {};

    if (selecao) {
      try {
        decoded = JSON.parse(selecao);
      } catch (e) {
      }
    }

    const title = getTitle()
    sendEventDataLayer({
      event: GTM_EVENT_PAGE_VIEW,
      pagePath: window.location.pathname,
      title,
      currency: "BRL",
      value: decoded?.carrinho?.servicoPrincipal?.valor,
      items: decoded
    })
  }
}

export const logPurchase = () => {
  if (process.env.NODE_ENV === 'production') {
    const title = getTitle()

    sendEventDataLayer({
      event: GTM_EVENT_PURCHASE,
      pagePath: window.location.pathname,
      title,
      currency: "BRL",
    })
  }
}

export const sendEventDataLayer = (event) => {
  if (process.env.NODE_ENV === 'production') {
    TagManager.dataLayer({dataLayer: event})
  }
}
