import React, {forwardRef} from 'react'
import MaterialTable from 'material-table'

import {useTheme} from "@mui/styles";
import Card from "@mui/material/Card";
import {Check, ChevronLeft, ChevronRight, Clear, DeleteOutline, Edit, FilterList, AddBox, ArrowUpward, ViewColumn, Search, SaveAlt, Remove, LastPage, FirstPage} from "@mui/icons-material";

export const defaultIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} style={{ margin: 0 }} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
}

export const defaultOptions = {
  search: true,
  paging: false,
  sorting: true,
  draggable: false,
  emptyRowsWhenPaging: false,
  actionsColumnIndex: -1,
}

const Table = ({ options, columns, components, localization, ...rest }) => {
  const [pageSize, setPageSize] = React.useState(5)

  const theme = useTheme()

  return <MaterialTable
    onChangeRowsPerPage={setPageSize}
    icons={defaultIcons}
    options={{
      pageSize: pageSize,
      ...defaultOptions,
      ...options,
      headerStyle: {
        color: theme.palette.primary.main,
        fontSize: 15,
        paddingRight: 5,
        lineHeight: '1rem',
        textAlign: 'center',
        backgroundColor: '#f0f0f1',
        ...options.headerStyle,
      }
    }}
    components={{
      Container: (props) => <Card {...props} />,
      ...components,
    }}
    localization={{
      pagination: {
        labelDisplayedRows: '{from}-{to} de {count}',
        firstTooltip: 'Primeira',
        nextTooltip: 'Próxima',
        lastTooltip: 'Última',
        previousTooltip: 'Anterior',
        labelRowsPerPage: 'Itens',
        labelRowsSelect: 'itens'
      },
      toolbar: {
        nRowsSelected: '{0} parcelas',
        searchPlaceholder: 'Buscar'
      },
      header: {
        actions: 'Ações'
      },
      body: {
        emptyDataSourceMessage: 'Nenhum item encontrado',
        filterRow: {
          filterTooltip: 'Filtrar'
        },
      },
      ...localization
    }}
    columns={columns.map(col => ({
      ...col,
      cellStyle: {
        fontSize: 13,
        paddingRight: 5,
        textAlign: 'center',
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
        ...col.cellStyle
      }
    }))}
    {...rest}
  />
}

export default Table
