import React, {useState} from 'react'
import {currency} from '../../../../utils/utils'
import Grid from '@mui/material/Grid'
import Payment from 'payment'
import {isEqual, omit, trim} from 'lodash'
import {Field, Form, withFormik} from 'formik'
import * as yup from 'yup'
import {
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  Link,
  Typography,
  useMediaQuery
} from '@mui/material'
import 'react-credit-cards/es/styles-compiled.css'
import CustomRadio from '../../../../components/CustomRadio'
import Installments from '../Installments'
import {useSelector} from 'react-redux'
import {PermissionFilter} from '../../../../components/PermissionsFilter'
import {DEBIT_FUNCTION} from '../../../../utils/permissions'
import {FormattedMessage} from 'react-intl'
import WalletModal from '../../../../components/WalletModal/WalletModal'
import Stack from '@mui/material/Stack'
import {useTheme} from '@mui/styles'

const CARD_TYPE = {
  CREDIT: 'credit',
  DEBIT: 'debit'
}

const MyFormWithFormik = withFormik({
  mapPropsToValues: props => ({
    cardName: props.payment.cardName,
    expiryDateMonth: props.payment.expiryDateMonth,
    expiryDateYear: props.payment.expiryDateYear,
    installment: props.payment.installment
  }),
  validationSchema: yup.object().shape({
    cardName: yup
      .string()
      .trim()
      .required('Obrigatório')
      .matches(/^[a-zA-Z ]+$/, 'Nome deve conter apenas letras'),
    expiryDateMonth: yup.string().required('Obrigatório'),
    expiryDateYear: yup.string().required('Obrigatório')
  }),
  handleSubmit: (values, props) => {
    const { expiryDateMonth, expiryDateYear, cardNumber } = values
    if (Payment.fns.validateCardExpiry(expiryDateMonth, expiryDateYear)) {
      props.props.setPayment({
        ...props.props.payment,
        ...values
      })
      props.props.handleNext({
        ...props.props.payment,
        ...values
      })
    } else {
      props.setErrors({ ...props.errors, expiryDateMonth: 'Mês inválido' })
      props.setSubmitting(false)
    }
  }
})

export default MyFormWithFormik(props => {
  const theme = useTheme()

  const isLoading = useSelector(props.isLoadingSelector)
  const { cardType, total, isNegotiation } = props.payment
  const { installment } = props.values
  const hasInstallment = props.payment.installment !== undefined
  const totalFormatted = currency(total)
  const [selectedCard, setSelectedCard] = React.useState(null)
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'))

  const [accept, setAccept] = useState(isNegotiation ? false : true)
  const acceptTerm = () => {
    setAccept(!accept)
    props.setDisabled(accept)
  }

  const openTermAgreement = () => {
    props.openTermAgreement()
  }

  const onFocus = event => {
    if (event.target.value && !trim(event.target.value)) {
      event.target.setSelectionRange(0, 0)
    }
  }

  const handleChange = event => {
    props.setPayment({ ...props.payment, cardType: event.target.value })
  }

  return (
    <Form onSubmit={values => props.handleSubmit(values, props)}>
      <Stack
        direction='column'
        justifyContent='center'
        alignItems='center'
        spacing={2}
      >
        <WalletModal
          onSelectCard={card => {
            setSelectedCard(card)
            props.setValues({
              ...props.values,
              cardName: card.cardholder_name,
              cardId: card.card_id,
              numberToken: card.number_token,
              expiryDateMonth: card.expiration_month,
              expiryDateYear: card.expiration_year,
              bin: card.bin
            })
          }}
          selectedCard={selectedCard?.card_id}
        />
        {hasInstallment && isEqual(cardType, CARD_TYPE.CREDIT) && (
          <Installments
            installment={installment}
            {...omit(props.payment, 'installment')}
          />
        )}
        {!isNegotiation && (
          <KindCard cardType={cardType} handleChange={handleChange} />
        )}
      </Stack>

      <Divider />

      {isNegotiation && (
        <>
          <FormControlLabel
            control={
              <Checkbox
                checked={accept}
                onChange={acceptTerm}
                value='accept'
                color='primary'
                disabled={isLoading}
                inputProps={{
                  'aria-label': 'secondary checkbox'
                }}
              />
            }
          />
          <Typography
            variant='body1'
            sx={{
              display: 'inline-block',
              cursor: 'default'
            }}
          >
            Li e concordo com o{' '}
            <Link sx={{ cursor: 'pointer' }} onClick={openTermAgreement}>
              {' '}
              Termo de Acordo{' '}
            </Link>{' '}
            estabelecido.
          </Typography>
        </>
      )}

      <Grid item xs={12} align='right'>
        <Typography
          sx={{
            display: 'inline',
            fontWeight: 'bolder',
            marginRight: '10px'
          }}
        >
          {`Valor total corrigido: ${totalFormatted}`}
        </Typography>
        <Button
          variant='contained'
          color='primary'
          disabled={isLoading || !accept || !selectedCard?.card_id}
          id='btn-mdl-pag-cfm-rev'
          sx={{
            marginLeft: theme.spacing(1),
            minWidth: 157,
            maxWidth: 300,
            height: 36
          }}
          type='submit'
        >
          {isLoading ? (
            <CircularProgress size={24} sx={{ width: 40, height: 40 }} />
          ) : isNegotiation ? (
            'Finalizar Acordo'
          ) : (
            'Avançar'
          )}
        </Button>
      </Grid>
    </Form>
  )
})

const KindCard = ({ cardType, handleChange }) => {
  return (
    <PermissionFilter list={[DEBIT_FUNCTION]}>
      <Grid container justifyContent='center'>
        <Grid
          item
          xs={6}
          sx={{ display: 'flex', justifyContent: 'center' }}
          md={3}
        >
          <Field
            name='credit'
            variant='outlined'
            checked={isEqual(cardType, CARD_TYPE.CREDIT)}
            color='primary'
            label='Crédito'
            id='ckc-mdl-pag-cred'
            value={CARD_TYPE.CREDIT}
            onClick={handleChange}
            component={CustomRadio}
          />
        </Grid>
        <Grid
          item
          sm
          xs={6}
          sx={{ display: 'flex', justifyContent: 'center' }}
          md={3}
        >
          <Field
            name='debit'
            variant='outlined'
            checked={isEqual(cardType, CARD_TYPE.DEBIT)}
            id='ckc-mdl-pag-deb'
            value={CARD_TYPE.DEBIT}
            color='primary'
            label='Débito'
            onClick={handleChange}
            component={CustomRadio}
          />
        </Grid>
        {isEqual(cardType, CARD_TYPE.DEBIT) && (
          <Grid item xs={12}>
            <FormattedMessage id='payment-form-card-warning' />
            <ul style={{ margin: 0, listStyle: 'decimal', marginBottom: 15 }}>
              <li>
                <FormattedMessage id='payment-form-card-warning-text1' />
              </li>
              <li>
                <FormattedMessage id='payment-form-card-warning-text2' />
              </li>
              <li>
                <FormattedMessage id='payment-form-card-warning-text3' />
              </li>
            </ul>
          </Grid>
        )}
      </Grid>
    </PermissionFilter>
  )
}
