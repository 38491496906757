import React from 'react';
import {Layout} from "../dashboard";
import {Box} from '@mui/material';
import FinancialSideBar from '../../components/FinancialNavigation';
import {Scrollbar} from "../../components/scrollbar";

export const PortalFinanceiroLayout = ({children}) => {
  return (
    <Layout>
      <Box
        display="flex"
        sx={{
          flexDirection: 'row',
        }}
      >
        <FinancialSideBar/>
        <Box
          sx={{
            overflowY: 'auto',
            width: '100%',
            maxHeight: 'calc(100vh - 80px)',
          }}
        >
          <Scrollbar>
            {children}
          </Scrollbar>
        </Box>
      </Box>
    </Layout>
  );
}
