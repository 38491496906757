import fetch from "../utils/fetch";
import env from "../env";

export function listarBancos(idUnidade) {

    return fetch(env.apiUrl+"api/Pagamento/ListarBancos/" + idUnidade, {
        method: "GET",
        mode: "cors",
    })
        .then(res => res.json())
        .then(
            (result) => {
                return result;
            },
            (error) => {
                console.log(error);
                return false;
            }
        )
        .catch(error => {
            console.log(error.message);
            return false;
        });
}
