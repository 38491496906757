import { apiGet, apiPost } from "../../../../utils/API/actions";
import {
  PAYMENT_TYPE_SELECTED,
  PAYMENT_TYPE_SIMPLE_MODE,
  PAYMENT_TYPE_AUTOMATIC_DEBIT,
  PAYMENT_TYPE_RECURRENCE_PAYMENT,
  INFORMATION_RECURRENT_CARD,
} from "../constants";

export const getPaymentTypeSelected = (
  appId,
  matriculaId,
  dataAreaId,
  responsibleid
) =>
  apiGet({
    entity: PAYMENT_TYPE_SELECTED,
    url: `/financial/${appId}/payment-mode/${matriculaId}/${dataAreaId}/${responsibleid}`,
    isChainable: true,
  });

export const changeToSimpleMode = (request) =>
  apiPost({
    entity: PAYMENT_TYPE_SIMPLE_MODE,
    url: "/financial/payment-simple-mode",
    data: request,
    isChainable: true,
  });

export const changeToAutomaticDebit = (request) =>
  apiPost({
    entity: PAYMENT_TYPE_AUTOMATIC_DEBIT,
    url: "/financial/payment-automatic-debit",
    data: request,
    isChainable: true,
  });

export const changeToRecurrencePayment = (request) =>
  apiPost({
    entity: PAYMENT_TYPE_RECURRENCE_PAYMENT,
    url: `/financial/payment-recurrent`,
    data: request,
    isChainable: true,
  });

export const getInformationRecurrentCard = (
  matriculaId,
  dataAreaId,
  responsibleId,
  unityId
) =>
  apiGet({
    entity: INFORMATION_RECURRENT_CARD,
    url: `/financial/information-recurrent-card/${matriculaId}/${dataAreaId}/${responsibleId}/${unityId}`,
  });
