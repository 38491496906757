import React from 'react'
import classNames from 'classnames'
import Payment from 'payment'
import {Form, withFormik} from 'formik'
import * as yup from 'yup'
import 'react-credit-cards/es/styles-compiled.css'
import useStyles from './style'
import Button from "@mui/material/Button";
import useMediaQuery from "@mui/material/useMediaQuery";
import WalletModal from "../../../../../../../components/WalletModal/WalletModal";
import Stack from "@mui/material/Stack";

const MyFormWithFormik = withFormik({
  mapPropsToValues: (props) => ({
    cardName: props.payment.cardName,
    cardId: props.payment.cardId,
    bin: props.payment.bin,
    numberToken: props.payment.numberToken,
    expiryDateMonth: props.payment.expiryDateMonth,
    expiryDateYear: props.payment.expiryDateYear,
    installment: props.payment.installment,
  }),
  validationSchema: yup.object().shape({
    cardName: yup
      .string()
      .trim()
      .required('Obrigatório')
      .matches(/^[a-zA-Z ]+$/, 'Nome deve conter apenas letras'),
    expiryDateMonth: yup.string().required('Obrigatório'),
    expiryDateYear: yup.string().required('Obrigatório'),
  }),
  handleSubmit: (values, props) => {
    const {expiryDateMonth, expiryDateYear, cardNumber} = values

    if (Payment.fns.validateCardExpiry(expiryDateMonth, expiryDateYear)) {
      props.props.setPayment({
        ...props.props.payment,
        ...values,
      })

      props.props.handleNext({
        ...props.props.payment,
        ...values,
      })
    } else {
      props.setErrors({...props.errors, expiryDateMonth: 'Mês inválido'})
      props.setSubmitting(false)
    }
  },
})

export default MyFormWithFormik((props) => {
  const {
    handleBack
  } = props
  const classes = useStyles()
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'))
  const [selectedCard, setSelectedCard] = React.useState(null);

  return (
    <React.Fragment>
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{
          width: '100%',
          height: '100%',
        }}
      >
        <Form onSubmit={(values) => props.handleSubmit(values, props)}>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={4}
          >
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx={{
                mt: 2,
                width: '100%',
                height: '100%',
              }}
            >
              <WalletModal
                onSelectCard={card => {
                  setSelectedCard(card)
                  props.setValues({
                    ...props.values,
                    cardId: card.card_id,
                    cardName: card.cardholder_name,
                    expiryDateMonth: card.expiration_month,
                    expiryDateYear: card.expiration_year,
                    bin: card.bin,
                    numberToken: card.number_token,
                  })
                }}
                selectedCard={selectedCard?.card_id}
              />
            </Stack>

            <Stack
              direction="row"
              justifyContent="space-between"
              className={classNames(isSmall && classes.buttonsSmall)}
              sx={{
                width: '100%',
              }}
            >
              <Button
                id='btn-mdl-pag-cfm-rev'
                className={classes.button}
                type='button'
                onClick={handleBack}
              >
                Voltar
              </Button>
              <Button
                variant='contained'
                color='primary'
                id='btn-mdl-pag-cfm-rev'
                className={classes.button}
                type='submit'
              >
                Avançar
              </Button>
            </Stack>
          </Stack>
        </Form>
      </Stack>
    </React.Fragment>
  )
})
