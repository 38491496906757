import React, {Suspense, useEffect} from 'react';
import App from './modules/App'
import {datadogRum} from '@datadog/browser-rum';
import packageJson from '../package.json';
import configurePaymentBrands from './utils/configurePaymentBrands'
import 'utils/polyfills'
import PageLoading from "./components/PageLoading/PageLoading";
import {createRoot} from "react-dom/client";
import 'simplebar-react/dist/simplebar.min.css';
import "nprogress/nprogress.css";
import * as Yup from 'yup';
import {pt} from 'yup-locale-pt';

Yup.setLocale(pt);

if (process.env.REACT_APP_ENABLE_DD === "ENABLE_DD") {
  datadogRum.init({
    clientToken: 'puba3a2fb64983ef30260a03fea8e234325',
    applicationId: '01adcfe5-1695-4fce-a0df-e29a2132dab7',
    site: 'datadoghq.com',
    service: 'app-smo-front-prod',
    env: 'prod',
    version: packageJson.version || undefined,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    sampleRate: 100,
    premiumSampleRate: 100,
    trackInteractions: true,
    trackFrustrations: true,
    defaultPrivacyLevel: 'mask-user-input',
    allowedTracingOrigins: ["*"]
  })
  datadogRum.startSessionReplayRecording()
}

function IndexApp() {
  useEffect(() => {
    window.appVersion = packageJson.version;
  }, []);
  return (
    <Suspense fallback={<PageLoading/>}>
      <App/>
    </Suspense>
  )
}

configurePaymentBrands();

const rootElement = document.getElementById('root');

const root = createRoot(rootElement); // createRoot(container!) if you use TypeScript
root.render(<IndexApp/>);
